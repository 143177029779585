import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Define a type for the slice state

export const ScriptType = {
  USER: 'USER',
  TEAM: 'TEAM',
};

export type ThresholdType = {
  concurrentUsers: number,
  expectedMeanResponseTime: number,
  maximumTimeDuration: number,
  rampUpTime: number,
  expectedTestSuccessPercentile: number,
};

export type TestCaseInput = {
  projectId: string,
  testcaseId: string,
  type: string,
  name: string,
  accountId: string,
  script: string,
  testData?: string,
  thresholds: ThresholdType | undefined,
  bddStatus?: string,
  automationScriptStatus?: string
};

export type TestCaseGenerateInput = {
  accountId?: string,
  projectId?: string | undefined,
  moduleName?: string | undefined,
  noOfTestcases: string,
  requirement: string,
  projectName?: string | undefined,
  thresholds?: ThresholdType | undefined,
  existingModuleId?: string | undefined,
  requirementDescription: string,
  useCaseOrUserStory?: string | undefined,
  acceptanceCriteria: string,
  requirementClassification: string,
};

export type UpdatedTestCase = {
  projectId: string;
  testcaseId: string;
  name?: string;
  type?: string;
  accountId: string;
  script?: string;
  testData?: string;
  createdAt?: string;
  thresholds?: string;
  estimates?: string;
};

export type TestCase = {
  projectId: string;
  testcaseId: string;
  name: string;
  type: string;
  accountId: string;
  script: string;
  testData?: string;
  createdAt: string;
  thresholds?: string;
  estimates?: string;
};

interface TestCaseDataState {
  testCases: Array<TestCase>;
  selectedTestCase?: TestCase;
}

// Define the initial state using that type
const initialState: TestCaseDataState = {
  testCases: [],
  selectedTestCase: undefined,
};

export const testCaseDataSlice = createSlice({
  name: 'testCaseData',
  initialState,
  reducers: {
    setTestCases: (state, action: PayloadAction<Array<TestCase>>) => {
      state.testCases = action.payload;
    },
    setSelectedTestCase: (state, action: PayloadAction<TestCase | undefined>) => {
      state.selectedTestCase = action.payload;
    },
  },
});

export const {
  setTestCases, setSelectedTestCase,
} = testCaseDataSlice.actions;

export default testCaseDataSlice.reducer;
