import React, { useState } from 'react';
import {
  Collapse, Typography,
} from 'antd';
import { ChromeExtensionPurple } from '../../components';
import Figure1 from '../../styles/assets/onboard_figure1.png';
import Figure2 from '../../styles/assets/onboard_figure2.png';
import Figure3 from '../../styles/assets/onboard_figure3.png';
import Figure4 from '../../styles/assets/onboard_figure4.png';
import Figure5 from '../../styles/assets/onboard_figure5.png';
import Figure6 from '../../styles/assets/onboard_figure6.png';
import CalendlySchedule from './components/CalendlySchedule';
import { ROUTES } from '../../constants';
import Loading from '../../components/Loading';
import InfoCardWithImage from './components/InfoCardWithImage';

const { Title } = Typography;
const { Panel } = Collapse;

const OnboardingGuide = () => {
  const [recordJourneyLoading, setRecordJourneyLoading] = useState<boolean>(true);
  const [executeJourneyLoading, setExecuteJourneyLoading] = useState<boolean>(true);

  const handleImageLoad = () => {
    setRecordJourneyLoading(false);
  };

  return (
    <div className="fp_onboard_page_container">
      <div>
        <Title level={2}>Getting started</Title>
      </div>
      <hr style={{ marginBottom: 30 }} />
      <p>Everything you need to set up footPrint.</p>
      <div className="fp_onboard_page_container_collapse">
        <Collapse expandIconPosition="end">
          <Panel header="Add data-test-ID to your code" key="1">
            <ul style={{ textAlign: 'left' }}>
              <li>
                Add data-test-ID to your code to improve the resilience
                of your automation scripts.
              </li>
              <li>
                Go to our
                <a href={ROUTES.ID_GENERATOR}> ID generator </a>
                section for a step by step guide on how to add data-test-ID to
                your code. Should you find that we don&apos;t currently support your front-end
                language,
                please raise a support ticket. This will help us prioritize which languages
                to support next based on your needs.
              </li>
            </ul>
          </Panel>
        </Collapse>
        <Collapse expandIconPosition="end">
          <Panel header="Download footPrint extension from Chrome webstore" key="2">
            <div style={{ textAlign: 'left' }}>
              <a
                href="https://chromewebstore.google.com/detail/footprint/ejneiaadimaofmidbaegbfljmnbkaeim"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Link to FootPrint extension on Chrome Web Store"
              >
                <ChromeExtensionPurple />
              </a>
            </div>
          </Panel>
        </Collapse>
        <Collapse expandIconPosition="end">
          <Panel header="Record the user journey using the footPrint extension" key="3">
            {recordJourneyLoading && <Loading plain size="large" />}
            <InfoCardWithImage
              text="Visit the site where you want to automate a user journey.
              Click on the footprint extension in your Chrome browser and start recording."
              imageSrc={Figure1}
              width={323}
              height={237}
              alt="figure1"
              onLoad={handleImageLoad}
              loading={recordJourneyLoading}
            />
            <InfoCardWithImage
              text="During recording, you can add validations for text, numeric values, and elements."
              imageSrc={Figure2}
              width={485}
              height={210}
              alt="figure2"
              onLoad={handleImageLoad}
              loading={recordJourneyLoading}
            />
            <InfoCardWithImage
              text="If you add validations, we&apos;ll prompt you to confirm
              each one to ensure accuracy."
              imageSrc={Figure3}
              width={485}
              height={193}
              alt="figure3"
              onLoad={handleImageLoad}
              loading={recordJourneyLoading}
            />
            <InfoCardWithImage
              text="Stop recording whenever you&apos;re ready. We&apos;ll then convert your actions into
              a Playwright script, which you can execute and validate directly on
              our footPrint platform."
              imageSrc={Figure4}
              width={350}
              height={382}
              alt="figure4"
              onLoad={handleImageLoad}
              loading={recordJourneyLoading}
            />
          </Panel>
        </Collapse>
        <Collapse expandIconPosition="end">
          <Panel header="Execute the generated scripts form extension in the footPrint platform" key="4">
            {executeJourneyLoading && <Loading plain size="large" />}
            <InfoCardWithImage
              text="Paste the script you previously generated with the footPrint extension here."
              imageSrc={Figure5}
              width={550}
              alt="figure5"
              onLoad={() => setExecuteJourneyLoading(false)}
              loading={executeJourneyLoading}
            />
            <InfoCardWithImage
              text="Then, choose the browser you want to use for
              execution/validation and click &apos;Run&apos;"
              imageSrc={Figure6}
              width={485}
              alt="figure6"
              onLoad={() => setExecuteJourneyLoading(false)}
              loading={executeJourneyLoading}
            />
          </Panel>
        </Collapse>
        <Collapse expandIconPosition="end">
          <Panel header="Schedule a onboard call" key="5">
            <p style={{ textAlign: 'left' }}>
              Our Co-Founder, PD will personally guide you in setting
              up your workspace and turn you into a power user.
            </p>
            <CalendlySchedule />
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default OnboardingGuide;
