import React, { useState } from 'react';
import {
  Row, Col, Typography, Form, Button, Input,
} from 'antd';
import { EditIcon, DPIcon } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateUserName } from '../actions';
import { notifyError, notifySuccess } from '../../../utils/notification';
import { setUser } from '../../../redux/userSlice';

const { Title, Text } = Typography;

const UserDetailsCard = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [formDisable, setFormDisable] = useState<boolean>(true);
  const { fName, lName } = useAppSelector((state) => state.userData.userData);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { email } = useAppSelector((state) => state.userData);

  const handleButtonClick = () => {
    // TODO: Implement the profile pic upload functionality
  };

  const handleSubmit = async () => {
    const { firstName, lastName } = form.getFieldsValue();
    try {
      setLoading(true);
      await updateUserName(firstName, lastName);
      const user = {
        fName: firstName,
        lName: lastName,
      };
      dispatch(setUser(user));
      notifySuccess('Name changed successfully!');
    } catch (error) {
      notifyError('Failed to change name!');
    } finally {
      setLoading(false);
      setFormDisable(true);
    }
  };

  const handleDiscard = () => {
    form.resetFields();
    setFormDisable(true);
  };

  return (
    <Row className="profile-details-card">
      <Col span={4}>
        <div className="profile-picture-uploader">
          <DPIcon className="user-image" />
          <Button className="text-btn" onClick={handleButtonClick}>Edit</Button>
        </div>
      </Col>
      <Col span={20}>
        <Row className="user-details">
          <Col>
            <Title level={5}>{`${fName} ${lName}`}</Title>
            <Text type="secondary">{email}</Text>
          </Col>
        </Row>
        <Form
          form={form}
          initialValues={{
            firstName: fName,
            lastName: lName,
          }}
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <Row className="user-form">
            <Col span={11} className="col-form-item">
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{
                  required: true,
                  message: 'Please input your First Name!',
                }]}
              >
                <Input value={form.getFieldValue('firstName')} disabled={formDisable} />
              </Form.Item>
            </Col>
            <Col span={11} className="col-form-item">
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[{
                  required: true,
                  message: 'Please input your Last Name!',
                }]}
              >
                <Input value={form.getFieldValue('lastName')} disabled={formDisable} />
              </Form.Item>
            </Col>
          </Row>
          {formDisable
            ? (
              <Row className="edit-btn-con">
                <Button type="primary" shape="round" icon={<EditIcon />} onClick={() => setFormDisable(false)}>
                  Edit
                </Button>
              </Row>
            )
            : (
              <Row className="edit-btn-con">
                <Button type="primary" shape="round" onClick={handleDiscard}>
                  Discard
                </Button>
                <Button className="fp_btn-primary save" type="primary" shape="round" htmlType="submit" loading={isLoading}>
                  Save
                </Button>
              </Row>
            )}
        </Form>
      </Col>
    </Row>
  );
};

export default UserDetailsCard;
