import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Row, Badge, Tooltip,
} from 'antd';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Service, setSelectedService } from '../../redux/serviceSlice';
import CardWrapper from './CardWrapper';
import {
  GenerateHomeIcon, ManualHomeIcon, RecordHomeIcon,
} from '../../components';
import { getServicesList } from '../main/actions';
import Loading from '../../components/Loading';

const Home = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const servicesData: Array<Service> = useAppSelector(({ serviceData }) => serviceData.serviceList);
  const selectedAccount = useAppSelector(({ userData }: any) => userData.selectedAccount);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hoveredService, setHoveredService] = useState<Service>();

  const goToPaymentPlan = (path: string, id: any) => {
    dispatch(setSelectedService(id));
    history.push(path);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount?.accountId) {
        setLoading(true);
        await getServicesList(selectedAccount?.accountId, dispatch);
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedAccount?.accountId, dispatch]);

  const sortedPaymentPlans = [...servicesData]
    .sort((a, b) => Number(a.sortIndex) - Number(b.sortIndex));

  const modifiedSortedPaymentPlans: Service[] = sortedPaymentPlans.map((paymentPlan) => {
    switch (paymentPlan.sortIndex) {
      case '1':
        return { ...paymentPlan, icon: <RecordHomeIcon className="icon" /> };
      case '2':
        return { ...paymentPlan, icon: <ManualHomeIcon className="icon" /> };
      case '3':
        return { ...paymentPlan, icon: <GenerateHomeIcon className="icon" /> };
      default:
        return paymentPlan;
    }
  });

  return (
    <div className="home_container">
      <div className="fp_cmpnt fp_cmpnt_cntr">
        <p className="home_sub_tittle">How would you like to automate user journeys for functional testing?</p>
      </div>
      <div>
        <Row className="row_main">
          {!modifiedSortedPaymentPlans || isLoading ? <Loading plain size="large" /> : modifiedSortedPaymentPlans.map((item) => (
            <div key={item.sortIndex}>
              {item.isDisabled ? (
                <Badge.Ribbon text="Coming soon" color="rgba(255, 0, 35, 0.49)">
                  <CardWrapper
                    item={item}
                    view={() => goToPaymentPlan(item.path, item.serviceId)}
                    icon={item.icon}
                    setHoveredService={setHoveredService}
                  />
                </Badge.Ribbon>
              ) : (
                <CardWrapper
                  item={item}
                  view={() => goToPaymentPlan(item.path, item.serviceId)}
                  icon={item.icon}
                  setHoveredService={setHoveredService}
                />
              )}
            </div>
          ))}
        </Row>
      </div>
      {hoveredService && (
      <div>
        <Tooltip open={!!hoveredService} showArrow={false} overlayClassName="home_tooltip" color="rgba(255, 255, 255, 0.10)" placement="bottom" title={hoveredService?.shortDescription}>
          <p> </p>
        </Tooltip>
      </div>
      )}
    </div>
  );
};

export default Home;
