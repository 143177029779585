import { PAYMENT_PLANS } from '../constants';

export const paymentPlan = (selectedBilledPlan: any) => {
  switch (selectedBilledPlan?.planName) {
    case PAYMENT_PLANS.PROFESSIONAL:
      return 'pro';
    case PAYMENT_PLANS.ENTERPRISE:
      return 'entp';
    default:
      return 'free';
  }
};
