import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import {
  Button, Form, Row, notification,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Loading from '../../../../components/Loading';
import {
  cancelExecution,
  generateScript, GetTestCaseResultView, RunTestCasePayload,
} from '../../action';
import { EXECUTION_RESULT, EXTENSION_INSTRUCTION_MODAL_STATUS, TESTCASE_CREATING_STATUS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { notifyError, notifyInProgress, notifySuccess } from '../../../../utils/notification';
import { getServicesList, getUserAccounts } from '../../../main/actions';
import {
  AUTOMATION_SCRIPT_TYPE, DataType, SCRIPT_STATUS, SCRIPT_TYPE, INPUT_TYPE, ENTRY_TYPE, SERVICE,
} from '../../../../types/common';
import {
  addTestCase, deleteTestCase, editTestCase, runTestCase,
} from '../../../testcases/action';
import RequirementViewHeader from './components/RequirementViewHeader';
import { paymentPlan } from '../../../../utils/paymentPlanUtils';
import RootModal, { ScriptType } from './modals';
import { formateTestDataToArray } from '../../../../utils/commonUtils';
import TableView from './components/TableView';

interface propTypes {
  moduleId: string
  requirementId: string
}

const CreateNewtestcaseView = ({
  moduleId,
  requirementId,
}: propTypes) => {
  const dispatch = useAppDispatch();
  const history: any = useHistory();
  const selectedAccount = useAppSelector(({ userData }) => userData.selectedAccount);
  const selectedBilledPlan: any = useAppSelector(
    ({ billedPlanData }) => billedPlanData.selectedBilledPlan,
  );
  const userId = useAppSelector(({ userData }) => userData.userId);
  const [form] = Form.useForm();
  const autoRefreshIntervalRef = useRef<any>(null);

  const [isChecked, setIsChecked] = useState(false);
  const [summeryData, setSummeryData] = useState<any | Array<string>>([]);
  const [tableData, setTableData] = useState<Array<string>>([]);
  const [rowData, setRowData] = useState<DataType[]>([]);
  const [indexValue, setIndexValue] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [isTableDataLoading, setTableDataLoading] = useState(true);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [isFreshGenerate, setFreshGenerate] = useState<boolean>(false);
  const [isTestCaseModal, setTestCaseModal] = useState<boolean>(false);
  const [isExtensionInstructionsModal, setExtensionInstructionsModal] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [isTestDataModal, setTestDataModal] = useState<boolean>(false);
  const [isDeleteConfirmModel, setDeleteConfirmModel] = useState<boolean>(false);
  const [selectedTestCase, setSelectedTestCase] = useState<DataType>();
  const [isIdeModal, setIdeModal] = useState<boolean>(false);
  const [autoRefresh, setAutoRefresh] = useState<string[]>([]);
  const [selectedScript, setSelectedScript] = useState<string>();
  const [selectedTestcases, setSelectedTestcases] = useState<any[]>([]);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  // Filter testcases based on conditions and return the updated autoRefresh array
  const handleTestCaseAutoRefresh = (testcases: any[]): string[] => testcases
    .reduce((updatedAutoRefresh: string[], testcase: any) => {
      const isGenerating = testcase.bddStatus === SCRIPT_STATUS.GENERATING
        || testcase.automationScriptStatus === SCRIPT_STATUS.GENERATING;
      const isComplete = (testcase.bddStatus && testcase.bddStatus === SCRIPT_STATUS.COMPLETE)
        || (testcase.automationScriptStatus
          && testcase.automationScriptStatus === SCRIPT_STATUS.COMPLETE);

      const isRunning = testcase?.executionStatus === EXECUTION_RESULT.RUNNING
        || testcase?.executionStatus === EXECUTION_RESULT.PROVISIONING;

      if (((testcase?.executionStatus && isRunning) || isGenerating)
        && !autoRefresh.includes(testcase.testcaseId)) {
      // Add testcaseId to updatedAutoRefresh
        updatedAutoRefresh.push(testcase.testcaseId);
      } else if (
        autoRefresh.includes(testcase.testcaseId)
        && isComplete
      ) {
      // Exclude testcaseId from updatedAutoRefresh
      // Skip adding it to updatedAutoRefresh
        return updatedAutoRefresh;
      }
      return updatedAutoRefresh;
    }, []);

  const loadTableData = async (initialRun: boolean = false) => {
    const dataForTable = await GetTestCaseResultView(
      requirementId,
      moduleId,
    );
    setLoading(false);
    try {
      dataForTable.testcases = dataForTable.testcases.map((testcase: any) => {
        const updatedTestData: any = {};

        if (!testcase.testData) {
          return testcase;
        }

        testcase.testData.forEach((item: any, index: number) => {
          updatedTestData[index + 1] = {
            testDataType: item.testDataType,
            testData: item.testData,
            assetTag: item.assetTag,
          };
        });
        return { ...testcase, testData: updatedTestData };
      });

      const isScenarioGenerating: boolean = dataForTable
        .testcases.some(
          (
            { scenarioGeneratingStatus }: any,
          ) => scenarioGeneratingStatus === TESTCASE_CREATING_STATUS.GENERATING,
        );

      setSummeryData(dataForTable);
      setTableData(
        dataForTable.testcases.sort((a: any, b: any) => {
          const dateA = new Date(a.createdAt).getTime();
          const dateB = new Date(b.createdAt).getTime();
          return dateA - dateB;
        }),
      );
      const {
        masterAutomationScriptStatus,
        creating_status: taskStatus,
      } = dataForTable.requirement;
      if (initialRun) setShowNotification(taskStatus === TESTCASE_CREATING_STATUS.GENERATING);
      setTableDataLoading(taskStatus === TESTCASE_CREATING_STATUS.GENERATING);
      setError(taskStatus === TESTCASE_CREATING_STATUS.ERROR);
      setLoading(false);
      const updatedAutoRefreshList = handleTestCaseAutoRefresh(dataForTable.testcases);
      // TODO: commented this part due to bug fix
      // const isMasterScriptsExecuting: boolean = (dataForTable.requirement.masterAutomationScripts
      //     || [])
      //   .some(({ executionStatus }: ScriptType) => [
      //     EXECUTION_RESULT.RUNNING, EXECUTION_RESULT.PROVISIONING]
      //     .some((status: string) => status === executionStatus));

      const isMasterScriptsExecuting = false;

      return {
        taskStatus,
        updatedAutoRefreshList,
        masterAutomationScriptStatus,
        isMasterScriptsExecuting,
        isScenarioGenerating,
      };
    } catch (error) {
      setError(true);
      notifyError('Data Loading error', 'datda_getting_error');
      // eslint-disable-next-line no-console
      console.error('error: ', error);
      return {
        taskStatus: TESTCASE_CREATING_STATUS.ERROR,
        masterAutomationScriptStatus: TESTCASE_CREATING_STATUS.ERROR,
      };
    }
  };

  useEffect(() => {
    if (!isExtensionInstructionsModal && initialLoading) {
      const doNotShow = localStorage.getItem(EXTENSION_INSTRUCTION_MODAL_STATUS.KEY);
      if (summeryData?.requirement?.entryType === ENTRY_TYPE.RECORD
        && !summeryData?.testcases?.length && (!doNotShow || doNotShow === 'DISABLED')) {
        setExtensionInstructionsModal(true);
        setInitialLoading(false);
      } else {
        setExtensionInstructionsModal(false);
      }
    }
  }, [summeryData, initialLoading]);

  const handleRowClick = (record: DataType, index: number) => {
    setRowData([record]);
    setIndexValue(index);
    setModalVisible(true);
  };

  const delTestCase = async (testcaseId: string) => {
    notifyInProgress('Deleting Test Case');
    try {
      setDeleteConfirmModel(false);
      await deleteTestCase(requirementId, moduleId, testcaseId);
      await loadTableData();
      setSelectedScript(undefined);
      setSelectedTestCase(undefined);
      notifySuccess('Test Case Deleted Successfully!');
    } catch (error: any) {
      console.log('Failed To Delete Test Case :', error.message);
      setSelectedScript(undefined);
      setSelectedTestCase(undefined);
      notifyError('Failed To Delete Test Case!');
    }
  };

  const setFormData = (selectedTestcaseId: string, index: number | undefined) => {
    const testcase: any = tableData
      .find(({ testcaseId }: any) => testcaseId === selectedTestcaseId);
    setSelectedTestCase(testcase);
    form.setFieldsValue({ ...testcase, testcaseIndex: index });
    return testcase;
  };

  const triggerTestCaseModal = async (
    selectedTestcaseId: string | undefined = undefined,
    index: number | undefined = undefined,
    isEdit: boolean = false,
  ) => {
    form.resetFields();
    if (selectedTestcaseId) {
      setFormData(selectedTestcaseId, index);
    }
    const doNotShow = localStorage.getItem(EXTENSION_INSTRUCTION_MODAL_STATUS.KEY);
    if (summeryData.requirement?.entryType === ENTRY_TYPE.RECORD
       && (!doNotShow || doNotShow === 'DISABLED')
       && !isEdit) {
      setExtensionInstructionsModal(true);
    } else if (isEdit || summeryData.requirement?.entryType === ENTRY_TYPE.MANUAL) {
      setTestCaseModal(true);
    } else {
      setIdeModal(true);
    }
  };

  const triggerAutoRefresh = ({ isDestroyNotifications } = { isDestroyNotifications: true }) => {
    loadTableData(true);
    if (autoRefreshIntervalRef.current) clearInterval(autoRefreshIntervalRef.current);
    const interval = setInterval(async () => {
      const {
        taskStatus = undefined,
        updatedAutoRefreshList = [],
        masterAutomationScriptStatus = undefined,
        isMasterScriptsExecuting = false,
        isScenarioGenerating = false,
      } = await loadTableData();
      if (taskStatus
        && taskStatus !== TESTCASE_CREATING_STATUS.GENERATING
        && !isScenarioGenerating
        && !(updatedAutoRefreshList.length)
        && ((masterAutomationScriptStatus
          && masterAutomationScriptStatus !== TESTCASE_CREATING_STATUS.GENERATING)
            || !masterAutomationScriptStatus) && !isMasterScriptsExecuting) {
        getUserAccounts(userId, dispatch);
        getServicesList(selectedAccount?.accountId, dispatch);
        setAutoRefresh(updatedAutoRefreshList);
        clearInterval(interval);
        clearInterval(autoRefreshIntervalRef.current);
      }
    }, 5000);
    if (isDestroyNotifications) notification.destroy();
    autoRefreshIntervalRef.current = interval;
  };

  const saveTestCase = async (
    { skipCleanUp = false, inputType = undefined }:
      { skipCleanUp?: Boolean, inputType?: string } = {},
  ) => {
    const isNewTestCase: Boolean = !form.getFieldValue('testcaseId');
    try {
      notifyInProgress(`${isNewTestCase ? 'Creating' : 'Saving'} the Test Case`);
      let testcase: any = form.getFieldsValue();

      // Convert testData to object array
      if (testcase.testData) {
        // TODO: these delete statements are temporary solution
        if (Array.isArray(testcase?.testData)) {
          testcase.testData = testcase?.testData
            ?.filter((testdata: any) => testdata !== null && testdata !== undefined);
        } else {
          testcase.testData = formateTestDataToArray(testcase.testData);
          testcase.testData = testcase?.testData
            ?.filter((testdata: any) => testdata !== null && testdata !== undefined);
        }
      }

      switch (inputType) {
        case INPUT_TYPE.TEST_DATA:
          delete testcase?.bdd;
          delete testcase?.automationScript;
          break;
        case INPUT_TYPE.BDD:
          testcase = {
            bdd: testcase.bdd,
            testcaseIndex: testcase.testcaseIndex,
            testcaseId: testcase.testcaseId,
          };
          break;
        case INPUT_TYPE.AUTOMATION_SCRIPT:
          testcase = {
            automationScript: testcase.automationScript,
            testcaseIndex: testcase.testcaseIndex,
            testcaseId: testcase.testcaseId,
          };
          break;
        default:
          break;
      }

      if (isNewTestCase) {
        // Add new test case
        await addTestCase(requirementId, moduleId, testcase);
      } else {
        // Edit existing test case
        await editTestCase(requirementId, testcase.testcaseId, testcase);
      }

      // Load table data and reset form
      triggerAutoRefresh();
      if (!skipCleanUp) {
        setTestCaseModal(false);
        form.resetFields();
        setSelectedScript(undefined);
        setSelectedTestCase(undefined);
      }

      notifySuccess(`Test Case ${isNewTestCase ? 'Created' : 'Saved'} Successfully!`);
    } catch (error: any) {
      console.log('Failed To Save the Test Case :', error.message);
      notifyError(`Failed To ${isNewTestCase ? 'Create' : 'Save'} the Test Case!`);
    }
  };

  const generateTestCaseScript = async (testcase: any, type: string = SCRIPT_TYPE.BDD) => {
    const payload: any = {
      requirementId: testcase.requirementId,
      testcaseId: testcase.testcaseId,
      type,
      automationScriptType: summeryData?.requirement?.testScriptLanguage
        || AUTOMATION_SCRIPT_TYPE.PLAYWRIGHT,
    };
    await generateScript(payload);
    setAutoRefresh([...autoRefresh, payload.testcaseId]);
    triggerAutoRefresh();
  };

  const handleScriptGeneration = async (testcase: any, index: number, scriptType: string) => {
    form.resetFields();
    setSelectedScript(scriptType);
    const selectedTestcase: any = setFormData(testcase.key, index);

    const scriptStatus = scriptType === SCRIPT_TYPE.BDD
      ? selectedTestcase?.bddStatus : selectedTestcase?.automationScriptStatus;

    if (!scriptStatus) {
      await generateTestCaseScript(selectedTestcase, scriptType);
    } else {
      switch (scriptStatus) {
        case SCRIPT_STATUS.REQUIRED_TO_REGENERATE:
          setShowNotification(true);
          break;
        case SCRIPT_STATUS.COMPLETE:
          setIdeModal(true);
          break;
        default:
          break;
      }
    }
  };

  const isScriptNotificationOpen = Boolean(
    showNotification
    && (
      (selectedScript === SCRIPT_TYPE.BDD
        && selectedTestCase?.bddStatus === SCRIPT_STATUS.REQUIRED_TO_REGENERATE)
      || (selectedScript === SCRIPT_TYPE.AUTOMATION_SCRIPT
        && selectedTestCase?.automationScriptStatus === SCRIPT_STATUS.REQUIRED_TO_REGENERATE)
    ),
  );

  const runAutomationScript = async (testcaseId: string | undefined = undefined,
    masterScript: ScriptType | undefined = undefined, script?: string | undefined,
    selectedEDB: any, region: string) => {
    const automationScript: string | undefined = form.getFieldValue('automationScript');
    const params: RunTestCasePayload = {
      accountId: selectedAccount?.accountId as string,
      projectId: summeryData?.requirement?.projectId,
      testcaseId,
      userId: userId as string,
      service: SERVICE.FOOT_PRINT,
      isMasterScript: !!masterScript,
      requirementId,
      automationScript: script || automationScript,
      moduleId: summeryData.module.moduleId,
      masterScript,
      selectedEDB,
      region,
    };
    await runTestCase(params);
    if (testcaseId) setAutoRefresh((preList: string[]) => [...preList, testcaseId]);
    triggerAutoRefresh();
  };

  const setNewTestCase = (testcase: any, index?: undefined) => {
    const testcaseIndex: number = index || tableData.length + 1;
    setSelectedTestCase(testcase);
    form.setFieldsValue({ ...testcase, testcaseIndex });
  };

  const cancelExecutionScript = async (
    testcaseId: string | undefined = undefined,
    executionId: string | undefined = undefined,
    masterScript: ScriptType | undefined = undefined,
  ) => {
    const params: any = {
      testcaseId,
      executionId,
      isMasterScript: !!masterScript,
      requirementId,
      moduleId: summeryData.module.moduleId,
      masterScript,
    };
    await cancelExecution(params);
    triggerAutoRefresh();
  };

  useEffect(() => {
    triggerAutoRefresh();
    setFreshGenerate(history?.location?.state?.freshGenetared);
    return () => {
      clearInterval(autoRefreshIntervalRef.current);
      setExtensionInstructionsModal(false);
      setInitialLoading(true);
    };
  }, []);

  useMemo(() => {
    if (summeryData?.testcases?.length
        && selectedTestCase?.testcaseId) {
      const updatedTemp = summeryData.testcases
        .find(({ testcaseId }: any) => testcaseId === selectedTestCase?.testcaseId);
      if (updatedTemp) setSelectedTestCase(updatedTemp);
    }
  }, [summeryData, selectedTestCase]);

  if (isLoading) return <Loading plain size="large" />;

  return (
    <div className="fp_pg_base">
      <RequirementViewHeader
        summeryData={summeryData}
        triggerTestCaseModal={triggerTestCaseModal}
        isChecked={isChecked}
        selectedTestcases={selectedTestcases}
      />
      {summeryData?.requirement?.noOfTestcases > 0 ? (
        <Row className={`fp_tbl_rw ${paymentPlan(selectedBilledPlan)}-frm-cdmodal`}>
          <TableView
            tableData={tableData}
            setIsChecked={setIsChecked}
            setSelectedTestcases={setSelectedTestcases}
            handleRowClick={handleRowClick}
            form={form}
            triggerTestCaseModal={triggerTestCaseModal}
            setFormData={setFormData}
            setDeleteConfirmModel={setDeleteConfirmModel}
            handleScriptGeneration={handleScriptGeneration}
            setTestDataModal={setTestDataModal}
            requirement={summeryData.requirement}
            isTableDataLoading={isTableDataLoading}
            triggerAutoRefresh={triggerAutoRefresh}
          />
        </Row>
      ) : (
        <div className="new-testcase-outer-container">
          <div className="inner-container">
            <p className="text">Create your first automation script</p>
            <div className="button-container">
              <Button
                className="fp_btn-primary fp_btn"
                data-testid="fp_previous_btn"
                icon={<PlusOutlined />}
                onClick={() => triggerTestCaseModal()}
              >
                New
              </Button>
            </div>
          </div>
        </div>
      )}

      <Form form={form}>
        <Form.Item name="testcaseId" className="fp_dymmy_form_item" />
        <Form.Item name="testcaseIndex" className="fp_dymmy_form_item" />
      </Form>

      <RootModal
        showNotification={showNotification}
        tableData={tableData}
        summeryData={summeryData}
        setShowNotification={setShowNotification}
        rowData={rowData}
        indexValue={indexValue}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        isError={isError}
        isFreshGenerate={isFreshGenerate}
        setError={setError}
        isTestCaseModal={isTestCaseModal}
        setTestCaseModal={setTestCaseModal}
        isExtensionInstructionsModal={isExtensionInstructionsModal}
        setExtensionInstructionsModal={setExtensionInstructionsModal}
        saveTestCase={saveTestCase}
        form={form}
        isIdeModal={isIdeModal}
        setIdeModal={setIdeModal}
        selectedTestCase={selectedTestCase}
        selectedScript={selectedScript}
        setSelectedTestCase={setSelectedTestCase}
        setSelectedScript={setSelectedScript}
        isTestDataModal={isTestDataModal}
        setTestDataModal={setTestDataModal}
        isDeleteConfirmModel={isDeleteConfirmModel}
        setDeleteConfirmModel={setDeleteConfirmModel}
        delTestCase={delTestCase}
        isScriptNotificationOpen={isScriptNotificationOpen}
        generateTestCaseScript={generateTestCaseScript}
        triggerAutoRefresh={triggerAutoRefresh}
        runAutomationScript={runAutomationScript}
        cancelExecutionScript={cancelExecutionScript}
        setNewTestCase={setNewTestCase}
        projectName=""
      />
    </div>
  );
};

export default CreateNewtestcaseView;
