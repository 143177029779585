import {
  DislikeFilled,
  DislikeOutlined, LikeFilled, LikeOutlined, MoreOutlined,
} from '@ant-design/icons';
import {
  Row, Button, Dropdown, Col,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import {
  DataType, ENTRY_TYPE, SCRIPT_STATUS, SCRIPT_TYPE, SERVICE_TYPE, TEST_CASE_RATING,
} from '../../../../../types/common';
import CustomRow from './CustomRow';
import TestCaseDropDownMenu from './TestCaseDropDownMenu';

export const tableColumns = (
  handleRowClick: Function,
  form: any,
  triggerTestCaseModal: Function,
  setFormData: Function,
  setDeleteConfirmModel: Function,
  handleScriptGeneration: Function,
  setTestDataModal: Function,
  serviceId: string,
  addRating: Function,
  requirement: any,
): ColumnsType<DataType> => {
  const getButtonText = (status = undefined): string => {
    if (!status) return 'Generate script';
    switch (status) {
      case SCRIPT_STATUS.COMPLETE:
        return 'View script';
      case SCRIPT_STATUS.REQUIRED_TO_REGENERATE:
        return 'View script';
      case SCRIPT_STATUS.GENERATING:
        return 'Generating script';
      default:
        return 'Generate script';
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Test Scenario',
      dataIndex: 'TestScenario',
      render: (text, record, index) => (
        <>
          <Row onClick={() => handleRowClick(record, index)}>
            <CustomRow text={text} />
          </Row>
          {serviceId === SERVICE_TYPE.TEST_CASE_GENERATOR && (
          <Row>
            <Col lg={{ span: 6 }} md={{ span: 3 }} xs={{ span: 3 }}>
              {record.TestCaseRate === TEST_CASE_RATING.LIKED
                ? <LikeFilled />
                : <LikeOutlined onClick={() => addRating(record.key, TEST_CASE_RATING.LIKED)} />}
            </Col>
            <Col lg={{ span: 6 }} md={{ span: 3 }} xs={{ span: 3 }}>
              {record.TestCaseRate === TEST_CASE_RATING.DISLIKED
                ? <DislikeFilled />
                : (
                  <DislikeOutlined
                    onClick={() => addRating(record.key, TEST_CASE_RATING.DISLIKED)}
                  />
                )}
            </Col>
          </Row>
          )}
        </>
      ),
    },
    {
      title: 'Pre-conditions',
      dataIndex: 'Preconditions',
      render: (text, record, index) => (
        <Row onClick={() => handleRowClick(record, index)}>
          <CustomRow text={text} />
        </Row>
      ),
    },
    {
      title: 'Test Case Steps',
      dataIndex: 'TestCaseSteps',
      render: (text, record, index) => (
        <Row onClick={() => handleRowClick(record, index)}>
          <CustomRow text={text} />
        </Row>
      ),
    },
    {
      title: 'Expected Results',
      dataIndex: 'ExpectedResults',
      render: (text, record, index) => (
        <Row onClick={() => handleRowClick(record, index)}>
          <CustomRow text={text} />
        </Row>
      ),
    },
    {
      title: '',
      dataIndex: 'Action',
      width: 40,
      render: (_, record, index) => (
        <Row>
          <Dropdown overlay={(
            <TestCaseDropDownMenu
              index={index}
              testcase={record}
              triggerTestCaseModal={triggerTestCaseModal}
              setFormData={setFormData}
              setDeleteConfirmModel={setDeleteConfirmModel}
            />
          )}
          >
            <Button className="fp-drop-down-button" style={{ padding: 0 }}>
              <MoreOutlined
                className="fp_list_item_more_icon"
              />
            </Button>
          </Dropdown>
        </Row>
      ),
    },
  ];

  if (serviceId === SERVICE_TYPE.AUTOMATION_SCRIPT_GENERATOR) {
    if (requirement?.entryType === ENTRY_TYPE.MANUAL) {
      columns.splice(4, 0, {
        title: 'Test Data',
        dataIndex: 'TestData',
        render: (_, record, index) => (
          <Row className="center_elements_inside_contenter">
            {requirement?.entryType !== ENTRY_TYPE.RECORD && (
            <Button
              className="fp_btn-secondary fp_btn"
              data-testid="fp_previous_btn"
              onClick={() => {
                form.resetFields();
                setFormData(record.key, index);
                setTestDataModal(true);
              }}
            >
              {record.IsTestDataAdded ? 'View test data' : 'Add test data'}
            </Button>
            )}
          </Row>
        ),
      });

      columns.splice(5, 0, {
        title: 'Test Cases in BDD Format',
        dataIndex: 'TestCasesBDDFormat',
        render: (_, record, index) => (
          <Row className="center_elements_inside_contenter">
            {requirement?.entryType !== ENTRY_TYPE.RECORD && (
            <Button
              className="fp_btn-secondary fp_btn"
              data-testid="fp_previous_btn"
              onClick={() => handleScriptGeneration(record, index, SCRIPT_TYPE.BDD)}
              disabled={record.BDDStatus === SCRIPT_STATUS.GENERATING || !record.IsTestDataAdded}
              loading={record.BDDStatus === SCRIPT_STATUS.GENERATING}
            >
              {getButtonText(record.BDDStatus)}
            </Button>
            )}
          </Row>
        ),
      });
    }

    columns.splice(requirement?.entryType === ENTRY_TYPE.RECORD ? 4 : 6, 0, {
      title: 'Automation script',
      dataIndex: 'automationScript',
      width: 200,
      render: (_, record, index) => (
        <Row className="center_elements_inside_contenter">
          <Button
            className="fp_btn-secondary fp_btn"
            data-testid="fp_previous_btn"
            style={{ background: '#FF988E' }}
            onClick={() => handleScriptGeneration(record, index, SCRIPT_TYPE.AUTOMATION_SCRIPT)}
            disabled={(record.AutomationScriptStatus === SCRIPT_STATUS.GENERATING
              || (!record.BDDStatus || record.BDDStatus === SCRIPT_STATUS.ERROR
                || record.BDDStatus === SCRIPT_STATUS.GENERATING))}
            loading={record.AutomationScriptStatus === SCRIPT_STATUS.GENERATING}
          >
            {getButtonText(record.AutomationScriptStatus)}
          </Button>
        </Row>
      ),
    });
  }
  return columns;
};
