import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { setSelectedAccount } from '../../redux/userSlice';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';

const { Option } = Select;
const AccountSelector = () => {
  const dispatch = useAppDispatch();
  const initAccount = useAppSelector(({ userData }) => userData.selectedAccount);
  const [selectedAccountId, setSelectedAccountId] = useState<any>(initAccount?.accountId);
  const accounts = useAppSelector(({ userData }) => userData.accounts);

  useEffect(() => {
    const selectedAccount = accounts?.find(({ accountId }) => accountId === selectedAccountId);
    dispatch(setSelectedAccount(selectedAccount));
  }, [selectedAccountId]);

  const renderOptions = () => accounts?.map(({ accountId, accountName }) => (
    <Option key={accountId} value={accountId}>{accountName}</Option>
  ));

  return (
    <div className="fp_slctr_cntnr fp_cmpnt fp_cmpnt_flx_rw">
      <p className="fp_slctr_lbl">Account :</p>
      <Select data-testid="fp_account_selector" defaultValue={initAccount?.accountId} className="fp_slctr" onSelect={(accountId) => setSelectedAccountId(accountId)}>
        {renderOptions()}
      </Select>
    </div>
  );
};

export default AccountSelector;
