import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import {
  Menu, Checkbox, Tag, TabsProps, Row, Col, Form, Dropdown, Button, Tabs,
} from 'antd';
import Ansi from 'ansi-to-react';
import { TextEditor } from '../../../../../components';
import EditorIDE from './EditorIDE';
import { ScriptType } from '../modals';
import ResultsView from './ResultsView';

interface IDETabMenuProps {
  selectedMasterScript?: ScriptType | undefined
  executionData: any
  script: string
  handleOnChange: Function
  testcase: any
  testcases: any[]
  addedPreRequisiteScripts: any[]
  selectAutomationScript: Function
  selectedScript: string
  handlePreConditionAddition: Function
  form: any
  setCTROpenModal: Function
}

const IDETabMenu = ({
  selectedMasterScript,
  executionData,
  script,
  handleOnChange,
  testcase,
  testcases,
  addedPreRequisiteScripts,
  selectAutomationScript,
  selectedScript,
  handlePreConditionAddition,
  form,
  setCTROpenModal,
}: IDETabMenuProps) => {
  const menu = (
    <Menu className="fp-drop-down-menu">
      {testcases
        ?.filter(({ automationScript, testcaseId }: any) => !!automationScript
          && testcase?.testcaseId !== testcaseId)
        ?.map(({ automationScript, scenario }: any) => {
          const selectedPRS = addedPreRequisiteScripts
            ?.find((s: any) => s.originalScript === automationScript);

          return (
            <Menu.Item
              key={automationScript}
              onChange={() => selectAutomationScript(automationScript)}
            >
              <Checkbox checked={!!selectedPRS}>
                {scenario}
              </Checkbox>
              {selectedPRS && <Tag className="dry-run-tag">{`Step ${selectedPRS?.step}`}</Tag>}
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const getExecutionErrors = (error: string | any[]) => {
    if (typeof error === 'string') {
      return (
        <TextEditor
          value={error}
          language="bash"
          padding={15}
          disabled
        />
      );
    }
    const filteredErrors = error?.filter(({ message }: any) => message !== 'No tests found');
    return filteredErrors?.map((err: any) => (
      (
        <>
          {!err?.snippet
          && (
            <div
              className="fp_logview_editor"
              style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', padding: 20 }}
            >
              <Ansi>
                {err?.message}
              </Ansi>
            </div>
          )}
          <br />
          {err.snippet && (
          <>
            <div
              className="fp_logview_editor"
              style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', padding: 20 }}
            >
              <Ansi>
                {err?.snippet}
              </Ansi>
            </div>
            <br />
          </>
          )}
        </>
      )
    ));
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Edit',
      children: (
        <div>
          {selectedMasterScript
            ? (
              <TextEditor
                value={script}
                language="javascript"
                onChange={handleOnChange}
                padding={15}
              />
            )
            : (
              <>
                <Row>
                  <Col span={7}>
                    <Form.Item label="Prerequisite scripts">
                      <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']} className="fp_dropdown_pre_req">
                        <a onClick={(e) => e.preventDefault()}>
                          Select here
                          {' '}
                          <DownOutlined />
                        </a>
                      </Dropdown>
                    </Form.Item>
                  </Col>
                  <Col span={13}>
                    <Button type="primary" className="fp_btn fp_btn-outline" onClick={handlePreConditionAddition} disabled={!addedPreRequisiteScripts.length}>Add</Button>
                  </Col>
                </Row>
                <EditorIDE
                  content={script}
                  form={form}
                  handleOnChange={handleOnChange}
                  selectedScript={selectedScript}
                />
              </>
            )}
          <Button
            type="primary"
            className="fp_btn fp_btn-primary"
            onClick={() => setCTROpenModal(true)}
            style={{ marginLeft: 10, marginBottom: 15, marginTop: 5 }}
          >
            Import Test Resources
          </Button>
          {getExecutionErrors(executionData?.log?.errors?.length
            ? executionData?.log?.errors
            : executionData?.log?.suites[0]?.specs[0]?.tests[0]?.results[0]?.errors
            || executionData?.log?.suites[0]?.specs[0]?.tests[0]?.results[0]?.error?.snippet
            || executionData?.log?.suites[0]?.specs[0]?.tests[0]?.results[0]?.error?.message)}
        </div>
      ),
    },
  ];

  if (executionData?.log) {
    items.push({
      key: '2',
      label: 'Execution Log',
      children: <TextEditor
        value={JSON.stringify(executionData?.log || {}, null, 4)}
        language="json"
        padding={15}
        disabled
      />,
    });
  }

  if (executionData?.results) {
    items.push({
      key: '3',
      label: 'Results',
      children: <ResultsView results={executionData?.results || []} />,
    });
  }
  return (
    <Tabs defaultActiveKey="1" items={items} />
  );
};

export default IDETabMenu;
