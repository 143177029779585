import React, { ChangeEventHandler } from 'react';
import TextEditor from '../../../components/Editor';

interface EditorProps {
  disabled?: boolean
  content: string
  language?: string
  handleOnChange: ChangeEventHandler
}

const EditorIDE = ({
  disabled = false, content, language = 'javascript', handleOnChange,
}: EditorProps) => (
  <TextEditor
    value={content}
    language={language}
    placeholder="Please enter automation script"
    onChange={handleOnChange}
    padding={15}
    style={{ minHeight: 600 }}
    disabled={disabled}
  />
);

export default EditorIDE;
