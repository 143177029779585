import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { extraTeams, setSelectedTeam } from '../../redux/userSlice';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';

const { Option } = Select;
const TeamSelector = () => {
  const dispatch = useAppDispatch();
  const initTeam = useAppSelector(({ userData }) => userData.selectedTeam);
  const [selectedTeamId, setSelectedAccountId] = useState<any>(initTeam?.teamId);
  const accountTeams = useAppSelector(
    ({ userData }) => (userData.selectedAccount ? userData.selectedAccount.teams : []),
  );
  const teams = [...extraTeams, ...accountTeams];

  useEffect(() => {
    const selectedTeam = teams?.find((team) => team?.teamId === selectedTeamId);
    dispatch(setSelectedTeam(selectedTeam));
  }, [selectedTeamId]);

  const renderOptions = () => teams?.map((team) => (
    <Option key={team.teamId} value={team.teamId}>{team.teamName}</Option>
  ));

  return (
    <div className="fp_slctr_cntnr fp_cmpnt fp_cmpnt_flx_rw">
      <p className="fp_slctr_lbl">Team :</p>
      <Select data-testid="fp_team_selector" defaultValue={initTeam?.teamId} className="fp_slctr" onSelect={(teamId) => setSelectedAccountId(teamId)}>
        {renderOptions()}
      </Select>
    </div>
  );
};

export default TeamSelector;
