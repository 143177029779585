import React, { useEffect, useState } from 'react';
import {
  Row, Col, Typography, Button, Modal,
} from 'antd';
import { ReloadIcon, SelectIcon } from '../../../components';
import PaymentPlan from '../../testcaseCreation/components/paymentPlan/PaymentPlan';
import { PAYMENT_PLANS } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { BilledPlan, setSelectedBilledPlan } from '../../../redux/billedPlanSlice';
import { getPaymentPlan } from '../../testcaseCreation/action';

const { Title } = Typography;

type propType = {
  cardService: string
}

const CurrentPlanCard = ({ cardService }: propType) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const billedPlans: BilledPlan[] = useAppSelector(
    ({ billedPlanData }) => billedPlanData.billedPlans,
  );
  const subscribedServices = useAppSelector(({ serviceData }: any) => serviceData.servicesData);
  const selectedBilledPlan = useAppSelector(({ billedPlanData }) => billedPlanData
    .selectedBilledPlan);

  const handleCancel = () => {
    setModalOpen(false);
  };

  const showPaymentPlan = () => (
    <Modal
      className="delete-confirm-modal"
      open={isModalOpen}
      onCancel={handleCancel}
      closable
      footer={null}
      centered
      width={1300}
    >
      <PaymentPlan
        cardService={cardService}
        closePaymentModel={handleCancel}
        isPaymentPlanChange
      />
    </Modal>
  );

  const setDefaultConfig = async (serviceRecord: any) => {
    if (!billedPlans || billedPlans.length === 0) {
      await getPaymentPlan(dispatch);
    }
    const selectedSubscription = await billedPlans
      .find((subscription: any) => subscription.paymentPlanId === serviceRecord.paymentPlanId);
    dispatch(setSelectedBilledPlan(selectedSubscription));
  };

  const loadServices = async () => {
    if (subscribedServices) {
      const serviceRecord = await subscribedServices
        .find((service: any) => service.serviceId === cardService);
      if (serviceRecord && serviceRecord.paymentPlanId) {
        await setDefaultConfig(serviceRecord);
      }
    }
  };

  useEffect(() => {
    loadServices().finally();
  }, [subscribedServices]);

  const planSelector = (plan?: string) => {
    switch (plan) {
      case PAYMENT_PLANS.PAY_AS_YOU_GO:
        return (
          <>
            <Title className="title pay-as-go" level={5}>Pay as you go</Title>
            <Button type="primary" onClick={() => setModalOpen(true)} shape="round" icon={<ReloadIcon />}>
              Change
            </Button>
          </>
        );
      case PAYMENT_PLANS.PROFESSIONAL:
        return (
          <>
            <Title className="title professional" level={5}>Professional</Title>
            <Button type="primary" onClick={() => setModalOpen(true)} shape="round" icon={<ReloadIcon />}>
              Change
            </Button>
          </>
        );
      case PAYMENT_PLANS.ENTERPRISE:
        return (
          <>
            <Title className="title enterprise" level={5}>Enterprise</Title>
          </>
        );
      default:
        return (
          <>
            <Title className="title" level={5}>No Plan Selected</Title>
            <Button type="primary" shape="round" onClick={() => setModalOpen(true)} icon={<SelectIcon />}>
              Select
            </Button>
          </>
        );
    }
  };

  return (
    <Row className="current-plan-card">
      <Col span={24}>
        <Row className="plan-header">
          <Col>
            <Title level={5}>Current Plans</Title>
          </Col>
        </Row>
        <Row>
          <Col className="plan-content">
            <Title level={5}>Test case creation</Title>
            {planSelector(selectedBilledPlan?.planName)}
            {isModalOpen && showPaymentPlan()}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CurrentPlanCard;
