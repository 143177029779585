import { Menu, Button } from 'antd';
import React from 'react';
import { EditIcon, DeleteIcon, RegressionIcon } from '../../../../../components';
// import { ENTRY_TYPE } from '../../../../../types/common';

type propType = {
  index: number;
  testcase: any;
  triggerTestCaseModal: Function;
  setFormData: Function;
  setDeleteConfirmModel: Function;

}
const TestCaseDropDownMenu = ({
  index, testcase, triggerTestCaseModal, setFormData, setDeleteConfirmModel,
}: propType) => {
  const menuItems: any[] = [
    {
      key: '1',
      label: (
        <Button
          className="fp_btn fp_btn-link"
          onClick={() => triggerTestCaseModal(testcase.key, index, true)}
          style={{ width: '100%', textAlign: 'left' }}
          icon={<EditIcon />}
        >
          Edit testcase
        </Button>
      ),
    },
    {
      key: '2',
      label: (
        <Button
          className="fp_btn fp_btn-link"
          onClick={() => {
            setFormData(testcase.key, index);
            setDeleteConfirmModel(true);
          }}
          style={{ width: '100%', textAlign: 'left' }}
          icon={<DeleteIcon />}
        >
          Delete testcase
        </Button>
      ),
    },
    {
      key: '3',
      label: (
        <Button
          className="fp_btn fp_btn-link"
          onClick={() => { }}
          style={{ width: '100%', textAlign: 'left' }}
          icon={<RegressionIcon />}
          disabled
        >
          Add to a regression suite
        </Button>
      ),
    },
  ];

  // if (entryType !== ENTRY_TYPE.RECORD) {
  //   menuItems.splice(0, 0, {
  //     key: '1',
  //     label: (
  //       <Button
  //         className="fp_btn fp_btn-link"
  //         onClick={() => triggerTestCaseModal(testcase.key, index)}
  //         style={{ width: '100%', textAlign: 'left' }}
  //         icon={<EditIcon />}
  //       >
  //         Edit testcase
  //       </Button>
  //     ),
  //   });
  // }

  return (
    <Menu
      className="fp-drop-down-menu"
      items={menuItems}
    />
  );
};

export default TestCaseDropDownMenu;
