import Amplify, { Auth } from 'aws-amplify';
import { CONFIG } from '../configs/app-config';

export const APIS = {
  USER_API: 'USER_API',
  SIGNUP_API: 'SIGNUP_API',
  PROJECT_API: 'PROJECT_API',
  TEST_CASE_API: 'TEST_CASE_API',
  EXECUTION_API: 'EXECUTION_API',
  TESTCASE_CREATING_API: 'TESTCASE_CREATING_API',
  BILLING_API: 'BILLING_API',
  FEEDBACK_API: 'FEEDBACK_API',
  CONTACT_US_API: 'CONTACT_US_API',
  TEST_SUITE_API: 'TEST_SUITE_API',
  INTEGRATION_API: 'INTEGRATION_API',
  MONITOR_API: 'MONITOR_API',
};

export const PROVIDER = {
  GOOGLE: 'Google',
};

export type PROVIDER_TYPE = typeof PROVIDER[keyof typeof PROVIDER];

export const initApi = (token: undefined | string | null = undefined) => {
  const authHeader = async () => {
    try {
      return { Authorization: `Bearer ${token || (await Auth.currentSession()).getIdToken().getJwtToken()}` };
    } catch (error: any) {
      return undefined;
    }
  };

  Amplify.configure({
    API: {
      endpoints: [
        {
          name: APIS.SIGNUP_API,
          endpoint: `${CONFIG.baseApiUrl}user-api`,
        },
        {
          name: APIS.USER_API,
          endpoint: `${CONFIG.baseApiUrl}user-api/user`,
          custom_header: authHeader,
        },
        {
          name: APIS.PROJECT_API,
          endpoint: `${CONFIG.baseApiUrl}project-api/project`,
          custom_header: authHeader,
        },
        {
          name: APIS.TEST_CASE_API,
          endpoint: `${CONFIG.baseApiUrl}test-case-api/test-case`,
          custom_header: authHeader,
        },
        {
          name: APIS.EXECUTION_API,
          endpoint: `${CONFIG.baseApiUrl}execution-api`,
          custom_header: authHeader,
        },
        {
          name: APIS.TESTCASE_CREATING_API,
          endpoint: `${CONFIG.baseApiUrl}testcase-creating-api/testcase-creating`,
          custom_header: authHeader,
        },
        {
          name: APIS.BILLING_API,
          endpoint: `${CONFIG.baseApiUrl}billing-api/billing`,
          custom_header: authHeader,
        },
        {
          name: APIS.FEEDBACK_API,
          endpoint: `${CONFIG.baseApiUrl}feedback-api/feedback`,
          custom_header: authHeader,
        },
        {
          name: APIS.CONTACT_US_API,
          endpoint: `${CONFIG.baseApiUrl}contact-us-api/contact-us`,
          custom_header: authHeader,
        },
        {
          name: APIS.TEST_SUITE_API,
          endpoint: `${CONFIG.baseApiUrl}test-suite-api/test-suite`,
          custom_header: authHeader,
        },
        {
          name: APIS.INTEGRATION_API,
          endpoint: `${CONFIG.baseApiUrl}integration-api/api`,
          custom_header: authHeader,
        },
        {
          name: APIS.MONITOR_API,
          endpoint: `${CONFIG.baseApiUrl}monitor-api/api`,
          custom_header: authHeader,
        },
      ],
    },
  });
};
