import React, { useEffect, useState } from 'react';
import {
  CloseCircleOutlined, CheckCircleOutlined, PauseCircleOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import { EXECUTION_RESULT } from '../../../../constants';
import renderBrowserIcon from '../../../../components/Browsers';
import NewLoader from '../../../../components/NewLoader';

type propType = {
    status: typeof EXECUTION_RESULT[keyof typeof EXECUTION_RESULT]
    executionResults: any[]
    passPercentage: undefined | number | string
    showIcons?: boolean
    disableRunningAnimation?: boolean
};

type browsersStatus = {
  passedBrowsers: string[],
  failedBrowsers: string[],
}

const getColor = (percentage: number | string): string => {
  let percentageInt;
  if (typeof percentage === 'string') {
    percentageInt = parseInt(percentage, 10);
  } else {
    percentageInt = percentage as number;
  }
  if (percentageInt >= 66.67) {
    return '#B2FF80'; // Green
  }
  if (percentageInt >= 33.34) {
    return '#FFAA00'; // Orange
  }
  return '#DE4359'; // Red
};

const StatusView = ({
  status = EXECUTION_RESULT.SAVED,
  executionResults,
  passPercentage,
  showIcons = false,
  disableRunningAnimation = false,
}: propType) => {
  const [browserData, setBrowserData] = useState<browsersStatus>(
    { passedBrowsers: [], failedBrowsers: [] },
  );

  useEffect(() => {
    if (status === EXECUTION_RESULT.COMPLETED && executionResults) {
      const { passedBrowsers, failedBrowsers } = executionResults?.reduce(
        (acc, { projectName, results }) => {
          acc[results[0]?.status === 'passed'
            ? 'passedBrowsers' : 'failedBrowsers'].push(projectName);
          return acc;
        },
        { passedBrowsers: [], failedBrowsers: [] },
      );

      setBrowserData({ passedBrowsers, failedBrowsers });
    }
  }, [executionResults]);

  switch (status) {
    case EXECUTION_RESULT.PROVISIONING:
      return (
        <>
          {!disableRunningAnimation && <NewLoader />}
          <p className="fp_test_status_pending">Provisioning</p>
        </>
      );
    case EXECUTION_RESULT.CANCELED:
      return (
        <>
          <PauseCircleOutlined style={{ fontSize: 26, color: '#93DEFF' }} />
          <p className="fp_test_status_pending">Canceled</p>
        </>
      );
    case EXECUTION_RESULT.PASSED:
      return (
        <>
          <CheckCircleOutlined
            style={{ fontSize: 26, color: '#B2FF80' }}
          />
          <p className="fp_test_status_success">Test Pass</p>
        </>
      );
    case EXECUTION_RESULT.COMPLETED:
      return (
        <Row style={{ width: '100%' }}>
          {passPercentage ? (
            <p className="fp_test_status_without_color" style={{ color: getColor(passPercentage) }}>
              {showIcons && <CheckCircleOutlined style={{ marginRight: '10px' }} />}
              {`${passPercentage}% Passed`}
            </p>
          ) : (
            <>
              {browserData.passedBrowsers.map((browser: string) => (
                <Col offset={1}>
                  <div style={{ width: 30 }}>
                    {renderBrowserIcon(browser)}
                  </div>
                </Col>
              ))}
              {browserData.failedBrowsers.map((browser: string) => (
                <Col offset={1}>
                  <div style={{ width: 30 }}>
                    {renderBrowserIcon(browser)}
                  </div>
                </Col>
              ))}
              {browserData.failedBrowsers.length ? <Col><p className="fp_test_status_error">Failed</p></Col> : <></>}
            </>
          )}
        </Row>
      );
    case EXECUTION_RESULT.FAILED:
      return (
        <>
          <CloseCircleOutlined
            style={{ fontSize: 26, color: '#FF9C9C' }}
          />
          <p className="fp_test_status_error">Failed</p>
        </>
      );
    case EXECUTION_RESULT.RUNNING:
      return (
        <>
          {/* <SyncOutlined
            style={{ fontSize: 26, color: '#93DEFF' }}
          /> */}
          {!disableRunningAnimation && <NewLoader />}
          <p className="fp_test_status_pending">Running</p>
        </>
      );
    default:
      return <div />;
  }
};

export default StatusView;
