import React, { useState } from 'react';
import {
  Button, Form, Input, Modal,
} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import ConfirmationModal from './ConfirmationModal';

interface proType {
  open: boolean,
  setOpen: Function,
  saveTestCase: Function,
  setSelectedTestCase: Function,
  form: any,
}

const TestCaseAddEditModal = ({
  open, setOpen, saveTestCase, form, setSelectedTestCase,
}: proType) => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

  const triggerConfirmationModal = () => {
    setOpen(false);
    setConfirmationModalOpen(true);
  };

  const handleConfirmation = () => {
    setConfirmationModalOpen(false);
    saveTestCase();
  };

  const handleCancel = () => {
    setConfirmationModalOpen(false);
    setOpen(true);
  };

  const clearClose = () => {
    setSelectedTestCase(undefined);
    form.resetFields();
    setOpen(false);
  };

  return (
    <>
      <Modal
        className="max-user-modal fp-modal fp-modal-primary"
        open={open}
        title={form.getFieldValue('testcaseId') ? `Testcase #${form.getFieldValue('testcaseIndex')}` : 'Create New Testcase'}
        onCancel={() => clearClose()}
        footer={[
          <Button type="primary" className="fp_btn fp_btn-outline" onClick={() => clearClose()}>Cancel</Button>,
          <Button
            type="primary"
            className="fp_btn fp_btn-primary"
            onClick={() => triggerConfirmationModal()}
          >
            {form.getFieldValue('testcaseId') ? 'Save' : 'Create'}
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form}>
          <h4 className="fp_frm_title">Test Scenario</h4>
          <Form.Item name="scenario" rules={[{ required: true, message: 'Please enter a test scenario!' }]}>
            <Input placeholder="Enter test scenario description here" className="fp_input" />
          </Form.Item>

          <div className="form-section">
            <h4 className="fp_frm_title">Pre-Conditions</h4>
            <Form.List name="preConditions">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...field}
                      label={`Pre-condition ${index + 1}`}
                      rules={[{ required: true, message: 'Please enter a test pre-condition!' }]}
                      key={field.key}
                    >
                      <Input
                        placeholder={`Enter test pre-condition ${index + 1} here`}
                        className="fp_input_suffix"
                        suffix={
                          <Button type="dashed" icon={<CloseCircleOutlined />} onClick={() => remove(field.name)} className="fp-drop-down-button" />
                      }
                      />
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block className="fp_btn fp_btn-outline">Add another Pre-condition</Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>

          <div className="form-section">
            <h4 className="fp_frm_title">Test Case Steps</h4>
            <Form.List name="steps">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...field}
                      label={`Step ${index + 1}`}
                      rules={[{ required: true, message: 'Please enter a test case step!' }]}
                      key={field.key}
                    >
                      <Input
                        placeholder={`Enter test case step ${index + 1} here`}
                        className="fp_input_suffix"
                        suffix={
                          <Button type="dashed" icon={<CloseCircleOutlined />} onClick={() => remove(field.name)} className="fp-drop-down-button" />
                      }
                      />
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block className="fp_btn fp_btn-outline">Add another Step</Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>

          <div className="form-section">
            <h4 className="fp_frm_title">Expected Outcome</h4>
            <Form.List name="expectedOutcome">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...field}
                      label={`Expected Result ${index + 1}`}
                      rules={[{ required: true, message: 'Please enter an expected result!' }]}
                      key={field.key}
                    >
                      <Input
                        placeholder={`Enter expected result ${index + 1} here`}
                        className="fp_input_suffix"
                        suffix={(
                          <Button
                            type="dashed"
                            icon={<CloseCircleOutlined />}
                            onClick={() => remove(field.name)}
                            className="fp-drop-down-button"
                          />
                      )}
                      />
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      className="fp_btn fp_btn-outline"
                    >
                      Add another Expected Result
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
          <Form.Item name="testcaseId" className="fp_dymmy_form_item" />
          <Form.Item name="testcaseIndex" className="fp_dymmy_form_item" />
        </Form>
      </Modal>
      <ConfirmationModal
        open={isConfirmationModalOpen}
        title={form.getFieldValue('testcaseId') ? 'Are you sure you want to edit this testcase' : ''}
        body={form.getFieldValue('testcaseId') ? 'You are attempting to edit a test case generated by TestGenie. Please note that this action is permanent and cannot be undone. Are you sure you want to proceed with editing this test case?' : 'Are you sure you want to create this testcase'}
        onCancel={() => handleCancel()}
        onProceed={() => handleConfirmation()}
      />
    </>
  );
};

export default TestCaseAddEditModal;
