import {
  Modal, Button, Input, Form,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { generateGuestUrl } from '../action';
import { useAppSelector } from '../../../redux/hooks';
import Loading from '../../../components/Loading';
import { notifyInProgress, notifySuccess } from '../../../utils/notification';

interface propType {
  open: boolean,
  onCancel: Function,
}
const GuestLinkModal = ({
  open, onCancel,
}: propType) => {
  const [guestData, setGuestData] = useState<any>();
  const { monitorId } = useParams<any>();
  const accountId = useAppSelector(({ userData }: any) => userData?.selectedAccount?.accountId);

  const fetchData = async () => {
    const payload = await generateGuestUrl({ accountId, monitorId });
    setGuestData(payload);
  };

  const copyGuestData = () => {
    onCancel();
    notifyInProgress('Copying Guest Link Information');
    const text = `Guest URL: ${guestData.url}
    OTP Code: ${guestData.otp}`;
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      notifySuccess('Guest Link Information Copied To Your Clipboard Sucessfully!');
      setGuestData(undefined);
    }, 1500);
  };

  const handleOnClose = () => {
    setGuestData(undefined);
    onCancel();
  };

  useEffect(() => {
    if (open) fetchData();
  }, [open]);

  return (
    <Modal
      className="max-user-modal fp-modal fp-modal-primary fp_middle_notification_model"
      open={open}
      title="Guest Link"
      onCancel={() => handleOnClose()}
      closable={false}
      footer={guestData ? [
        <Button
          type="primary"
          className="fp_btn fp_btn-primary"
          onClick={() => copyGuestData()}
          style={{ marginBottom: 15, marginRight: 15 }}
        >
          Copy & Close
        </Button>,
        <Button
          type="primary"
          className="fp_btn fp_btn-primary"
          onClick={() => handleOnClose()}
          style={{ marginBottom: 15, marginRight: 15 }}
        >
          Close
        </Button>,
      ] : []}
    >
      {guestData ? (
        <Form layout="vertical">
          <Form.Item label="Guest Link">
            <Input.TextArea disabled value={guestData.url} className="fp_input" rows={4} style={{ height: 70, cursor: 'pointer' }} />
          </Form.Item>
          <Form.Item style={{ marginTop: 25 }} label="OTP Code">
            <Input disabled value={guestData.otp} className="fp_input" style={{ cursor: 'pointer' }} />
          </Form.Item>
        </Form>
      ) : <Loading plain />}
    </Modal>
  );
};

export default GuestLinkModal;
