import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Form, Input, Button, Spin, Checkbox,
} from 'antd';
import { signUp, SignUpParams } from './action';
import { useAppDispatch } from '../../redux/hooks';
import { ROUTES } from '../../constants';
import { setEmail } from '../../redux/userSlice';
import { Logo } from '../../components';
import SignUpErrorModal from './components/SignUpErrorModal';
import GoogleReCaptcha from './components/GoogleReCaptcha';

const SignUp = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [signupInProgress, setSignupInProgress] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [recaptchaToken, setRecaptchaToken] = useState<string>('');

  const navigateToLogin = () => {
    history.push(`${ROUTES.LOGIN}`);
  };

  const onSignUp = async () => {
    setErrorText('');
    setSignupInProgress(true);
    const {
      fname, lname, email, password,
    } = form.getFieldsValue();
    const params: SignUpParams = {
      firstName: fname,
      lastName: lname,
      email,
      password,
      token: recaptchaToken,
    };
    try {
      const signedUp = await signUp(params);
      dispatch(setEmail(email));
      if (signedUp) history.replace(ROUTES.VERIFY, { from: 'signup' });
    } catch (error: any) {
      if (error.message.includes('Email is already registered but not verified')) {
        setModalOpen(true);
      } else {
        setErrorText(error.message);
      }
    } finally {
      setSignupInProgress(false);
    }
  };

  const errorMSGView = errorText
    && (
      <div className="fp_cmpnt fp_cmpnt_jsfy_cntn_cntr">
        <p className="fp_medium-text-2 fp_err_text">
          {errorText}
        </p>
      </div>
    );

  const loadingIcon = signupInProgress ? <Spin /> : null;

  return (
    <div className="fp_full_size fp_pg_center">
      <Form className="fp_lgn_frm" form={form} layout="vertical" requiredMark onFinish={onSignUp}>
        <div className="fp_cmpnt fp_cmpnt_cntr fp_logo_color">
          <Logo />
        </div>
        <div className="fp_cmpnt fp_cmpnt_cntr">
          <p className="fp_lgn_ttl">Sign up to new account</p>
        </div>
        <Form.Item label="First Name" name="fname" rules={[{ required: true, message: 'Please input your first name!' }]}>
          <Input data-testid="fp_first_name" disabled={signupInProgress} />
        </Form.Item>
        <Form.Item label="Last Name" name="lname" rules={[{ required: true, message: 'Please input your last name!' }]}>
          <Input data-testid="fp_last_name" disabled={signupInProgress} />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'The input is not valid email!', validateTrigger: 'onChange' }, { required: true, message: 'Please input your email!' }]}>
          <Input data-testid="fp_email" disabled={signupInProgress} />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }, { pattern: new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*[!@#$&*])(?=.*?[0-9]).{8,}$'), message: 'Your password must be a minimum of 8 characters and include at least one uppercase letter, one lowercase letter, one number, and one special character' }]}>
          <Input.Password data-testid="fp_password" disabled={signupInProgress} />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          requiredMark
          rules={[{ required: true, message: 'Please input your confirm password!' }, ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
          ]}
        >
          <Input.Password data-testid="fp_confirm_password" disabled={signupInProgress} />
        </Form.Item>
        <Form.Item
          name="termsAndConditions"
          valuePropName="checked"
          wrapperCol={{ offset: 0, span: 24 }}
          rules={[
            {
              required: true,
              message: 'You must accept the Terms and Conditions in order to proceed with signing up',
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject(
                    new Error(),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Checkbox>
            <p>
              Accept
              <a className="fp_lnk" href="https://www.footprint.systems/termsAndConditions" target="_blank" rel="noopener noreferrer">
                terms of service
              </a>
              and
              <a className="fp_lnk" href="https://www.footprint.systems/privacyPolicy" target="_blank" rel="noopener noreferrer">
                privacy policies.
              </a>
            </p>
          </Checkbox>
        </Form.Item>
        <Form.Item className="fp_mrgn_tp_20">
          <Button data-testid="fp_signup" type="primary" icon={loadingIcon} disabled={signupInProgress} className="fp_btn fp_btn-primary fp_btn-login" htmlType="submit">Sign up</Button>
        </Form.Item>
        {errorMSGView}
        <div className="fp_cmpnt fp_cmpnt_flx_rw fp_cmpnt_jsfy_cntn_cntr">
          <p className="fp_medium-text-2">Already have an account?</p>
          <Link data-testid="fp_go_login" className="fp_lnk" to="/login">Login here</Link>
        </div>
        <SignUpErrorModal
          open={isModalOpen}
          navigateToLogin={navigateToLogin}
        />
      </Form>
      <GoogleReCaptcha setRecaptchaToken={setRecaptchaToken} />
    </div>
  );
};

export default SignUp;
