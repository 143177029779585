import React from 'react';
import {
  Drawer, Row, Tag, Col,
} from 'antd';
import moment from 'moment';
import { CloseCircleOutlined } from '@ant-design/icons';
import StatusView from './StatusView';

interface propTypes {
  executions: any[];
  open: boolean;
  onClose: any;
  onSelect: Function;
}

const ExecutionDrawer = ({
  executions, open, onClose, onSelect,
}: propTypes) => (
  <Drawer
    title="Executions"
    closable={false}
    placement="right"
    onClose={onClose}
    open={open}
    className="fp-drawer"
    width={600}
    extra={(
      <CloseCircleOutlined style={{ color: 'black', fontSize: 30 }} onClick={onClose} />
  )}
  >
    {executions.map(({
      status, executionId, placedAt, dryRun = false,
    }: any, index: number) => (
      <Row className="execution-item" onClick={() => onSelect(executionId)}>
        <Col md={18}>
          <p style={{ marginTop: '3px' }}>{`Execution ${executions.length - index} ${moment(placedAt).format('lll')}`}</p>
        </Col>
        <Col md={6}>
          {dryRun ? <Tag className="dry-run-tag">Dry Run</Tag> : <div className="execution-drawer-execution-status"><StatusView status={status} /></div>}
        </Col>
      </Row>
    ))}
  </Drawer>
);

export default ExecutionDrawer;
