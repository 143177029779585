import React from 'react';
import { Skeleton, SkeletonProps, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

export type SkeletonTableColumnsType = {
  key: string;
};

type SkeletonTableProps = SkeletonProps & {
  columns: ColumnsType<SkeletonTableColumnsType>;
  rowCount?: number;
};

export default function SkeletonTable({
  loading = false,
  active = false,
  columns,
  rowCount = 5,
  children,
  className,
}: SkeletonTableProps) {
  return loading ? (
    <Table
      rowKey="key"
      className="fp_tst_rslt"
      pagination={false}
      dataSource={[...Array(rowCount)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={columns.map((column) => ({
        ...column,
        render: function renderPlaceholder() {
          return (
            <Skeleton.Input
              key={column.key}
              active={active}
              className={className}
              size="large"
            />
          );
        },
      }))}
    />
  ) : (
    <>{children}</>
  );
}
