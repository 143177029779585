import React, { useEffect, useState } from 'react';
import {
  Row, Col, Typography, Button, Modal, Card,
} from 'antd';
import { EditIcon, VisaIcon, AddIcon } from '../../../components';
import { StripeIPG } from '../../../components/IPG/StripeIPG';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getCardList } from '../../testcaseCreation/action';
import { notifyError } from '../../../utils/notification';
import Loading from '../../../components/Loading';
import { getUserAccounts } from '../../main/actions';

const { Title } = Typography;

const CardDetails = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(({ userData }) => userData.userId);
  const [isCardAvailable, setCardAvailable] = useState<boolean>(false);
  const [availableCards, setAvailableCards] = useState<[]>([]);
  const [isCardInfoOpen, setIsCardInfoOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const selectedAccount = useAppSelector(({ userData }: any) => userData.selectedAccount);

  const fetchCardList = async () => {
    const cardList = await getCardList(selectedAccount.customerId);
    setAvailableCards(cardList);
    setCardAvailable(cardList.length > 0);
  };

  useEffect(() => {
    async function loadCards() {
      await fetchCardList();
      setLoading(false);
    }
    try {
      if (selectedAccount?.customerId) {
        setLoading(true);
        loadCards();
      }
    } catch (e) {
      notifyError("Something wen't wrong", 'something_went_wrong');
    }
  }, [selectedAccount?.customerId]);

  const handleOk = async () => {
    setIsCardInfoOpen(false);
    setLoading(true);
    await getUserAccounts(userId, dispatch);
    if (selectedAccount?.customerId) {
      await fetchCardList();
      setLoading(false);
    }
  };

  const renderCardInfoForm = () => (
    <Modal
      className="fp_profile_modal"
      style={{ top: '10%' }}
      open={isCardInfoOpen}
      onOk={handleOk}
      onCancel={() => setIsCardInfoOpen(false)}
      closable={false}
      footer={null}
    >
      <StripeIPG
        accountId={selectedAccount?.accountId}
        handleOk={handleOk}
        onCancel={() => setIsCardInfoOpen(false)}
        profileview
      />
    </Modal>
  );

  const cardDetailsView = isCardAvailable ? (
    <div>
      {
        availableCards.map(({ card }: any) => (
          <Row className="card-main">
            <Col>
              <Card className="card-container" key={card.last4}>
                <h3 className="fp_rqg_crd-ttl">{`**** **** ${card.last4}`}</h3>
                <p className="fp_rqg_crd-exp-dte">{`${card.exp_year}/${card.exp_month}`}</p>
                <VisaIcon />
              </Card>
            </Col>
          </Row>
        ))
      }
      <Col className="edit-btn-con">
        <Button onClick={() => setIsCardInfoOpen(true)} type="primary" shape="round" icon={<EditIcon />}>
          Edit
        </Button>
      </Col>
    </div>
  ) : (
    <Row style={{ cursor: 'pointer' }} onClick={() => setIsCardInfoOpen(true)} className="add-card-view">
      <Col span={24}>
        <AddIcon />
        <span>Add new Card</span>
      </Col>
    </Row>
  );

  return (
    <Row className="bank-details-card">
      <Col span={24}>
        <Row className="card-details">
          <Col>
            <Title level={5}>Card Details</Title>
            <p>
              {isCardAvailable ? 'Edit card details' : 'Add card details'}
            </p>
          </Col>
        </Row>
        {isCardInfoOpen && renderCardInfoForm()}
        {isLoading ? <Loading plain size="large" /> : cardDetailsView}
      </Col>
    </Row>
  );
};

export default CardDetails;
