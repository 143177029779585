import {
  Button,
  Modal,
  Row,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';

interface SupportViewModalProps {
  visible: boolean,
  onOk: () => void,
  onCancel: () => void,
}

const SupportViewModal = ({ visible, onOk, onCancel }: SupportViewModalProps) => (
  <Modal
    className="fp-modal fp-modal-primary"
    closable={false}
    open={visible}
    centered
    onOk={onOk}
    onCancel={onCancel}
    footer={[
      <Row justify="center" align="middle">
        <Button
          type="primary"
          className="fp_btn fp_btn-primary"
          onClick={onOk}
        >
          Ok
        </Button>
      </Row>,
    ]}
  >
    <Title level={4} style={{ color: '#FFFFFF' }}>Thank you for choosing Footprint.</Title>
    <p>We value your feedback and look forward to assisting you.</p>
  </Modal>
);

export default SupportViewModal;
