import {
  Breadcrumb, Input,
  Button, Col, Row, notification,
} from 'antd';
import moment from 'moment';
import React, {
  useEffect, useState, useRef,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { EXECUTION_RESULT } from '../../../constants';
import { notifyInProgress, notifySuccess } from '../../../utils/notification';
import GeneratedTestSuitListView from './GeneratedTestSuitListView';
import { useAppSelector } from '../../../redux/hooks';
import {
  createEditTestSuite,
  deleteTestCaseFromSuite, getTestCase, getTestSuite, runTestSuite,
} from '../action';
import IDEModal from '../modals/IDEModal';
import StatusView from '../../testcases/result/components/StatusView';
import { SERVICE } from '../../../types/common';
import ExecutionDeviceSelectionModal from '../../testcaseCreation/components/testCaseCreatedResult/modals/ExecutionDeviceSelectionModal';
import ExecutionApproachSelectionModal from './ExecutionApproachSelectionModal';
import RunningApproachSelectionModal from './RunningApproachSelectionModal';

const GeneratedTestScript = () => {
  const { testSuiteId }: { testSuiteId: string } = useParams();
  const accountId = useAppSelector(({ userData }: any) => userData?.selectedAccount?.accountId);
  const userId = useAppSelector((state) => state.userData.userId);
  const history = useHistory();
  const autoRefreshIntervalRef = useRef<any>(null);

  const [testSuite, setTestSuite] = useState<any>();
  const [searchedTestCases, SetSearchedTestCases] = useState<any[]>([]);
  const [isIdeModal, setIdeModal] = useState<boolean>(false);
  const [selectedTestCase, setSelectedTestCase] = useState<any>();
  const [isEDSModal, setEDSModal] = useState<boolean>(false);
  const [isEASModal, setEASModal] = useState<boolean>(false);
  const [isRASModal, setRASModal] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isParallelRun, setParallelRun] = useState<boolean | undefined>();
  const [buttonName, setButtonName] = useState<string>('');

  const fetchData = async () => {
    const tsRes: any = await getTestSuite(accountId, testSuiteId);
    SetSearchedTestCases(tsRes?.testcases || []);
    setTestSuite(tsRes);
    return [EXECUTION_RESULT.COMPLETED, EXECUTION_RESULT.FAILED]
      .some((s: string) => s === tsRes.executionStatus);
  };

  const handleSearch = (keyword: string) => {
    SetSearchedTestCases(testSuite?.testcases
      .filter(({ name }: any) => name.toLowerCase().includes(keyword.toLowerCase())));
  };

  const deleteTestCase = async (testcaseId: string) => {
    notifyInProgress('Removing test case from test suite');
    await deleteTestCaseFromSuite(accountId, testSuiteId, testcaseId);
    notification.destroy();
    notifySuccess('Successfully removed test case from test suite');
  };

  const selectTestCase = async (testcaseId: string) => {
    const { testcase } = await getTestCase(accountId, testSuiteId, testcaseId);
    setSelectedTestCase(testcase);
    setIdeModal(true);
  };

  const handleAutoRefresh = async () => {
    await fetchData();
    if (autoRefreshIntervalRef.current) clearInterval(autoRefreshIntervalRef.current);
    const interval = setInterval(async () => {
      const flag: Boolean = await fetchData();
      if (flag) {
        clearInterval(interval);
        clearInterval(autoRefreshIntervalRef.current);
      }
    }, 10000);
    autoRefreshIntervalRef.current = interval;
  };

  const runSuite = async (selectedEDB: string[]) => {
    await runTestSuite(
      {
        accountId,
        testSuiteId,
        service: SERVICE.TEST_SUITE,
        userId,
        selectedEDB,
        isParallelRun,
      },
    );
    handleAutoRefresh();
  };

  const changeTestCaseOrder = async (testcases: any[]) => {
    await createEditTestSuite({ accountId, testSuiteId, testcases });
    handleAutoRefresh();
  };

  const handleInitialDataFetch = async () => {
    setLoading(true);
    await handleAutoRefresh();
    setLoading(false);
  };

  useEffect(() => {
    if (accountId) {
      handleInitialDataFetch();
    }
    return () => {
      clearInterval(autoRefreshIntervalRef.current);
    };
  }, []);

  return (
    <div className="fp_pg_base fp_test_exec_suit">
      <Row>
        <Button
          data-testid="fp_previous_btn"
          className="back_btn fp_btn-primary fp_btn"
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </Row>
      <Row>
        <p className="fp_heading-2 fp_heading-2-middle">{testSuite?.name}</p>
      </Row>

      <Row justify="space-between" className="fp_mrgn_tp_30">
        <Col>
          <Row>
            <Breadcrumb separator=">" className="bread-crumb-seperator">
              <Breadcrumb.Item className="breadcrumb-item">Test execution suite</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-item">{testSuite?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Col>
        <Col>
          <Input
            prefix={<SearchOutlined />}
            className="fp_exec_suit_search"
            placeholder="Search test scripts"
            onChange={(e: any) => handleSearch(e.target.value)}
            data-testid="fp_project_search"
          />
        </Col>
      </Row>
      <Row className="fp_overall_container">
        <div className="fp_overall_container_left">
          <span>Overall Result</span>
          <span>
            <div className="fp_testcase_test_status">
              <StatusView
                status={testSuite?.executionStatus}
                executionResults={[]}
                passPercentage={String(Math.round(testSuite?.passPercentage * 100) / 100 || 0)}
                showIcons
              />
            </div>
          </span>
          {testSuite?.lastRun && (
          <span>
            Last run :
            {' '}
            {moment(testSuite?.lastRun).format('lll')}
          </span>
          )}
        </div>
        <Button className="fp_btn fp_btn-primary" onClick={() => setEASModal(true)}>Run all</Button>
      </Row>
      <GeneratedTestSuitListView
        testcases={searchedTestCases}
        deleteTestCase={deleteTestCase}
        selectTestCase={selectTestCase}
        isLoading={isLoading}
        changeTestCaseOrder={changeTestCaseOrder}
      />
      <IDEModal
        open={isIdeModal}
        setOpen={(flag: boolean) => setIdeModal(flag)}
        testcase={selectedTestCase}
        testSuite={testSuite}
      />
      <ExecutionApproachSelectionModal
        open={isEASModal}
        onClose={(flag: boolean) => {
          setEASModal(false);
          if (flag) {
            setRASModal(true);
          }
        }}
        setParallelRun={setParallelRun}
      />
      <RunningApproachSelectionModal
        open={isRASModal}
        onClose={(flag: boolean, isSchedule: boolean) => {
          setRASModal(false);
          if (isSchedule) {
            setButtonName('Schedule');
          } else {
            setButtonName('Run Now');
          }
          if (flag) {
            setEDSModal(true);
          }
        }}
        setParallelRun={setParallelRun}
      />
      <ExecutionDeviceSelectionModal
        open={isEDSModal}
        onClose={setEDSModal}
        onRun={runSuite}
        buttonName={buttonName}
      />
    </div>
  );
};

export default GeneratedTestScript;
