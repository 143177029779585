import React, { useEffect, useState } from 'react';
import { TESTCASE_CREATION_STEPS } from '../../constants';
import RequirementGatheringView from './RequirementGatheringView';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setSelectedBilledPlan } from '../../redux/billedPlanSlice';
import Loading from '../../components/Loading';
import { getPaymentPlan } from '../testcaseCreation/action';
import PaymentPlan from '../testcaseCreation/components/paymentPlan/PaymentPlan';
import { SERVICE_TYPE } from '../../types/common';

const TestcaseCreationMain = ({ entryType }: { entryType: string }) => {
  const dispatch = useAppDispatch();
  const [testcaseCreationStep, setTestcaseCreationStep] = useState<string>();
  const paymentPlans = useAppSelector(({ billedPlanData }: any) => billedPlanData.billedPlans);
  const subscribedServices = useAppSelector(({ serviceData }: any) => serviceData.servicesData);

  const setDefaultConfig = async (serviceRecord: any) => {
    if (!paymentPlans || paymentPlans.length === 0) {
      await getPaymentPlan(dispatch);
    }
    const selectedSubscription = await paymentPlans
      .find((subscription: any) => subscription.paymentPlanId === serviceRecord.paymentPlanId);
    dispatch(setSelectedBilledPlan(selectedSubscription));
    setTestcaseCreationStep(TESTCASE_CREATION_STEPS.REQUIREMENT_GATHERING);
  };

  useEffect(() => {
    async function loadServices() {
      if (subscribedServices) {
        const serviceRecord = await subscribedServices
          .find((service: any) => service.serviceId === SERVICE_TYPE.TEST_CASE_GENERATOR);
        if (serviceRecord && serviceRecord.paymentPlanId) {
          await setDefaultConfig(serviceRecord);
        } else {
          setTestcaseCreationStep(TESTCASE_CREATION_STEPS.PAYMENT_PLAN);
        }
      }
    }
    loadServices().finally();
  }, [subscribedServices]);

  const renderView = () => {
    switch (testcaseCreationStep) {
      case TESTCASE_CREATION_STEPS.PAYMENT_PLAN:
        return <PaymentPlan setTestcaseCreationStep={setTestcaseCreationStep} />;
      case TESTCASE_CREATION_STEPS.REQUIREMENT_GATHERING:
        return <RequirementGatheringView entryType={entryType} />;
      default:
        return <Loading plain size="large" />;
    }
  };

  if (!subscribedServices || subscribedServices.lenght === 0) {
    return <Loading plain size="large" />;
  }

  return renderView();
};

export default TestcaseCreationMain;
