import { BulbOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { FormInstance } from 'antd/es/form/Form';
import ConfirmationModal from './ConfirmationModal';
import ErrorModal from './ErrorModal';
import IDEModal from './IDEModal';
import TestCaseAddEditModal from './TestCaseAddEditModal';
import TestCaseModal from './TestCaseModal';
import TestCaseTestDataModal from './TestCaseTestDataModal';
import ExtensionInstructionsModal from './ExtensionInstructionsModal';

export interface ScriptType {
  generatedAt: string,
  fileName: string,
  key: string,
  masterScriptName: string
  script?: string
  executionStatus?: string
  executionId?: string
  execution?: any
}
interface RootModalProps {
  showNotification: Boolean,
  tableData: any[],
  summeryData: any,
  setShowNotification: Function,
  rowData: any[],
  indexValue: number,
  modalVisible: boolean,
  setModalVisible: Function,
  isError: boolean,
  isFreshGenerate: boolean,
  setError: Function,
  isTestCaseModal: boolean,
  setTestCaseModal: Function,
  saveTestCase: Function,
  form: FormInstance,
  isIdeModal: boolean,
  setIdeModal: Function,
  selectedTestCase: any,
  setSelectedTestCase: Function,
  selectedScript: any,
  setSelectedScript: Function,
  isTestDataModal: boolean,
  setTestDataModal: Function,
  isExtensionInstructionsModal: boolean,
  setExtensionInstructionsModal: Function,
  isDeleteConfirmModel: boolean,
  setDeleteConfirmModel: Function,
  delTestCase: Function,
  isScriptNotificationOpen: boolean,
  generateTestCaseScript: Function,
  triggerAutoRefresh: Function,
  runAutomationScript: Function,
  cancelExecutionScript: Function,
  projectName: string,
  setNewTestCase: Function
}

const RootModal = ({
  showNotification,
  tableData,
  summeryData,
  setShowNotification,
  rowData,
  indexValue,
  modalVisible,
  setModalVisible,
  isError,
  isFreshGenerate,
  setError,
  isTestCaseModal,
  setTestCaseModal,
  saveTestCase,
  form,
  isIdeModal,
  setIdeModal,
  selectedTestCase,
  setSelectedTestCase,
  selectedScript,
  setSelectedScript,
  isTestDataModal,
  setTestDataModal,
  isExtensionInstructionsModal,
  setExtensionInstructionsModal,
  isDeleteConfirmModel,
  setDeleteConfirmModel,
  delTestCase,
  isScriptNotificationOpen,
  generateTestCaseScript,
  triggerAutoRefresh,
  runAutomationScript,
  cancelExecutionScript,
  projectName,
  setNewTestCase,
}: RootModalProps) => {
  const [selectedMasterScript, setSelectedMasterScript] = useState<ScriptType | undefined>();
  return (
    <>
      <ConfirmationModal
        open={Boolean(showNotification && !tableData.length)}
        title={<BulbOutlined />}
        body={summeryData?.requirement?.noOfTestcases > 0 ? 'We are currently processing your requirements to generate test cases. This process may take 2-3 minutes. We appreciate your patience and thank you for choosing FootPrint.' : "If you're planning to automate a user journey, consider breaking it down into smaller, more manageable steps. This approach often leads to more effective and reliable scripts. Instead of trying to automate the entire process in one go, identify individual actions or stages in the journey and create separate automation tasks for each. This can also make it easier to diagnose and fix any issues that may arise during automation"}
        onCancel={() => setShowNotification(false)}
        onProceed={() => setShowNotification(false)}
        proceedButtonText="Ok"
        showCancelButton={false}
      />

      <TestCaseModal
        rowData={rowData}
        indexValue={indexValue}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
      />

      <ErrorModal
        isError={isError && isFreshGenerate}
        setError={setError}
      />

      <TestCaseAddEditModal
        open={isTestCaseModal}
        setOpen={setTestCaseModal}
        saveTestCase={saveTestCase}
        setSelectedTestCase={setSelectedTestCase}
        form={form}
      />

      <IDEModal
        open={isIdeModal}
        setOpen={setIdeModal}
        saveTestCase={saveTestCase}
        summeryData={summeryData}
        form={form}
        testcase={selectedTestCase}
        setSelectedTestCase={setSelectedTestCase}
        selectedScript={selectedScript}
        setSelectedScript={setSelectedScript}
        runAutomationScript={runAutomationScript}
        cancelExecutionScript={cancelExecutionScript}
        selectedMasterScript={selectedMasterScript}
        setSelectedMasterScript={setSelectedMasterScript}
        reGenerateTCScript={generateTestCaseScript}
        projectId={summeryData?.requirement?.projectId}
        projectName={projectName}
        triggerAutoRefresh={triggerAutoRefresh}
        setNewTestCase={setNewTestCase}
      />

      <TestCaseTestDataModal
        open={isTestDataModal}
        setOpen={setTestDataModal}
        saveTestCase={saveTestCase}
        form={form}
        testcase={selectedTestCase}
        projectId={summeryData?.requirement?.projectId}
      />

      <ConfirmationModal
        open={isDeleteConfirmModel}
        title="Are you sure you want to delete this testcase"
        body="You are attempting to delete a test case generated by TestGenie. Please note that this action is permanent and cannot be undone. Are you sure you want to proceed with deleting this test case?"
        onCancel={() => {
          setDeleteConfirmModel(false);
          form.resetFields();
          setSelectedTestCase(undefined);
          setSelectedScript(undefined);
        }}
        onProceed={() => {
          form.getFieldValue('testcaseId');
          delTestCase(form.getFieldValue('testcaseId'));
        }}
      />

      <ExtensionInstructionsModal
        visible={isExtensionInstructionsModal}
        onCancel={() => {
          setExtensionInstructionsModal(false);
          setIdeModal(true);
        }}
      />

      <ConfirmationModal
        open={isScriptNotificationOpen}
        title=""
        body="It seems that the details of the Test Case have been modified. Would you like to generate a new script to reflect these changes?"
        onCancel={() => {
          setShowNotification(false);
          setIdeModal(true);
        }}
        onProceed={async () => {
          await generateTestCaseScript(selectedTestCase, selectedScript);
          setShowNotification(false);
        }}
        proceedButtonText="Regenerate"
      />
    </>
  );
};

export default RootModal;
