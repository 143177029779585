import React, { useEffect, useState } from 'react';
import {
  Button, Form, Input, Modal, Table, Tooltip, Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DeleteIcon } from '../../../../components';
import ConfirmationModal from '../../../testcaseCreation/components/testCaseCreatedResult/modals/ConfirmationModal';
import { formateTestDataToArray } from '../../../../utils/commonUtils';
import { addEditProjectTestData, getGlobalTestData } from '../../actions';
import { useAppSelector } from '../../../../redux/hooks';
import Loading from '../../../../components/Loading';

interface proType {
  open: boolean,
  setOpen: Function,
  projectId: string,
}

type TestDataType = {
  key: string;
  testDataType: string;
  testData: string;
  assetTag: string;
};

const initialData: TestDataType[] = [
  {
    key: '1',
    testDataType: '',
    testData: '',
    assetTag: '',
  },
];

const { Title } = Typography;

const GlobalTestDataModal = ({
  open, setOpen, projectId,
}: proType) => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

  const accountId: string = useAppSelector(
    ({ userData }: any) => userData.selectedAccount.accountId,
  );
  const [data, setData] = useState<TestDataType[]>(initialData);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const triggerConfirmationModal = () => {
    setOpen(false);
    setConfirmationModalOpen(true);
  };

  const handleConfirmation = async () => {
    setConfirmationModalOpen(false);
    const globalTestData = formateTestDataToArray(form.getFieldValue('testData'));
    setData(initialData);
    await addEditProjectTestData(accountId, projectId, globalTestData);
  };

  const handleCancel = () => {
    setConfirmationModalOpen(false);
    setOpen(true);
    form.resetFields();
  };

  const handleDeleteRow = (record: TestDataType) => {
    const updatedData = data.filter((item) => item.key !== record.key);
    setData(updatedData);
  };

  const handleAddRow = () => {
    const newRow: any = {
      key: `${data.length + 1}`,
      testDataType: '',
      testData: '',
      assetTag: '',
    };
    setData([...data, newRow]);
  };

  const fetchTestData = async () => {
    setLoading(true);
    const { globalTestData } = await getGlobalTestData(accountId, projectId);
    const testData: any = {};
    globalTestData.forEach((item: any, index: number) => {
      testData[index + 1] = {
        testDataType: item.testDataType,
        testData: item.testData,
        assetTag: item.assetTag,
      };
    });
    setData(formateTestDataToArray(testData, true));
    form.setFieldsValue({ testData });
    setLoading(false);
  };

  useEffect(() => {
    if (accountId && open && projectId) {
      fetchTestData();
    }
  }, [accountId, open, projectId]);

  const columns: ColumnsType<TestDataType> = [
    {
      title: 'Test data type',
      dataIndex: 'testDataType',
      key: 'testDataType',
      render: (_, record: TestDataType) => (
        <Form.Item
          name={['testData', record.key, 'testDataType']}
          style={{ margin: 0 }}
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Input placeholder="Test data type" className="fp_table_input" />
        </Form.Item>
      ),
    },
    {
      title: 'Test data',
      dataIndex: 'testData',
      key: 'testData',
      render: (_, record: TestDataType) => (
        <Form.Item
          name={['testData', record.key, 'testData']}
          style={{ margin: 0 }}
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Input placeholder="Test data" className="fp_table_input" />
        </Form.Item>
      ),
    },
    {
      title: (
        <span>
          Asset tag
          {' '}
          <Tooltip title="This for the tool tip for asset tag (table) : Asset Tags are unique identifiers like 'AssetID', 'XML Paths', or 'CSS Selectors'. They help your test scripts locate and interact with elements in your application, boosting the robustness and maintainability of your testing process.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: 'assetTag',
      key: 'assetTag',
      render: (_, record: TestDataType) => (
        <Form.Item
          name={['testData', record.key, 'assetTag']}
          style={{ margin: 0 }}
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Input placeholder="Asset tag" className="fp_table_input" />
        </Form.Item>
      ),
    },
  ];

  if (data.length > 1) {
    columns.push({
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 25,
      render: (_, record: TestDataType) => (
        <div className="test_data_modal_action_button_container">
          <Button type="text" onClick={() => handleDeleteRow(record)}>
            <DeleteIcon />
          </Button>
        </div>
      ),
    });
  }

  return (
    <div>
      <Modal
        className="max-user-modal fp-modal fp-modal-primary"
        open={open}
        onCancel={() => { setData(initialData); setOpen(false); }}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" className="fp_btn fp_btn-primary" onClick={() => { setData(initialData); setOpen(false); }}>Discard</Button>
            <Button type="primary" className="fp_btn fp_btn-outline" onClick={() => triggerConfirmationModal()}>Save</Button>
          </div>,
        ]}
      >
        <div>
          <Title level={5} style={{ color: 'white' }}>Global test data</Title>
        </div>
        <Form layout="vertical" form={form} className="fp_frm-ant-form-item">
          {isLoading ? <Loading plain size="large" /> : (
            <>
              <Table className="fp_tst_rslt_modal_table" dataSource={data} columns={columns} pagination={false} />
              <div className="center_elements_inside_contenter">
                <Button type="primary" onClick={handleAddRow} className="fp_btn fp_btn-outline">Add new data</Button>
              </div>

            </>
          )}

        </Form>
      </Modal>
      <ConfirmationModal
        open={isConfirmationModalOpen}
        title="Warning"
        body="The Global Test Data File you're about to modify may affect various automation scripts in your project. Please double-check your edits and understand the implications of your changes before saving"
        onCancel={() => handleCancel()}
        onProceed={() => handleConfirmation()}
      />
    </div>
  );
};

export default GlobalTestDataModal;
