import React, { useState } from 'react';
import {
  Button,
  Col, Dropdown, Menu, Row, Tooltip,
  notification,
} from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { MoreOutlined } from '@ant-design/icons';
import { renderText } from '../../utils/commonUtils';
import { ROUTES } from '../../constants';
import { MONITOR_STATUS } from '../../types/common';
import { DeleteIcon, EditIcon } from '../../components';
import DeleteConfirmationModal from '../testExecutionSuit/modals/DeleteConfirmationModal';
import { notifyInProgress, notifySuccess, notifyError } from '../../utils/notification';
import { deleteMonitorFunc, editMonitorJob } from './action';
import { ENABLE_MONITOR_MODE } from '../../configs/app-config';
import EditMonitorModal from './modals/EditMonitorModal';

interface propType {
  job: any
  handleRefresh: Function
}

const renderStatus = (status: string) => {
  switch (status) {
    case MONITOR_STATUS.COMPLETE:
      return <p className="customize-text-completed">Complete</p>;
    case MONITOR_STATUS.IN_PROGRESS:
      return <p className="customize-text-inprogress">In Progress</p>;
    case MONITOR_STATUS.PAUSED:
      return <p className="customize-text-paused">Paused</p>;
    default:
      return <></>;
  }
};

const MonitoringItemListViewItem = ({ job, handleRefresh }: propType) => {
  const history = useHistory();

  const [editMonitor, setEditMonitor] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleViewButton = () => {
    history.push(`${ROUTES.MONITOR}/${job.monitorId}`);
  };

  const deleteMonitorJob = async () => {
    try {
      notifyInProgress('Deleting monitor');
      setModalOpen(false);
      await deleteMonitorFunc({ accountId: job.accountId, monitorId: job.monitorId });
      handleRefresh();
      notification.destroy();
      notifySuccess('Monitor deleted successfully');
    } catch (err: any) {
      console.log('err :', err.message);
      notification.destroy();
      notifyError('Failed to delete the monitor');
    }
  };

  const handleSave = async (monitor: any) => {
    try {
      notifyInProgress('Saving monitor name');
      setEditMonitor(false);
      await editMonitorJob({ accountId: job.accountId, monitorId: job.monitorId }, monitor);
      handleRefresh();
      notification.destroy();
      notifySuccess('Monitor saved successfully');
    } catch (err: any) {
      console.log('error: ', err?.message);
      notification.destroy();
      notifyError('Failed to save monitor');
    }
  };

  return (
    <div className="fp_test_exec_list_item" key={JSON.stringify(job)}>
      <Row justify="start" className="fp_list_item_new">
        <Col span={ENABLE_MONITOR_MODE ? 12 : 7} className="right-border">
          <Tooltip title={job.name} className="sm-tooltip-container">
            <p>{renderText(job.name, 60)}</p>
          </Tooltip>
        </Col>
        {!ENABLE_MONITOR_MODE && (
          <Col span={5} className="fp_list_item_module column-align right-border">
            <Tooltip title={`${job.projectName ? job.projectName : 'none'} / ${job.moduleName}`}><div>{job.projectName}</div></Tooltip>
            <span>/</span>
            <Tooltip title={`${job.projectName ? job.projectName : 'none'} / ${job.moduleName}`}><div>{job.moduleName}</div></Tooltip>
          </Col>
        )}
        <Col span={3} className="column-align right-border"><p>{`Every ${job.repeatFrequency}`}</p></Col>
        <Col span={4} className="column-align right-border"><p>{job?.lastRun ? moment(job?.lastRun).format('lll') : '-'}</p></Col>
        <Col span={2} className="column-align right-border">{renderStatus(job.status)}</Col>
        <Col span={2} className="column-align right-border" style={{ display: 'flex' }}>
          <Row className="fp_test_exec_list_item_action">
            <Button
              style={{ fontSize: '16px' }}
              onClick={handleViewButton}
              type="primary"
              className="fp_btn fp_btn-primary fp_btn-view"
            >
              View
            </Button>
          </Row>
        </Col>
        <Col span={1} className="column-align right-border" style={{ display: 'flex' }}>
          <Row>
            <Dropdown overlay={(
              <Menu className="fp-drop-down-menu">
                <Menu.Item>
                  <Button
                    className="fp_btn fp_btn-link"
                    onClick={() => setEditMonitor(true)}
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<EditIcon />}
                  >
                    Edit
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Button
                    className="fp_btn fp_btn-link"
                    onClick={() => setModalOpen(true)}
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                </Menu.Item>
              </Menu>
            )}
            >
              <Button className="fp-drop-down-button" style={{ padding: 0 }}>
                <MoreOutlined
                  className="fp_list_item_more_icon"
                />
              </Button>
            </Dropdown>
          </Row>
        </Col>
      </Row>
      <EditMonitorModal
        title="Edit Monitor Name"
        isModalOpen={editMonitor}
        handleCancel={() => setEditMonitor(false)}
        onOkay={handleSave}
        monitor={job}
        isLoading={false}
      />
      <DeleteConfirmationModal
        title="Are you sure you want to delete this monitor?"
        deleteButtonText="Delete Monitor"
        isModalOpen={isModalOpen}
        handleCancel={() => setModalOpen(false)}
        handleDelete={deleteMonitorJob}
        isLoading={false}
      />
    </div>
  );
};
export default MonitoringItemListViewItem;
