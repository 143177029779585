import React from 'react';
import { Upload, UploadProps } from 'antd';
import { UploadIcon } from '../../../../components';

const { Dragger } = Upload;

type UploadTileProps = {
  title: string;
  description: string;
  setFile: Function;
  acceptExtensions: string[];
  disabled?: boolean;
  fileValidate?: Function;
  onFileRemove: Function;
}

const UploadTile = ({
  title, description, setFile, acceptExtensions, disabled = false, fileValidate = () => { },
  onFileRemove,
}: UploadTileProps) => {
  const props: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    accept: acceptExtensions.join(', '),
    action: '',
    async beforeUpload(file) {
      try {
        await fileValidate(file);
        setFile(file);
        return false;
      } catch (e: unknown) {
        return Upload.LIST_IGNORE;
      }
    },
    onRemove() {
      onFileRemove();
      return true;
    },
  };
  return (
    <Dragger {...props} disabled={disabled}>
      <p className="ant-upload-drag-icon">
        <UploadIcon />
      </p>
      <p className="ant-upload-text">{title}</p>
      <p className="ant-upload-hint">{description}</p>
    </Dragger>
  );
};

export default UploadTile;
