import {
  Button, Col, Image, Row,
} from 'antd';
import React, { useCallback } from 'react';
import {
  TextInfo,
} from '../../../components';

import SeleniumLogo from '../../../components/Logo/selenium_logo.svg';
import PlaywriteLogo from '../../../components/Logo/playwrite_logo.svg';
import GatlingLogo from '../../../components/Logo/gatling_logo.svg';
import { TESTCASE_CREATE_VIEW_TYPE, TESTCASE_TYPE } from '../../../constants';
import { notifyError } from '../../../utils/notification';

type TypeSelectProps = {
  testCaseType: string|undefined;
  setTestCaseType: Function;
  setTestcaseCreateView: Function;
  goNext: Function;
}

const TypeSelect = ({
  testCaseType, setTestCaseType, setTestcaseCreateView, goNext,
}: TypeSelectProps) => {
  const isActive = useCallback((type: string) => (testCaseType === type ? 'fp_testcase_type_tile-active' : ''), [testCaseType]);

  return (
    <div className="fp_type_select_container">
      <TextInfo
        title="Which tool should I use ?"
        text="This page lets you select the tool you have used in your script. Please note, we are supporting only Playwright scripts for the moment."
      />
      <Row
        justify="center"
        gutter={[16, 16]}
        className="fp_testcase_type_tile_container"
      >
        <Col onClick={() => setTestCaseType(TESTCASE_TYPE.PLAYWRITE)} md={{ span: 11, offset: 0 }} sm={{ span: 20, offset: 0 }} xs={{ span: 21, offset: 1 }} key="1" className={`fp_testcase_type_tile ${isActive(TESTCASE_TYPE.PLAYWRITE)}`}><Image preview={false} src={PlaywriteLogo} /></Col>
        <Col onClick={() => setTestCaseType(TESTCASE_TYPE.PLAYWRITE)} md={{ span: 11, offset: 0 }} sm={{ span: 20, offset: 0 }} xs={{ span: 21, offset: 1 }} key="2" className={`fp_testcase_type_tile ${isActive(TESTCASE_TYPE.SELENIUM)}`}><Image preview={false} src={SeleniumLogo} /></Col>
        <Col onClick={() => setTestCaseType(TESTCASE_TYPE.PLAYWRITE)} md={{ span: 11, offset: 0 }} sm={{ span: 20, offset: 0 }} xs={{ span: 21, offset: 1 }} key="3" className={`fp_testcase_type_tile ${isActive(TESTCASE_TYPE.GATLING)}`}><Image preview={false} src={GatlingLogo} /></Col>
        <Col md={{ span: 11 }} sm={{ span: 20 }} xs={{ span: 21 }} className="fp_testcase_type_tile fp_testcase_type_tile_empty" style={{ background: '#0000', cursor: 'default', boxShadow: 'none' }} />
      </Row>
      <Row
        justify="space-between"
        className="fp_navigate_btn_container"
        gutter={[16, 16]}
      >
        <Col
          xl={{ span: 4 }}
          lg={{ span: 5 }}
          md={{ span: 6 }}
          xs={{ span: 24 }}
        >
          <Button
            data-testid="fp_previous_btn"
            type="primary"
            className="fp_btn fp_btn-primary fp_navigate_btn"
            onClick={() => setTestcaseCreateView(TESTCASE_CREATE_VIEW_TYPE.INIT)}
          >
            Previous
          </Button>
        </Col>
        <Col
          xl={{ span: 4 }}
          lg={{ span: 5 }}
          md={{ span: 6 }}
          xs={{ span: 24 }}
        >
          <Button
            ata-testid="fp_next_btn"
            type="primary"
            className="fp_btn fp_btn-primary fp_navigate_btn"
            onClick={() => (testCaseType ? goNext() : notifyError('Please select a type'))}
          >
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TypeSelect;
