/* eslint-disable max-len */
import React from 'react';
import { Col, Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import { passwordValidate } from '../../utils/password';

type ColFormItemProps = {
  label: string,
  name: string,
  isPassword?: boolean,
  value?: string,
  placeHolder?: string,
  disable?: boolean,
  dependencies?: string[];
}

const ColFormItem: React.FC<ColFormItemProps> = (props: ColFormItemProps) => {
  const {
    label, name, isPassword, placeHolder, value, disable, dependencies,
  } = props;

  const rulesHandler = (): Rule[] => {
    if (dependencies && dependencies.length) {
      return [
        ({ getFieldValue }) => ({
          validator(_, input) {
            if (!input || getFieldValue(`${dependencies[0]}`) === input) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
          },
        })];
    }
    if (isPassword) {
      return [{
        required: true,
        message: `Please input ${label} here!`,
      }, ({ getFieldValue }) => (passwordValidate(getFieldValue(`${name}`), label))];
    }
    return [{
      required: true,
      message: `Please input ${label} here!`,
    }];
  };

  return (
    <Col lg={{ span: 24 }} xs={{ span: 24 }} className="col-form-item">
      <Form.Item
        label={label}
        name={name}
        rules={rulesHandler()}
      >
        { isPassword ? <Input.Password defaultValue={value} disabled={disable || false} /> : <Input placeholder={placeHolder} defaultValue={value} disabled={disable || false} /> }
      </Form.Item>
    </Col>
  );
};

export default ColFormItem;
