import React, { useEffect, useState } from 'react';
import {
  Button, Card, Col, Modal, Row,
} from 'antd';
import { useHistory } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import { notifyError } from '../../../../utils/notification';
import {
  ROUTES, TESTCASE_CREATION_STEPS,
} from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { BilledPlan, setSelectedBilledPlan } from '../../../../redux/billedPlanSlice';
import EnterprisePlanForm from './EnterpricePlanForm';
import { changeServiceSubscription, getPaymentPlan } from '../../action';
import { getServicesList } from '../../../main/actions';

type propType = {
  setTestcaseCreationStep?: Function;
  cardService?: string;
  closePaymentModel?: Function;
  isPaymentPlanChange?: boolean;
}

const PaymentPlan = ({
  cardService,
  closePaymentModel,
  setTestcaseCreationStep,
  isPaymentPlanChange = false,
}: propType) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [paymentPlans, setPaymentPlans] = useState<BilledPlan[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const billedPlans: Array<BilledPlan> = useAppSelector(({ billedPlanData }) => billedPlanData
    .billedPlans);
  const selectedBilledPlan = useAppSelector(({ billedPlanData }) => billedPlanData
    .selectedBilledPlan);
  const subscribedServices = useAppSelector(({ serviceData }: any) => serviceData.servicesData);

  const accountId = useAppSelector(({ userData }) => userData?.selectedAccount?.accountId);

  const handleClick = (key: any) => {
    dispatch(setSelectedBilledPlan(key));
    if (key.planName === 'Trailblazer') {
      setIsModalOpen(true);
    }
  };

  const setDefaultConfig = async (serviceRecord: any) => {
    if (!paymentPlans || paymentPlans.length === 0) {
      await getPaymentPlan(dispatch);
    }
    const selectedSubscription = await paymentPlans
      .find((subscription: any) => subscription.paymentPlanId === serviceRecord.paymentPlanId);
    dispatch(setSelectedBilledPlan(selectedSubscription));
  };

  const loadServices = async () => {
    if (subscribedServices) {
      const serviceRecord = await subscribedServices
        .find((service: any) => service.serviceId === cardService);
      if (serviceRecord && serviceRecord.paymentPlanId) {
        await setDefaultConfig(serviceRecord);
      }
    }
  };

  useEffect(() => {
    loadServices().finally();
  }, [subscribedServices]);

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handlePlanChange = async () => {
    if (selectedBilledPlan && accountId) {
      await changeServiceSubscription(
        accountId, selectedBilledPlan.paymentType, selectedBilledPlan.paymentPlanId,
      );
      await getServicesList(accountId, dispatch);
      await loadServices();
    } else {
      notifyError('Please select payment plan');
    }
    handleModalCancel();
    if (closePaymentModel) closePaymentModel();
  };

  const initialPaymentPlan = () => {
    if (selectedBilledPlan) {
      setTestcaseCreationStep?.(TESTCASE_CREATION_STEPS.REQUIREMENT_GATHERING);
    } else {
      notifyError('Please select payment plan');
    }
  };

  const goNext = async () => {
    if (isPaymentPlanChange) handlePlanChange();
    else initialPaymentPlan();
  };

  const goBack = () => history.push(`${ROUTES.HOME}`);

  const isSelectedPlan = (selectedPlan: any, item: any) => selectedPlan?.paymentPlanId
    === item.paymentPlanId;

  const filterPlan = () => [...paymentPlans]
    ?.sort((a, b) => Number(a.sortIndex) - Number(b.sortIndex));

  const renderContactSalesForm = () => (
    <Modal
      className="fp-modal entp-frm"
      open={isModalOpen}
      onCancel={handleModalCancel}
      footer={null}
      closeIcon={false}
      closable={false}
      width={650}
      style={{ top: 50 }}
    >
      <EnterprisePlanForm modalClose={handleModalCancel} />
    </Modal>
  );

  useEffect(() => {
    setPaymentPlans(billedPlans);
    getServicesList(accountId, dispatch);
  }, [billedPlans]);

  if (!billedPlans || billedPlans.length === 0) return <Loading plain size="large" />;
  return (
    <div className="fp_pg_base">
      {!isPaymentPlanChange && <Button className="back_btn fp_btn-primary fp_btn" onClick={goBack}>Back</Button>}
      <div className="pay_plan_container ">
        <div className="fp_cmpnt fp_cmpnt_cntr">
          <p className="pay_plan_tittle">Subscription Plans</p>
          <p className="pay_plan_sub_tittle">Select a price plan that suits you</p>
        </div>
        <div>
          <Row className="row_main">
            {filterPlan()
              ?.map((item: any) => (
                <Col key={item.planName}>
                  <Card hoverable className={`card_content ${isSelectedPlan(selectedBilledPlan, item) ? 'selected' : ''} ${item.disabledReson ? 'disable_active dis' : 'border_gradient'} ${item.planName}`}>
                    <div className="card_item">
                      <p className="content_title">{item.planName}</p>
                      <p className="content_sub">
                        <span className={`content_price ${item.planName} ${item.disabledReson ? 'disable_actived' : ''}`}>{item.price}</span>
                      </p>
                      <ul>
                        {item.features.map((feature: any) => (
                          <li key={feature}>
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <br />
                    <div className="card_item">
                      <Button disabled={item.disabledReson || isSelectedPlan(selectedBilledPlan, item)} className={`pay_plan_button ${item.disabledReson ? 'disable_active' : ''} ${item.planName}`} onClick={() => handleClick(item)}>
                        {item.planName === 'Trailblazer' && 'Contact Sales'}
                        {item.planName !== 'Trailblazer' && (isSelectedPlan(selectedBilledPlan, item) ? 'Selected' : 'Select')}
                      </Button>
                    </div>
                  </Card>
                  {item.disabledReson && (
                    <p className="disable_note">
                      {item.disabledReson}
                    </p>
                  )}
                </Col>
              ))}
          </Row>
        </div>
        {renderContactSalesForm()}
        <Button className="button_next fp_btn fp_btn-primary" onClick={() => goNext()}>{isPaymentPlanChange ? 'Change' : 'Continue'}</Button>
      </div>
    </div>
  );
};

export default PaymentPlan;
