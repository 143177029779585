import React, { useEffect, useState } from 'react';
import {
  Button, Col, notification, Row,
} from 'antd';
import moment from 'moment';
import StatusView from '../testcases/result/components/StatusView';
import Loading from '../../components/Loading';
import IDETabMenu from '../testExecutionSuit/components/IDETabMenu';
import { getExecution } from '../testcaseCreation/action';
import { EXECUTION_RESULT } from '../../constants';
import { DeleteIcon } from '../../components';
import DeleteConfirmationModal from '../testExecutionSuit/modals/DeleteConfirmationModal';
import { notifyInProgress, notifySuccess, notifyError } from '../../utils/notification';
import { deleteMonitorTestRun } from './action';

// const { Title } = Typography;

const ResultLogs = ({
  testRun, index, job, isShared, handleRefresh,
}: any) => {
  const [viewResults, setViewResults] = useState<boolean>(false);
  const [executionData, setExecutionData] = useState<any>();
  const [isDeleteConfirmationModal, setDeleteConfirmationModal] = useState<boolean>(false);

  const toggleView = () => setViewResults(!viewResults);

  const fetchData = async () => {
    setExecutionData(undefined);
    const data = await getExecution(job.testcaseId, testRun.executionId, job.monitorId);
    setExecutionData(data);
  };

  const deleteMonitorJob = async () => {
    try {
      notifyInProgress('Deleting test run');
      setDeleteConfirmationModal(false);
      await deleteMonitorTestRun({
        accountId: job.accountId,
        monitorId: job.monitorId,
        executionId: testRun.executionId,
        testcaseId: job.testcaseId,
      });
      handleRefresh();
      notification.destroy();
      notifySuccess('Test run deleted successfully');
    } catch (err: any) {
      console.log('err :', err.message);
      notification.destroy();
      notifyError('Failed to delete the test run');
    }
  };

  useEffect(() => {
    if (viewResults) {
      fetchData();
    }
  }, [viewResults]);

  return (
    <div className="fp_test_exec_list_item" key={JSON.stringify(testRun)}>
      <Row className="fp_list_item_new">
        <Col span={7} className="right-border"><p>{testRun.resultId || `Result id #${index}`}</p></Col>
        <Col span={4} className="right-border column-align">
          <p>{moment(testRun.placedAt).format('DD/MM/YYYY')}</p>
        </Col>
        <Col span={3} className="column-align right-border">
          <p>{moment(testRun.placedAt).format('h:mm a')}</p>
        </Col>
        <Col span={6} className="column-align right-border" style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          <p>
            <StatusView
              status={(testRun.status !== EXECUTION_RESULT.COMPLETED
                || testRun?.log?.suites?.length) ? testRun.status : testRun?.statistics?.status}
              executionResults={testRun?.log?.suites[0]?.specs[0]?.tests}
              passPercentage={testRun?.passPercentage}
            />
          </p>
        </Col>
        <Col span={isShared ? 4 : 3} style={{ display: 'flex' }} className="column-align right-border">
          <Button
            type="primary"
            className="fp_btn fp_btn-view"
            style={{ fontSize: '16px' }}
            onClick={toggleView}
            disabled={
              [EXECUTION_RESULT.PROVISIONING, EXECUTION_RESULT.RUNNING]
                .includes(testRun?.status)
            }
          >
            {viewResults ? 'View Less' : 'View'}
          </Button>
        </Col>
        {!isShared && (
        <Col span={1} style={{ display: 'flex' }} className="column-align">
          <Button
            className="fp_btn fp_btn-link"
            shape="circle"
            onClick={() => setDeleteConfirmationModal(true)}
            icon={<DeleteIcon />}
          />
        </Col>
        )}
        {viewResults && (
          <Row style={{ width: '100%' }}>
            {!executionData ? <Loading plain /> : (
              <div style={{ padding: 20, width: '100%' }}>
                <IDETabMenu
                  executionData={executionData}
                  script={testRun?.automationScript}
                  handleOnChange={() => { }}
                  disableEditMode
                  isShared={isShared}
                  testcaseId={job.testcaseId}
                />
              </div>
            )}
          </Row>
        )}
      </Row>

      <DeleteConfirmationModal
        title="Are you sure you want to delete this test run?"
        deleteButtonText="Delete test run"
        isModalOpen={isDeleteConfirmationModal}
        handleCancel={() => setDeleteConfirmationModal(false)}
        handleDelete={deleteMonitorJob}
        isLoading={false}
      />
    </div>
  );
};
export default ResultLogs;
