import { API, Auth } from 'aws-amplify';
import { SignUpParams } from '../../types/common';
import { APIS } from '../../utils/apiUtils';

export const verifyEmail = async (email: string, code: string)
  : Promise<any> => {
  await Auth.confirmSignUp(email, code);
  return true;
};

export const reSendEmailverifyCode = async (email: string)
  : Promise<any> => {
  await Auth.resendSignUp(email);
};

export const signUp = async (params: SignUpParams) => {
  try {
    await API.post(APIS.SIGNUP_API, '/user/signup', { body: params });
    return true;
  } catch (err) {
    throw err.response.data;
  }
};
