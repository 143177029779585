import React from 'react';
import ChromiumIcon from './Icons/chromium_icon.svg';
import FirefoxIcon from './Icons/firefox_icon.svg';
import SafariIcon from './Icons/safari_icon.svg';

const renderBrowserIcon = (browser: string) => {
  switch (browser) {
    case 'Chromium':
      return <img src={ChromiumIcon} alt="Chromium Icon" />;
    case 'Chrome':
      return <img src={ChromiumIcon} alt="Chromium Icon" />;
    case 'Firefox':
      return <img src={FirefoxIcon} alt="Firefox Icon" />;
    case 'Safari':
      return <img src={SafariIcon} alt="Safari Icon" />;
    default:
      return <div />;
  }
};

export default renderBrowserIcon;
