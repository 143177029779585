import React, { useEffect, useState } from 'react';
import {
  Button, Col, Modal, Row, Typography, notification,
} from 'antd';
import { getExecution } from '../../testcaseCreation/action';
import Loading from '../../../components/Loading';
import { EXECUTION_RESULT } from '../../../constants';
import IDETabMenu from '../components/IDETabMenu';
import ConfirmationModal from '../../testcaseCreation/components/testCaseCreatedResult/modals/ConfirmationModal';
import StatusView from '../../testcases/result/components/StatusView';
import { notifyError, notifyInProgress, notifySuccess } from '../../../utils/notification';
import { updateAutomationScript } from '../action';

interface proType {
  open: boolean
  setOpen: Function
  testcase: any
  testSuite: any
}

const CONFIRMATION_FOR = {
  TESTCASE: 'testcase',
  RE_GENERATE: 're-generate',
};

const { Title } = Typography;

const IDEModal = ({
  open, setOpen, testcase,
  testSuite,
}: proType) => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [executionStatus, setExecutionStatus] = useState<string | undefined>();
  const [executionData, setExecutionData] = useState<any | undefined>();
  const [confirmationFor, setConfirmationFor] = useState<string>(CONFIRMATION_FOR.TESTCASE);
  const [script, setScript] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(true);

  const triggerConfirmationModal = (cFor: string) => {
    setOpen(false);
    setConfirmationModalOpen(true);
    setConfirmationFor(cFor);
  };

  const handleFullCleanUp = () => {
    setExecutionData(undefined);
    setScript('');
    setExecutionStatus(undefined);
    if (!isLoading) setLoading(true);
    setConfirmationFor(CONFIRMATION_FOR.TESTCASE);
  };

  const handleCancel = ({ closeModal = true }: { closeModal?: Boolean } = {}) => {
    setLoading(true);
    setConfirmationModalOpen(false);
    setOpen(!closeModal);
    if (confirmationFor !== CONFIRMATION_FOR.RE_GENERATE) handleFullCleanUp();
  };

  const handleOnChange = (e: any) => {
    setScript(e.target.value);
  };

  const saveTestCase = async () => {
    notifyInProgress('Saving test case..');
    const res = await updateAutomationScript(testcase.requirementId, testcase.testcaseId, script);
    handleCancel({ closeModal: true });
    notification.destroy();
    if (res) {
      notifySuccess('Test case saved successfully!');
    } else {
      notifyError('Failed to updated the testcase');
    }
  };

  const handleConfirmation = async () => {
    setConfirmationModalOpen(false);
    setLoading(true);
    setExecutionData(undefined);
    await saveTestCase();
  };

  const fetchExecutionData = async (testcaseId: string,
    executionId: string,
    exStatus: string) => {
    const res = await getExecution(testcaseId, executionId);
    setExecutionStatus((exStatus === EXECUTION_RESULT.COMPLETED
      && !res?.log?.suites[0]?.specs[0]?.tests) ? EXECUTION_RESULT.FAILED : exStatus);
    if (res) {
      setExecutionData(res);
      return typeof res.videoUrl === 'string';
    }
    return false;
  };

  const setIDEData = async () => {
    const scriptFile = testcase;
    if (scriptFile?.executionStatus) {
      if (executionStatus === EXECUTION_RESULT.PROVISIONING
        && scriptFile?.executionStatus === EXECUTION_RESULT.COMPLETED) {
        setExecutionStatus(executionStatus);
      } else if (scriptFile?.executionStatus === EXECUTION_RESULT.COMPLETED
          && scriptFile.executionId) {
        const { testcaseId } = testcase;
        await fetchExecutionData(testcaseId, scriptFile.executionId, scriptFile?.executionStatus);
      } else {
        setExecutionStatus(scriptFile?.executionStatus);
      }
    } else {
      setExecutionStatus(undefined);
      setExecutionData(undefined);
    }
    setLoading(false);
  };

  const getConfirmationBody = () => {
    switch (confirmationFor) {
      case CONFIRMATION_FOR.TESTCASE:
        return 'Are you sure you want to create this testcase';
      case CONFIRMATION_FOR.RE_GENERATE:
        return 'Are you sure you want to regenerate the script? If you proceed, the existing script will be replaced with a new one, and all your previous changes will be lost';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (open) setIDEData();
  }, [testcase, open, executionStatus]);

  useEffect(() => {
    if (testcase && (!script || script === '') && open) {
      setScript(testcase.automationScript);
    }
  }, [testcase, open]);

  return (
    <div>
      <Modal
        className="max-user-modal fp-modal fp-modal-primary ide-modal"
        width="75%"
        open={open}
        onCancel={() => handleCancel({ closeModal: true })}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" className="fp_btn fp_btn-outline" onClick={() => handleCancel({ closeModal: true })}>Discard</Button>
            <Button type="primary" className="fp_btn fp_btn-primary" onClick={() => triggerConfirmationModal(CONFIRMATION_FOR.TESTCASE)}>Save</Button>
          </div>,
        ]}
      >
        {isLoading ? <Loading plain /> : (
          <div>
            <div>
              <Row>
                <Col span={20}>
                  <Title
                    level={5}
                    style={{ color: 'white' }}
                  >
                    Test Case in Automation script Format
                  </Title>
                  <p style={{ marginBottom: 20 }}>
                    {testSuite?.name}
                  </p>
                </Col>
                {executionStatus && (
                <Col span={4}>
                  <div className="fp_testcase_test_status margin_top_bottom_20">
                    <StatusView
                      status={executionStatus}
                      executionResults={executionData?.log?.suites[0]?.specs[0]?.tests}
                      passPercentage={executionData?.passPercentage}
                    />
                  </div>
                </Col>
                )}
              </Row>
            </div>
            <IDETabMenu
              executionData={executionData}
              script={script}
              handleOnChange={handleOnChange}
            />
            <p>
              Please note: Our automation scripts are now generated
              in the format compatible with Playwright&apos;s new test runner.
              This runner provides better organization, isolated execution,
              and superior debugging features for your test scripts.
              The scripts you generate with this tool will be ready to use
              with Playwright&apos;s new test runner directly.
            </p>
          </div>
        )}
      </Modal>
      <ConfirmationModal
        open={isConfirmationModalOpen}
        title={confirmationFor === CONFIRMATION_FOR.TESTCASE ? 'Are you sure you want to edit this testcase' : 'Are you sure you want to regenerate'}
        body={getConfirmationBody()}
        onCancel={() => (confirmationFor === CONFIRMATION_FOR.TESTCASE
          ? handleCancel() : handleCancel({ closeModal: false }))}
        onProceed={() => handleConfirmation()}
      />
    </div>
  );
};

export default IDEModal;
