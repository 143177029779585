import React, { useState } from 'react';
import {
  Button, Col, Row, notification,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { TrashCanIcon } from '../../../components';
import { ROUTES } from '../../../constants';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';
import { notifyError, notifyInProgress, notifySuccess } from '../../../utils/notification';
import { useAppSelector } from '../../../redux/hooks';
import { deleteTestSuite } from '../action';
import StatusView from '../../testcases/result/components/StatusView';

interface propType {
  testSuite: any
  handleRefresh: Function
  openEDSModal: Function
}

const TestExecutionListViewItem = ({ testSuite, handleRefresh, openEDSModal }: propType) => {
  const history = useHistory();
  const [deleteSuit, setDeleteSuit] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const accountId = useAppSelector(({ userData }: any) => userData?.selectedAccount?.accountId);

  const viewTestSuit = () => history.push(`${ROUTES.TEST_EXECUTION}/${testSuite.testSuiteId}`);

  const deleteTestSuit = async () => {
    notifyInProgress('Deleting test suite');
    setModalOpen(false);
    const flag = await deleteTestSuite(accountId, testSuite.testSuiteId);
    notification.destroy();
    if (flag) notifySuccess('Deleted test suite successfully');
    else notifyError('Failed to delete test suite');
    handleRefresh();
  };

  const handleButtonAction = () => {
    if (deleteSuit) {
      setModalOpen(true);
    } else {
      openEDSModal(testSuite);
    }
  };

  const renderExecutionStatus = () => {
    if (testSuite?.executionStatus) {
      return (
        <div className="fp_testcase_test_status">
          <StatusView
            status={testSuite?.executionStatus}
            executionResults={[]}
            passPercentage={String(Math.round(testSuite?.passPercentage * 100) / 100 || 0)}
          />
        </div>
      );
    }
    return '-';
  };

  return (
    <div className="fp_test_exec_list_item" key={JSON.stringify(testSuite)}>
      <Row justify="space-between" className="fp_list_item">
        <Col span={10} onClick={viewTestSuit}><p>{testSuite?.name}</p></Col>
        <Col span={4} onClick={viewTestSuit}><p>{testSuite?.lastRun ? moment(testSuite?.lastRun).format('lll') : '-'}</p></Col>
        <Col span={3} className={testSuite?.executionStatus ? 'fp_list_item_result' : ''} onClick={viewTestSuit}><p>{renderExecutionStatus()}</p></Col>
        <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Row className="fp_test_exec_list_item_action">
            <Col>
              <Button onClick={handleButtonAction} type="primary" icon={deleteSuit && <TrashCanIcon />} className={`fp_btn fp_btn-primary ${deleteSuit ? 'btn_delete' : 'btn_run'}`}>{!deleteSuit ? 'Run' : 'Delete suite'}</Button>
            </Col>
            <Col>
              <p><MoreOutlined onClick={() => setDeleteSuit(!deleteSuit)} style={{ fontSize: '20px' }} /></p>
            </Col>
          </Row>
        </Col>
        <DeleteConfirmationModal
          title="Are you sure you want to delete this test suite?"
          deleteButtonText="Delete suite"
          isModalOpen={isModalOpen}
          handleCancel={() => setModalOpen(false)}
          handleDelete={deleteTestSuit}
          isLoading={false}
        />
      </Row>
    </div>
  );
};

export default TestExecutionListViewItem;
