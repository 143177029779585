import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Define a type for the slice state

export type BilledPlan = {
  paymentPlanId: string;
  disabledReson?: string;
  features: Array<string>;
  gradient: string;
  paymentType: string;
  period: string;
  planName: string;
  price: string;
  unit: string;
  sortIndex: string
};

interface BilledPlanDataState {
  billedPlans: Array<BilledPlan>;
  selectedBilledPlan?: BilledPlan;
}

// Define the initial state using that type
const initialState: BilledPlanDataState = {
  billedPlans: [],
  selectedBilledPlan: undefined,
};

export const billedPlanDataSlice = createSlice({
  name: 'billedPlanData',
  initialState,
  reducers: {
    setBilledPlans: (state, action: PayloadAction<Array<BilledPlan>>) => {
      state.billedPlans = action.payload;
    },
    setSelectedBilledPlan: (state, action: PayloadAction<BilledPlan | undefined>) => {
      state.selectedBilledPlan = action.payload;
    },
  },
});

export const {
  setBilledPlans, setSelectedBilledPlan,
} = billedPlanDataSlice.actions;

export default billedPlanDataSlice.reducer;
