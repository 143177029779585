import React, { useState } from 'react';
import {
  Modal, Button, Form, InputNumber, Space, notification,
} from 'antd';
import { createTestCase } from '../../action';
import { notifySuccess, notifyInProgress, notifyError } from '../../../../utils/notification';
import { TestCaseInput, ThresholdType } from '../../../../redux/testCaseSlice';

const _ = require('lodash');

type propTypes = {
    testCase: TestCaseInput,
    showThresholds: boolean,
    setThresholds: Function,
    reload: Function,
};

const ThresholdModal = ({
  testCase, showThresholds, setThresholds, reload,
}: propTypes) => {
  const [isEditMode, setEditMode] = useState<boolean>(false);

  const [form] = Form.useForm();

  const toggleEditMode = () => {
    if (isEditMode) form.resetFields();
    setEditMode(!isEditMode);
  };

  const handleModelClose = (isSavedOnClose: boolean = false) => {
    setThresholds(false);
    form.resetFields();
    setEditMode(false);
    if (isSavedOnClose) reload(false);
  };

  const saveNewThreshold = async (updatedThresholds: ThresholdType) => {
    const updatedTestCase: TestCaseInput = { ...testCase, thresholds: updatedThresholds };
    notifyInProgress('Updating test case thresholds');
    await createTestCase(updatedTestCase);
    notification.destroy();
    notifySuccess('Test case thresholds updated successfully', 'test_case_thresholds_updated_successfully');
  };

  const handleThresholdChanges = async () => {
    try {
      let isThresholdsNotChanged: boolean = !isEditMode;
      if (isEditMode) {
        const isValidationFailed: boolean = form
          .getFieldsError().some(({ errors = [] }) => errors.length);
        if (isValidationFailed) return;
        const updatedThresholds: ThresholdType = {
          ...testCase.thresholds,
          ...form.getFieldsValue(),
        };
        isThresholdsNotChanged = _.isEqual(updatedThresholds, testCase.thresholds);
        if (!isThresholdsNotChanged) await saveNewThreshold(updatedThresholds);
      }
      handleModelClose(!isThresholdsNotChanged);
    } catch (e: any) {
      notification.destroy();
      notifyError('Unable to update thresholds', 'unable_to_update_thresholds');
    }
  };

  return (
    <Modal
      className="fp-modal fp-modal-primary"
      open={showThresholds}
      onCancel={() => handleModelClose()}
      footer={[
        <Button
          onClick={toggleEditMode}
          data-testid="fp_new_projec_cancel_btn"
          type="primary"
          className="fp_btn fp_btn-outline"
        >
          {isEditMode ? 'Cancel Edit' : 'Edit'}
        </Button>,
        <Button
          onClick={handleThresholdChanges}
          data-testid="fp_new_projec_cancel_btn"
          type="primary"
          className="fp_btn fp_btn-outline"
        >
          {isEditMode ? 'Save' : 'Cancel'}
        </Button>,
      ]}
    >
      <p className="fp_heading-2">{isEditMode ? 'Edit Thresholds' : 'Thresholds'}</p>
      <br />
      <Form
        name="update-settings_form"
        requiredMark={false}
        labelCol={{ lg: { span: 16 }, xs: { span: 8 } }}
        wrapperCol={{ lg: { span: 4 }, xs: { span: 4, offset: 2 } }}
        labelAlign="left"
        colon={false}
        initialValues={testCase.thresholds}
        form={form}
        className="update-settings-form"
      >
        <Form.Item
          name="concurrentUsers"
          label="Concurrent Users"
          rules={[{ required: true, message: 'Please enter number of concurrent users.' }]}
        >
          <InputNumber max={1000} min={1} disabled={!isEditMode} />
        </Form.Item>
        <Form.Item
          label="Max. time duration for the test"
        >
          <Space>
            <Form.Item
              name="maximumTimeDuration"
              noStyle
              rules={[{ required: true, message: 'Please enter max time duration per test.' }]}
            >
              <InputNumber min={1} disabled={!isEditMode} />
            </Form.Item>
            <p>
              mins
            </p>
          </Space>
        </Form.Item>
        <Form.Item
          label="Expected average response time"
        >
          <Space>
            <Form.Item
              name="expectedMeanResponseTime"
              noStyle
              rules={[{ required: true, message: 'Please enter average response time.' }]}
            >
              <InputNumber min={1} disabled={!isEditMode} />
            </Form.Item>
            <p>
              secs
            </p>
          </Space>
        </Form.Item>
        <Form.Item
          style={{ display: 'none' }}
          label="Ramp up time "
        >
          <Space>
            <Form.Item
              name="rampUpTime"
              noStyle
              rules={[{ required: true, message: 'Please enter ramp up time.' }]}
            >
              <InputNumber min={1} disabled={!isEditMode} />
            </Form.Item>
            <p>
              min
            </p>
          </Space>
        </Form.Item>
        <Form.Item
          label="Expected 90th percentile response time "
        >
          <Space>
            <Form.Item
              name="expectedTestSuccessPercentile"
              noStyle
              rules={[{ required: true, message: 'Please enter 90th percentile res. time.' }]}
              initialValue={1}
            >
              <InputNumber min={1} disabled={!isEditMode} />
            </Form.Item>
            <p>
              secs
            </p>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ThresholdModal;
