import React from 'react';
import { Col, Row } from 'antd';
import TestExecutionListViewItem from './TestExecutionListViewItem';
import Loading from '../../../components/Loading';

interface propType {
  testSuites: any[]
  handleRefresh: Function
  isLoading: boolean
  openEDSModal: Function
}

const TestExecutionListView = ({
  testSuites, handleRefresh, isLoading, openEDSModal,
}: propType) => (
  <div className="fp_list">
    <Row justify="space-between" className="fp_list_title">
      <Col span={10}><p>Test suite name</p></Col>
      <Col span={4}><p>Last run</p></Col>
      <Col span={3}><p>Overall result</p></Col>
      <Col span={4}><p> </p></Col>
    </Row>
    {isLoading ? <Loading plain size="large" /> : testSuites.map((testSuite: any) => (
      <TestExecutionListViewItem
        testSuite={testSuite}
        handleRefresh={handleRefresh}
        openEDSModal={openEDSModal}
      />
    ))}
  </div>
);

export default TestExecutionListView;
