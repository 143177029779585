import { PlusOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button, Col, Form, Input, Modal, Row, notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { ROUTES } from '../../../constants';
import { notifyError, notifyInProgress, notifySuccess } from '../../../utils/notification';
import { getModulesByProjectId } from '../../testcaseCreation/action';
import GeneratedTestcaseModuleListView from './GeneratedTestcaseModuleListView';
import { sortByCreateAtTime } from '../../../utils/commonUtils';
import GlobalTestDataModal from './modals/GlobalTestDataModal';
import CTRModal from './modals/CTRModal';
import { createModule, deleteModule } from '../actions';
import { useAppSelector } from '../../../redux/hooks';

const GeneratedTetcase = () => {
  const { projectId }: { projectId: undefined|string } = useParams();
  const accountId = useAppSelector(({ userData }: any) => userData?.selectedAccount?.accountId);
  const location: any = useLocation();
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [generatedTestModules, setGeneratedTestModules] = useState<any>([]);
  const [globalTestDataModal, setGlobalTestDataModal] = useState<boolean>(false);
  const [commonTestResourcesModal, setCommonTestResourcesModal] = useState<boolean>(false);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const [form] = Form.useForm();

  const load = async () => {
    try {
      setLoading(true);
      const data = await getModulesByProjectId(projectId as string);
      setGeneratedTestModules(sortByCreateAtTime(data.requiments));
      setLoading(false);
    } catch (e) {
      notifyError('Generated Testcase getting error', 'test_case_get_error');
    } finally {
      setLoading(false);
    }
  };

  const addModule = async () => {
    try {
      setModalVisible(false);
      const fData = form.getFieldsValue();
      if (!fData || !fData.name || !fData.name.trim()) {
        notification.destroy();
        notifyError('Empty module names are not allowed!');
        return;
      }
      const moduleName = fData.name.trim();
      const processedModuleName = moduleName.replace(/ +/g, ' ');
      if (generatedTestModules.find((module: any) => module.name === processedModuleName)) {
        notification.destroy();
        notifyError('The module name already exists!');
        return;
      }
      notifyInProgress('Creating module...', 'module_create_in_progress');
      if (form.getFieldsError().some(({ errors }) => errors.length)) {
        return;
      }
      const module: any = {
        name: processedModuleName,
        accountId,
        projectId,
      };
      const moduleId = await createModule(module);
      history.push(`${ROUTES.PROJECTS}/${projectId}/${moduleId}`, { moduleId, moduleName: module.name, projectName: location?.state?.projectName });
      notification.destroy();
      notifySuccess('Module created successfully.', 'module_create_success');
    } catch (e) {
      notification.destroy();
      notifyError('Module adding error.', 'module_get_err');
    }
  };

  const handleDeleteModule = async (moduleId: string) => {
    notifyInProgress('Deleting selected module');
    const success = await deleteModule(accountId, projectId as string, moduleId);
    load();
    notification.destroy();
    if (success) notifySuccess('Module deleted successfully');
    else notifyError('Failed to delete the module');
  };

  const routeTo = (path: string) => history.push(path, location?.state);

  useEffect(() => {
    if (projectId) {
      load();
    }
  }, []);
  const renderContent = isLoading
    ? <Loading plain size="large" />
    : (
      <GeneratedTestcaseModuleListView
        testModules={generatedTestModules}
        deleteModule={handleDeleteModule}
      />
    );
  return (
    <div className="fp_pg_base">
      <Row>
        <Button
          data-testid="fp_previous_btn"
          className="back_btn fp_btn-primary fp_btn"
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </Row>
      <Row>
        <p className="fp_heading-2 fp_heading-2-middle">{location?.state?.projectName}</p>
      </Row>

      <Row justify="space-between" className="fp_mrgn_tp_30">
        <Col span={12}>
          <Row>
            <Breadcrumb separator=">" className="bread-crumb-seperator">
              <Breadcrumb.Item className="breadcrumb-item" onClick={() => routeTo(`${ROUTES.PROJECTS}`)}>Projects</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-item">{location?.state?.projectName}</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-item">Modules</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                onClick={() => setCommonTestResourcesModal(true)}
                data-testid="fp_new_project_btn"
                type="primary"
                className="fp_btn fp_btn-primary fp_new_project_btn"
              >
                Test Resources
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => setGlobalTestDataModal(true)}
                data-testid="fp_new_project_btn"
                type="primary"
                className="fp_btn fp_btn-primary fp_new_project_btn"
              >
                Global test data
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => setModalVisible(true)}
                data-testid="fp_new_project_btn"
                type="primary"
                className="fp_btn fp_btn-primary fp_new_project_btn"
                icon={<PlusOutlined />}
              >
                New Module
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {renderContent}
      <GlobalTestDataModal
        open={globalTestDataModal}
        setOpen={setGlobalTestDataModal}
        projectId={projectId as string}
      />

      <CTRModal
        open={commonTestResourcesModal}
        onClose={setCommonTestResourcesModal}
        projectId={projectId}
        projectName={location?.state?.projectName}
      />

      <Modal
        className="fp-modal fp-modal-primary"
        open={isModalVisible}
        footer={[
          <Button onClick={() => setModalVisible(false)} data-testid="fp_new_projec_cancel_btn" className="fp_btn fp_btn-outline">Cancel</Button>,
          <Button onClick={addModule} data-testid="fp_new_project_add_btn" className="fp_btn fp_btn-primary">Add</Button>,
        ]}
        onCancel={() => setModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please type a name for module',
              },
              {
                validator(_, value) {
                  const moduleName = value?.trim();
                  if (generatedTestModules.find((module: any) => module.name === moduleName)) {
                    notification.destroy();
                    notifyError('Sorry, this module name already exists. Please choose a different name');
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="Login Module" className="fp_input" />
          </Form.Item>
        </Form>
      </Modal>

    </div>
  );
};

export default GeneratedTetcase;
