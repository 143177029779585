import React from 'react';

interface ColumnListProps {
  stepList: string[];
}

const ColumnList = ({ stepList }: ColumnListProps) => (
  <div style={{ padding: 2 }}>
    {stepList.map((step, index) => (
      <div key={step} style={{ marginTop: 5 }}>
        <span>
          {index + 1}
          .
          {' '}
        </span>
        <span>{step}</span>
      </div>
    ))}
  </div>
);

export default ColumnList;
