import { Modal, Button, Input } from 'antd';
import React, { useState } from 'react';

interface propType {
  open: boolean,
  onCancel: Function,
  onOkay: Function,
}
const OTPAuthenticationModal = ({
  open, onCancel, onOkay,
}: propType) => {
  const [value, setValue] = useState<string>();

  const handleConfirm = async () => {
    onOkay(value);
  };

  return (
    <Modal
      className="max-user-modal fp-modal fp-modal-primary fp_middle_notification_model"
      open={open}
      title="Enter your OTP"
      onCancel={() => onCancel(false)}
      closable={false}
      footer={[
        <Button
          type="primary"
          className="fp_btn fp_btn-primary"
          onClick={handleConfirm}
          style={{ marginBottom: 15, marginRight: 15 }}
          disabled={!value}
        >
          Authenticate
        </Button>,
      ]}
    >
      <Input onChange={(e:any) => setValue(e.target.value)} className="fp_input" />
    </Modal>
  );
};

export default OTPAuthenticationModal;
