import React, { useEffect, useState } from 'react';
import {
  Button, Form, Input, Modal, Table, Tooltip, Typography, AutoComplete,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import ConfirmationModal from './ConfirmationModal';
import { DeleteIcon } from '../../../../../components';
import { formateTestDataToArray } from '../../../../../utils/commonUtils';
import { DataType, INPUT_TYPE } from '../../../../../types/common';
import { getGlobalTestData } from '../../../../projects/actions';
import { useAppSelector } from '../../../../../redux/hooks';

const { Option } = AutoComplete;
interface proType {
  open: boolean,
  setOpen: Function,
  saveTestCase: Function,
  form: any,
  testcase: DataType,
  projectId: string,
}

type TestDataType = {
  key: string;
  testDataType: string;
  testData: string;
  assetTag: string;
};

const initialData: TestDataType[] = [
  {
    key: '1',
    testDataType: '',
    testData: '',
    assetTag: '',
  },
];

const { Title } = Typography;

const TestCaseTestDataModal = ({
  open, setOpen, saveTestCase, form, testcase, projectId,
}: proType) => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<TestDataType[]>(initialData);
  const accountId = useAppSelector(({ userData }) => userData?.selectedAccount?.accountId);
  const [globalTestData, setGlobalTestData] = useState<any[]>([]);

  const triggerConfirmationModal = () => {
    setOpen(false);
    setConfirmationModalOpen(true);
  };

  const handleCleanUp = () => {
    setData(initialData);
    form.resetFields();
    setGlobalTestData([]);
    setOpen(false);
  };

  const handleConfirmation = async () => {
    setConfirmationModalOpen(false);
    await saveTestCase({ inputType: INPUT_TYPE.TEST_DATA });
    setData(initialData);
    handleCleanUp();
  };

  const handleCancel = () => {
    setConfirmationModalOpen(false);
    setOpen(true);
  };

  const handleDeleteRow = (record: TestDataType) => {
    const updatedData = data.filter((item) => item.key !== record.key);
    setData(updatedData);
  };

  const handleAddRow = (indexToInsertAfter: number) => {
    const updatedData = [...data];
    const { testData } = form.getFieldsValue();
    const filteredTestData = testData.filter((row: any) => row !== undefined);

    let updatedRows = updatedData
      .map((row: TestDataType, index: number) => ({
        ...row,
        key: `${index + 1}`,
        testDataType: filteredTestData[index].testDataType,
        testData: filteredTestData[index].testData,
        assetTag: filteredTestData[index].assetTag,
      }));

    const newKey: number = indexToInsertAfter + 1;
    const newRow: TestDataType = {
      key: `${newKey}`,
      testDataType: '',
      testData: '',
      assetTag: '',
    };
    updatedRows.splice(newKey, 0, newRow);
    updatedRows = updatedRows.map((row: TestDataType, index: number) => ({ ...row, key: `${index + 1}` }));

    // Update the form data
    const formDataUpdate: any = {};
    updatedRows.forEach((row: TestDataType) => {
      formDataUpdate[row.key] = {
        testDataType: row.testDataType,
        testData: row.testData,
        assetTag: row.assetTag,
      };
    });

    form.setFieldsValue({ testData: formDataUpdate });
    setData(updatedRows);
  };

  const handleTestDataTypeChange = (value: string, record: TestDataType) => {
    const selectedDataType = globalTestData.find(
      ({ testDataType }) => testDataType === value,
    );

    const updatedData = data.map((item) => {
      if (item.key === record.key) {
        return {
          ...item,
          testDataType: value,
          testData: selectedDataType?.testData || '',
          assetTag: selectedDataType?.assetTag || '',
        };
      }
      return item;
    });

    const testData: any = {};
    updatedData.forEach((item, index: number) => {
      testData[index + 1] = {
        testDataType: item.testDataType,
        testData: item.testData,
        assetTag: item.assetTag,
      };
    });

    setData(formateTestDataToArray(testData, true));
    form.setFieldsValue({ testData });
  };

  const loadGlobalTestData = async () => {
    const response = await getGlobalTestData(accountId as string, projectId);
    setGlobalTestData(response.globalTestData);
  };

  useEffect(() => {
    const initialTestData: TestDataType | undefined = form.getFieldValue('testData');
    if (initialTestData) {
      setData(formateTestDataToArray(initialTestData, true));
    }
  }, [form.getFieldValue('testData')]);

  useEffect(() => {
    if (open && accountId) {
      loadGlobalTestData();
    }
  }, [open, accountId]);

  const columns: ColumnsType<TestDataType> = [
    {
      title: 'Identifier',
      dataIndex: 'testDataType',
      key: 'testDataType',
      render: (_, record: TestDataType) => (
        <Form.Item
          name={['testData', record.key, 'testDataType']}
          style={{ margin: 0 }}
          rules={[{ required: true, message: 'Required!' }]}
        >
          <AutoComplete
            placeholder="Select or type test data type"
            filterOption={
              (inputValue, option: any) => option?.value?.toLowerCase()
                .indexOf(inputValue.toLowerCase()) !== -1
}
            onSelect={(value) => handleTestDataTypeChange(value, record)}
            className="fp_autocomplete_input"
          >
            {globalTestData.map(({ testDataType }) => (
              <Option key={testDataType} value={testDataType}>
                {testDataType}
              </Option>
            ))}
          </AutoComplete>
        </Form.Item>
      ),
    },
    {
      title: 'Locator Type',
      dataIndex: 'testData',
      key: 'testData',
      render: (_, record: TestDataType) => (
        <Form.Item
          name={['testData', record.key, 'testData']}
          style={{ margin: 0 }}
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Input placeholder="Test data" className="fp_table_input" />
        </Form.Item>
      ),
    },
    {
      title: (
        <span>
          Value
          {' '}
          <Tooltip title="This for the tool tip for Value (table) : Value can be unique identifiers like 'AssetID', 'XML Paths', or 'CSS Selectors'. They help your test scripts locate and interact with elements in your application, boosting the robustness and maintainability of your testing process.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: 'assetTag',
      key: 'assetTag',
      render: (_, record: TestDataType) => (
        <Form.Item
          name={['testData', record.key, 'assetTag']}
          style={{ margin: 0 }}
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Input placeholder="Asset tag" className="fp_table_input" />
        </Form.Item>
      ),
    },
  ];

  if (data.length > 1) {
    columns.push({
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 25,
      render: (_, record: TestDataType) => (
        <div className="test_data_modal_action_button_container">
          <Button type="text" onClick={() => handleDeleteRow(record)}>
            <DeleteIcon />
          </Button>
        </div>
      ),
    });
    columns.push({
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 25,
      render: (_, __, index: number) => (
        <div className="test_data_modal_action_button_container">
          <Button type="text" onClick={() => handleAddRow(index)}>
            <PlusOutlined />
          </Button>
        </div>
      ),
    });
  }

  return (
    <div>
      <Modal
        className="max-user-modal fp-modal fp-modal-primary"
        open={open}
        width={600}
        onCancel={() => { setData(initialData); setOpen(false); }}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" className="fp_btn fp_btn-primary" onClick={() => { setData(initialData); setOpen(false); }}>Discard</Button>
            <Button type="primary" className="fp_btn fp_btn-outline" onClick={() => triggerConfirmationModal()}>Save</Button>
          </div>,
        ]}
      >
        <div>
          <Title level={5} style={{ color: 'white' }}>{`Test Data:  ${testcase?.scenario}`}</Title>
        </div>
        <Form layout="vertical" form={form} className="fp_frm-ant-form-item">
          <div className="test_data_model_content_container" />

          <Table className="fp_tst_rslt_modal_table" dataSource={data} columns={columns} pagination={false} />

          <div className="center_elements_inside_contenter">
            <Button type="primary" onClick={() => handleAddRow(data.length)} className="fp_btn fp_btn-outline">Add new data</Button>
          </div>
        </Form>
      </Modal>
      <ConfirmationModal
        open={isConfirmationModalOpen}
        title="Are you sure you want to edit this testcase"
        body={form.getFieldValue('testcaseId') ? 'You are attempting to edit a test case generated by TestGenie. Please note that this action is permanent and cannot be undone. Are you sure you want to proceed with editing this test case?' : 'Are you sure you want to create this testcase'}
        onCancel={() => handleCancel()}
        onProceed={() => handleConfirmation()}
      />
    </div>
  );
};

export default TestCaseTestDataModal;
