import { PlusOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button, Col, Form, Input, Modal, Row, Select, notification,
} from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { ROUTES } from '../../../constants';
import { notifyError, notifyInProgress, notifySuccess } from '../../../utils/notification';
import { createRequirement, deleteRequirementAPI, getGeneratedTestcases } from '../actions';
import GeneratedTestCaseListViewItem from './GeneratedTestcaseListViewItem';
import { renderText, sortByCreateAtTime } from '../../../utils/commonUtils';
import { ENTRY_TYPE } from '../../../types/common';

const GeneratedTestCaseListView = () => {
  const [requirements, setRequirements] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const { projectId }: { projectId: undefined|string } = useParams();
  const location: any = useLocation();
  const moduleId = location?.state?.moduleId;
  const moduleName = location?.state?.moduleName;
  const projectName = location?.state?.projectName;
  const history = useHistory();

  const [form] = Form.useForm();

  const fetchGeneratedTestcase = async () => {
    setLoading(true);
    try {
      form.setFieldValue('entryType', ENTRY_TYPE.RECORD);
      const data = await getGeneratedTestcases(moduleId);
      setRequirements(sortByCreateAtTime(data.requiments));
      setLoading(false);
    } catch (error: any) {
      notifyError(error.message, 'generated_testcase_error');
    }
  };

  const deleteRequirement = async (requirementId: string) => {
    try {
      await deleteRequirementAPI(moduleId, requirementId);
      await fetchGeneratedTestcase();
    } catch (error: any) {
      console.log('deleteRequirement failed :', error.message);
      notification.destroy();
      notifyError('Failed to delete the requirement');
    }
  };

  const requirementList = useMemo(() => (
    requirements?.map((requirement) => (
      <GeneratedTestCaseListViewItem
        requirement={requirement}
        key={requirement.requirementId}
        deleteRequirement={deleteRequirement}
      />
    ))
  ), [requirements]);

  const routeTo = (path: string) => history.push(path, location?.state);

  const addRequirement = async () => {
    try {
      setModalVisible(false);
      const fData = form.getFieldsValue();
      if (!fData || !fData.requirementDescription || !fData.requirementDescription.trim()) {
        notification.destroy();
        notifyError('Empty requirement description not allowed!');
        return;
      }
      const requirementDescription = fData.requirementDescription.trim().replace(/ +/g, ' ');
      notifyInProgress('Creating requirement...', 'requirement_create_in_progress');
      if (form.getFieldsError().some(({ errors }) => errors.length)) {
        return;
      }
      const requirement: any = {
        projectId,
        requirementDescription,
        testScriptLanguage: 'PLAYWRIGHT',
        entryType: fData.entryType,
        moduleId,
      };
      const requirementId = await createRequirement(requirement);
      history.push(`${ROUTES.TESTCASE_RESULT}/${moduleId}/${requirementId}`);
      notification.destroy();
      notifySuccess('Requirement created successfully.', 'requirement_create_success');
    } catch (e) {
      notification.destroy();
      notifyError('Requirement adding error.', 'requirement_get_err');
    }
  };

  const renderContent = isLoading ? <Loading plain size="large" /> : requirementList;
  useEffect(() => {
    fetchGeneratedTestcase();
  }, [moduleId]);

  return (
    <div className="fp_pg_base">
      <Row>
        <Button
          data-testid="fp_previous_btn"
          className="back_btn fp_btn-primary fp_btn"
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </Row>
      <Row>
        <p className="fp_heading-2 fp_heading-2-middle">{moduleName}</p>
      </Row>
      <Row justify="space-between" className="fp_mrgn_tp_30">
        <Col span={13}>
          <Row>
            <Breadcrumb separator=">" className="bread-crumb-seperator">
              <Breadcrumb.Item className="breadcrumb-item" onClick={() => routeTo(`${ROUTES.PROJECTS}`)}>Projects</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-item" onClick={() => routeTo(`${ROUTES.PROJECTS}/${projectId}`)}>{renderText(projectName, 20)}</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-item" onClick={() => routeTo(`${ROUTES.PROJECTS}/${projectId}`)}>Modules</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-item">{renderText(moduleName, 20)}</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-item">Requirements</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Col>
        <Col span={10} className="fp_cmpnt fp_cmpnt_flx_rw fp_cmpnt_jsfy_cntn_spc_btwn">
          <Row
            justify="space-between"
            style={{ width: '100%' }}
          >
            <Col lg={{ span: 24 }} md={{ span: 24 }} sm={12} xs={{ span: 24 }} className="fp_new_project_col_col fp_new_project_col_col">
              <Button onClick={() => setModalVisible(true)} data-testid="fp_new_project_btn" type="primary" className="fp_btn fp_btn-primary fp_new_project_btn" icon={<PlusOutlined />}>New Requirement</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="fp_list">
        <Row justify="space-between" className="fp_list_title">
          <Col span={6}><p>Requirement Name</p></Col>
          <Col span={4}><p>Test Type</p></Col>
          <Col span={6}>
            <Row>
              <Col span={18}>
                <p>Created At</p>
              </Col>
            </Row>
          </Col>
        </Row>
        {renderContent}
      </div>

      <Modal
        className="fp-modal fp-modal-primary"
        open={isModalVisible}
        footer={[
          <Button onClick={() => setModalVisible(false)} data-testid="fp_new_projec_cancel_btn" className="fp_btn fp_btn-outline">Cancel</Button>,
          <Button onClick={addRequirement} data-testid="fp_new_project_add_btn" className="fp_btn fp_btn-primary">Add</Button>,
        ]}
        onCancel={() => setModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Entry Type"
            name="entryType"
            className="fp_recording_browser_select"
            rules={[
              {
                required: true,
                message: 'Please type entry type',
              },
            ]}
          >
            <Select>
              {Object.values(ENTRY_TYPE)
                .map((value: string) => (
                  <Select.Option value={value} key={value} selected>
                    {value}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="requirementDescription"
            label="Requirement Description"
            rules={[
              {
                required: true,
                message: 'Please type requirement description',
              },
            ]}
          >
            <Input placeholder="User login with correct email and password" className="fp_input" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default GeneratedTestCaseListView;
