import { notification } from 'antd';
import { Auth } from 'aws-amplify';
import { AppDispatch } from '../../redux/store';
import {
  setEmail, setUser, setUserId, storeLogout,
} from '../../redux/userSlice';
import { PROVIDER_TYPE } from '../../utils/apiUtils';
import { notifyInProgress, notifySuccess } from '../../utils/notification';

export const LoginStatus = {
  Success: 1,
  Fail: 2,
  Verify: 3,
};

export const oauth = async (provider: PROVIDER_TYPE) => {
  try {
    await Auth.federatedSignIn({ provider } as any);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const signIn = async (username: string, password: string, dispatch: AppDispatch) => {
  try {
    await Auth.signIn(username.trim(), password.trim());
    const session = await Auth.currentSession();
    const data = session.getIdToken().payload;
    const user = {
      fName: data.name,
      lName: data.family_name,
      emailVerified: data.email_verified,
    };
    dispatch(setUserId(data.sub));
    dispatch(setUser(user));
    dispatch(setEmail(data.email));
    return LoginStatus.Success;
  } catch (err) {
    if (err.message === 'User is not confirmed.') {
      dispatch(setEmail(username.trim()));
      return LoginStatus.Verify;
    }
    throw err;
  }
};

export const isLoggedIn = async (dispatch: AppDispatch) => {
  try {
    const session = await Auth.currentSession();
    const data = session.getIdToken().payload;
    const user = {
      fName: data.name,
      lName: data.family_name,
      emailVerified: data.email_verified,
    };
    dispatch(setUserId(data.sub));
    dispatch(setUser(user));
    dispatch(setEmail(data.email));
  } catch (err) {
    dispatch(setUserId(null));
  }
};

export const logout = async (dispatch: AppDispatch) => {
  notifyInProgress('Signing out. Please wait...', 'signing_out_in_progress');
  try {
    await Auth.signOut();
    dispatch(setUserId(null));
    dispatch(storeLogout());
    notifySuccess('Signed out successfully', 'signing_out_success');
    notification.destroy();
  } catch (error) {
    notification.destroy();
  }
};

export const forgetPassword = async (username: string) => {
  if (typeof username !== 'string') {
    throw new Error('username must be a string');
  }
  try {
    const response = await Auth.forgotPassword(username.trim());
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const resetPassword = async (username: string, code: string, newPassword: string) => {
  if (typeof username !== 'string' || typeof code !== 'string' || typeof newPassword !== 'string') {
    throw new Error('username, code and password must be a string');
  }
  try {
    await Auth.forgotPasswordSubmit(username.trim(), code.trim(), newPassword.trim());
  } catch (err) {
    throw new Error(err.message);
  }
};
