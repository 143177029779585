import {
  COMPLEX_EXPECT_TYPES, PLAY_WRIGHT_COMMANDS, PLAY_WRIGHT_EXPECT_FUNCTION_LIST,
  PLAY_WRIGHT_HTML_ATTRIBUTES,
} from '../constants';
import { notifyError } from './notification';

type commandPayload = {
  type: string,
  target: string,
  value?: string;
  options?: string;
}

export const generateFile = (recordedScript: string, type: string, fileName: string) => {
  const fileBlob: Blob = new Blob([recordedScript], { type });
  const file: File = new File([fileBlob], fileName, {
    lastModified: new Date(0).getTime(),
    type,
  });
  return file;
};

const makeId = (length: number) => {
  let result: string = '';
  const characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength: number = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const validateAndCreateCommand = (
  playWrightCommand: string, noOfData: number, noOfArgs: number, lineNumber: number,
) => {
  if (noOfData === noOfArgs) {
    return playWrightCommand;
  }
  throw new Error(`Script generate error: Invalid arguments at line: ${lineNumber}`);
};

const setNthChild = (child: string | undefined) => (child ? `.nth(${child})` : '');

const generateLocator = (targetConfig: string) => {
  const [targetString, childString]: string[] = targetConfig.split(',');
  const child: string|undefined = childString ? childString.split('=')[1] : undefined;

  if (targetString === 'page') {
    return 'page';
  }
  if (targetString.startsWith('[')) {
    // expected string: [data-testid="value"]
    const [targetAttriute, value]: string[] = targetString.replace(/(\[|\])/gm, '').split('=');
    switch (targetAttriute) {
      case PLAY_WRIGHT_HTML_ATTRIBUTES.TEST_ID:
        return `page.getByTestId(${value})${setNthChild(child)}`;
      case PLAY_WRIGHT_HTML_ATTRIBUTES.ALT:
        return `page.getByAltText(${value})${setNthChild(child)}`;
      case PLAY_WRIGHT_HTML_ATTRIBUTES.PLACEHOLDER:
        return `page.getByPlaceholder(${value})${setNthChild(child)}`;
      case PLAY_WRIGHT_HTML_ATTRIBUTES.ROLE:
        return `page.getByRole(${value})${setNthChild(child)}`;
      case PLAY_WRIGHT_HTML_ATTRIBUTES.TITLE:
        return `page.getByTitle(${value})${setNthChild(child)}`;
      case PLAY_WRIGHT_HTML_ATTRIBUTES.TXT:
        return `page.getByText(${value})${setNthChild(child)}`;
      default:
        return `page.locator('${targetString}')${setNthChild(child)}`;
    }
  }
  return `page.locator('${targetString}')${setNthChild(child)}`;
};

const generateExpectFunction = (payload: commandPayload, lineNumber: number) => {
  const [functionType, value]: string[] = payload.value?.trim().split('=').map((subString: string) => subString.trim()) || [];
  if (!PLAY_WRIGHT_EXPECT_FUNCTION_LIST.includes(functionType)) {
    throw new Error(`unknown expect function at line: ${lineNumber} : ${functionType}`);
  }

  switch (functionType) {
    case COMPLEX_EXPECT_TYPES.toHaveClass: {
      const clsArray: string[] = value.split(',');
      return `${functionType}(${clsArray});`;
    }
    case COMPLEX_EXPECT_TYPES.toHaveAttribute:
    case COMPLEX_EXPECT_TYPES.toHaveCSS: {
      const [attribute, attributeValue]: string[] = value.split(',').map((subString: string) => subString.trim());
      return `${functionType}('${attribute}', '${attributeValue}');`;
    }

    default: {
      // escape the special characters contains the script
      const preparedValue = value ? value.trim().replace(/[;:!@#%^&*()']/g, (match) => `\\${match}`) : undefined;
      return `${functionType}(${preparedValue || ''});`;
    }
  }
};

const testcaseStepGenerator = (command: string, lineNumber: number, isParameterize: boolean) => {
  const [type, targetData]: string[] = command.replace(/\s+/, '\x01').split('\x01').map((subString: string) => subString.trim());

  const targetString: string = targetData.includes('--') ? targetData.split('--')[0].trim() : targetData.split('value=')[0].trim();

  let payload: commandPayload = { type, target: targetString };
  switch (payload.type) {
    case PLAY_WRIGHT_COMMANDS.GOTO:
      return validateAndCreateCommand(`await page.goto('${payload.target}', { waitUntil: 'networkidle' });`, Object.keys(payload).length, 2, lineNumber);

    // escape the special characters when generating the script
    case PLAY_WRIGHT_COMMANDS.FILL:
      payload = {
        ...payload,
        value: command.split('value=')[1]
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace(/[;:!@#%^&*()']/g, (match) => `\\${match}`),
      };
      return validateAndCreateCommand(`await ${generateLocator(payload.target)}.fill(${isParameterize ? `data.param_${makeId(5)}` : `'${payload.value}'`});`, Object.keys(payload).length, 3, lineNumber);

    case PLAY_WRIGHT_COMMANDS.CLICK:
      return validateAndCreateCommand(`await ${generateLocator(payload.target)}.click();`, Object.keys(payload).length, 2, lineNumber);

    case PLAY_WRIGHT_COMMANDS.EXPECT:
      payload = { ...payload, value: command.split('--')[1].replace(/(\r\n|\n|\r)/gm, '') };
      return validateAndCreateCommand(`await expect(${generateLocator(payload.target)}).${generateExpectFunction(payload, lineNumber)}`, Object.keys(payload).length, 3, lineNumber);

    default:
      throw new Error(`Script generate error: Unknown command at line: ${lineNumber}`);
  }
};

export const manipulateRecordData = (
  recordText: string, contentOnly: boolean = false, isParameterize: boolean,
) => {
  try {
    if (!recordText) {
      throw new Error('Please add commands before continue');
    }
    const commandList: string[] = recordText.trim().split('\n');
    const script: string = `
    const { test, expect } = require('@playwright/test');
    
    test.describe('loadteser testcase', () => {
      test('Recorded Test from loadtester', async ({ page }) => {
        ${commandList.map(((command: string, i: number) => testcaseStepGenerator(command, i + 1, isParameterize))).join('\n \t')}
      });
    });
    `;
    if (contentOnly) {
      return script;
    }
    return generateFile(script, 'text/javascript', 'loadtesterScript.spec.js');
  } catch (error: any) {
    notifyError(error.message ? error.message : 'Script Generate error', 'SCRIPT_GENERATE_ERROR');
    throw error;
  }
};
