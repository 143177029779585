import React from 'react';

interface CustomRowProps {
  text: string,
}

const CustomRow = ({ text }: CustomRowProps) => (
  <div>{text}</div>
);

export default CustomRow;
