import React, { useEffect, useRef, useState } from 'react';
import {
  Col, Row, Input, Breadcrumb,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../redux/hooks';
import MonitoringItemListView from './MonitoringItemListView';
import { notifyError } from '../../utils/notification';
import { getAllMonitorJobs } from './action';

const Monitor = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const accountId: string = useAppSelector(
    ({ userData }: any) => userData?.selectedAccount?.accountId,
  );

  const autoRefreshIntervalRef = useRef<any>(null);

  const [monitorJobs, setMonitorJobs] = useState<any[]>([]);
  const [searchedMonitorJobs, setSearchedMonitorJobs] = useState<any[]>([]);
  // const [autoRefreshList, setAutoRefreshList] = useState<string[]>([]);

  const fetchData = async () => {
    const jobs = await getAllMonitorJobs(accountId);
    setMonitorJobs(jobs || []);
    setSearchedMonitorJobs(jobs || []);
  };

  const handleSearch = (keyword: string) => {
    setSearchedMonitorJobs(monitorJobs.filter(
      ({ name }) => name.toLowerCase().includes(keyword.toLowerCase()),
    ));
  };

  const handleAutoRefresh = () => fetchData();

  const initialTestSuitsFetch = async () => {
    try {
      setLoading(true);
      await handleAutoRefresh();
    } catch (error) {
      notifyError('Failed to fetch data !');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (accountId) {
      initialTestSuitsFetch();
    }
    return () => {
      clearInterval(autoRefreshIntervalRef.current);
    };
  }, []);

  return (
    <div className="fp_pg_base fp_test_exec_suit">
      <Row>
        <p className="fp_heading-2 fp_heading-2-middle">Synthetic Monitoring</p>
      </Row>
      <Row justify="end" className="fp_mrgn_tp_30">
        <Col span={12}>
          <Row>
            <Breadcrumb separator=">" className="bread-crumb-seperator">
              <Breadcrumb.Item className="breadcrumb-item">Synthetic Monitoring</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end" gutter={16}>
            <Col>
              <Input
                prefix={<SearchOutlined />}
                className="fp_exec_suit_search"
                placeholder="Search"
                onChange={(text) => handleSearch(text.target.value)}
                data-testid="fp_monitor_search"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <MonitoringItemListView
        jobs={searchedMonitorJobs}
        handleRefresh={fetchData}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Monitor;
