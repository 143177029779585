import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button, Image, Row } from 'antd';

import stripeImage from '../../styles/assets/stripe.png';
import { useAppSelector } from '../../redux/hooks';

type propType = {
  handleOk: Function
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
  errorMessage?: string
  onCancel: Function
  profileview: boolean
}

const SetupForm = ({
  handleOk, setLoading, setErrorMessage, errorMessage, onCancel, profileview,
}: propType) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isDisabled, setDisabled] = useState<boolean>(false);

  const selectedBilledPlan: any = useAppSelector(({ billedPlanData }) => billedPlanData
    .selectedBilledPlan);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setErrorMessage('');
    setDisabled(true);
    try {
      if (!stripe || !elements) {
        return;
      }

      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: 'http://localhost:3000/testcase-creation',
        },
        redirect: 'if_required',
      });
      setLoading(true);
      if (error) {
        setErrorMessage(error.message);
        setDisabled(false);
        setLoading(false);
      } else {
        handleOk();
        setLoading(false);
      }
    } catch (error: any) {
      setErrorMessage(error.message);
      setDisabled(false);
      setLoading(false);
    }
  };

  const formHeader = profileview
    ? (
      <div>
        <h1 className="fp_rqg_crd-ttl">Please provide your new card information below.</h1>
        <p className="fp_rqg_crd-sub">
          This will replace the previous card information associated with your account.
        </p>
      </div>
    )
    : (
      <div>
        <h1 className="fp_rqg_crd-ttl">Enter your card information to claim free test cases.</h1>
        <p className="fp_rqg_crd-sub">
          Please note that billing will occur only after you&apos;ve utilized
          your complimentary test cases.
        </p>
        {selectedBilledPlan && selectedBilledPlan?.planName
          && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Row>
                <p className={`fp_frm_title fp_frm_title-${selectedBilledPlan.planName}`}>
                  {selectedBilledPlan.price}
                </p>
                <p className="fp_frm_title fp_frm_title-perunit">{selectedBilledPlan.unit}</p>
              </Row>
            </div>
          )}
      </div>
    );

  return (
    <form onSubmit={handleSubmit}>
      {formHeader}
      <PaymentElement />
      <Row align="middle" justify="space-between" style={{ margin: '10px' }}>
        <div className="fp_rqg_crd_inf-ftr-pay">
          <p>Powered by </p>
          <Image src={stripeImage} draggable={false} preview={false} />
        </div>
        <Button className="button_next fp_btn-primary fp_btn" onClick={() => onCancel()}>Cancel</Button>
        <Button htmlType="submit" className="fp_btn fp_btn-primary button_next" disabled={!stripe || isDisabled}>Submit</Button>
      </Row>
      {errorMessage && <div style={{ color: '#ff0000' }}>{errorMessage}</div>}
    </form>
  );
};

export default SetupForm;
