import React from 'react';
import { Button, Modal, Tooltip } from 'antd';
import SummaryView from '../result/SummaryView';

interface propTypes {
  open: boolean;
  onCancel: any;
  testcaseId: string;
  projectId: string;
  isDryRunComplete: boolean;
  setDryRunComplete: Function;
}

const DryRunModal = ({
  open = false, onCancel, projectId, testcaseId, isDryRunComplete, setDryRunComplete,
}: propTypes) => (
  <Modal
    className="fp-modal fp-modal-primary"
    open={open}
    closable={false}
    footer={[
      <Tooltip title={!isDryRunComplete ? 'The modal cannot be closed until the dry run is complete.' : 'Click to close the modal.'}>
        <Button
          onClick={onCancel}
          data-testid="fp_new_projec_cancel_btn"
          type="primary"
          className="fp_btn fp_btn-outline"
          disabled={!isDryRunComplete}
        >
          Close
        </Button>
      </Tooltip>,
    ]}
    width={1000}
  >
    <SummaryView
      projectId={projectId}
      testcaseId={testcaseId}
      isDryRunResults
      setDryRunComplete={setDryRunComplete}
      isChart={false}
    />
  </Modal>
);

export default DryRunModal;
