import { notification } from 'antd';
import _ from 'lodash';
import csv from 'csvtojson';
import { getContentFromAFile } from './fileUtils';
import { notifyError } from './notification';

// scrap data attriutes from script
export const scrapDataAttriutesFromScript = (fileContent: string) => {
  const regEx = new RegExp('(?:data\\.).*(?=\\))', 'g');
  return fileContent.match(regEx);
};

export const makeTestDataObject = (fileContent: string) => {
  const sampleTestOject = {};
  const keyPaths = scrapDataAttriutesFromScript(fileContent) || [];
  keyPaths.forEach((path: string) => {
    _.set(sampleTestOject, path.replace('data.', ''), 'sampledata');
  });
  return sampleTestOject;
};

export const getTestDataFileInput = async (file: any) => {
  switch (file.type) {
    case 'application/json':
      return JSON.parse((await getContentFromAFile(file)) as string);
    case 'text/csv':
      return csv().fromString((await getContentFromAFile(file)) as string);

    default:
      return {};
  }
};

export const validateTestDataFile = async (testDataFile: any, scriptContent: string) => {
  const testDataOject = makeTestDataObject(scriptContent);
  const testDataFileInput = await getTestDataFileInput(testDataFile);
  const fileSize = Math.round(testDataFile.size / 1024);
  let isError = false;
  notification.destroy();
  try {
    if (fileSize > 0) {
      notifyError('The file size must be less than 1Mb!', 'file_size_is_larger', 5000);
    }
    if (!Array.isArray(testDataFileInput)) {
      notifyError('Invalid test data file format', 'test_data_format_error');
      throw new Error('Test data format error');
    }
    testDataFileInput.forEach((inputObject: any, index: number) => {
      const diff = _.difference(_.keys(testDataOject), _.keys(inputObject));
      if (diff.length > 0) {
        isError = true;
        notifyError(
          `Missing Data Keys: ${diff.join(', ')} in line/object ${index + 1}`,
          `data_file_error_${index}`,
        );
      }
    });
    if (isError) throw new Error('Test data file error');
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error('Test Data validate error: ', error.message);
    throw error;
  }
};
