export const downloadObjectAsJson = (exportObj: any, fileName: string) => {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(exportObj, null, 4))}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `${fileName}.json`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const getContentFromAFile = (file: any) => new Promise((resolve, reject) => {
  const read = new FileReader();
  if (!file) {
    reject();
    return;
  }

  read.readAsBinaryString(file);
  read.onloadend = () => {
    const content = read?.result;
    if (!content) {
      reject();
    } else {
      resolve(content);
    }
  };
});
