import { Modal, Button, Input } from 'antd';
import React, { useState } from 'react';

interface propType {
  open: boolean;
  createFolder: Function;
  onCancel: Function;
}

const FolderNameModal = ({
  open,
  createFolder,
  onCancel,
}: propType) => {
  const [folderName, setFolderName] = useState<string>();
  const [validationError, setValidationError] = useState<string | undefined>();

  const handleOnClose = () => {
    onCancel(false);
    setFolderName(undefined);
    setValidationError(undefined);
  };

  const handleConfirm = async () => {
    if (folderName && folderName.includes(' ')) {
      setValidationError('Folder name cannot contain spaces');
      return;
    }

    setValidationError(undefined);
    onCancel(false);
    await createFolder(folderName);
    handleOnClose();
  };

  return (
    <Modal
      className="max-user-modal fp-modal fp-modal-primary fp_middle_notification_model"
      open={open}
      title="Folder name"
      onCancel={() => handleOnClose()}
      footer={[
        <Button type="primary" className="fp_btn fp_btn-outline" onClick={handleOnClose}>
          Cancel
        </Button>,
        <Button
          type="primary"
          className="fp_btn fp_btn-primary"
          onClick={handleConfirm}
          disabled={!folderName || validationError}
        >
          Create
        </Button>,
      ]}
    >
      <Input
        placeholder="Enter folder name here"
        className="fp_input_suffix"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFolderName(e.target.value);
          setValidationError(undefined);
        }}
      />
      {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
    </Modal>
  );
};

export default FolderNameModal;
