/* eslint-disable no-unused-vars */
import { Col, Row } from 'antd';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import GeneratedTestSuitListViewItem from './GeneratedTestSuitListViewItem';
import Loading from '../../../components/Loading';
import { createEditTestSuite } from '../action';

interface propType {
  testcases: any[]
  deleteTestCase: Function
  selectTestCase: Function
  changeTestCaseOrder: Function
  isLoading: boolean
}

const GeneratedTestcaseModuleListView = (
  {
    testcases, deleteTestCase, selectTestCase, isLoading = true, changeTestCaseOrder,
  }: propType,
) => {
  const [orderedItems, setOrderedItems] = useState<any[]>([]);
  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();

  const dragStart = (position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (position: any) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    const copyListItems = [...orderedItems];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    changeTestCaseOrder(copyListItems);
    setOrderedItems(copyListItems);
  };

  useEffect(() => {
    if (testcases) {
      setOrderedItems(testcases);
    }
  }, [testcases]);

  return (
    <div className="fp_list" style={{ marginTop: '0px' }}>
      <Row justify="space-between" className="fp_list_title">
        <Col span={8}><p>Name</p></Col>
        <Col span={6}><p>Project/Module</p></Col>
        <Col span={3}><p>Result</p></Col>
        <Col span={4}><p> </p></Col>
      </Row>
      {isLoading ? <Loading plain size="large" /> : orderedItems?.map((testcase, index: number) => (
        <div
          draggable
          key={JSON.stringify(testcase)}
          onDragStart={() => dragStart(index)}
          onDragEnter={() => dragEnter(index)}
          onDragEnd={drop}
        >
          <GeneratedTestSuitListViewItem
            testcase={testcase}
            deleteTestCase={deleteTestCase}
            selectTestCase={selectTestCase}
          />
        </div>
      ))}
    </div>
  );
};

export default GeneratedTestcaseModuleListView;
