import React, { MouseEventHandler, useEffect, useState } from 'react';
import {
  Modal, Button, Col, Row, Typography,
  Input,
  Form,
} from 'antd';

const { Title } = Typography;

interface EditMonitorModalPropType {
  isModalOpen: boolean,
  handleCancel: MouseEventHandler<HTMLElement>,
  onOkay: MouseEventHandler<HTMLElement>,
  isLoading: boolean,
  title: string,
  monitor: any,
}

const EditMonitorModal = ({
  isModalOpen,
  handleCancel,
  onOkay,
  isLoading,
  title,
  monitor,
}: EditMonitorModalPropType) => {
  const [job, setJob] = useState<any>();

  useEffect(() => {
    if (monitor) {
      setJob({ name: monitor.name });
    }
  }, [monitor]);
  return (
    <Modal
      className="delete-confirm-modal"
      open={isModalOpen}
      centered
      width={700}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Title level={5}>{title}</Title>
              <Form.Item>
                <Input
                  value={job?.name}
                  onChange={(e) => setJob((prev: any) => ({ ...prev, name: e?.target?.value }))}
                  className="fp_input"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="btn-main" justify="center">
            <Col>
              <Button
                className="fp_btn fp_btn-outline"
                shape="round"
                onClick={handleCancel}
                loading={isLoading}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                className="fp_btn fp_btn-primary"
                onClick={() => onOkay(job)}
                loading={isLoading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default EditMonitorModal;
