import { Auth } from 'aws-amplify';

export const resetPassword = async (oldPassword: string, newPassword: string) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.changePassword(user, oldPassword, newPassword);
    return result;
  } catch (error) {
    return null;
  }
};

export const deleteAccount = async () => {
  try {
    return await Auth.deleteUser();
  } catch (error: unknown) {
    if (error instanceof Error) {
      return error.message ? error.message : 'Error occured';
    }
    return 'Error deleting user';
  }
};

export const updateUserName = async (firstName: string, lastName: string) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const updatedAttributes = {
      name: firstName,
      family_name: lastName,
    };
    return await Auth.updateUserAttributes(user, updatedAttributes);
  } catch (error: unknown) {
    if (error instanceof Error) {
      return error.message ? error.message : 'Error occured';
    }
    return 'Error updating username';
  }
};
