export const exportToCsv = (filename: string, rows: string[]): void => {
  if (!rows || !rows.length) {
    return;
  }
  const separator: string = ',';

  const keys: string[] = Object.keys(rows[0]);

  const columnHeaders = keys;

  const csvContent = 'sep=,\n'
    + `${columnHeaders.join(separator)}`
    + '\n'
    // eslint-disable-next-line arrow-body-style
    + `${rows.map((row) => {
      return keys.map((k) => {
        let cell = row[k as keyof typeof row] === null || row[k as keyof typeof row] === undefined ? '' : row[k as keyof typeof row];

        if (typeof cell !== 'string') {
          cell = cell.toString();
        }

        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator);
    }).join('\n')}`;

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
