import { Button, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';

interface QAServiceModalProps {
  visible: boolean,
  onOk: () => void,
}

const QAServiceModal = ({ visible, onOk }: QAServiceModalProps) => (
  <Modal
    className="fp-modal fp_qa_service"
    open={visible}
    centered
    onOk={onOk}
    footer={null}
  >
    <p className="fp_qa_title">Thank you for choosing Footprint.</p>
    <p className="mdl-txt">
      Our sales team will review your
      submission and be in touch with
      you shortly to discuss next steps.
    </p>
    <Row justify="center" align="middle" className="fp_qa_row">
      <Button
        className="fp_btn fp_btn-primary"
        onClick={onOk}
      >
        Ok
      </Button>
    </Row>
  </Modal>
);

export default QAServiceModal;
