import React, { useState } from 'react';
import {
  Button,
  Col, Form, Input, notification, Radio, Row, Select,
} from 'antd';
import { Project } from '../../../redux/projectSlice';
import { getModulesByProjectId } from '../../testcaseCreation/action';
import { notifyError } from '../../../utils/notification';

type propType = {
  projects?: Project[];
  isTestModuleExists: boolean;
  setTestModuleExists: Function;
  isProjectExists: boolean;
  setProjectExists: Function;
  isLoading: boolean;
};

const RequirementForm = ({
  projects = [],
  isTestModuleExists,
  setTestModuleExists,
  isProjectExists,
  setProjectExists,
  isLoading,
}: propType) => {
  const [modules, setModules] = useState<any[]>([]);
  const [isProjectNameEntered, setProjectNameEntered] = useState<boolean>(false);

  const fetchModules = async (projectId: string) => {
    const { requiments: requirements = [] } = await getModulesByProjectId(projectId);
    setModules(requirements);
  };
  const toggleTestModuleStatus = () => setTestModuleExists(!isTestModuleExists);
  const toggleProjectStatus = () => setProjectExists(!isProjectExists);

  const handleProjectChange = () => {
    setProjectNameEntered(true);
  };

  const renderProjects = () => projects
    .map((option: Project) => (
      <Select.Option value={option.projectId} key={option.projectId} selected>
        {option.name}
      </Select.Option>
    ));

  const renderModules = () => modules
    .map((module: any) => (
      <Select.Option value={module.moduleId} key={module.moduleId} selected>
        {module.name}
      </Select.Option>
    ));

  return (
    <div>
      <Form.Item name="testcaseProject">
        <Radio.Group
          defaultValue={1}
          style={{ display: 'flex' }}
          onChange={toggleProjectStatus}
        >
          <Row className="radio_container">
            <Col span={12} style={{ textAlign: 'left' }}>
              <Radio value={1}>Create new project</Radio>
            </Col>
            <Col span={12} style={{ textAlign: 'left' }}>
              <Radio value={2}>Select existing project</Radio>
            </Col>
          </Row>
        </Radio.Group>

      </Form.Item>

      {isProjectExists ? (
        <Form.Item name="testcaseExProject" className="fp_rqg_frm-prj-inp">
          <Select placeholder="E- Commerce Website" onChange={fetchModules}>
            {renderProjects()}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item
          name="testcaseNewProject"
          className="fp_rqg_frm-prj-inp"
          rules={[
            {
              required: true,
              message: 'Please provide a name for the project',
            },
            {
              validator(_, value) {
                const projectName = value?.trim();
                if (projects.find((project) => project.name === projectName)) {
                  notification.destroy();
                  notifyError('Sorry, this project name already exists. Please choose a different name');
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="E- Commerce Website" onChange={handleProjectChange} />
        </Form.Item>
      )}

      <Form.Item name="testcaseTestModule" style={{ marginTop: 25 }}>
        <Radio.Group
          defaultValue={1}
          style={{ display: 'flex' }}
          onChange={toggleTestModuleStatus}
        >
          <Row className="radio_container">
            <Col span={12} style={{ textAlign: 'left' }}>
              <Radio value={1}>Create new module</Radio>
            </Col>
            <Col span={12} style={{ textAlign: 'left' }}>
              <Radio value={2} disabled={!isProjectExists} className={isProjectExists ? '' : 'fp_disabled-radio'}>Select existing module</Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>
      {isTestModuleExists ? (
        <Form.Item name="testcaseExModule" className="fp_rqg_frm-prj-inp">
          <Select placeholder="Login Module">
            {renderModules()}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item
          name="testcaseNewModule"
          className="fp_rqg_frm-prj-inp"
          rules={[
            {
              required: true,
              message: 'Please provide a name for the module',
            },
            {
              validator(_, value) {
                const moduleName = value?.trim();
                if (modules.find((module) => module.name === moduleName)) {
                  notification.destroy();
                  notifyError('Sorry, this module name already exists. Please choose a different name');
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            placeholder="Login Module"
            disabled={!isProjectExists && !isProjectNameEntered}
          />
        </Form.Item>
      )}

      <Form.Item style={{ marginTop: 25 }} label="Name of the 'Functional Test' or 'User Journey'" name="requirementDescription" rules={[{ required: true, message: 'Please provide a name for the functional test or user journey' }]}>
        <Input placeholder="User login with correct email and password" className="fp_rqg_frm-reqInp" />
      </Form.Item>

      <div>
        <Button className="fp_btn fp_btn-primary fp_tcc-btn" type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>Next</Button>
      </div>
    </div>
  );
};

export default RequirementForm;
