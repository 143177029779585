import React from 'react';
import { useParams } from 'react-router-dom';
import CreateNewtestcaseView from './CreateNewtestcaseView';

interface routerParams {
  moduleId: string
  requirementId: string
}

const TestcaseResultView = () => {
  const { moduleId, requirementId }: routerParams = useParams();

  return (
    <CreateNewtestcaseView
      moduleId={moduleId}
      requirementId={requirementId}
    />
  );
};

export default TestcaseResultView;
