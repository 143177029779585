import React, { MouseEventHandler } from 'react';
import {
  Modal, Button, Col, Row, Typography,
} from 'antd';

const { Title } = Typography;

interface ConfirmDeleteModalProptype {
  isModalOpen: boolean,
  handleCancel: MouseEventHandler<HTMLElement>,
  handleDelete: MouseEventHandler<HTMLElement>,
  isLoading: boolean,
  title: string,
  deleteButtonText: string,
}

const DeleteConfirmationModal = ({
  isModalOpen,
  handleCancel,
  handleDelete,
  isLoading,
  title,
  deleteButtonText,
}: ConfirmDeleteModalProptype) => (
  <Modal
    className="delete-confirm-modal"
    open={isModalOpen}
    centered
    width={448}
    onCancel={handleCancel}
    closable={false}
    footer={null}
  >
    <Row>
      <Col span={24}>
        <Row>
          <Col>
            <Title level={5}>{title}</Title>
            <p className="delete-content">Please note this action is irreversible.</p>
          </Col>
        </Row>
        <Row className="btn-main">
          <Col span={4}>
            <Button className="edit-btn" shape="round" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
          <Col span={4}>
            <Button
              className="delete-btn"
              shape="round"
              onClick={handleDelete}
              loading={isLoading}
            >
              {deleteButtonText}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
);

export default DeleteConfirmationModal;
