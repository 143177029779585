import React from 'react';
import {
  FormInstance, Steps,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { NEW_TESTCASE_STEPS_MANUAL } from '../../../constants';
import TypeSelect from './TypeSelect';
import ScriptUpload from './ScriptUpload';
import SaveScript from './SaveScript';

type SelectTestTypeProps = {
  goNext: Function;
  goBack: Function;
  current: number;
  setTestcaseCreateView: Function;
  testCaseType: string|undefined;
  setTestCaseType: Function;
  testCaseSettings: FormInstance;
  setTestScriptFile: Function;
  setTestDataFile: Function;
  testScriptFile: RcFile|File|undefined;
  onSaveTestCase: Function;
  onRunTestcase: Function;
  setTestCaseEdited: Function;
  exit: Function;
  resultView: Function;
  isDryRunComplete: boolean;
  testDataFile: RcFile|File|undefined;
}

const { Step } = Steps;

const ManualScriptFlowView = ({
  goNext, goBack, current, setTestcaseCreateView, testCaseType, setTestCaseType,
  testCaseSettings, setTestScriptFile, setTestDataFile, testScriptFile, onSaveTestCase, exit,
  resultView, setTestCaseEdited, testDataFile,
  onRunTestcase,
  isDryRunComplete,
}: SelectTestTypeProps) => {
  const SelectStepView = () => {
    switch (current) {
      case NEW_TESTCASE_STEPS_MANUAL.SELECT_TYPE:
        return (
          <TypeSelect
            testCaseType={testCaseType}
            setTestCaseType={setTestCaseType}
            setTestcaseCreateView={setTestcaseCreateView}
            goNext={goNext}
          />
        );

      case NEW_TESTCASE_STEPS_MANUAL.UPLOAD_SCRIPT:
        return (
          <ScriptUpload
            setTestScriptFile={setTestScriptFile}
            setTestDataFile={setTestDataFile}
            goBack={goBack}
            goNext={goNext}
            testScriptFile={testScriptFile}
            testDataFile={testDataFile}
          />
        );

      case NEW_TESTCASE_STEPS_MANUAL.SAVE:
        return (
          <SaveScript
            testCaseSettings={testCaseSettings}
            goBack={goBack}
            onSaveTestCase={onSaveTestCase}
            onRunTestcase={onRunTestcase}
            setTestCaseEdited={setTestCaseEdited}
            exit={exit}
            resultView={resultView}
            isDryRunComplete={isDryRunComplete}
            setTestScriptFile={setTestScriptFile}
            testScriptFile={testScriptFile}
          />
        );

      default:
        return <div>empty</div>;
    }
  };

  return (
    <div className="fp_testcase_container">
      <div>
        <Steps
          type="navigation"
          size="default"
          responsive
          current={current}
        >
          <Step title="Type of script" />
          <Step title="Upload test scripts &amp; test data files" />
          <Step title="Setting up thresholds &amp; run test" />
        </Steps>
      </div>
      {SelectStepView()}
    </div>
  );
};

export default ManualScriptFlowView;
