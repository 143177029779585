import React from 'react';
import { Form } from 'antd';
import { TextEditor } from '../../../../../components';
import { SCRIPT_TYPE } from '../../../../../types/common';

interface EditorProps {
  disabled?: boolean
  content: string
  language?: string
  form: any
  handleOnChange: Function
  selectedScript: string
}

const EditorIDE = ({
  form, disabled = false, content, language = 'javascript', handleOnChange, selectedScript,
}: EditorProps) => {
  const handleScriptChange = (s: any) => {
    form.setFieldValue(selectedScript === SCRIPT_TYPE.BDD ? 'bdd' : 'automationScript', s.target.value);
    handleOnChange(s);
  };
  return (
    <Form layout="vertical" form={form} className="fp_frm-ant-form-item" key={`${content}-${selectedScript}`}>
      <Form.Item
        name={selectedScript === SCRIPT_TYPE.BDD ? 'bdd' : 'automationScript'}
        style={{
          marginBottom: 10,
        }}
        rules={[{ required: true, message: `Please enter ${selectedScript === SCRIPT_TYPE.BDD ? 'BDD script' : 'automation script'}` }]}
      >
        <TextEditor
          value={content}
          language={language}
          placeholder={`Please enter ${selectedScript === SCRIPT_TYPE.BDD ? 'BDD script' : 'automation script'}.`}
          onChange={handleScriptChange}
          padding={15}
          style={{ minHeight: 600 }}
          disabled={disabled}
          allowCopyOption
          allowFormatOption
        />
      </Form.Item>
    </Form>
  );
};

export default EditorIDE;
