import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import GeneratedTestcaseModuleListViewItem from './GeneratedTestcaseModuleListViewItem';

interface GeneratedTestcaseModuleListViewProps {
  testModules: any[]
  deleteModule: Function
}

const GeneratedTestcaseModuleListView = ({
  testModules, deleteModule,
}: GeneratedTestcaseModuleListViewProps) => {
  const moduleList = useMemo(() => (
    testModules.map((module: any) => (
      <GeneratedTestcaseModuleListViewItem
        module={module}
        key={module.requirementId}
        deleteModule={deleteModule}
      />
    ))
  ), []);

  return (
    <div className="fp_list">
      <Row justify="space-between" className="fp_list_title">
        <Col span={6}><p>Module Name</p></Col>
        <Col span={13}>
          <Row>
            <Col span={18}>
              <p>Created At</p>
            </Col>
          </Row>
        </Col>
      </Row>
      {moduleList}
    </div>
  );
};

export default GeneratedTestcaseModuleListView;
