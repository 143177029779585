import { UploadOutlined } from '@ant-design/icons';
import {
  Modal,
  Button,
  Upload,
  UploadFile,
  notification,
} from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import React, { useEffect, useState } from 'react';
import { TestCase } from '../../../../redux/testCaseSlice';
import { notifyError, notifyInProgress, notifySuccess } from '../../../../utils/notification';
import { getSignedUrl, updateTestCase, uploadFile } from '../../action';

interface ReRunModalType {
  open: boolean;
  onClose: Function;
  testCase: TestCase;
  triggerReRun: Function;
}

const ReRunModal = ({
  open, onClose, testCase, triggerReRun,
}: ReRunModalType) => {
  const {
    accountId, projectId,
    testcaseId, testData,
  } = testCase;
  const [isTestDataUpdated, testDataUpdated] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onCloseCleanup = () => {
    testDataUpdated(false);
    onClose(false);
  };

  const uploadTestDataFile = async (file: UploadFile) => {
    notifyInProgress('Uploading new test data file', 'upload_new_test_data_file');
    const key = `${accountId}/${projectId}/${testcaseId}/${file.name}`;
    const url = await getSignedUrl(key, 'application/json', 'putObject');
    await uploadFile(url, file.originFileObj, 'application/json');
    notification.destroy();
    notifySuccess('Test data uploaded successfully', 'test_data_uploaded_successfully');
    return file.name;
  };

  const updateTestCaseAttributes = async () => {
    const [file]: any[] = fileList;
    if (!isTestDataUpdated) return false;
    const fileName = await uploadTestDataFile(file);
    notifyInProgress('Uploading test case file', 'upload_new_test_data_file');
    await updateTestCase({
      accountId, projectId, testcaseId, testData: fileName,
    });
    notification.destroy();
    return notifySuccess('Test case uploaded successfully', 'test_data_uploaded_successfully');
  };

  const handelReRun = async () => {
    try {
      await updateTestCaseAttributes();
      onCloseCleanup();
      triggerReRun();
    } catch (error: any) {
      notification.destroy();
      notifyError("Something wen't wrong", 'something_went_wrong');
    }
  };

  const getTestDataFile = async () => {
    if (!testData || testData === '') return setFileList([]);
    const url = await getSignedUrl(`${accountId}/${projectId}/${testcaseId}/${testData}`);
    setFileList([{
      uid: '1',
      name: testData,
      status: 'done',
      url,
    }]);
    return null;
  };

  const handleFileOnChange = ({ file }: UploadChangeParam<UploadFile<any>>) => {
    testDataUpdated(true);
    setFileList([file]);
  };

  useEffect(() => {
    if (testCase) {
      getTestDataFile();
    }
  }, [testCase]);

  return (
    <Modal
      className="max-user-modal fp-modal fp-modal-primary"
      visible={open}
      title="Configure test data"
      closable={false}
      onCancel={() => onClose(false)}
      footer={[
        <Button onClick={onCloseCleanup} type="primary" className="fp_btn fp_btn-primary">Cancel</Button>,
        <Button onClick={handelReRun} type="primary" className="fp_btn fp_btn-outline">{isTestDataUpdated ? 'Upload test data & Re-run' : 'Re-run'}</Button>,
      ]}
    >
      <Upload accept=".json, .csv" className="upload-test-data-component" multiple={false} fileList={fileList} onChange={handleFileOnChange}>
        <Button
          className="fp_btn fp_btn-primary fp_navigate_btn"
          icon={<UploadOutlined />}
          style={{ width: 475 }}
        >
          Upload
        </Button>
      </Upload>
    </Modal>
  );
};

export default ReRunModal;
