import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form, Input, Button, Spin,
} from 'antd';
import { Logo } from '../../components';
import { reSendEmailverifyCode, verifyEmail } from './action';
import { useAppSelector } from '../../redux/hooks';
import { ROUTES } from '../../constants';

export const EmailVerify = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const email = useAppSelector((state) => state.userData.email);

  const [reSendDisable, setreSendDisable] = useState<boolean>(false);
  const [codeSendProgress, setCodeSendProgress] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [sucessMsg, setSuccessMSG] = useState<string>('');

  useEffect(() => {
    if (!email) {
      history.replace(ROUTES.LOGIN, { from: 'verify' });
    }
  }, [email]);

  const onVerify = async () => {
    setErrorText('');
    setSuccessMSG('');
    setCodeSendProgress(true);
    const { code } = form.getFieldsValue();
    try {
      const verified = await verifyEmail(email ?? '', code);
      if (verified) history.replace(ROUTES.LOGIN, { from: 'verify' });
    } catch (error) {
      setErrorText(error.message);
    } finally {
      setCodeSendProgress(false);
    }
  };

  const reSend = async () => {
    setSuccessMSG('');
    setErrorText('');
    setreSendDisable(true);
    try {
      await reSendEmailverifyCode(email ?? '');
      setSuccessMSG('Code send to your email successfully.');
    } catch (error) {
      setErrorText(error.message);
    }
    setreSendDisable(false);
  };

  const successMsgView = sucessMsg
    && (
      <div className="fp_cmpnt fp_cmpnt_jsfy_cntn_cntr">
        <p className="fp_medium-text-2 fp_scss_text">
          {sucessMsg}
        </p>
      </div>
    );

  const errorMsgView = errorText
    && (
      <div className="fp_cmpnt fp_cmpnt_jsfy_cntn_cntr">
        <p className="fp_medium-text-2 fp_err_text">
          {errorText}
        </p>
      </div>
    );

  const loadingIcon = codeSendProgress ? <Spin /> : null;

  return (
    <div className="fp_full_size fp_pg_center">
      <Form className="fp_lgn_frm" form={form} requiredMark layout="vertical" onFinish={onVerify}>
        <div className="fp_cmpnt fp_cmpnt_cntr fp_logo_color">
          <Logo />
        </div>
        <div className="fp_cmpnt">
          <p className="fp_lgn_ttl_update">Email Verification</p>
        </div>
        <div className="fp_cmpnt fp_cmpnt_jsfy_cntn_cntr fp_mrgn_tp_20">
          <p className="fp_regular-text-1">
            We&apos;ve sent a verifcation code to your email -
            {` ${email} `}
            Please check your mailbox
          </p>
        </div>
        <Form.Item label="Verification Code" name="code" rules={[{ required: true, message: 'Please input your code!' }]}>
          <Input data-testid="fp_email_code" disabled={codeSendProgress} />
        </Form.Item>
        <Form.Item className="fp_mrgn_tp_20">
          <Button data-testid="fp_continue" type="primary" icon={loadingIcon} disabled={codeSendProgress} className="fp_btn fp_btn-primary fp_btn-login" htmlType="submit">Continue</Button>
        </Form.Item>
        {successMsgView}
        {errorMsgView}
        <div className="fp_cmpnt fp_cmpnt_flx_rw fp_cmpnt_jsfy_cntn_cntr fp_mrgn_tp_20">
          <p className="fp_medium-text-2">Haven&apos;t received a verifcation email?</p>
          <Button data-testid="fp_send_code_again" type="primary" onClick={reSend} disabled={reSendDisable} className="fp_btn fp_btn-link">Send again</Button>
        </div>
      </Form>
    </div>
  );
};

export default EmailVerify;
