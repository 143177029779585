import React from 'react';
import { Button, Modal } from 'antd';

type ErrorProps = {
  isError: boolean;
  setError: Function;
}

const ErrorModal = ({ isError, setError }: ErrorProps) => (
  <Modal
    open={isError}
    onCancel={() => setError(false)}
    className="error-popup"
    closable={false}
    footer={null}
    centered
  >
    <h2 className="error-title">Our Servers are not available at the moment</h2>
    <p>
      {'Apologies, servers are temporarily unavailable. We\'re working to restore them promptly. Thank you for your patience.'}
    </p>
    <div className="error-popup-btn-container">
      <Button
        data-testid="error-popup-btn"
        className="fp_btn-primary fp_btn frm-btn"
        htmlType="button"
        onClick={() => setError(false)}
      >
        Ok
      </Button>
    </div>
  </Modal>
);

export default ErrorModal;
