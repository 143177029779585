import React from 'react';
import {
  Button, Modal, notification, Row, Spin,
} from 'antd';
import {
  SmileTwoTone, FrownTwoTone, LoadingOutlined, WarningTwoTone, FrownOutlined,
} from '@ant-design/icons';

export const notifyError = (title: string, key = '', duration: null | number = null) => {
  notification.error({
    message: title,
    icon: <FrownOutlined style={{ color: '#ff4d4d' }} />,
    key,
    duration,
  });
};

export const notifyWarning = (message: any, duration = undefined) => {
  notification.warning({
    message,
    icon: <FrownTwoTone twoToneColor="#F6C627" />,
    duration,
  });
};

export const notifySuccess = (title: any, key = '') => {
  notification.success({
    message: title,
    icon: <SmileTwoTone twoToneColor="#52c41a" />,
    key,
  });
};

export const notifyInProgress = (title: any, key = '', duration = 0) => {
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  notification.open({
    message: title,
    icon: <Spin indicator={loadingIcon} />,
    key,
    duration,
  });
};

export const notifiableAPICall = async (
  fn: Function, key: string, inProgressMessage: any, successMessage: any, errorMessage: string,
  errorThrowable = false,
) => {
  try {
    notifyInProgress(inProgressMessage, key);
    const res = await fn();
    notification.close(key);
    if (successMessage) notifySuccess(successMessage, key);
    return res;
  } catch (e) {
    notification.close(key);
    notifyError(errorMessage, key);
    if (errorThrowable) throw e;
  }
  return {};
};

export const notifyConfirmation = (message: any, actionButtonText: any, onActionClick: () => void, cancelButtonText = 'Cancel', onCancelClick: () => void, key = 'default_key', duration = 0) => {
  const onAction = () => {
    onActionClick();
    notification.close(key);
  };

  const onCancel = () => {
    if (onCancelClick) onCancelClick();
    notification.close(key);
  };

  const content = (
    <div>
      <p>{message}</p>
      <Row className="intnnt-ntfcn-btn-rw">
        <Button type="link" onClick={() => onCancel()} className="intnnt-btn intnnt-btn-cncl">{cancelButtonText}</Button>
        <Button type="link" onClick={() => onAction()} className="intnnt-btn intnnt-btn-dngr">{actionButtonText}</Button>
      </Row>
    </div>
  );
  notification.open({
    message: 'Warning',
    description: content,
    icon: <WarningTwoTone twoToneColor="#f5222d" />,
    key,
    duration,
  });
};

export const confirm = (title: any, content: any, onOk: any, onCancel: any) => {
  Modal.confirm({
    className: 'intenant-confirm',
    width: 520,
    title: (<p className="admin-modal-title">{title}</p>),
    icon: null,
    content: (<p className="admin-modal-text">{content}</p>),
    okText: 'Confirm',
    okType: 'primary',
    okButtonProps: { className: 'ant-btn btn btn--primary' },
    cancelText: 'Cancel',
    cancelButtonProps: { className: 'ant-btn btn btn-bordered' },
    onOk,
    onCancel,
  });
};
