import React from 'react';
import { Form, Input, Button } from 'antd';
import { addContactUs } from '../../action';
import { CONTACT_US_TYPES } from '../../../../constants';
import { useAppSelector } from '../../../../redux/hooks';

const { TextArea } = Input;

type FormProps = {
  modalClose: Function;
}

const EnterprisePlanForm = ({ modalClose }: FormProps) => {
  const [form] = Form.useForm();
  const selectedAccount = useAppSelector(({ userData }: any) => userData.selectedAccount);

  const onSubmit = async (formValues: any) => {
    const { description } = formValues;
    const contactDetails = formValues;
    delete contactDetails.description;
    await addContactUs({
      accountId: selectedAccount?.accountId,
      description,
      contactDetails,
      type: CONTACT_US_TYPES.ENTERPRISE_SALES,
    });
    modalClose();
  };

  return (
    <Form className="enterprise-form fp_rqg_crd_inf fp_rqg_crd-frm fp_rqg_crd-entpfrm" layout="vertical" form={form} onFinish={onSubmit}>
      <h1 className="fp_rqg_crd-ttl">Contact Sales</h1>
      <p className="fp_rqg_crd-sub">
        To better understand your requirements and see how our
        product can benefit you, please fill out the following sales inquiry form.
      </p>
      <Form.Item label="Company name" name="companyName" rules={[{ required: true, message: 'Please enter the company name' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Company website"
        name="companyWebsite"
        rules={[
          {
            required: true,
            message: 'Please enter the company website',
          },
          {
            type: 'url',
            message: 'This field must be a valid url.',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Contact name" name="contactName" rules={[{ required: true, message: 'Please enter the contact name' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Email address"
        name="email"
        rules={[
          {
            required: true,
            message: 'Please enter the email address',
          },
          {
            type: 'email',
            message: 'This field must be a valid email.',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Message" name="description" rules={[{ required: true, message: 'Please enter your message' }]}>
        <TextArea className="fp_rqg_frm-reqInp" rows={4} />
      </Form.Item>
      <div className="fp_sls-frm-ftr">
        <Button className="fp_btn fp_btn-primary" style={{ marginRight: 10 }} onClick={() => modalClose()}>Cancel</Button>
        <Button className="fp_btn fp_btn-primary" htmlType="submit">Contact Sales</Button>
      </div>
    </Form>
  );
};

export default EnterprisePlanForm;
