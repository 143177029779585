import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe } from '@stripe/stripe-js';

import { getSecret } from './action';
import Loading from '../Loading';
import SetupForm from './SetupForm';
import { STRIPE_KEY } from '../../configs/app-config';

const stripePromise = loadStripe(STRIPE_KEY);
const appearance: Appearance = {
  theme: 'night',
  labels: 'floating',
};

type propType = {
  accountId: string
  handleOk: Function
  onCancel: Function
  profileview: boolean
}

export const StripeIPG = ({
  accountId, handleOk, onCancel, profileview,
}: propType) => {
  const [clientSecret, setClientSecret] = useState<string|undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function loadData() {
      const secret = await getSecret(accountId);
      setClientSecret(secret);
    }
    loadData();
  }, []);

  if (!clientSecret || isLoading) {
    return <Loading plain size="large" />;
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
      <SetupForm
        handleOk={handleOk}
        setLoading={setLoading}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        onCancel={onCancel}
        profileview={profileview}
      />
    </Elements>
  );
};
