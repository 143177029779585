import React from 'react';
import {
  Button, Col, Modal, Row, Typography,
} from 'antd';

const { Title, Link: MailLink } = Typography;

type SignUpErrorModalProps = {
  open: boolean
  navigateToLogin: () => void,
};

const SignUpErrorModal = ({ open, navigateToLogin }: SignUpErrorModalProps) => (
  <Modal
    className="delete-confirm-modal"
    open={open}
    centered
    closable
    footer={null}
    width={550}
  >
    <Row>
      <Col span={24}>
        <Row>
          <Col>
            <Title level={5}>Email is already registered but not verified</Title>
            <p style={{ textAlign: 'justify' }}>
              Hello, we&apos;re sorry but the email address you entered is already
              associated with an account that has not yet completed the verification process.
              This can complicate things and prevent you from using the account as intended.
              To resolve this, please use a different email address to create a new account
              or contact our support team at
              <MailLink underline href="support@footprint.systems"> support@footprint.systems </MailLink>
              for assistance.
            </p>
            <p style={{ textAlign: 'center', margin: '10px 0' }}>Thank you.</p>
          </Col>
        </Row>
        <Row className="btn-main">
          <Col>
            <Button className="edit-btn" shape="round" onClick={navigateToLogin}>
              Ok
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
);

export default SignUpErrorModal;
