import { Modal, Button, Select } from 'antd';
import React, { useState } from 'react';
import { generateToken } from '../action';
import { useAppSelector } from '../../../redux/hooks';
import { notifyError } from '../../../utils/notification';

interface propType {
  open: boolean,
  onCancel: Function,
}
const GenerateTokenModal = ({
  open, onCancel,
}: propType) => {
  const [expiration, setExpiration] = useState<number>();
  const accountId = useAppSelector(({ userData }: any) => userData?.selectedAccount?.accountId);

  const handleOnClose = () => {
    onCancel(false);
    setExpiration(undefined);
  };

  const handleConfirm = async () => {
    if (!expiration) notifyError('Expiration is required');
    onCancel(false);
    await generateToken({ accountId, expireInDays: expiration || 7 });
    handleOnClose();
  };

  return (
    <Modal
      className="max-user-modal fp-modal fp-modal-primary fp_middle_notification_model"
      open={open}
      title="Select expiration for the token"
      onCancel={() => onCancel(false)}
      footer={[
        <Button type="primary" className="fp_btn fp_btn-outline" onClick={handleOnClose}>Cancel</Button>,
        <Button
          type="primary"
          className="fp_btn fp_btn-primary"
          onClick={handleConfirm}
          disabled={!expiration}
        >
          Generate
        </Button>,
      ]}
    >
      <Select
        placeholder="Select expiration for the token"
        className="selector"
        onSelect={(e: any) => setExpiration(e)}
        style={{ width: '100%' }}
      >
        <Option value={7}>7 days</Option>
        <Option value={30}>30 days</Option>
        <Option value={60}>60 days</Option>
        <Option value={90}>90 days</Option>
        <Option value={365}>365 days</Option>
      </Select>
    </Modal>
  );
};

export default GenerateTokenModal;
