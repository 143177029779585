import React from 'react';
import { Row } from 'antd';
import { IconInfoTile, PlayIcon, ScriptIcon } from '../../components';
import { NEW_TESTCASE_INIT_VIEW_TILES, TESTCASE_CREATE_VIEW_TYPE } from '../../constants';

type InitViewProps = {
  selectCreateType: Function;
};
// sample comment
const NewTestcaseInitView = ({ selectCreateType }: InitViewProps) => (
  <div className="fp_pg_base fp_full_size">
    <Row>
      <p className="fp_heading-2 fp_cmpnt fp_cmpnt_jsfy_cntn_cntr fp_width_full">Let&apos;s get to actual business!</p>
    </Row>
    <Row className="fp_full_size fp_nw_tstcs_tile_cntnr" justify="center">
      <IconInfoTile title={NEW_TESTCASE_INIT_VIEW_TILES[0].title} icon={ScriptIcon} description={NEW_TESTCASE_INIT_VIEW_TILES[0].description} selectType={(() => selectCreateType(TESTCASE_CREATE_VIEW_TYPE.OWN_SCRIPT))} className="fp_nw_tstcs_tile bg-1" />
      <IconInfoTile title={NEW_TESTCASE_INIT_VIEW_TILES[1].title} icon={PlayIcon} description={NEW_TESTCASE_INIT_VIEW_TILES[1].description} selectType={() => selectCreateType(TESTCASE_CREATE_VIEW_TYPE.RECORD)} className="fp_nw_tstcs_tile bg-2" />
    </Row>
  </div>
);
export default NewTestcaseInitView;
