import React from 'react';
import { Col, Row } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

type TextInfoProps = {
  title?: string;
  // eslint-disable-next-line no-undef
  text: string | JSX.Element;
}

const TextInfo = ({ title, text }: TextInfoProps) => (
  <Row
    justify="center"
    className="fp_textinfo_container"
  >
    <Col span={1} className="fp_textinfo_icon">
      <InfoCircleFilled style={{ color: '#93DEFF', fontSize: 20 }} />
    </Col>
    <Col span={22}>
      {title && <p className="fp_textinfo_title">{title}</p>}
      <p className="fp_textinfo_desc">{text}</p>
    </Col>
  </Row>
);

TextInfo.defaultProps = {
  title: undefined,
};

export default TextInfo;
