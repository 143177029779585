import {
  Modal,
  Row,
  Button,
  Col,
  DatePicker,
  TimePicker,
  Form,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import {
  RunIcon,
  ScheduleIcon,
  DateIcon,
  TimeIcon,
} from '../../../components';

interface RunningApproachSelectionModalProps {
  open: boolean
  onClose: Function
  setParallelRun: Function
}

const buttons: any[] = [
  {
    tooltip: 'Choose this option to execute your test suite immediately.',
    label: 'Run Now',
    value: false,
    icon: <RunIcon />,
  },
  {
    tooltip: 'Schedule your test suite to run at a specific date and time, or set it to monitor at regular intervals for synthetic monitoring.',
    label: 'Schedule',
    value: true,
    icon: <ScheduleIcon />,
  },
];

const RunningApproachSelectionModal = ({
  open,
  onClose,
  setParallelRun,
}: RunningApproachSelectionModalProps) => {
  const [tooltip, setTooltip] = useState<string | undefined>();
  const [isBtnClicked, setBtnClicked] = useState<boolean>(false);
  const [isButtonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [isSchedule, setSchedule] = useState<boolean>(false);
  const [date, setDate] = useState<any>(null);
  const [time, setTime] = useState<any>(null);

  const handleApproachSelection = (flag: boolean, label: string) => {
    setButtonEnabled(false);
    if (label === 'Schedule') {
      setSchedule(true);
      setBtnClicked(true);
    } else {
      setSchedule(false);
      setButtonEnabled(true);
      setParallelRun(flag);
      setBtnClicked(false);
    }
  };

  const handleDateChange = (selectedDate: any) => {
    setDate(selectedDate);
  };

  const handleTimeChange = (selectedTime: any) => {
    setTime(selectedTime);
  };

  const handleNextButton = () => {
    setButtonEnabled(false);
    onClose(true, isSchedule);
  };

  useEffect(() => {
    if (date !== null && time !== null) {
      setButtonEnabled(true);
    }
  }, [date, time]);

  return (
    <>
      <Modal
        className={`max-user-modal fp-modal fp-modal-primary modal-margins ${isSchedule ? 'modal-min-top-margin' : ''}`}
        width={600}
        open={open}
        title="When would you like to run your test execution suite"
        closable
        maskClosable={false}
        onCancel={() => onClose()}
        footer={[]}
      >
        <Row>
          {buttons.map((item: any, index: number) => (
            <Col
              span={11}
              offset={buttons.length === (index + 1) ? 2 : 0}
              key={JSON.stringify(item)}
            >
              <Button
                onClick={() => handleApproachSelection(item.value, item.label)}
                onMouseEnter={() => setTooltip(item?.tooltip)}
                onMouseLeave={() => setTooltip(undefined)}
                className={`fp-eas-button fp-eas-button-device-button ${item.label === 'Schedule' && isBtnClicked ? 'fp-eas-button-btn-active' : ''}`}
              >
                {item.icon}
                {item.label}
              </Button>
            </Col>
          ))}
        </Row>
        <Row>
          {!tooltip && <div className="aes-tooltip-container">Select when to run your test execution suite</div>}
          {tooltip && <div className="aes-tooltip-container aes-tooltip-container-bg">{tooltip}</div>}
        </Row>
        <Row>
          {isSchedule && <div className="segmented-title">When would you like to run your test execution suite</div>}
          {isSchedule && (
            <Form
              layout="vertical"
              wrapperCol={{ span: 12 }}
              className="required-icon"
            >
              <Row>
                <Col className="form-col">
                  <DateIcon style={{ textAlign: 'center', marginTop: '40px', marginRight: '20px' }} />
                  <Form.Item name="datepicker" label="Date" rules={[{ required: true, message: 'Date is required!' }]}>
                    <DatePicker suffixIcon={<DownOutlined />} format="dddd, MMMM D" className="fb_picker_input" onChange={handleDateChange} />
                  </Form.Item>
                </Col>
                <Col className="form-col">
                  <TimeIcon style={{ textAlign: 'center', marginTop: '40px', marginRight: '20px' }} />
                  <Form.Item name="timepicker" className="required-icon" label="Time" rules={[{ required: true, message: 'Time is required!' }]}>
                    <TimePicker suffixIcon={<DownOutlined />} format="h:mm a" className="fb_picker_input" onChange={handleTimeChange} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Row>
        <Row>
          <Button className="fp_btn fp_btn-tertiary" disabled={!isButtonEnabled} onClick={handleNextButton}>Next</Button>
        </Row>
      </Modal>
    </>
  );
};

export default RunningApproachSelectionModal;
