/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { ClearOutlined, CopyOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';
import AceEditor from 'react-ace';
import beautify from 'js-beautify';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github_dark';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-beautify';
import Loading from './Loading';

type TextEditorProps = {
  value: string;
  language: string;
  readOnly?: boolean;
  placeholder?: string;
  onChange?: Function;
  style?: React.CSSProperties;
  padding?: number;
  disabled?: boolean;
  allowCopyOption: boolean;
  allowFormatOption: boolean;
  editMode: boolean;
};

const TextEditor = ({
  disabled,
  value,
  language,
  readOnly,
  placeholder,
  onChange,
  style,
  padding = 15,
  allowCopyOption = false,
  allowFormatOption = false,
  editMode = false,
}: TextEditorProps) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [script, setScript] = useState<string>('');
  const [timeoutId, setTimeoutId] = useState(null);
  const [isInitialValueConfigured, setInitialValueConfigured] = useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };

  const handleScriptChange = (s: string) => {
    setScript(s);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId: any = setTimeout(() => {
      // Here you can perform auto-save or any action after 2 seconds of inactivity
      if (onChange) onChange({ target: { value: s } });
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const normalizeFunctionArguments = () => {
    const regex = /async\s*\(\s*\{\s*([\s\S]*?)\s*\}\s*\)/g;

    return value.replace(regex, (match, args) => {
      const normalizedArgs = args.replace(/\n\s*/g, ' ');
      return `async ({ ${normalizedArgs.trim()} })`;
    });
  };

  const formatScript = () => {
    const code: string = normalizeFunctionArguments();
    const options: any = {
      indent_size: 2,
      space_in_empty_paren: true,
      jslint_happy: true,
      preserve_newlines: true,
      max_preserve_newlines: 2,
      brace_style: 'collapse-preserve-inline',
      break_chained_methods: true,
      keep_array_indentation: true,
      space_before_conditional: true,
      unescape_strings: false,
      wrap_line_length: 0,
      semicolon_insertion: true,
    };

    const formatted = beautify.js(code, options);
    handleScriptChange(formatted);
  };

  useEffect(() => {
    if (!isInitialValueConfigured) {
      setScript(value);
      setInitialValueConfigured(true);
    }
  }, [value]);

  return (
    <div style={{ position: 'relative' }}>
      {editMode || (language === 'javascript' && !(readOnly || disabled)) ? (
        <AceEditor
          placeholder={placeholder}
          mode="javascript"
          theme="github_dark"
          name="code-editor"
          width="100%"
          className="fp_logview_editor"
          style={{ ...style, padding }}
          editorProps={{ $blockScrolling: true }}
          onChange={handleScriptChange}
          fontSize={16}
          lineHeight={19}
          showPrintMargin={false}
          showGutter
          highlightActiveLine={false}
          value={script}
          defaultValue={value}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
          }}
          readOnly={readOnly || disabled}
        />
      ) : (
        <CodeEditor
          value={value}
          language={language}
          readOnly={readOnly}
          style={style}
          className="fp_logview_editor"
          placeholder={placeholder}
          padding={padding}
          disabled={disabled}
        />
      ) }
      {allowCopyOption && (
      <Tooltip title={copySuccess ? 'Copied!' : 'Copy to Clipboard'} placement="topRight">
        <Button
          type="text"
          icon={<CopyOutlined />}
          onClick={handleCopy}
          style={{ position: 'absolute', top: 8, right: 8 }}
        />
      </Tooltip>
      )}
      {allowFormatOption && (
      <Tooltip title="Format the script" placement="topRight">
        <Button
          type="text"
          icon={<ClearOutlined />}
          onClick={formatScript}
          style={{ position: 'absolute', top: 8, right: 40 }}
        />
      </Tooltip>
      )}
    </div>
  );
};

export default TextEditor;
