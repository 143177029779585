import React, { useState } from 'react';
import {
  Button,
  Col, Form, FormInstance, InputNumber, Modal, notification, Row, Space, Input,
} from 'antd';
// import { CaretRightOutlined, CheckCircleFilled } from '@ant-design/icons';
import { CaretRightOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { TextInfo } from '../../../components';
import { notifyError } from '../../../utils/notification';
import EditTestCaseModal from './EditTestCaseModal';
import { TestCase } from '../../../redux/testCaseSlice';
import { useAppSelector } from '../../../redux/hooks';

type SaveScriptProps = {
  testCaseSettings: FormInstance;
  goBack: Function;
  onSaveTestCase: Function;
  onRunTestcase: Function;
  setTestCaseEdited: Function;
  exit: Function;
  resultView: Function;
  isDryRunComplete: boolean;
  setTestScriptFile: Function;
  testScriptFile: RcFile | File | undefined;
}

const ACTION = {
  SAVE: 'SAVE',
  EXIT: 'EXIT',
  SAVE_AND_RUN: 'SAVE_AND_RUN',
  DRY_RUN: 'DRY_RUN',
};

type ActionType = typeof ACTION[keyof typeof ACTION]

const SaveScript = ({
  testCaseSettings,
  goBack,
  onSaveTestCase,
  setTestCaseEdited, onRunTestcase,
  exit,
  resultView, isDryRunComplete, testScriptFile, setTestScriptFile,
}: SaveScriptProps) => {
  const [isModelVisible, setModelVisible] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<ActionType>();
  const [isEditTestCase, setEditTestCase] = useState<boolean>(false);

  const triggerEditTestCaseModal = () => setEditTestCase(!isEditTestCase);
  const testCases: Array<TestCase> = useAppSelector(({ testCaseData }) => testCaseData.testCases);

  const onSave = async () => {
    await onSaveTestCase();
    resultView();
  };

  const onRun = async () => {
    try {
      await onRunTestcase();
      resultView();
    } catch (error: any) {
      notification.destroy();
      const { message = 'Error occurred' } = error;
      notifyError(message, 'testcase_run_error', 3);
    }
  };

  const onCloseModel = () => {
    if (testCaseSettings.getFieldError('name').length) return;
    if (!testCaseSettings.getFieldsValue().name.trim()) {
      notification.destroy();
      notifyError('Empty test case names are not allowed!');
      return;
    }
    if (testCases.find((test) => test.name === testCaseSettings.getFieldsValue().name.trim())) {
      notification.destroy();
      notifyError('The test case name already exists!');
      return;
    }
    setModelVisible(false);
    switch (selectedAction) {
      case ACTION.SAVE:
        onSave();
        break;
      case ACTION.SAVE_AND_RUN:
        onRun();
        break;
      case ACTION.DRY_RUN:
        onRunTestcase(true);
        break;
      default:
        break;
    }
  };

  const triggerModal = (action: ActionType) => {
    setModelVisible(true);
    setSelectedAction(action);
  };

  return (
    <div className="fp_savescript_container">
      <TextInfo
        title="Configure thresholds and validate the script"
        text="Configure the maximum concurrent users, duration per test, expected average response time and 90th percentile so that we can give you clear results of your application."
      />
      <Row
        justify="space-around"
        className="fp_testcse_setting"
        gutter={[16, 16]}
      >
        <Col
          xl={{ span: 10 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
          className="fp_run_form"
        >
          <Form
            name="settings_form"
            requiredMark={false}
            labelCol={{ lg: { span: 12 }, xs: { span: 8, offset: 2 } }}
            wrapperCol={{ lg: { span: 4 }, xs: { span: 4, offset: 2 } }}
            labelAlign="left"
            colon={false}
            form={testCaseSettings}
          >
            <Form.Item
              name="concurrentUsers"
              label="Concurrent users"
              rules={[{ required: true, message: 'Please enter number of concurrent users.' }]}
              initialValue={1}
            >
              <InputNumber max={1000} min={1} />
            </Form.Item>
            <Form.Item
              label="Maximum time duration for the test"
            >
              <Space>
                <Form.Item
                  name="maximumTimeDuration"
                  noStyle
                  rules={[{ required: true, message: 'Please enter max time duration per test.' }]}
                  initialValue={2}
                >
                  <InputNumber min={1} />
                </Form.Item>
                <p>
                  minutes
                </p>
              </Space>
            </Form.Item>
            <Form.Item
              label="Expected average response time"
            >
              <Space>
                <Form.Item
                  name="expectedMeanResponseTime"
                  noStyle
                  rules={[{ required: true, message: 'Please enter average response time.' }]}
                  initialValue={1}
                >
                  <InputNumber min={1} />
                </Form.Item>
                <p>
                  seconds
                </p>
              </Space>
            </Form.Item>
            {/* TODO: Enable in future virsion */}
            <Form.Item
              style={{ display: 'none' }}
              label="Ramp up time "
            >
              <Space>
                <Form.Item
                  name="rampUpTime"
                  noStyle
                  rules={[{ required: true, message: 'Please enter ramp up time.' }]}
                  initialValue={1}
                >
                  <InputNumber min={1} />
                </Form.Item>
                <p>
                  minutes
                </p>
              </Space>
            </Form.Item>
            <Form.Item
              label="Expected 90th percentile response time"
            >
              <Space>
                <Form.Item
                  name="expectedTestSuccessPercentile"
                  noStyle
                  rules={[{ required: true, message: 'Please enter 90th percentile res. time.' }]}
                  initialValue={1}
                >
                  <InputNumber min={1} />
                </Form.Item>
                <p>
                  seconds
                </p>
              </Space>
            </Form.Item>
            {/* <Form.Item
              label="Expected test success percentile"
            rules={[{ required: true, message: 'Please enter expected test success percentile.' }]}
              wrapperCol={{ span: 24, xs: { offset: 2 } }}
              labelCol={{ span: 24, xs: { offset: 2 } }}
            >
              <Row
                justify="space-between"
              >
                <Col span={15}>
                  <Form.Item
                    wrapperCol={{ span: 24 }}
                    name="expectedTestSuccessPercentile"
                  >
                    <Slider
                      min={0}
                      max={100}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <p>
                    {successRate}
                    %
                  </p>
                </Col>
              </Row>
            </Form.Item> */}
          </Form>
        </Col>
        <Col
          xl={{ span: 10 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
          className="fp_run_container"
        >
          <div className="fp_testcase_run_container">
            <p>
              Verify your script before the actual run,
              please click on dry run to validate your script.
            </p>
            <Button
              data-testid="fp_next_btn"
              type="primary"
              className="fp_btn fp_btn-outline fp_navigate_btn"
              icon={<CaretRightOutlined style={{ fontSize: 17 }} />}
              onClick={() => triggerModal(ACTION.DRY_RUN)}
            >
              {isDryRunComplete ? 'View Results' : 'Dry Run'}
            </Button>
            <div className="fp_testcase_check_status">
              {/* <CheckCircleFilled style={{ fontSize: 24, color: '#B2FF80' }} />
              <p>Pass</p> */}
            </div>
            <Button
              data-testid="fp_next_btn"
              type="primary"
              className="fp_btn fp_btn-link"
              onClick={() => triggerEditTestCaseModal()}
            >
              Edit Script
            </Button>
            {/* <p>
              Lorem Ipsum is simply dummy text of the
              printing and typesetting industry.
            </p> */}
          </div>
        </Col>
      </Row>
      <Row
        justify="space-between"
        className="fp_navigate_btn_container"
      >
        <Col
          span={4}
          style={{ padding: 2 }}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 4 }}
        >
          <Button
            data-testid="fp_previous_btn"
            type="primary"
            className="fp_btn fp_btn-primary fp_navigate_btn"
            onClick={() => goBack()}
          >
            Previous
          </Button>
        </Col>
        <Col
          span={4}
          style={{ padding: 2 }}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 4 }}
        >
          <Button
            ata-testid="fp_next_btn"
            type="primary"
            className="fp_btn fp_btn-primary fp_navigate_btn"
            onClick={() => exit()}
          >
            Cancel
          </Button>

        </Col>
        <Col
          span={4}
          style={{ padding: 2 }}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 4 }}
        >
          <Button
            ata-testid="fp_next_btn"
            type="primary"
            className="fp_btn fp_btn-primary fp_navigate_btn"
            onClick={() => triggerModal(ACTION.SAVE_AND_RUN)}
          >
            Run test
          </Button>
        </Col>
        <Col
          span={4}
          style={{ padding: 2 }}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 4 }}
        >
          <Button
            ata-testid="fp_next_btn"
            type="primary"
            className="fp_btn fp_btn-primary fp_navigate_btn"
            onClick={() => triggerModal(ACTION.SAVE)}
          >
            Save &amp; Exit
          </Button>
        </Col>
      </Row>
      <Modal
        className="max-user-modal fp-modal fp-modal-primary"
        open={isModelVisible}
        title="Test Case Name"
        closable={false}
        footer={[
          <Button type="primary" className="fp_btn fp_btn-primary" onClick={() => setModelVisible(false)}>Cancel</Button>,
          <Button type="primary" className="fp_btn fp_btn-outline" onClick={onCloseModel}>Continue</Button>,
        ]}
      >
        <Form form={testCaseSettings} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name is required!' }]}>
            <Input placeholder="Test case name" className="fp_input" />
          </Form.Item>
        </Form>
      </Modal>

      <EditTestCaseModal
        visible={isEditTestCase}
        onClose={triggerEditTestCaseModal}
        setTestScriptFile={setTestScriptFile}
        testScriptFile={testScriptFile}
        setTestCaseEdited={setTestCaseEdited}
      />
    </div>
  );
};

export default SaveScript;
