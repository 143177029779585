import {
  Modal,
  Row,
  Button,
  Col,
} from 'antd';
import React, { useState } from 'react';
import { ParallelIcon, SequentialIcon } from '../../../components';

interface ExecutionApproachSelectionModalProps {
  open: boolean
  onClose: Function
  setParallelRun: Function
}

const buttons: any[] = [
  {
    tooltip: 'Select this option to run your test scripts one after another. Use it when tests need to be executed in the test suite order or when they are dependent on each other.',
    label: 'Sequential Execution',
    value: false,
    icon: <SequentialIcon />,
  },
  {
    tooltip: 'Choose this to run multiple test scripts simultaneously. Ideal for speeding up the testing process when script independence allows for concurrent execution.',
    label: 'Parallel Execution',
    value: true,
    icon: <ParallelIcon />,
  },
];

const ExecutionApproachSelectionModal = ({
  open,
  onClose,
  setParallelRun,
}: ExecutionApproachSelectionModalProps) => {
  const [tooltip, setTooltip] = useState<string | undefined>();
  const [isButtonEnabled, setButtonEnabled] = useState<boolean>(false);

  const handleApproachSelection = (flag: boolean) => {
    setButtonEnabled(true);
    setParallelRun(flag);
  };

  const handleNextButton = () => {
    setButtonEnabled(false);
    onClose(true);
  };

  return (
    <>
      <Modal
        className="max-user-modal fp-modal fp-modal-primary modal-margins"
        width={600}
        open={open}
        title="How would you like to run your test execution suite"
        closable
        maskClosable={false}
        onCancel={() => onClose()}
        footer={[]}
      >
        <Row>
          {buttons.map((item: any, index: number) => (
            <Col
              span={11}
              offset={buttons.length === (index + 1) ? 2 : 0}
              key={JSON.stringify(item)}
            >
              <Button
                onClick={() => handleApproachSelection(item.value)}
                onMouseEnter={() => setTooltip(item?.tooltip)}
                onMouseLeave={() => setTooltip(undefined)}
                className="fp-eas-button fp-eas-button-device-button"
              >
                {item.icon}
                {item.label}
              </Button>
            </Col>
          ))}
        </Row>
        <Row>
          {!tooltip && <div className="aes-tooltip-container">Select how to run your test execution suites</div>}
          {tooltip && <div className="aes-tooltip-container aes-tooltip-container-bg">{tooltip}</div>}
        </Row>
        <Row>
          <Button className="fp_btn fp_btn-tertiary" disabled={!isButtonEnabled} onClick={handleNextButton}>Next</Button>
        </Row>
      </Modal>
    </>
  );
};

export default ExecutionApproachSelectionModal;
