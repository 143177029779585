import React from 'react';
import {
  FileOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  FilePptOutlined,
  FileImageOutlined,
} from '@ant-design/icons';

export function getFileIcon(extension: string) {
  switch (extension.toLowerCase()) {
    case 'pdf':
      return <FilePdfOutlined />;
    case 'doc':
    case 'docx':
      return <FileWordOutlined />;
    case 'xls':
    case 'xlsx':
      return <FileExcelOutlined />;
    case 'ppt':
    case 'pptx':
      return <FilePptOutlined />;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return <FileImageOutlined />;
    default:
      return <FileOutlined />;
  }
}
