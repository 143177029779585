import React, {
  useEffect, useMemo, useState,
} from 'react';

import { VideoCameraOutlined } from '@ant-design/icons';
import {
  Row, Col, Form, Select, Tag,
  Button,
} from 'antd';
import { capitalize } from 'lodash';
import renderBrowserIcon from '../../../../../components/Browsers';
import Loading from '../../../../../components/Loading';
import { convertVariableToString } from '../../../../../utils/commonUtils';

interface props {
  results: any | any[]
  disableEditMode?: boolean
}

const ResultsView = ({ results, disableEditMode }: props) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedBrowser, setSelectedBrowser] = useState<string>();
  const [selectedDevice, setSelectedDevice] = useState<string>('Desktop');
  const [selectedBVideos, setSelectedBVideos] = useState<any>();
  const [failedDevices, setFailedDevices] = useState<any[]>();

  const addBrowserButtonClass = (browser: any) => {
    const { expectedStatus, actualStatus } = browser.result;
    if (browser.video === selectedBrowser) {
      return 'browser-btn-selected';
    }
    if (expectedStatus === actualStatus) {
      return 'browser-btn-default-passed';
    }
    return 'browser-btn-default-failed';
  };

  useEffect(() => {
    if (results && typeof results === 'string') {
      setSelectedBrowser(results);
    } else if (results && typeof results === 'object') {
      let fList: any[] = results
        ?.filter((device: any) => device?.browsers
          ?.some(({ result }: any) => result?.expectedStatus !== result?.actualStatus));
      fList = fList.map((device) => {
        const browsers = device?.browsers
          ?.filter(({ result }: any) => result?.expectedStatus !== result?.actualStatus);
        return { ...device, browsers };
      });
      setFailedDevices(fList);
    }
  }, [results]);

  useMemo(() => {
    if (selectedDevice && results) {
      const videoSet = results.find(({ name }: { name: string }) => name === selectedDevice);
      setSelectedBVideos(videoSet?.browsers.filter((video: any) => video.isLogAvailable));
    }
  }, [selectedDevice, results]);

  const renderBody = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (!selectedBVideos) {
      return (
        <div className="placeholder-container">
          <div className="placeholder-icon"><VideoCameraOutlined style={{ fontSize: 64 }} /></div>
        </div>
      );
    }

    if (selectedBrowser) {
      return (
        <video controls width="100%" height="500px" style={{ marginTop: '5px' }} autoPlay>
          <source src={selectedBrowser} type="video/webm" />
          <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
        </video>
      );
    }

    return (
      <div className="placeholder-container">
        <div className="placeholder-icon"><VideoCameraOutlined style={{ fontSize: 64 }} /></div>
        {!disableEditMode && <div className="placeholder-text">Video is not available</div>}
      </div>
    );
  };

  return (
    <div className="fp-results-container">
      {((failedDevices && failedDevices.length) || disableEditMode) ? (
        <div>
          <h2>{disableEditMode ? 'Select Browser' : 'Results'}</h2>
          {disableEditMode ? (
            <div className="browser-results-container" style={{ display: 'flex', flexDirection: 'row' }}>
              {results[0].browsers
                .filter(({ isLogAvailable }: any) => isLogAvailable)
                .map((browser: any, index: number) => (
                  <Button
                    className={`browser-result ${addBrowserButtonClass(browser)}`}
                    key={`${JSON.stringify(browser)}-button`}
                    style={index > 0 ? { marginLeft: 20, height: 40 } : { height: 40 }}
                    icon={renderBrowserIcon(browser.key)}
                    onClick={() => {
                      setLoading(true);
                      setSelectedBrowser(undefined);
                      setTimeout(() => {
                        setSelectedBrowser(browser.video);
                        setLoading(false);
                      }, 500);
                    }}
                  >
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      {convertVariableToString(browser.result.actualStatus)}
                    </div>
                  </Button>
                ))}
            </div>
          ) : (
            <div style={{
              background: '#4f4f4eff', borderRadius: 25, padding: 20,
            }}
            >
              <h3>Failed Devices</h3>
              <ol>
                {failedDevices?.map((device: any) => (
                  <li style={{ margin: '10px 0' }}>
                    <Row>
                      <Col>{device.name}</Col>
                      <Col offset={1}>
                        {device?.browsers?.map(({ key, result }: any) => (
                          <Tag color="#DE4359" style={{ borderRadius: 25 }}>
                            <Row>
                              <Col>
                                <div style={{
                                  width: 15, paddingRight: 3,
                                }}
                                >
                                  {renderBrowserIcon(key)}
                                </div>
                              </Col>
                              <Col>
                                {capitalize(result?.actualStatus)}
                              </Col>
                            </Row>
                          </Tag>
                        ))}
                      </Col>
                    </Row>
                  </li>
                ))}
              </ol>
            </div>
          )}
        </div>
      ) : <></>}
      <br />
      {typeof results !== 'string' && (
      <div className="fp_recording_browser_select">
        <h2>Recording</h2>
        {!disableEditMode && (
        <Row>
          <Col span={11} style={{ paddingLeft: 20 }}>
            <Form.Item label="Device selection:">
              <Select
                placeholder="Select a device"
                onChange={(name: any) => {
                  setSelectedBrowser(undefined);
                  setSelectedDevice(name);
                }}
              >
                {results.map(({ name, fullName }: any) => (
                  <Select.Option value={name} key={name}>
                    {fullName || name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {selectedBVideos && (
          <Col span={11} offset={2}>
            <Form.Item label="Browser selection:">
              <Select
                placeholder="Select Browser"
                onChange={(video: string) => {
                  setLoading(true);
                  setSelectedBrowser(undefined);
                  setTimeout(() => {
                    if (!video.includes('NOT AVAILABLE')) setSelectedBrowser(video);
                    setLoading(false);
                  }, 500);
                }}
                value={selectedBrowser}
              >
                {
                selectedBVideos?.map(({ key, video }: any, index: number) => (
                  <Select.Option value={video || `${index} - NOT AVAILABLE`} key={JSON.stringify({ key, video })}>
                    <Row>
                      <Col span={1}>
                        <div style={{ width: 15, paddingBottom: 5 }}>
                          {renderBrowserIcon(key)}
                        </div>
                      </Col>
                      <Col span={22} offset={1}>{capitalize(key)}</Col>
                    </Row>
                  </Select.Option>
                ))
              }
              </Select>
            </Form.Item>
          </Col>
          )}
        </Row>
        )}
      </div>
      )}
      {renderBody()}
    </div>
  );
};

export default ResultsView;
