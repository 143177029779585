import { Modal, Button } from 'antd';
import React, { ReactElement } from 'react';

interface propType {
  open: boolean,
  title: string | ReactElement,
  body: string,
  onCancel: Function,
  onProceed: Function,
  proceedButtonText?: string
  showCancelButton?: boolean
}
const ConfirmationModal = ({
  open, title, body, onCancel, onProceed, proceedButtonText, showCancelButton = true,
}: propType) => (
  <Modal
    className="max-user-modal fp-modal fp-modal-primary fp_middle_notification_model"
    open={open}
    title={title}
    onCancel={() => onCancel(false)}
    footer={[
      <Button type="primary" className="fp_btn fp_btn-outline" onClick={() => onCancel(false)} style={showCancelButton ? {} : { display: 'none' }}>Cancel</Button>,
      <Button
        type="primary"
        className="fp_btn fp_btn-primary"
        onClick={() => onProceed()}
      >
        {proceedButtonText || 'Continue'}
      </Button>,
    ]}
  >

    <div className="form-section">
      {body}
    </div>
  </Modal>
);

export default ConfirmationModal;
