import { API } from 'aws-amplify';
import { TestCaseGenerateInput } from '../../redux/testCaseSlice';
import { APIS } from '../../utils/apiUtils';
import { AutomationScript } from '../../types/automationScript';

export const generateTestcase = async (
  testCase: TestCaseGenerateInput | AutomationScript,
  service: string,
) => {
  try {
    const data = await API.post(APIS.TESTCASE_CREATING_API, '/create', { body: testCase, queryStringParameters: { service } });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const invokeRecorder = async (queryStringParameters: any = {}) => {
  try {
    return await API.get(APIS.TESTCASE_CREATING_API, '/testcase/record-test/invoke', { queryStringParameters });
  } catch (err: any) {
    console.log('invokeRecorder error :', err.message);
    return null;
  }
};

export const getRecorderStatus = async (serverId: string) => {
  try {
    return await API.get(APIS.TESTCASE_CREATING_API, '/testcase/record-test/get-status', { queryStringParameters: { serverId } });
  } catch (err: any) {
    console.log('invokeRecorder error :', err.message);
    return null;
  }
};
