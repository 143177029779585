import React, { useEffect } from 'react';
import './styles/App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Amplify from 'aws-amplify';
import * as amplitude from '@amplitude/analytics-browser';
import LoginPage from './views/login/LoginPage';
import { AMPLITUDE_KEY, AWS_CONFIG, ENABLE_MONITOR_MODE } from './configs/app-config';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { isLoggedIn } from './views/login/actions';
import { initApi } from './utils/apiUtils';
import applyTheme from './styles/theme';
import SignUp from './views/signup/SignUp';
import EmailVerify from './views/signup/EmailVerify';
import Main from './views/main/Main';
import { ROUTES } from './constants';
import { notifyError } from './utils/notification';
import Loading from './components/Loading';
import MonitoringJobListViewItemView from './views/monitor/MonitoringJobListViewItemView';
import AnalyticsView from './views/analytics/AnalyticsView';

Amplify.configure(AWS_CONFIG);
initApi();

function App() {
  const userId = useAppSelector((state: any) => state.userData.userId);
  const dispatch = useAppDispatch();

  const loadAmplitude = () => {
    amplitude.init(AMPLITUDE_KEY, undefined, {
      defaultTracking: {
        sessions: true, pageViews: true, formInteractions: true, fileDownloads: true,
      },
    });
  };

  useEffect(() => {
    const errorDescriptionKeyAndValue = window.location.search;
    const urlSerarchParams = new URLSearchParams(errorDescriptionKeyAndValue);
    const googleSignInError = urlSerarchParams.get('error_description');
    if (googleSignInError) {
      notifyError(googleSignInError, 'google_sign_in_error', 5000);
    }
    isLoggedIn(dispatch);
  }, []);

  useEffect(() => {
    loadAmplitude();
    applyTheme();
  }, []);

  const redirectLogin = (location: any) => (
    <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: location } }} />
  );

  if (userId === undefined) {
    return <Loading plain size="large" />;
  }

  return (
    <div className="fp_pg_full fp_base_container">
      <Router>
        <Switch>
          <Route path={`${ROUTES.SHARE_MONITOR}/:shareId`}><MonitoringJobListViewItemView /></Route>
          <Route path={`${ROUTES.SHARE_ANALYTICS}/:shareId`}><AnalyticsView /></Route>
          {!userId && <Route path={ROUTES.LOGIN}><LoginPage /></Route>}
          {!ENABLE_MONITOR_MODE && !userId && <Route path={ROUTES.SIGN_UP}><SignUp /></Route>}
          {!ENABLE_MONITOR_MODE && !userId && <Route path={ROUTES.VERIFY}><EmailVerify /></Route>}
          {!userId && <Route render={({ location }) => redirectLogin(location)} />}
          {userId && <Route path={ROUTES.ANY} component={() => <Main />} />}
          {userId && <Redirect to={ROUTES.ANY} />}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
