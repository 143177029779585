import { Col, Row } from 'antd';
import React from 'react';
import { Project } from '../../redux/projectSlice';
import TestCaseListViewItem from './ProjectListViewItem';

interface ProjectListProps {
  projects: Array<Project>
  deleteProject: Function
}

const ProjectListView = ({ projects = [], deleteProject }: ProjectListProps) => {
  const projectList = projects.map((project: Project) => (
    <TestCaseListViewItem project={project} key={project.projectId} deleteProject={deleteProject} />
  ));
  const noDataView = <Row justify="center"><p>No Projects Found.</p></Row>;
  const renderView = projects.length > 0 ? projectList : noDataView;
  return (
    <div className="fp_list">
      <Row justify="space-between" className="fp_list_title">
        <Col span={6}><p>Project Name</p></Col>
        {/* <Col span={6}><p>Type</p></Col> */}
        {/* <Col span={6}><p>Service Type</p></Col> */}
        <Col span={11}><p>Created At</p></Col>
      </Row>
      {renderView}
    </div>
  );
};

export default ProjectListView;
