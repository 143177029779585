import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Service = {
  serviceName: string;
  path: string;
  shortDescription: string;
  isDisabled: boolean;
  serviceId: string;
  sortIndex: string;
  longDescription?: string;
  ribbon?: string,
  icon: React.ReactNode
};

interface ServiceDataState {
  serviceList: Array<Service>;
  servicesData?: Array<any>;
  selectedService?: Service;
}

const initialState: ServiceDataState = {
  serviceList: [],
  servicesData: undefined,
  selectedService: undefined,
};

export const serviceDataSlice = createSlice({
  name: 'serviceData',
  initialState,
  reducers: {
    setServiceList: (state, action: PayloadAction<Array<Service>>) => {
      state.serviceList = action.payload;
    },
    setServiceData: (state, action: PayloadAction<Array<any>>) => {
      state.servicesData = action.payload;
    },
    setSelectedService: (state, action: PayloadAction<Service | undefined>) => {
      state.selectedService = action.payload;
    },
  },
});

export const {
  setServiceList, setSelectedService, setServiceData,
} = serviceDataSlice.actions;

export default serviceDataSlice.reducer;
