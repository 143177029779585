import {
  Col, Row, Select,
} from 'antd';
import React from 'react';
import renderBrowserIcon from '../../../components/Browsers';
import { BROWSER } from '../../../types/common';

type propType = {
  selectBrowser: Function;
  defaultKey: string | undefined;
  browserDataObject: any
};

const BrowserSelector = ({ selectBrowser, defaultKey, browserDataObject }: propType) => {
  const renderBrowserName = (key: string) => {
    switch (key) {
      case BROWSER.Chromium:
        return (
          <Row>
            <Col>
              <div style={{
                width: 20, paddingRight: 3,
              }}
              >
                {renderBrowserIcon('Chromium')}
              </div>
            </Col>
            <Col offset={1}>
              Chromium
            </Col>
          </Row>
        );
      case BROWSER.Firefox:
        return (
          <Row>
            <Col>
              <div style={{
                width: 20, paddingRight: 3,
              }}
              >
                {renderBrowserIcon('Firefox')}
              </div>
            </Col>
            <Col offset={1}>
              Firefox
            </Col>
          </Row>
        );
      case BROWSER.Safari:
        return (
          <Row>
            <Col>
              <div style={{
                width: 20, paddingRight: 3,
              }}
              >
                {renderBrowserIcon('Safari')}
              </div>
            </Col>
            <Col offset={1}>
              Safari
            </Col>
          </Row>
        );
      default:
        return key;
    }
  };

  return (
    <>
      <p style={{ marginTop: '10px', margin: '10px 5px 0 0' }}>Browser</p>
      <p style={{ marginTop: '10px', margin: '10px 2px 0 0' }}>:</p>
      <Select
        className="fb_select-max-width selection-menu"
        style={{ width: 190, marginRight: 10 }}
        placeholder="Select Browser"
        onChange={selectBrowser}
        defaultValue={defaultKey}
      >
        {browserDataObject && Object.keys(browserDataObject)
          .map((key: string) => (
            <Select.Option
              key={JSON.stringify(key)}
              value={key}
            >
              {renderBrowserName(key)}
            </Select.Option>
          ))}
      </Select>
    </>
  );
};

export default BrowserSelector;
