import React from 'react';
import {
  Form, Input, Button,
} from 'antd';
import { Logo } from '../../../components';
import { FORGET_PASSWORD_VIEW_TYPE } from '../../../constants';
import { forgetPassword } from '../actions';
import { notifyError, notifySuccess } from '../../../utils/notification';

type InitViewProps = {
  drawerType: Function;
  formEmail: string|undefined;
  setFormMail: Function;
}

export const ForgetPasswordSendCode = ({ drawerType, formEmail, setFormMail }: InitViewProps) => {
  const [formSendCode] = Form.useForm();

  const onSendCode = async () => {
    const formSendEmail = formSendCode.getFieldValue('email');
    // TODO: Need to remove below console log
    console.log(formEmail);
    await forgetPassword(formSendEmail)
      .then((result) => {
        if (result.CodeDeliveryDetails) {
          notifySuccess('Verification Code send Successfully. Please Check your email.', 'code_send_success');
          drawerType(FORGET_PASSWORD_VIEW_TYPE.RESET_PASSWORD);
          setFormMail(formSendEmail);
        }
      })
      .catch((error) => {
        switch (error.message) {
          case 'Username/client id combination not found.':
            notifyError('You are not a registered user', 'code_send_error');
            break;
          case 'Attempt limit exceeded, please try after some time':
            notifyError('Attempt limit exceeded, please try after some time', 'code_send_error');
            break;
          default:
            notifyError('Error Occurred', 'code_send_error');
            break;
        }
      });
  };
  return (
    <div>
      <div className="fp_full_size fp_pg_center">
        <Form className="fp_lgn_frm" layout="vertical" form={formSendCode} requiredMark onFinish={onSendCode}>
          <div className="fp_cmpnt fp_cmpnt_cntr fp_logo_color">
            <Logo />
          </div>
          <div className="fp_cmpnt fp_cmpnt_cntr">
            <p className="fp_lgn_ttl_update">Reset Your Password</p>
          </div>
          <div className="fp_cmpnt fp_cmpnt_cntr fp_cmpnt_dv">
            <p>
              Enter your email address associated with your footprint account,
              and we’ll send you an email with verification code to reset your password.
            </p>
          </div>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ type: 'email', message: 'The input is not valid email!', validateTrigger: 'onSubmit' },
              { required: true, message: 'Please input your email!' },
            ]}
          >
            <Input data-testid="fp_email" />
          </Form.Item>
          <Form.Item className="fp_mrgn_tp_20">
            <Button data-testid="fp_login" type="primary" className="fp_btn fp_btn-primary fp_btn-login" htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ForgetPasswordSendCode;
