import React, { useState } from 'react';
import {
  Form, Input, Button, Typography, Col, Row, notification,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/hooks';
import { SupportInfoType } from '../../../../types/common';
import { generateSupportRequest, getSignedUrl, uploadFile } from '../../action';
import { notifyError, notifyInProgress, notifySuccess } from '../../../../utils/notification';
import UploadTile from '../UploadFile/UploadTile';
import SupportViewModal from './components/SupportViewModal';

const { Title } = Typography;
const { TextArea } = Input;

const SupportView: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const currentAccount = useAppSelector(({ userData }) => userData.selectedAccount);
  const [issueFile, setIssueFile] = useState<RcFile | File | undefined>(undefined);

  const generateS3key = (file: RcFile | File | undefined) => (file ? `${currentAccount?.accountId}/${file.name}` : '');

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const createSupportRequest = async () => {
    const uploadFileKey = generateS3key(issueFile);

    if (issueFile) {
      const supportRequestSignUrl = await getSignedUrl(uploadFileKey, undefined, 'putObject');
      await uploadFile(supportRequestSignUrl, issueFile, undefined);
    }

    const {
      fname, email, descriptionOfIssue,
    } = form.getFieldsValue();

    const supportRequestData: SupportInfoType = {
      accountId: currentAccount?.accountId,
      userName: fname,
      userEmail: email,
      issue: descriptionOfIssue,
      fileKey: uploadFileKey,
    };
    try {
      notification.destroy();
      notifyInProgress('Creating support request', 'create_new_support_request');
      await generateSupportRequest(supportRequestData);
      notification.destroy();
      notifySuccess('Support request created successfully', 'support_request_created_successfully');
      // TODO: add form reset
    } catch (error: any) {
      notifyError("Something wen't wrong", 'something_went_wrong');
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async () => {
    await createSupportRequest();
    onReset();
    showModal();
  };

  return (
    <div className="fp_pg_base">
      <Row>
        <Button
          data-testid="fp_previous_btn"
          className="back_btn fp_btn-primary fp_btn"
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </Row>
      <Row align="middle">
        <Col
          span={11}
          offset={7}
        >
          <Col span={24} offset={1}>
            <Row className="fp_supprt_titlerow">
              <Title level={2}>
                Welcome to FootPrint Support!
              </Title>
            </Row>
          </Col>
          <Row className="form-row">
            <Form className="fp_rqg_frm free-frm " form={form} layout="vertical" requiredMark onFinish={onFinish}>
              <div className="frm-dscrptn">
                <p className="form-row frm-txt">
                  We understand the importance of a seamless
                  user experience and are dedicated to providing
                  you with the best possible support. Our team is
                  here to assist you with any issues you may encounter.
                </p>
              </div>
              <Form.Item label="Name" name="fname" rules={[{ required: true, message: 'Please input your first name!' }]}>
                <Input data-testid="fp_first_name" />
              </Form.Item>
              <Form.Item label="Email address" name="email" rules={[{ type: 'email', message: 'The input is not valid email!', validateTrigger: 'onSubmit' }, { required: true, message: 'Please input your email!' }]}>
                <Input data-testid="fp_email" />
              </Form.Item>
              <Form.Item
                label="Description of issue"
                name="descriptionOfIssue"
                rules={[{ required: true, message: 'Please input your issue!' }]}
              >
                <TextArea
                  className="fp_rqg_frm-reqInp"
                  rows={6}
                  style={{
                    height: 80,
                  }}
                />
              </Form.Item>
              <Form.Item
                name="issuefile"
              >
                <UploadTile
                  title="Drag a file here Or Click to Browse"
                  description="Support for a single file only."
                  setFile={setIssueFile}
                  acceptExtensions={['.png', '.pdf', '.jpg', '.txt']}
                  onFileRemove={() => setIssueFile(undefined)}
                />
              </Form.Item>
              <Form.Item className="fp_mrgn_tp_20">
                <Row className="fp_supprt_titlerow">
                  <Button data-testid="fp_signup" type="primary" className="fp_btn fp_btn-primary" htmlType="submit">Submit</Button>
                </Row>
              </Form.Item>
            </Form>
            <Row>
              <SupportViewModal
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              />
            </Row>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default SupportView;
