/* eslint-disable no-unused-vars */
import {
  Modal,
  Row,
  Col,
  Button,
  Pagination,
  PaginationProps,
  Input,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import TestExecutionFailedList from './TestExecutionFailedList';
import { InsightIcon } from '../../../../components';
import { getFailedTestRuns, updateTestRunErrorMessage } from '../../action';
import { useAppSelector } from '../../../../redux/hooks';

interface TEFLModalProps {
  open: boolean
  onClose: Function
}

const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return <a className="nav-buttons">Previous</a>;
  }
  if (type === 'next') {
    return <a className="nav-buttons">Next</a>;
  }
  return originalElement;
};

const TestExecutionFailedListModal = ({
  open,
  onClose,
}: TEFLModalProps) => {
  const { monitorId, shareId } = useParams<any>();
  const accountId = useAppSelector(({ userData }: any) => userData?.selectedAccount?.accountId);
  const [payload, setPayload] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isErrorMessageLoading, setErrorMessageLoading] = useState<boolean>(false);
  const [selectedTestRun, setSelectedTestRun] = useState<any>();
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [timeoutId, setTimeoutId] = useState(null);
  const [newErrorMessage, setNewErrorMessage] = useState<string>();
  const pageSize: number = 10;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const onClearClose = () => {
    onClose(false);
  };

  const fetchData = async (page: number, keyword: string | undefined = undefined) => {
    setLoading(true);
    let sk = keyword;
    if (keyword === '#EMPTY#') {
      sk = undefined;
    } else if (!keyword && searchKeyword) {
      sk = searchKeyword;
    }
    const response = await getFailedTestRuns(shareId, accountId, monitorId, pageSize, page, searchParams.get('isGuestUrl') === 'true', sk);
    setPayload(response);
    setLoading(false);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchData(page);
  };

  const handleEdit = (testRun: any) => {
    setSelectedTestRun(testRun);
    setNewErrorMessage(testRun?.error?.message || testRun?.error?.stack);
    onClearClose();
  };

  const updateErrorMessage = async () => {
    setSelectedTestRun(undefined);
    setErrorMessageLoading(true);
    onClose(true);
    setLoading(true);
    const updateEMPayload = {
      accountId,
      monitorId,
      executionId: selectedTestRun.executionId,
      message: newErrorMessage,
    };
    await updateTestRunErrorMessage(updateEMPayload);
    await fetchData(currentPage);
    setNewErrorMessage(undefined);
    setErrorMessageLoading(false);
  };

  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);
    setCurrentPage(1);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId: any = setTimeout(() => {
      fetchData(1, keyword || '#EMPTY#');
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    if (open && !isErrorMessageLoading) fetchData(currentPage);
  }, [open]);

  useEffect(() => () => {
    setSearchKeyword(undefined);
    setLoading(true);
    setCurrentPage(1);
    setPayload(undefined);
    setErrorMessageLoading(false);
    setSelectedTestRun(undefined);
    setNewErrorMessage(undefined);
  }, []);

  return (
    <>
      <Modal
        className="max-user-modal fp-modal fp-modal-primary close-modal-icon modal-max-width"
        width={600}
        open={open}
        closable
        maskClosable={false}
        onCancel={onClearClose}
        style={{ textAlign: 'center' }}
        footer={null}
      >
        <h3 style={{ color: 'white', marginBottom: 20 }}>Test Execution Fails</h3>
        <Row justify="end" style={{ marginBottom: '10px' }}>
          <Col>
            <Button className="fp_btn fp_btn-analytics" type="primary" icon={<InsightIcon />}>
              Insights
            </Button>
          </Col>
          <Col style={{ marginLeft: 10 }}>
            <Input
              prefix={<SearchOutlined />}
              className="fp_monitor_job_results_log_search"
              placeholder="Search"
              onChange={(text) => handleSearch(text.target.value)}
              data-testid="fp_monitor_job_results_log_search"
              disabled={isLoading}
            />
          </Col>
        </Row>
        <TestExecutionFailedList
          isLoading={isLoading}
          job={payload?.failedTestRuns}
          handleEdit={handleEdit}
        />
        <Row>
          <Col span={24} style={{ textAlign: 'right' }} className="pagination-container">
            {payload?.failedTestRuns?.length ? (
              <Pagination
                defaultCurrent={1}
                itemRender={itemRender}
                pageSize={pageSize}
                total={payload?.totalItemCount}
                current={currentPage}
                onChange={handlePageChange}
              />
            ) : <></>}
          </Col>
        </Row>
      </Modal>

      <Modal
        className="max-user-modal fp-modal fp-modal-primary close-modal-icon modal-max-width"
        width={300}
        open={!!selectedTestRun}
        closable
        maskClosable={false}
        onCancel={() => {
          setSelectedTestRun(undefined);
          setNewErrorMessage(undefined);
          onClose(true);
        }}
        style={{ textAlign: 'center' }}
        footer={null}
      >
        <h3 style={{ color: 'white', marginBottom: 20 }}>Error Message</h3>
        {selectedTestRun && (
        <Row style={{ marginBottom: '10px' }}>
          <Input
            className="fp_input"
            value={newErrorMessage}
            defaultValue={newErrorMessage}
            onChange={(e: any) => setNewErrorMessage(e?.target?.value)}
          />
        </Row>
        )}
        <Row justify="center" align="middle">
          <Button
            type="primary"
            className="fp_btn fp_btn-primary"
            onClick={() => updateErrorMessage()}
          >
            Save
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export default TestExecutionFailedListModal;
