import {
  Button, Col, Form, Input, Row, Typography,
} from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CONTACT_US_TYPES } from '../../constants';
import { useAppSelector } from '../../redux/hooks';
import { contactUsParams } from '../../types/common';
import { addContactUs } from '../testcaseCreation/action';
import QAServiceModal from './components/QAServiceModal';

const { Title } = Typography;
const { TextArea } = Input;

const QAService = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const currentAccount = useAppSelector(({ userData }) => userData.selectedAccount);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = async (formValues: contactUsParams) => {
    const { description } = formValues;
    const contactDetails = formValues;
    delete contactDetails.description;
    await addContactUs({
      accountId: currentAccount?.accountId,
      description,
      contactDetails,
      type: CONTACT_US_TYPES.QA_SERVICE_SALES,
    });
    form.resetFields();
    setIsModalOpen(true);
  };

  return (
    <div className="fp_pg_base">
      <Row>
        <Button
          data-testid="fp_previous_btn"
          className="back_btn fp_btn-primary fp_btn"
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </Row>
      <Row className="fp_supprt_titlerow">
        <Title level={2} className="mn-hdr">
          Thank you for considering our QA as a Service
        </Title>
      </Row>
      <Row justify="center">
        <Col className="frm-cntnr">
          <Form className="fp_rqg_frm free-frm" form={form} layout="vertical" requiredMark onFinish={handleFormSubmit}>
            <div className="fp_cmpnt fp_cmpnt_cntr fp_logo_color" />
            <div className="fp_cmpnt fp_cmpnt_cntr">
              <div className="frm-dscrptn">
                <p className="form-row frm-txt">
                  To better understand your requirements and
                  how our service can benefit you, please complete
                  the following sales inquiry form. Our Quality
                  Assurance team will review your information and get
                  back to you as soon as possible. Thank you for considering our services.
                </p>
              </div>
            </div>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input data-testid="fp_name" />
            </Form.Item>
            <Form.Item
              label="Company"
              name="company"
              rules={[{ required: true, message: 'Please input your company!' }]}
            >
              <Input data-testid="fp_company" />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[{
                type: 'email', message: 'The input is not valid email!', validateTrigger: 'onSubmit',
              },
              { required: true, message: 'Please input your email!' }]}
            >
              <Input data-testid="fp_email" />
            </Form.Item>
            <Form.Item
              label="Description about your business"
              name="description"
              rules={[{ required: true, message: 'Please input your description!' }]}
            >
              <TextArea
                className="fp_rqg_frm-reqInp"
                rows={6}
                style={{
                  height: 80,
                }}
              />
            </Form.Item>
            <Form.Item label="Phone Number" name="phone">
              <Input data-testid="fp_phone" />
            </Form.Item>
            <Form.Item label="Number of Users" name="numOfUsers">
              <Input data-testid="fp_users" />
            </Form.Item>
            <Form.Item className="fp_mrgn_tp_20">
              <Row justify="center">
                <Button data-testid="fp_signup" type="primary" className="fp_btn fp_btn-primary fp_tcc-btn" htmlType="submit">
                  Submit
                </Button>
                <Row>
                  <Col span={6} offset={6}>
                    <QAServiceModal
                      visible={isModalOpen}
                      onOk={handleOk}
                    />
                  </Col>
                </Row>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default QAService;
