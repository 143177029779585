import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Form, Input, Button, Spin, Image, Checkbox, Modal,
} from 'antd';
import { LoginStatus, oauth, signIn } from './actions';
import { useAppDispatch } from '../../redux/hooks';
import { Logo } from '../../components';
import { ROUTES, FORGET_PASSWORD_VIEW_TYPE } from '../../constants';
import googleIcon from '../../styles/assets/login_google_icon.png';
import { PROVIDER } from '../../utils/apiUtils';
import ForgetPasswordSendCode from './components/ForgetPasswordSendCode';
import ForgetPasswordResetPassword from './components/ForgetPasswordReset';
import { ENABLE_MONITOR_MODE } from '../../configs/app-config';

export const LoginPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loginInProgress, setLoginInProgress] = useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [formEmail, setFormMail] = useState<string|undefined>('');
  const [drawerView, setDrawerView] = useState<string>(FORGET_PASSWORD_VIEW_TYPE.SEND_CODE);
  const [errorText, setErrorText] = useState<string>('');

  const onLogin = async () => {
    setErrorText('');
    setLoginInProgress(true);
    const { email, password } = form.getFieldsValue();
    try {
      const loggedIn = await signIn(email, password, dispatch);
      switch (loggedIn) {
        case LoginStatus.Success:
          history.replace(ENABLE_MONITOR_MODE ? ROUTES.MONITOR : ROUTES.HOME, { from: 'login' });
          break;
        case LoginStatus.Verify:
          history.replace(ROUTES.VERIFY, { from: 'login' });
          break;
        default:
          break;
      }
    } catch (error: any) {
      setErrorText(error.message);
    } finally {
      setLoginInProgress(false);
    }
  };

  const errorMSGView = errorText
    && (
      <div className="fp_cmpnt fp_cmpnt_jsfy_cntn_cntr">
        <p className="fp_medium-text-2 fp_err_text">
          {errorText}
        </p>
      </div>
    );
  const forgotPasswordTrigger = () => {
    setDrawerVisible(true);
    setDrawerView(FORGET_PASSWORD_VIEW_TYPE.SEND_CODE);
  };
  const drawerRenderView = () => {
    switch (drawerView) {
      case FORGET_PASSWORD_VIEW_TYPE.SEND_CODE:
        return (
          <ForgetPasswordSendCode
            drawerType={setDrawerView}
            formEmail={formEmail}
            setFormMail={setFormMail}
          />
        );

      case FORGET_PASSWORD_VIEW_TYPE.RESET_PASSWORD:
        return (
          <ForgetPasswordResetPassword
            drawerType={setDrawerView}
            formEmail={formEmail}
            setFormMail={setFormMail}
            setDrawerVisible={setDrawerVisible}
          />
        );

      default:
        return <div>empty</div>;
    }
  };
  const forgetPasswordDrawer = () => (
    <Modal
      className="fp-modal fp-modal-primary"
      open={drawerVisible}
      onCancel={() => setDrawerVisible(false)}
      footer={null}
    >
      {drawerRenderView()}
    </Modal>
  );

  const loadingIcon = loginInProgress ? <Spin /> : null;

  return (
    <div className="fp_full_size fp_pg_center">
      <Form className="fp_lgn_frm" form={form} layout="vertical" requiredMark onFinish={onLogin}>
        <div className="fp_cmpnt fp_cmpnt_cntr fp_logo_color">
          <Logo />
        </div>
        <div className="fp_cmpnt fp_cmpnt_cntr">
          <p className="fp_lgn_ttl">Login to your account</p>
        </div>
        {!ENABLE_MONITOR_MODE && (
        <>
          <div className="fp_mrgn_tp_20">
            <Button data-testid="fp_google_login" disabled={loginInProgress} className="fp_btn fp_btn-long fp-google-login-button" onClick={() => oauth(PROVIDER.GOOGLE)}>
              <Image src={googleIcon} draggable={false} preview={false} />
              {' '}
              Sign In With Google
            </Button>
          </div>
          <div className="fp_lgn_area_dvde">
            <p>OR</p>
          </div>
        </>
        )}
        <Form.Item
          label="Email"
          name="email"
          rules={[{ type: 'email', message: 'The input is not valid email!', validateTrigger: 'onSubmit' },
            { required: true, message: 'Please input your email!' },
          ]}
        >
          <Input data-testid="fp_email" disabled={loginInProgress} />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password data-testid="fp_password" disabled={loginInProgress} />
        </Form.Item>
        <div className="fp_lgn_rembrme">
          <div>
            <Checkbox disabled={loginInProgress}>
              <p>Remember me</p>
            </Checkbox>
          </div>
          {!ENABLE_MONITOR_MODE && (
          <div>
            <Button
              className="fp_btn fp_btn-link fp_navigate_btn"
              onClick={() => forgotPasswordTrigger()}
            >
              Forgot Password?
            </Button>
          </div>
          )}
        </div>
        <Form.Item className="fp_mrgn_tp_20">
          <Button
            data-testid="fp_login"
            type="primary"
            icon={loadingIcon}
            disabled={loginInProgress}
            className="fp_btn fp_btn-primary fp_btn-login"
            htmlType="submit"
          >
            Login
          </Button>
        </Form.Item>
        {errorMSGView}
        {!ENABLE_MONITOR_MODE && (
        <div className="fp_cmpnt fp_cmpnt_flx_rw fp_cmpnt_jsfy_cntn_cntr fp_mrgn_tp_20">
          <p className="fp_medium-text-2">Don&apos;t have an account?</p>
          <Link data-testid="fp_go_to_signup" className="fp_lnk" to="/signup">Sign up here</Link>
        </div>
        )}
      </Form>
      {forgetPasswordDrawer()}
    </div>
  );
};

export default LoginPage;
