import {
  Button, Col, Row, Tag,
} from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import { ROUTES } from '../../../constants';
import { getFormattedDateTime } from '../../../utils/commonUtils';
import { SERVICE_TYPE } from '../../../types/common';
import { TrashCanIcon } from '../../../components';
import DeleteConfirmationModal from '../../testExecutionSuit/modals/DeleteConfirmationModal';

interface GeneratedTestcaseListVIewItemProps {
  requirement: any
  deleteRequirement: Function
}
const GeneratedTestCaseListViewItem = ({
  requirement, deleteRequirement,
}: GeneratedTestcaseListVIewItemProps) => {
  const [deleteScript, setDeleteScript] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const viewResults = () => history.push(`${ROUTES.TESTCASE_RESULT}/${requirement.moduleId}/${requirement.requirementId}`);

  const getServiceType = (serviceId: string = '') => {
    switch (serviceId) {
      case SERVICE_TYPE.AUTOMATION_SCRIPT_GENERATOR:
        return 'Automation Script';
      case SERVICE_TYPE.TEST_CASE_GENERATOR:
        return 'Testcase Generation';
      default:
        return '';
    }
  };

  return (
    <div className="fp_test_exec_list_item">
      <Row justify="space-between" className="fp_list_item">
        <Col span={6} onClick={viewResults}>
          <Row
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Col span={24}>
              <p className="fp_testcase_name">{requirement.requirement || requirement.requirementDescription}</p>
            </Col>
            {/* <Col span={6}>
            <Button className="fp_btn fp_btn-link">Edit</Button>
          </Col> */}
          </Row>
        </Col>
        <Col span={4} onClick={viewResults}>
          <Tag style={{ border: 'none' }}>{getServiceType(requirement?.serviceId)}</Tag>
          <p />
        </Col>
        {/* TODO: Removed result section for demo version */}
        {/* <Col span={4}><p>{test.type}</p></Col> */}
        <Col span={6} onClick={viewResults}>
          <Row>
            <Col span={18}>
              <p>{getFormattedDateTime(requirement.createdAt)}</p>
            </Col>
          </Row>
        </Col>
        <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Row className="fp_test_exec_list_item_action">
            <Col>
              {deleteScript && <Button onClick={() => setModalOpen(true)} type="primary" icon={deleteScript && <TrashCanIcon />} className="fp_btn fp_btn-primary btn_delete">Delete Requirement</Button>}
            </Col>
            <Col>
              <p><MoreOutlined onClick={() => setDeleteScript(!deleteScript)} style={{ fontSize: '20px' }} /></p>
            </Col>
          </Row>
        </Col>
      </Row>

      <DeleteConfirmationModal
        title="Are you sure you want to delete this module?"
        deleteButtonText="Delete Module"
        isModalOpen={isModalOpen}
        handleCancel={() => setModalOpen(false)}
        handleDelete={() => {
          deleteRequirement(requirement.requirementId);
          setModalOpen(false);
        }}
        isLoading={false}
      />
    </div>
  );
};

export default GeneratedTestCaseListViewItem;
