import React from 'react';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import ReCaptcha from '../../../components/Recaptcha';
import { RECAPTCHA_SITE_KEY } from '../../../configs/app-config';

type TokenType = {
  setRecaptchaToken: Function;
}
const GoogleReCaptcha = ({ setRecaptchaToken }: TokenType) => (
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY || ''}>
    <ReCaptcha setRecaptchaToken={setRecaptchaToken} />
  </GoogleReCaptchaProvider>
);

export default GoogleReCaptcha;
