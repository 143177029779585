import React from 'react';
import {
  Form, Input, Button,
} from 'antd';
import { Logo } from '../../../components';
import { resetPassword } from '../actions';
import { FORGET_PASSWORD_VIEW_TYPE } from '../../../constants';
import { notifyError, notifySuccess } from '../../../utils/notification';

type InitViewProps = {
  drawerType: Function;
  formEmail: String|undefined;
  setFormMail: Function;
  setDrawerVisible: Function;
}

export const ForgetPasswordResetPassword = ({
  drawerType, formEmail,
  setFormMail, setDrawerVisible,
}: InitViewProps) => {
  const [formResetPassword] = Form.useForm();
  const restPasswordPage = async () => {
    const { code, password } = formResetPassword.getFieldsValue();
    try {
      await resetPassword(formEmail, code, password);
      notifySuccess('Password Reset Successfully', 'password_reset_success');
      drawerType(FORGET_PASSWORD_VIEW_TYPE.SEND_CODE);
      setFormMail(setFormMail);
    } catch (error) {
      notifyError(error.message, 'verify_code_error');
    } finally {
      setDrawerVisible(false);
    }
  };
  return (
    <div>
      <div className="fp_full_size fp_pg_center">
        <Form className="fp_lgn_frm" layout="vertical" requiredMark form={formResetPassword} onFinish={restPasswordPage}>
          <div className="fp_cmpnt fp_cmpnt_cntr fp_logo_color">
            <Logo />
          </div>
          <div className="fp_cmpnt fp_cmpnt_cntr">
            <p className="fp_lgn_ttl_update">Reset Your Password</p>
          </div>
          <div className="fp_cmpnt fp_cmpnt_cntr fp_cmpnt_dv">
            <p>
              Enter your email address associated with your footprint account,
              and we’ll send you an email with verification code to reset your password.
            </p>
          </div>
          <Form.Item label="Verification Code" name="code" rules={[{ required: true, message: 'Please input your code!' }]}>
            <Input data-testid="fp_email_code" />
          </Form.Item>
          <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }, { pattern: new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*[!@#$&*])(?=.*?[0-9]).{8,}$'), message: 'Your password must be a minimum of 8 characters and include at least one uppercase letter, one lowercase letter, one number, and one special character' }]}>
            <Input.Password data-testid="fp_password" />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            requiredMark
            rules={[{ required: true, message: 'Please input your confirm password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password data-testid="fp_confirm_password" />
          </Form.Item>
          <Form.Item className="fp_mrgn_tp_20">
            <Button data-testid="fp_rest_pswrd" type="primary" className="fp_btn fp_btn-primary fp_btn-login" htmlType="submit">Reset Password</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ForgetPasswordResetPassword;
