import {
  DownloadOutlined, FileTextOutlined, FolderAddOutlined, FolderOutlined, DeleteOutlined,
} from '@ant-design/icons';
import {
  Modal,
  Row,
  Col,
  Button,
  notification,
  Upload,
  Breadcrumb,
} from 'antd';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import moment from 'moment';
import axios from 'axios';
import { notifyError, notifyInProgress, notifySuccess } from '../../../../utils/notification';
import { uploadFile } from '../../../testcaseCreation/action';
import FolderNameModal from './FolderNameModal';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  deleteProjectCTR,
  getCTRContent, getPreSignedURLForCTR, getProjects, updateCTRContent, updateProject,
} from '../../actions';
import { getFileExtension } from '../../../../utils/getFileExtension';
import { getFileIcon } from '../../../../utils/fileIcon';
import { CommonResource, Project } from '../../../../redux/projectSlice';
import CTRImportModal from './CTRImportModal';
import { TextEditor } from '../../../../components';
import Loading from '../../../../components/Loading';

interface CTRModalProps {
  open: boolean
  onClose: Function
  projectId: string | undefined
  projectName: string | undefined
  importMode?: boolean
  selectFile?: Function
}

const scriptList = (
  importMode: boolean,
  resources: CommonResource[],
  selectFolder: Function,
  handleDeleteTrigger: Function,
  handleDownloadFile: Function,
  selectFile: Function,
) => {
  const editableFileExtensionList = ['js', 'ts', 'json', 'txt'];
  const isEditable = (extension: string) => editableFileExtensionList
    .includes(extension?.toLowerCase());
  return resources
    .map((resource: CommonResource) => (
      <Row className="max-user-item fp_mas_row" key={JSON.stringify(resource)}>
        <Col
          onClick={() => {
            if (resource?.resourceFiles) selectFolder(resource);
            else if (isEditable(resource?.extension) || importMode) selectFile(resource);
          }}
          style={!resource?.resourceFiles && !isEditable(resource?.extension) && !importMode ? { cursor: 'not-allowed' } : {}}
          span={20}
        >
          <Row>
            <Col span={2}>
              {resource?.resourceFiles ? <FolderOutlined /> : getFileIcon(resource?.extension)}
            </Col>
            <Col span={7} offset={1} style={{ paddingTop: '3px' }}>
              <p>{resource.name}</p>
            </Col>
            <Col span={13} offset={1} style={{ paddingTop: '3px' }}>
              <p>{moment(resource?.createdTime).format('lll')}</p>
            </Col>
          </Row>
        </Col>
        <Col span={1} offset={1}>
          <Button type="text" onClick={() => handleDownloadFile(resource)} className="ctr-dowload-btn" icon={<DownloadOutlined />} />
        </Col>
        <Col span={2}>
          <Button type="text" onClick={() => handleDeleteTrigger(resource)} className="ctr-dowload-btn" icon={<DeleteOutlined />} />
        </Col>
      </Row>
    ));
};

const CTRModal = ({
  open,
  onClose,
  projectId,
  projectName,
  importMode = false,
  selectFile = () => { },
}: CTRModalProps) => {
  const [isCFModalOpen, setCFModalOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isCTRImportModalOpen, setCTRImportModalOpen] = useState<boolean>(false);
  const [commonTestResources, setCommonTestResources] = useState<CommonResource[]>([]);
  const [selectedResourceFolder, setSelectedResourceFolder] = useState<CommonResource>();
  const [selectedResource, setSelectedResource] = useState<CommonResource>();

  const dispatch = useAppDispatch();

  const accountId: string = useAppSelector(
    ({ userData }: any) => userData?.selectedAccount?.accountId,
  );

  const projects: Array<Project> = useAppSelector(
    ({ projectData }) => projectData.projects,
  );

  const onClearClose = () => {
    onClose(false);
    setSelectedResourceFolder(undefined);
    setCommonTestResources([]);
  };

  const deleteResource = async (file: CommonResource) => {
    try {
      notifyInProgress(`Deleting ${file.name}`);
      await deleteProjectCTR(file.key);
      await getProjects(accountId, dispatch);
      notification.destroy();
    } catch (err) {
      notification.destroy();
      notifyError(`Failed to delete ${file.name}`);
    }
  };

  const handleDownload = async (filename: string, downloadUrl: string) => {
    try {
      const response = await axios({
        method: 'GET',
        url: downloadUrl,
        responseType: 'blob',
      });

      const anchor = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      anchor.href = url;
      anchor.download = filename;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleSelectFile = async (file: CommonResource) => {
    setLoading(true);
    if (importMode) {
      onClose(false);
      setSelectedResourceFolder(file);
      setCTRImportModalOpen(true);
    } else {
      const res = await getCTRContent(file.key);
      setSelectedResource({ ...file, content: res.body });
    }
    setLoading(false);
  };

  const handleDownloadFile = async (file: CommonResource) => {
    try {
      notifyInProgress(`Preparing to download ${file.name}`);
      const res = await getPreSignedURLForCTR(file.key, undefined, 'getObject');
      await handleDownload(file.name, res.body);
      notification.destroy();
    } catch (error: any) {
      notification.destroy();
      notifyError(`Failed to download ${file.name}`);
    }
  };

  const renderContent = useCallback(() => {
    if (selectedResourceFolder?.resourceFiles) {
      return scriptList(
        importMode,
        selectedResourceFolder.resourceFiles,
        setSelectedResourceFolder, deleteResource,
        handleDownloadFile, handleSelectFile,
      );
    }
    return scriptList(
      importMode,
      commonTestResources,
      setSelectedResourceFolder, deleteResource,
      handleDownloadFile, handleSelectFile,
    );
  }, [commonTestResources, selectedResourceFolder]);

  const createFolder = async (folderName: string) => {
    notifyInProgress(`Creating folder ${folderName}`);
    await updateProject(
      accountId, projectId as string, {
        commonTestResources: [...commonTestResources, {
          path: folderName,
          name: folderName,
          icon: 'folder',
          key: `${accountId}/${projectId}/${folderName}`,
          resourceFiles: [],
          createdTime: new Date().toISOString(),
        }],
      },
    );
    await getProjects(accountId, dispatch);
    notification.destroy();
  };

  const handleUpload = async (file: any) => {
    notifyInProgress(`Uploading ${file.name}`);
    const newFile: CommonResource = {
      path: selectedResourceFolder?.resourceFiles ? `${selectedResourceFolder?.path}/${file.name}` : `${file.name}`,
      key: selectedResourceFolder?.resourceFiles ? `${selectedResourceFolder?.key}/${file.name}` : `${accountId}/${projectId}/${file.name}`,
      name: file.name,
      extension: getFileExtension(file.name),
      icon: 'file',
      createdTime: new Date().toISOString(),
    };
    const res = await getPreSignedURLForCTR(newFile.key, file.type, 'putObject');
    await uploadFile(res.body, file, file.type);
    let newCTRList: CommonResource[] = [];
    if (selectedResourceFolder?.resourceFiles) {
      newCTRList = commonTestResources.map((f: CommonResource) => {
        if (f.path === selectedResourceFolder.path) {
          return { ...f, resourceFiles: [...f?.resourceFiles, newFile] };
        }
        return f;
      });
    } else {
      newCTRList = [...commonTestResources, newFile];
    }
    await updateProject(
      accountId, projectId as string, { commonTestResources: newCTRList },
    );
    await getProjects(accountId, dispatch);
    notification.destroy();
  };

  const fetchData = () => {
    const project = projects?.find((p: Project) => p.projectId === projectId);
    if (project && project?.commonTestResources) {
      setCommonTestResources(project?.commonTestResources);
    }
    if (selectedResourceFolder) {
      setSelectedResourceFolder(project?.commonTestResources?.find(
        (f: CommonResource) => f.path === selectedResourceFolder.path,
      ));
    }
  };

  const saveEdit = async () => {
    setLoading(true);
    try {
      notifyInProgress('Saving changes');
      await updateCTRContent(selectedResource?.key, selectedResource?.content);
      notification.destroy();
      setSelectedResource(undefined);
      notifySuccess('Changes saved successfully!');
    } catch (err: any) {
      notifyError(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) fetchData();
  }, [projects, open]);

  return (
    <>
      <Modal
        className="fp-crt-modal fp-modal fp-modal-primary"
        width={800}
        open={open}
        title="Common Test Resources"
        closable={false}
        onCancel={onClearClose}
        footer={selectedResource?.content ? [
          <Button onClick={() => setSelectedResource(undefined)} type="primary" className="fp_btn fp_btn-outline">Cancel edit</Button>,
          <Button onClick={() => saveEdit()} type="primary" className="fp_btn fp_btn-outline">Save</Button>,
        ] : [
          <Button onClick={onClearClose} type="primary" className="fp_btn fp_btn-outline">Cancel</Button>,
        ]}
      >
        {
          isLoading ? <Loading plain /> : (
            <>
              {!importMode && (
              <Row justify="end">
                {!selectedResourceFolder && (
                <Col>
                  <Button
                    type="primary"
                    className="fp_btn fp_btn-primary"
                    icon={<FolderAddOutlined />}
                    onClick={() => {
                      onClose(false);
                      setCFModalOpen(true);
                    }}
                  >
                    New Folder
                  </Button>
                </Col>
                )}
                {!selectedResource?.content && (
                <Col offset={1}>
                  <Upload
                    multiple
                    showUploadList={false}
                    customRequest={({ file }) => handleUpload(file)}
                  >
                    <Button
                      className="fp_btn fp_btn-primary"
                      icon={<FileTextOutlined />}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Col>
                )}
              </Row>
              )}
              {selectedResourceFolder ? (
                <Breadcrumb separator="/" className="bread-crumb-seperator">
                  <Breadcrumb.Item
                    className="breadcrumb-item"
                    onClick={() => {
                      setSelectedResourceFolder(undefined);
                      setSelectedResource(undefined);
                    }}
                  >
                    {projectName}
                  </Breadcrumb.Item>
                  {selectedResourceFolder?.path.split('/').map((file: string) => (
                    <Breadcrumb.Item className="breadcrumb-item">{file}</Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              ) : (
                <Breadcrumb separator="/" className="bread-crumb-seperator">
                  <Breadcrumb.Item className="breadcrumb-item">/</Breadcrumb.Item>
                </Breadcrumb>
              )}
              <br />
              {!selectedResource?.content ? renderContent() : (
                <TextEditor
                  value={selectedResource?.content}
                  language={selectedResource?.extension}
                  onChange={(s: string) => setSelectedResourceFolder(
                    (prev: any) => ({ ...prev, content: s.target.value }),
                  )}
                  padding={15}
                  style={{ minHeight: 600 }}
                  allowCopyOption
                  allowFormatOption={false}
                  editMode
                />
              )}
            </>
          )
        }
      </Modal>
      <FolderNameModal
        open={isCFModalOpen}
        createFolder={createFolder}
        onCancel={() => {
          setCFModalOpen(false);
          onClose(true);
        }}
      />
      <CTRImportModal
        open={isCTRImportModalOpen}
        setCTRImportMethod={(method: string) => {
          selectFile(selectedResourceFolder, method);
          setCTRImportModalOpen(false);
          onClearClose();
        }}
      />
    </>
  );
};

export default CTRModal;
