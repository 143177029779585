import { CaretRightOutlined, FileTextOutlined } from '@ant-design/icons';
import {
  Modal,
  Row,
  Col,
  Button,
} from 'antd';
import React, { useCallback, useState } from 'react';
import Loading from '../../../../components/Loading';
import { getLogContent, getSignedUrl } from '../../action';
import { TextEditor } from '../../../../components';

interface UserType { isPassed: boolean, user: string }

interface MaxUserRecordingsProps {
  open: boolean;
  onClose: Function;
  usersList: UserType[];
  projectId: string;
  testcaseId: string;
  accountId: string | undefined;
  executionId: string;
}

const SELECT_TYPE = {
  LOGS: 'LOGS',
  VIDEO: 'VIDEO',
};

const recordingView = (url: string) => (
  <video controls width="100%" style={{ marginTop: '5px' }} autoPlay>
    <source src={url} type="video/webm" />
    <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
  </video>
);

const logView = (logContent: string) => (
  <TextEditor
    value={logContent}
    language="json"
    readOnly
  />
);

const userList = (usersList: UserType[], onSelect: Function) => usersList.map((user: UserType) => (
  <Row className={`max-user-item ${user.isPassed ? 'max-user-item-passed' : 'max-user-item-failed'}`}>
    <Col onClick={() => onSelect(user.user, SELECT_TYPE.VIDEO, user.isPassed)}>
      <CaretRightOutlined />
    </Col>
    <Col onClick={() => onSelect(user.user, SELECT_TYPE.LOGS, user.isPassed)}>
      <FileTextOutlined />
    </Col>
    <Col onClick={() => onSelect(user.user, SELECT_TYPE.VIDEO, user.isPassed)} offset={1} style={{ paddingTop: '3px' }}>
      <p>{`User ${user.user}`}</p>
    </Col>
  </Row>
));

const Recordings = ({
  open, onClose, usersList = [], accountId, testcaseId, projectId, executionId,
}: MaxUserRecordingsProps) => {
  const [isLoading, setLoading] = useState(false);
  const [video, setVideo] = useState<string | undefined>();
  const [log, setLog] = useState<any>();
  const [isTestUserPassed, setTestUserPassed] = useState<boolean>(true);
  const [isAdvanceLog, setAdvanceLog] = useState<boolean>(false);

  const onSelect = async (user: string, type: string, isPassed: boolean) => {
    setLoading(true);
    const keyPrefix = `${accountId}/${projectId}/${testcaseId}/${executionId}/${user}`;
    setTestUserPassed(isPassed);
    if (type === SELECT_TYPE.VIDEO) {
      const key = `${keyPrefix}/video.webm`;
      const url = await getSignedUrl(key);
      setVideo(url);
    } else {
      const key = `${keyPrefix}/log.json`;
      const url = await getSignedUrl(key);
      const logContent = await getLogContent(url);
      setLog(logContent);
    }
    setLoading(false);
  };

  const onClearClose = () => {
    setVideo(undefined);
    setLog(undefined);
    onClose(false);
  };

  const getErrorMessages = () => {
    const errors = log.errors.length > 0
      ? log.errors : log.suites[0].suites[0].specs[0].tests[0].results[0].errors;

    const errorMsgs = errors.map((err: any) => err.message).join('\n');
    return errorMsgs;
  };

  const renderContent = useCallback(() => {
    if (video) {
      return recordingView(video);
    }
    if (log) {
      return logView((isTestUserPassed || isAdvanceLog)
        ? JSON.stringify(log, null, 4)
        : getErrorMessages());
    }
    return userList(usersList, onSelect);
  }, [isAdvanceLog, log, video, isTestUserPassed, usersList]);

  const renderBody = () => (isLoading ? <Loading plain size="large" /> : renderContent());

  return (
    <Modal
      className="max-user-modal fp-modal fp-modal-primary"
      width={log ? '75vw' : '520px'}
      style={{ top: '40px' }}
      open={open}
      title={log ? 'Logs' : 'Recordings'}
      closable={false}
      onCancel={onClearClose}
      footer={[
        (log && !isTestUserPassed) && (
        <Button onClick={() => setAdvanceLog(!isAdvanceLog)} type="primary" className="fp_btn fp_btn-primary">
          {isAdvanceLog ? 'Minimal' : 'Advance'}
          {' Logs'}
        </Button>
        ),
        (video || log) && <Button onClick={() => { setVideo(undefined); setLog(undefined); }} type="primary" className="fp_btn fp_btn-primary">Back</Button>,
        <Button onClick={onClearClose} type="primary" className="fp_btn fp_btn-outline">Cancel</Button>,
      ]}
    >
      {renderBody()}
    </Modal>
  );
};

export default Recordings;
