import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, Form, Row, notification,
} from 'antd';
import { useHistory } from 'react-router-dom';
import {
  ROUTES,
} from '../../constants';
import { notifyError } from '../../utils/notification';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Project } from '../../redux/projectSlice';
import { getProjects } from '../projects/actions';
import { generateTestcase } from './action';
import RequirementForm from './components/RequirementForm';
import { setSelectedBilledPlan } from '../../redux/billedPlanSlice';
import { AutomationScript, TestingFrameworkType } from '../../types/automationScript';
import { AUTOMATION_SCRIPT_TYPE, ENTRY_TYPE, SERVICE_TYPE } from '../../types/common';
import Loading from '../../components/Loading';
import { Subscription } from '../../types/subscription';
import { addServiceRecord } from '../testcaseCreation/action';
import GuideModal from '../../components/modals/GuideModal';

const RequirementGatheringView = ({ entryType }: { entryType: string }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const [isTestModuleExists, setTestModuleExists] = useState<boolean>(false);
  const [isProjectExists, setProjectExists] = useState<boolean>(false);
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isGuideModalOpen, setGuideModalOpen] = useState<boolean>(true);

  const servicesData = useAppSelector(({ serviceData }) => serviceData.servicesData);
  const currentAccount = useAppSelector(({ userData }) => userData.selectedAccount);
  const paymentPlans = useAppSelector(({ billedPlanData }) => billedPlanData.billedPlans);

  const projects: Array<Project> = useAppSelector(
    ({ projectData }) => projectData.projects,
  );
  const selectedBilledPlan: any = useAppSelector(
    ({ billedPlanData }) => billedPlanData.selectedBilledPlan,
  );

  const load = useCallback(async () => {
    try {
      await getProjects(currentAccount?.accountId, dispatch);
    } catch (e) {
      notifyError('Project getting error.', 'project_get_err');
    }
  }, [currentAccount?.accountId, dispatch]);

  const setTestcaseCount = async (subscription: any) => {
    const paymentPlanId: string = subscription
      ? subscription.paymentPlanId : selectedBilledPlan.paymentPlanId;
    const selectedSubscriptionFromCall = await paymentPlans
      .find((sub: any) => sub
        .paymentPlanId === paymentPlanId);
    dispatch(setSelectedBilledPlan(selectedSubscriptionFromCall));
  };

  const createTestCase = async () => {
    const {
      testcaseNewModule = undefined,
      testcaseExModule = undefined,
      testcaseNewProject,
      testcaseExProject,
      requirementDescription,
    } = form.getFieldsValue();
    setLoading(true);

    const automationScriptData: AutomationScript = {
      accountId: currentAccount?.accountId,
      projectId: testcaseExProject,
      projectName: testcaseNewProject,
      moduleName: testcaseNewModule,
      existingModuleId: testcaseExModule,
      requirementDescription,
      testingFramework: AUTOMATION_SCRIPT_TYPE.PLAYWRIGHT as TestingFrameworkType,
      entryType,
    };
    try {
      if (!servicesData?.length && selectedBilledPlan) {
        await addServiceRecord(
          currentAccount?.accountId as string,
          selectedBilledPlan?.paymentType,
          selectedBilledPlan?.paymentPlanId,
          0,
        );
      }
      if (!isProjectExists) {
        const projectName = testcaseNewProject.trim();
        const processedProjectName = projectName.replace(/ +/g, ' ');
        if (projects.find((project) => project.name === processedProjectName)) {
          notification.destroy();
          notifyError('The project name already exists!');
          return;
        }
        automationScriptData.projectName = processedProjectName;
      }
      notification.destroy();
      const response = await generateTestcase(
        automationScriptData, SERVICE_TYPE.AUTOMATION_SCRIPT_GENERATOR,
      );
      notification.destroy();
      await getProjects(currentAccount?.accountId, dispatch);
      form.resetFields();
      // TODO: added module id from this side, need to check from the testcase result page
      history.push(`${ROUTES.TESTCASE_RESULT}/${response.moduleId}/${response.requirementId}`, { freshGenetared: true });
      setLoading(false);
    } catch (error: any) {
      notification.destroy();
      notifyError('Something went wrong', 'something_went_wrong');
      setLoading(false);
    }
  };

  const onSubmit = () => createTestCase();
  const backToHomeOrPaymentPlan = () => history.push(ROUTES.HOME);

  useEffect(() => {
    load().finally();
  }, [load]);

  useEffect(() => {
    if (!servicesData?.length && selectedBilledPlan) {
      setSelectedSubscription(selectedBilledPlan);
      setTestcaseCount(selectedBilledPlan);
    } else if (servicesData) {
      const subscription = servicesData.find((service: Subscription) => service.serviceId === 'testcase_creating');
      if (subscription) {
        setSelectedSubscription(subscription);
        setTestcaseCount(subscription);
      }
    }
  }, [servicesData, selectedBilledPlan]);

  if (!selectedSubscription) {
    return <Loading plain size="large" />;
  }

  return (
    <div>
      <div className="fp_pg_base">
        <Row>
          <Button
            data-testid="fp_previous_btn"
            className="back_btn fp_btn-primary fp_btn"
            onClick={() => backToHomeOrPaymentPlan()}
          >
            Back
          </Button>
        </Row>
        <Row className="fp_sub">
          <h1 className="fp_tcc-ttl" style={{ marginBottom: 70 }}>Let&apos;s Get You Organized</h1>
        </Row>
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <div className="fp_rqg_inner-view">
            <div className="fp_rqg_frm fp_auto_rqg_frm">
              <RequirementForm
                projects={projects}
                isTestModuleExists={isTestModuleExists}
                setTestModuleExists={setTestModuleExists}
                isProjectExists={isProjectExists}
                setProjectExists={setProjectExists}
                isLoading={isLoading}
              />
            </div>
          </div>
        </Form>
      </div>
      <GuideModal
        open={isGuideModalOpen}
        onCancel={() => setGuideModalOpen(false)}
        guideId={`guide-modal-${entryType.toLowerCase()}`}
        body={entryType === ENTRY_TYPE.RECORD ? '3-step automation tool, tailored for Product Leaders and QA Engineers. It offers a user-friendly, low-code interface, ideally suited for straightforward automation workflows.' : 'Guided automation workflow, specifically crafted for QA engineers seeking a more structured and resilient approach to their automation processes'}
      />
    </div>
  );
};

export default RequirementGatheringView;
