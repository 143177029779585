import { API } from 'aws-amplify';
import { APIS } from '../../utils/apiUtils';

export const billingSummeryData = async (accountId: string) => {
  try {
    return API.get(APIS.TESTCASE_CREATING_API, '/billing-summary', { queryStringParameters: { accountId } });
  } catch (error: any) {
    throw error.response.data;
  }
};
