import React from 'react';
import { Spin } from 'antd';

interface LoadingProps {
  plain?: boolean;
  size?: any;
}

const Loading = ({ plain = false, size = 'large' }: LoadingProps) => (
  <div className="fp_full_size fp_pg_center">
    <div className={`${plain ? '' : 'fp_spin_cntnr'} fp_cmpnt fp_cmpnt_cntr`}>
      <Spin size={size} />
    </div>
  </div>
);

export default Loading;
