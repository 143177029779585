import React from 'react';
import { SmallLogo, ChromeExtensionPurple } from '../../../../../components';
import { ROUTES } from '../../../../../constants';

type ExtensionInstructionContentProps = {
  showTitle?: boolean;
};

const ExtensionInstructionContent = ({ showTitle = true }: ExtensionInstructionContentProps) => (
  <>
    {showTitle
    && (
    <div className="fp_extension_instructions_modal_header">
      <SmallLogo width={32} height={29} />
      <p>
        Extension Instructions
      </p>
    </div>
    )}
    <div className="fp_extension_instructions_modal_section1">
      <a
        href="https://chromewebstore.google.com/detail/footprint/ejneiaadimaofmidbaegbfljmnbkaeim"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Link to FootPrint extension on Chrome Web Store"
      >
        <ChromeExtensionPurple />
      </a>
    </div>
    <div className="fp_extension_instructions_modal_section2">
      <p>
        Download the
        <span>
          <SmallLogo width={16} height={12} />
        </span>
        extension by clicking on Chrome logo ⬆️
      </p>
      <p>
        Once it&apos;s added to your browser, Use it to record User
        journeys or Function you would like to automate
      </p>
      <p>Once finished, click &apos;Stop&apos;</p>
      <p>Click the ‘Copy’ button, then return to the FootPrint site.</p>
      <p>Click the ‘Paste’ button in the FootPrint IDE</p>
      <p>Review and improve the test script by adding any needed assertions.</p>
      <p>
        When ready, click &apos;Run&apos; at the bottom of the IDE & test your
        script on the browsers and devices listed,
      </p>
    </div>
    <div className="fp_extension_instructions_modal_section3">
      <p>
        To ensure the best performance and stability of your script, we suggest
        adding data-test-id attributes
        to your elements. Use FootPrint&apos;s complimentary ID Generator for this purpose.
      </p>
      <p>
        <a href={ROUTES.ID_GENERATOR}>Click here </a>
        for a step-by-step guide on how to do it.
      </p>
    </div>
  </>
);

export default ExtensionInstructionContent;
