import React, { useState } from 'react';
import {
  Row, Col, Typography, Button, Modal,
} from 'antd';
import { deleteAccount } from '../actions';
import { notifyError, notifySuccess } from '../../../utils/notification';
import { useAppDispatch } from '../../../redux/hooks';
import { logout } from '../../login/actions';

const { Title } = Typography;

const AccountDeletions = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleAccountDelete = async () => {
    try {
      setLoading(true);
      await deleteAccount();
      notifySuccess('Account delete successfully !');
      await new Promise((resolve) => setTimeout(resolve, 3000));
    } catch (error) {
      notifyError('Failed to delete account !');
    } finally {
      setLoading(false);
      logout(dispatch);
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const deleteConfirmation = () => (
    <Modal
      className="delete-confirm-modal"
      open={isModalOpen}
      centered
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <Row>
        <Col span={24}>
          <Row>
            <Col>
              <Title level={5}>Are you sure you want to delete your account</Title>
              <p className="delete-content">
                This action cannot be undone and you will permanently lose access to the platform.
              </p>
            </Col>
          </Row>
          <Row className="btn-main">
            <Col span={4}>
              <Button className="edit-btn" shape="round" onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button className="delete-btn" shape="round" onClick={handleAccountDelete} loading={isLoading}>
                Delete
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );

  return (
    <Row className="bank-details-card">
      <Col span={24}>
        <Row className="card-details">
          <Col>
            <Title level={5}>Danger Zone</Title>
            <Title level={5} className="sub-header">Delete footprint account</Title>
            <p>
              By deleting your account you will lose all of your data and access
              associated with footprint
            </p>
          </Col>
        </Row>
        <Row className="delete-btn-con">
          <Col span={4}>
            <Button type="primary" shape="round" onClick={() => setModalOpen(true)}>
              Request account deletion
            </Button>
          </Col>
          {isModalOpen && deleteConfirmation()}
        </Row>
      </Col>
    </Row>
  );
};

export default AccountDeletions;
