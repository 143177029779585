import React from 'react';
import GeneratedTetcase from './GeneratedTestCase';

const TestCaseInitView = () => (
  <div>
    <GeneratedTetcase />
  </div>
);

export default TestCaseInitView;
