import { Col, Row } from 'antd';
import React from 'react';
import Loading from '../../components/Loading';
import MonitoringItemListViewItem from './MonitoringItemListViewItem';
import { ENABLE_MONITOR_MODE } from '../../configs/app-config';

interface propType {
  jobs: any[]
  handleRefresh: Function
  isLoading: boolean
}

const MonitoringItemListView = (
  {
    jobs, handleRefresh, isLoading = true,
  }: propType,
) => (
  <div className="fp_list" style={{ marginTop: '20px' }}>
    <Row justify="start" className="fp_list_title" style={{ marginTop: '10px' }}>
      <Col span={ENABLE_MONITOR_MODE ? 12 : 7} className="column-align"><p>Name</p></Col>
      {!ENABLE_MONITOR_MODE && <Col span={5} className="column-align"><p>Project/Module</p></Col>}
      <Col span={3} className="column-align"><p>Repeat</p></Col>
      <Col span={4} className="column-align"><p>Last run</p></Col>
      <Col span={2} className="column-align"><p>State</p></Col>
      <Col span={3} className="column-align"><p> </p></Col>
    </Row>
    {isLoading ? <Loading plain size="large" /> : jobs
      .sort((a: any, b: any) => new Date(b?.createdAt)
        .getTime() - new Date(a?.createdAt)
        .getTime())
      .map((job: any) => (
        <MonitoringItemListViewItem
          job={job}
          handleRefresh={handleRefresh}
        />
      ))}
  </div>
);

export default MonitoringItemListView;
