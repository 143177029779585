import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider, Row, Typography,
} from 'antd';
import { CheckOutlined, LinkOutlined } from '@ant-design/icons';
import { notifySuccess } from '../../utils/notification';
import GuideModal from '../../components/modals/GuideModal';

const { Title, Text } = Typography;

const IdGeneratorPage = () => {
  const [isCopied, setCopied] = useState<boolean>(false);
  const [isGuideModalOpen, setGuideModalOpen] = useState<boolean>(true);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setCopied(false), 5000);
    }
  }, [isCopied]);

  return (
    <div className="fp_id_generator_page_container">
      <Row justify="space-between" align="top">
        <Col span={12}>
          <Title level={2}>footPrint CLI</Title>
        </Col>
        <Col
          span={12}
          style={{
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Button
            className="fp_btn fp_btn-primary fp_tcc-btn"
            type="primary"
            icon={isCopied ? <CheckOutlined /> : <LinkOutlined />}
            onClick={() => {
              navigator.clipboard.writeText('https://www.npmjs.com/package/@footprintorg/footprint-cli');
              setCopied(true);
              notifySuccess('Link Copied successfully!');
            }}
          >
            Share with Developers
          </Button>
        </Col>
      </Row>
      <hr style={{ marginBottom: 30 }} />
      <Title level={3}>What footPrint CLI Does</Title>
      <Text>
        Simplify Your Testing Workflow with FootPrint CLI:
        This command-line tool is designed for developers
        working with React and HTML. It generates testIDs and
        assigns them to elements in your projects, streamlining your
        test automation process. We&apos;re also expanding its capabilities
        to include other common front-end languages like CSS and PHP.
      </Text>

      <Title level={5}>Benefits</Title>
      <ul className="ul-list">
        <li><span>Enhanced resilience in automation scripts.</span></li>
        <li><span>Improved efficiency in testing workflows.</span></li>
      </ul>

      <Divider />

      {/* <Title level={3}>What Is footPrint</Title>
      <Text>
        Your Go-To Functional Test Automation Platform:
        FootPrint assists engineering teams in generating and
        validating Playwright automation scripts for web browser testing.
      </Text>

      <Title level={5}>How It Works:</Title>
      <ul className="ul-list">
        <li>
          <span>
            Use our Chrome extension to record user journeys.
            Or Input test cases in natural language with necessary test data.
            And Automatically receive ready-to-use Playwright scripts.
          </span>
        </li>
        <li>
          <span>
            No Credit Card Required.
          </span>
        </li>
      </ul>

      <Title level={5}>Ideal For:</Title>
      <ul className="ul-list">
        <li>
          <span>
            QA Engineers and Product Managers seeking user-friendly, low-code automation solutions.
          </span>
        </li>
      </ul>

      <Text>
        How It Works: Use our Chrome extension to record
        user journeys. Or Input test cases in natural language with necessary test data.
        And Automatically receive ready-to-use Playwright
        scripts. No Credit Card Required. Ideal For: QA Engineers and Product
        Managers seeking user-friendly, low-code automation solutions.
      </Text>

      <Divider /> */}

      <Title level={2}>Table of Contents</Title>
      <hr style={{ marginBottom: 30 }} />
      <ul className="ul-links">
        <li><a href="#installation">Installation</a></li>
        <li><a href="#usage">Usage</a></li>
        <li><a href="#commands">Commands</a></li>
      </ul>

      <Divider />

      <Title level={2} id="installation">Installation</Title>
      <hr style={{ marginBottom: 30 }} />
      <Text>
        Install Footprint CLI globally using npm:
      </Text>

      <pre className="fp_cli_command_block">
        <code>
          npm i @footprintorg/footprint-cli
        </code>
      </pre>

      <Divider />

      <Title level={3} id="usage">Usage</Title>
      <hr style={{ marginBottom: 30 }} />
      <Text>
        To execute commands, make sure to run them from the root directory of your project.
      </Text>

      <pre className="fp_cli_command_block">
        <code>
          {`footprint COMMAND

FLAGS
--version: Display the current version of Footprint CLI.
--help [COMMAND]: Display help for a specific command.

EXAMPLES
$ footprint --version`}
        </code>
      </pre>

      <Divider />

      <Title level={3} id="commands">Commands</Title>
      <hr style={{ marginBottom: 30 }} />
      <ul className="ul-links">
        <li><a href="#footprint-init">footprint init</a></li>
        <li><a href="#footprint-generate">footprint generate</a></li>
        <li><a href="#footprint-help-commands">footprint help [COMMANDS]</a></li>
      </ul>

      <Divider />

      <Title level={4} id="footprint-init">footprint init</Title>
      <hr style={{ marginBottom: 30 }} />
      <Text>
        Initialize Footprint CLI for your project.
      </Text>
      <pre className="fp_cli_command_block">
        <code>
          {`USAGE
  $ footprint init [-p <value>] [-c <value>]

FLAGS
  -c, --code=&lt;value&gt;: Your project code. (optional)
  -p, --project=&lt;value&gt;: Your project type (HTML, React). (optional)

DESCRIPTION
  This init command initialize the footprint cli for your project

EXAMPLES
  $ footprint init`}
        </code>
      </pre>

      <Divider />

      <Title level={4} id="footprint-generate">footprint generate</Title>
      <hr style={{ marginBottom: 30 }} />
      <Text>
        This command generate test ids for your project.
      </Text>

      <pre className="fp_cli_command_block">
        <code>
          {`USAGE
  $ footprint generate [-f]

FLAGS
  -f, --formatting  Enable formatting when test id generating (optional)

DESCRIPTION
  This command generate test ids for your project

EXAMPLES
  $ footprint generate`}
        </code>
      </pre>

      <Divider />

      <Title level={4}>footprint help [COMMANDS]</Title>
      <hr style={{ marginBottom: 30 }} />
      <Text>
        Display help for footprint.
      </Text>

      <pre className="fp_cli_command_block">
        <code>
          {`USAGE
  $ footprint help [COMMANDS] [-n]

ARGUMENTS
  COMMANDS  Command to show help for.

FLAGS
  -n, --nested-commands  Include all nested commands in the output.

DESCRIPTION
  Display help for footprint.`}
        </code>
      </pre>

      <div id="footprint-help-commands" />

      <GuideModal
        open={isGuideModalOpen}
        onCancel={() => setGuideModalOpen(false)}
        guideId="guide-modal-id-generator"
        body="This page guides you on how to add testIDs to elements in your code. Including testIDs significantly enhances the resilience of your automation scripts, offering a more reliable solution than using Xpath or IDs."
      />
    </div>
  );
};

export default IdGeneratorPage;
