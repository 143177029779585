import React from 'react';

interface propType {
  text: string;
  imageSrc: string;
  width: string | number;
  height?: string | number;
  alt: string;
  onLoad?: () => void;
  loading?: boolean;
}

const InfoCardWithImage = ({
  text, imageSrc, width, height = 'auto', alt, onLoad, loading,
} : propType) => (
  <>
    {loading ? null : <p>{text}</p>}
    <div style={{ position: 'relative' }}>
      <img
        src={imageSrc}
        width={width}
        height={height}
        alt={alt}
        onLoad={onLoad}
      />
    </div>
  </>
);

export default InfoCardWithImage;
