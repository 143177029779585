import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from 'antd';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';

import { AnalyticsBrowser } from '@june-so/analytics-next';
import SideBar from './SideBar';
import { ROUTES } from '../../constants';
import Projects from '../projects/Projects';
import Home from '../home/Home';
import MainHeader from './MainHeader';
import { getServicesList, getUserAccounts } from './actions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Loading from '../../components/Loading';
import { notifyError } from '../../utils/notification';
import NewTestcase from '../testcases/NewTestcase';
import ResultView from '../testcases/result/ResultView';
import TestcaseResultView from '../testcaseCreation/components/testCaseCreatedResult/TestcaseResultView';
import UserProfile from '../profile/UserProfile';
import BillingSummary from '../billingSummary/BillingSummary';
import { getProjects } from '../projects/actions';
import TestCaseInitView from '../projects/project/TestCaseInitView';
import { getPaymentPlan } from '../testcaseCreation/action';
import SupportView from '../testcaseCreation/components/supportView/SupportView';
import QAService from '../qaAsService/QAService';
import GeneratedTestCaseListView from '../projects/project/GeneratedTestcaseListView';
import AutomationScriptGenerator from '../automationScriptGenerator/AutomationScriptGenerator';
import { ENTRY_TYPE } from '../../types/common';
import UpcomingFeatureNotice from '../../components/UpcomingFeatureNotice';
import TestExecutionSuit from '../testExecutionSuit/TestExecutionSuit';
import GeneratedTestScript from '../testExecutionSuit/components/GeneratedTestScript';
import { ENABLE_MONITOR_MODE, JUNEIFY_KEY } from '../../configs/app-config';
import IdGeneratorPage from '../idGenerator/IdGeneratorPage';
import IntegrationPage from '../integration/Integration';
import OnboardingGuide from '../onboard/OnboardingGuide';
import MonitoringJobListViewItemView from '../monitor/MonitoringJobListViewItemView';
import AnalyticsView from '../analytics/AnalyticsView';
import TestRecorder from '../automationScriptGenerator/testRecorder/TestRecorder';
import Monitor from '../monitor/Monitor';

const { Header, Content } = Layout;

const Main = () => {
  const dispatch = useAppDispatch();
  const { userId, email } = useAppSelector(({ userData }) => userData);
  const currentAccount = useAppSelector(({ userData }) => userData.selectedAccount);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchServicesList = useCallback(async () => {
    try {
      if (currentAccount?.accountId) {
        await getProjects(currentAccount?.accountId, dispatch);
        await getPaymentPlan(dispatch);
        await getServicesList(currentAccount?.accountId, dispatch);
        await getServicesList(currentAccount?.accountId, dispatch);
      }
    } catch (e) {
      notifyError("Something wen't wrong", 'something_went_wrong');
    }
  }, [currentAccount?.accountId]);

  useEffect(() => {
    fetchServicesList();
  }, [fetchServicesList]);

  useEffect(() => {
    async function init() {
      try {
        setLoading(true);
        await getUserAccounts(userId, dispatch);
      } catch (e) {
        notifyError('Account data getting error.', 'get_account_data_error');
      } finally {
        setLoading(false);
      }
    }
    init();
  }, []);

  useEffect(() => {
    if (JUNEIFY_KEY && email) {
      const analytics = AnalyticsBrowser.load({ writeKey: JUNEIFY_KEY });
      analytics.identify('USER_ID', { email });
      analytics.track('Playwright scripts validated');
    }
  }, [JUNEIFY_KEY, email]);

  if (isLoading) {
    return <Loading plain={false} size="large" />;
  }

  const renderView = (route: any) => {
    const { page } = route.match.params;
    const pageRoute = `/${page}`;
    switch (pageRoute) {
      case ROUTES.GET_STARTED:
        return <OnboardingGuide />;
      case ROUTES.HOME:
        return <Home />;
      case ROUTES.PROJECTS:
        return <Projects />;
      case ROUTES.MONITOR:
        return <Monitor />;
      case ROUTES.TESTCASE_CREATION:
        return <AutomationScriptGenerator entryType={ENTRY_TYPE.RECORD} />;
      case ROUTES.AUTOMATION_SCRIPT_GENERATOR:
        return <AutomationScriptGenerator entryType={ENTRY_TYPE.MANUAL} />;
      case ROUTES.PROFILE:
        return <UserProfile />;
      case ROUTES.BILLING_SUMMARY:
        return <BillingSummary />;
      case ROUTES.SUPPORT_VIEW:
        return <SupportView />;
      case ROUTES.QA_SERVICE:
        return <QAService />;
      case ROUTES.ID_GENERATOR:
        return <IdGeneratorPage />;
      case ROUTES.INTEGRATION:
        return <IntegrationPage />;
      case ROUTES.TEST_EXECUTION:
        return <TestExecutionSuit />;
      case ROUTES.REPORTING:
        return <UpcomingFeatureNotice />;
      default:
        return <Redirect to={ROUTES.PROJECTS} />;
    }
  };

  return (
    <Layout className="fp_pg_hght_full fp_pg_bckgrnd_trnsprnt">
      <SideBar />
      <Layout className="fp_pg_cntnt_bckgrnd">
        <Header className="fp_main_header">
          <MainHeader />
        </Header>
        <Content className="fp_pg_cntnt">
          <Switch>
            {!ENABLE_MONITOR_MODE && <Route path={`${ROUTES.TEST_CASE}/:projectId/:testcaseId`}><NewTestcase /></Route>}
            {!ENABLE_MONITOR_MODE && <Route path={`${ROUTES.RECORD_TEST_CASE}/:moduleId/:requirementId`}><TestRecorder /></Route>}
            {!ENABLE_MONITOR_MODE && <Route path={`${ROUTES.VIEW_RESULTS}/:projectId/:testcaseId`}><ResultView /></Route>}
            {!ENABLE_MONITOR_MODE && <Route path={`${ROUTES.PROJECTS}/:projectId/:moduleId`} render={() => <GeneratedTestCaseListView />} />}
            {!ENABLE_MONITOR_MODE && <Route path={`${ROUTES.PROJECTS}/:projectId`} render={() => <TestCaseInitView />} />}
            <Route path={`${ROUTES.MONITOR}/:monitorId`}><MonitoringJobListViewItemView /></Route>
            <Route path={`${ROUTES.ANALYTICS}/:monitorId`}><AnalyticsView /></Route>
            {!ENABLE_MONITOR_MODE && <Route path={`${ROUTES.TESTCASE_RESULT}/:moduleId/:requirementId`}><TestcaseResultView /></Route>}
            {!ENABLE_MONITOR_MODE && <Route path={`${ROUTES.TEST_EXECUTION}/:testSuiteId`}><GeneratedTestScript /></Route>}
            <Route path="/:page" render={renderView} />
            <Redirect from={ROUTES.ANY} to={ENABLE_MONITOR_MODE ? ROUTES.MONITOR : ROUTES.HOME} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRouter(Main);
