import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

interface Props {
  avgRespTimes: string
}

const LineBar = ({ avgRespTimes }: Props) => {
  const avgResponSetime = JSON.parse(avgRespTimes || '[]');

  return (
    <>
      <p style={{ color: 'white' }}>Average Response Time</p>
      <ResponsiveContainer width="100%" height={300} min-width={300}>
        <LineChart
          data={avgResponSetime}
          margin={{
            top: 30, right: 80, left: 20, bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            label={{
              value: 'Time per resource (s)', fill: 'white', position: 'insideBottom', offset: -20,
            }}
            tick={{ fill: '#fff' }}
          />
          <YAxis
            label={{
              value: 'Time (s)', angle: -90, fill: 'white', position: 'left',
            }}
            tick={{ fill: '#fff' }}
          />
          <Tooltip wrapperClassName="fp_graph_tooltip" itemStyle={{ color: 'white' }} cursor={false} />
          <Line
            name="Time"
            dot={{ stroke: 'red', strokeWidth: 3 }}
            activeDot={{ stroke: 'red', strokeWidth: 3, r: 8 }}
            strokeWidth="5"
            dataKey="time"
            stroke="#fcfcfc"
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default LineBar;
