import React, { useState, useEffect } from 'react';
import {
  Button, Col, Row, Typography, Select, Tag, Checkbox, Spin, SelectProps,
  Table,
  Tooltip,
} from 'antd';
import {
  CopyOutlined,
  DownOutlined, RightOutlined,
} from '@ant-design/icons';
import CryptoJS from 'crypto-js';
import { TextEditor } from '../../components';
import { getTestSuites } from '../testExecutionSuit/action';
import { useAppSelector } from '../../redux/hooks';
import { INTEGRATION_PUBLIC_TOKEN_DECRYPT_KEY, INTEGRATION_TOOL } from '../../configs/app-config';
import GenerateTokenModal from './modals/GenerateTokenModal';
import { getTokenData } from './action';

type TagRender = SelectProps['tagRender'];
const { Title, Text } = Typography;

const tagRender: TagRender = (props) => {
  const { label, value } = props;

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      className="option-tag"
      color={value}
      onMouseDown={onPreventMouseDown}
    >
      {label}
      <span>,</span>
    </Tag>
  );
};

const IntegrationPage = () => {
  const [isGenerate, setGenerate] = useState<boolean>(false);
  const [isCopied, setCopied] = useState<boolean>(false);
  const [selectedTestSuits, setSelectedTestSuits] = useState<any>([]);
  const [isClicked, setClicked] = useState<boolean>(false);
  const [testSuitSelectorVisible, setTestSuitSelectorVisible] = useState<boolean>(false);
  const [toolSelectorVisible, setToolSelectorVisible] = useState<boolean>(false);
  const [testSuitsOptions, setTestSuitsOptions] = useState<any[]>([]);
  const [selectedIntegrationTool, setSelectedIntegrationTool] = useState<string>();
  const [isGenerateTokenModal, setGenerateTokenModal] = useState<boolean>(false);
  const [integrationGuideData, setIntegrationGuideData] = useState<any>();
  const [tokenData, setTokenData] = useState<any>();
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const accountId = useAppSelector(({ userData }: any) => userData?.selectedAccount?.accountId);

  useEffect(() => {
    if (isCopied) {
      setCopied(false);
    }
  }, [isCopied]);

  const fetchTestSuites = async (acId: string) => {
    const tcSuites = await getTestSuites(acId);
    const options = tcSuites
      .map(({ name: label, testSuiteId: value }: any) => ({ label, value }));
    setTestSuitsOptions(options);
  };

  const isDateExpired = (isoDate: string) => {
    const dateObject = new Date(isoDate);
    const currentDate = new Date();
    return dateObject < currentDate;
  };

  const fetchTokenData = async (acId: string) => {
    try {
      const fetchedTokenData = await getTokenData(acId);
      const decryptedData = JSON.parse(CryptoJS.AES
        .decrypt(fetchedTokenData, INTEGRATION_PUBLIC_TOKEN_DECRYPT_KEY)
        .toString(CryptoJS.enc.Utf8));
      const isExpired: boolean = isDateExpired(decryptedData?.expireDate);
      setTokenData({ ...decryptedData, isExpired });
      return decryptedData.encryptedToken;
    } catch (err: any) {
      console.log('failed to fetch token data: ', err.message);
      return null;
    }
  };

  useEffect(() => {
    if (accountId) {
      fetchTestSuites(accountId);
      fetchTokenData(accountId);
    }
  }, [accountId]);

  const handleTestSuitDropdownVisibleChange = (visible: boolean) => {
    setTestSuitSelectorVisible(visible);
  };

  const handleToolDropdownVisibleChange = (visible: boolean) => {
    setToolSelectorVisible(visible);
  };

  const tools: any[] = [{ icon: 'bitbucket', tool: 'Bitbucket Pipelines', active: true }, { icon: 'jenkins', tool: 'jenkins' }, { icon: 'github', tool: 'GitHub Actions' },
    { icon: 'bamboo', tool: 'Bamboo by Atlassian' },
    { icon: 'azure', tool: 'Azure DevOps' }, { icon: 'aws', tool: 'AWS CodePipeline' },
    { icon: 'travis', tool: 'Travis CI' }, { icon: 'circle', tool: 'CircleCI' },
    { icon: 'gitlab', tool: 'GitLab CI/CD' }, { icon: 'teamcity', tool: 'TeamCity by JetBrains' }];

  const toolOptions = tools.map(({ icon, tool, active }) => ({
    value: icon,
    label:
  <div style={{ opacity: active ? 1 : 0.2 }}>
    <Col className="tool-image">
      <img src={`integration-logo/${icon}.png`} alt={icon} />
    </Col>
    <Col className="span">
      {tool}
    </Col>
  </div>,
    disabled: !active,
  }));

  const generateBitBucket = (token = undefined) => {
    const payload = {
      accountId,
      selectedTestSuits,
      tool: selectedIntegrationTool,
      variables: [
        { name: 'FP_TOKEN', value: token || tokenData?.encryptedToken || 'Your TOKEN' },
        { name: 'FP_ACCOUNT_ID', value: accountId },
        { name: 'FP_TEST_SUITE_ID', value: selectedTestSuits.map(({ value }: any) => value).join(',') },
      ],
      code: `  - pipe: ${INTEGRATION_TOOL.bitbucket}
      variables:
        token: "$FP_TOKEN"
        accountId: '$FP_ACCOUNT_ID'
        testSuiteId: '$FP_TEST_SUITE_ID'`,
    };
    setIntegrationGuideData(payload);
  };

  const setWebhook = () => {
    setClicked(true);
    setGenerate(true);
    setClicked(false);

    switch (selectedIntegrationTool) {
      case 'bitbucket':
        generateBitBucket();
        break;
      default:
        break;
    }
  };

  const handleCheckboxChange = (checked: boolean, testSuite: any) => {
    if (checked) {
      setSelectedTestSuits([...selectedTestSuits, testSuite]);
    } else {
      setSelectedTestSuits(selectedTestSuits.filter((item: any) => item.value !== testSuite.value));
    }
  };

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (_, record: any) => (
        <Row>
          {record.name === 'FP_TOKEN' && (!tokenData || tokenData?.isExpired === true) ? (
            <Button
              className="fp_btn fp_btn-secondary generate-token-btn"
              type="primary"
              icon={<img src="/generate-webhook.png" alt="" width={20} height={20} style={{ marginRight: 20 }} />}
              onClick={() => setGenerateTokenModal(true)}
            >
              {tokenData?.isExpired ? 'Token is expired - Re-Generate Token' : 'Generate Token'}
            </Button>
          ) : (
            <>
              {record.value}
              <Tooltip title={copySuccess ? 'Copied!' : 'Copy to Clipboard'} placement="topRight">
                <Button
                  type="text"
                  icon={<CopyOutlined />}
                  onClick={() => handleCopy(record.value)}
                />
              </Tooltip>
            </>
          )}
        </Row>
      ),
    },
  ];

  return (
    <div className="fp_id_generator_page_container integration-container">
      <Row justify="space-between" align="top">
        <Col span={12}>
          <Title level={2}>Integration</Title>
        </Col>
      </Row>
      <hr />
      <Text className="integration-text">
        To facilitate the integration of your CI/CD pipeline
        with our test automation suites,
        we require some information from you.
      </Text>
      <Title level={5}>
        Select Your CI/CD Tool: Please choose the CI/CD tool
        you are currently using from the list below. This helps
        us tailor the integration to fit your specific deployment process.
      </Title>
      <Select
        placeholder="Select Your CI/CD Tool"
        className="selector toot-selector"
        options={toolOptions}
        popupClassName="integration-tools"
        dropdownStyle={{ width: '100%' }}
        suffixIcon={toolSelectorVisible ? <RightOutlined /> : <DownOutlined />}
        onDropdownVisibleChange={handleToolDropdownVisibleChange}
        onSelect={(value: string) => setSelectedIntegrationTool(value)}
      />

      <Title level={5}>
        Select the test execution suite that you&#8216;ve created within our platform.
        This suite will be executed at each deployment through the integration.
      </Title>

      <Select
        mode="multiple"
        placeholder="Select Your Test Execution Suites"
        className="selector selector-test-suit"
        popupClassName="test-suit"
        maxTagCount="responsive"
        tokenSeparators={[' , ']}
        tagRender={tagRender}
        value={selectedTestSuits}
        showArrow
        suffixIcon={testSuitSelectorVisible ? <RightOutlined /> : <DownOutlined />}
        onDropdownVisibleChange={handleTestSuitDropdownVisibleChange}
      >
        {testSuitsOptions.map((testSuite: any) => (
          <Option key={JSON.stringify(testSuite)}>
            <Checkbox className="integration-test-suit-checkbox" value={testSuite.label} onChange={(e: any) => handleCheckboxChange(e.target.checked, testSuite)}>{testSuite.label}</Checkbox>
          </Option>
        ))}
      </Select>

      <div className="webhook-btn-container">
        <Button
          className="fp_btn fp_btn-secondary webhook-btn"
          type="primary"
          icon={<img src="/generate-webhook.png" alt="" width={20} height={20} />}
          onClick={() => { setWebhook(); }}
          disabled={selectedTestSuits.length <= 0}
        >
          Get Guide
        </Button>
        {isClicked && (
          <div className="spin">
            <Spin size="default" />
          </div>
        )}

      </div>
      {isGenerate && (
        <div className="webhook-section">
          <Row className="link-space" gutter={24}>
            <Title
              level={4}
              style={{ color: 'white' }}
            >
              Create variables at the Bitbucket
              repository level and securely store the following secrets.
            </Title>
            <div className="fp_tbl_rw">
              <Table className="fp_tst_rslt" dataSource={integrationGuideData.variables} columns={columns} pagination={false} />
            </div>
          </Row>
          <br />
          <Row className="link-space" gutter={24}>
            <Col span={24}>
              <Title
                level={4}
                style={{ color: 'white' }}
              >
                Add footprint pipe to your pipeline.
              </Title>
              <TextEditor disabled language="yml" placeholder="" value={integrationGuideData?.code} allowCopyOption />
            </Col>
          </Row>
        </div>
      )}

      <GenerateTokenModal
        open={isGenerateTokenModal}
        onCancel={async (flag: boolean) => {
          setGenerateTokenModal(flag);
          const token = await fetchTokenData(accountId);
          generateBitBucket(token);
        }}
      />
    </div>
  );
};

export default IntegrationPage;
