export const ROUTES: any = {
  LOGIN: '/login',
  SIGN_UP: '/signup',
  VERIFY: '/verify',
  HOME: '/home',
  GET_STARTED: '/get-started',
  PROJECTS: '/projects',
  ANALYTICS: '/analytics',
  MONITOR: '/monitor',
  SHARE_MONITOR: '/share-monitor',
  SHARE_ANALYTICS: '/share-analytics',
  STATISTICS: '/statistics',
  TEST_CASE: '/new-testcase',
  VIEW_RESULTS: '/view-results',
  TESTCASE_CREATION: '/testcase-creation',
  RECORD_TEST_CASE: '/record-test-case',
  AUTOMATION_SCRIPT_GENERATOR: '/generate-and-modify-automation-scripts',
  TESTCASE_RESULT: '/testcase-result',
  BILLING_SUMMARY: '/billing-summary',
  PROFILE: '/profile',
  SUPPORT_VIEW: '/support-view',
  QA_SERVICE: '/qa-service-contact',
  ID_GENERATOR: '/id-generator',
  INTEGRATION: '/integration',
  TEST_EXECUTION: '/test-execution',
  REPORTING: '/reporting',
  ANY: '/',
};

export const FORGET_PASSWORD_VIEW_TYPE = {
  SEND_CODE: 'send_code',
  RESET_PASSWORD: 'reset_password',
};

export const TESTCASE_CREATE_VIEW_TYPE = {
  INIT: 'init',
  OWN_SCRIPT: 'own_script',
  RECORD: 'record',
};

export const EXECUTION_RESULT = {
  RUNNING: 'RUNNING',
  FAILED: 'FAILED',
  PASSED: 'PASSED',
  SAVED: 'SAVED',
  PROVISIONING: 'PROVISIONING',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
};
export const TESTCASE_TYPE = {
  PLAYWRITE: 'PLAY_WRIGHT',
  SELENIUM: 'SELENIUM',
  GATLING: 'GATLING',
  JMETER: 'JMETER',
};

export const NEW_TESTCASE_STEPS_MANUAL = {
  SELECT_TYPE: 0,
  UPLOAD_SCRIPT: 1,
  SAVE: 2,
};

export const NEW_TESTCASE_STEPS_RECORD = {
  INPUT_RECORD: 0,
  SAVE: 1,
};

export const NEW_TESTCASE_INIT_VIEW_TILES = [
  {
    title: 'I have my own scripts',
    description: 'Select this option if you have your own script',
  },
  {
    title: 'Record & play test',
    description: 'Select this option if you want to record the user flow',
  },
];

export const PLAY_WRIGHT_COMMANDS = {
  GOTO: 'goto', // goto https://sample.com
  FILL: 'fill', // fill #input-box,child=1 value="hello world"
  CLICK: 'click', // click #btn-press,child=1
  EXPECT: 'expect', // expect #title toHaveText="hello world" / expect #title not.toHaveText="hello world"
};

export const PLAY_WRIGHT_HTML_ATTRIBUTES = {
  TEST_ID: 'data-testid',
  PLACEHOLDER: 'placeholder',
  ROLE: 'role',
  ALT: 'alt',
  TITLE: 'title',
  TXT: 'text',
};

export const PLAY_WRIGHT_EXPECT_FUNCTION_LIST = [
  'toBeChecked',
  'toBeDisabled',
  'toBeEditable',
  'toBeEmpty',
  'toBeEnabled',
  'toBeFocused',
  'toBeHidden',
  'toBeVisible',
  'toContainText',
  'toHaveAttribute',
  'toHaveClass',
  'toHaveCount',
  'toHaveCSS',
  'toHaveId',
  'toHaveText',
  'toHaveValue',
];

export const COMPLEX_EXPECT_TYPES = {
  toHaveAttribute: 'toHaveAttribute',
  toHaveClass: 'toHaveClass',
  toHaveCSS: 'toHaveCSS',
};

export const TESTCASE_CREATION_STEPS = {
  PAYMENT_PLAN: 'payment-plan',
  REQUIREMENT_GATHERING: 'requirement-gathering',
};

export const PAYMENT_PLANS = {
  PAY_AS_YOU_GO: 'Pathfinder',
  PROFESSIONAL: 'Trailblazer',
  ENTERPRISE: 'Trailblazer',
};

export const BILLED_PLANS = {
  MONTHLY: 'Monthly',
  ANNUALLY: 'Annually',
};

export const PLANS = ['Monthly', 'Annually'];

export const TIME_PERIOD = {
  YEARLY: 'YEARLY',
  ANNUALLY: 'ANNUALLY',
};

export const PROJECT_SERVICE_TYPE = {
  TESTCASE_CREATING: 'TESTCASE_CREATING',
  LOAD_TESTING: 'LOAD_TESTING',
};

export const TESTCASE_CREATING_STATUS = {
  GENERATING: 'GENERATING',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR',
};

export const QUOTA_STATUS = {
  FREE_QUOTA_ENDED: 'FREE_QUOTA_ENDED',
  SUBSCRIPTION_QUOTA_ENDED: 'SUBSCRIPTION_QUOTA_ENDED',
  QUOTA_AVAILABLE: 'QUOTA_AVAILABLE',
};

export const FEEDBACKTYPE = {
  FEEDBACK_TYPE: 'TESTCASE_FEEDBACK',
};

export const CONTACT_US_TYPES = {
  ENTERPRISE_SALES: 'ENTERPRISE_SALES',
  QA_SERVICE_SALES: 'QA_SERVICE_SALES',
  GENERAL_CONTACT: 'GENERAL_CONTACT',
};

export const PAY_AS_YOU_GO_STATUS = {
  INSUFFICIENT_CREDIT: 'INSUFFICIENT_CREDIT',
};

export const EXTENSION_INSTRUCTION_MODAL_STATUS = {
  KEY: 'extension_instruction_modal_status',
};

export const CALENDLY_EVENT_URL = 'https://calendly.com/pdsilva/30min';
