import React, { useState, useEffect } from 'react';
import {
  Button, Row, Table, Space,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { exportToCsv } from '../../utils/exportToCSV';
import { useAppSelector } from '../../redux/hooks';
import { billingSummeryData } from './actions';
import { SkeletonTableColumnsType } from '../../components/SkeletonTable';
import { SkeletonTable } from '../../components';

interface DataType {
  key: React.Key;
  InvoiceId: string,
  ProductCategory: string;
  Date: Number;
  Currency: string,
  Amount: Number;
}

function BillingSummary() {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [checkedRows, setCheckedRows] = useState<object[]>([]);
  const [tableData, setTableData] = useState<Array<string>>([]);
  const [isTableDataLoading, setTableDataLoading] = useState(true);
  const selectedAccount = useAppSelector(({ userData }) => userData.selectedAccount);

  const loadTableData = async () => {
    try {
      const data = await billingSummeryData(selectedAccount?.accountId ?? '');
      setTableDataLoading(false);
      const invoiceData = data.invoices.data.map((invoice) => ({
        InvoiceID: invoice.id,
        ProductCategory: invoice.lines.data[0].description,
        Date: new Date(invoice.created * 1000).toLocaleString(),
        Amount: `$ ${(invoice.amount_paid / 100).toFixed(2)}`,
      }));
      setTableData(invoiceData);
    } catch (error) {
      setTableDataLoading(false);
      throw new Error('No data found');
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'InvoiceID',
      dataIndex: 'InvoiceID',
    },
    {
      title: 'Product Category',
      dataIndex: 'ProductCategory',
    },
    {
      title: 'Date',
      dataIndex: 'Date',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      align: 'right',
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: object[]) => {
      // Check if any row is selected
      if (selectedRowKeys.length !== 0) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
      setCheckedRows(selectedRows);
    },
  };

  const downloadCSV = () => {
    exportToCsv('Billing Summary', checkedRows);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      loadTableData();
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="fp_pg_base">
      <Row className="fp_hdn_center">
        <p className="fp_status-title-name">Billing Summary</p>
      </Row>
      <Row>
        <Space style={{ width: '100%', justifyContent: 'space-between', marginBlock: '10px' }}>
          <Button
            type="primary"
            className="fp_btn fp_btn-primary fp_navigate_btn"
            disabled={!isChecked}
            onClick={downloadCSV}
          >
            Download CSV
          </Button>
        </Space>
      </Row>
      <Row className="fp_tbl_rw">
        <SkeletonTable
          rowCount={2}
          active
          loading={isTableDataLoading}
          columns={columns as SkeletonTableColumnsType[]}
        >
          <Table
            className="fp_tst_rslt"
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        </SkeletonTable>

      </Row>
    </div>
  );
}

export default BillingSummary;
