import React from 'react';
import { UpcomingFeature } from '.';

const UpcomingFeatureNotice = () => (
  <div className="fp_upcoming_feature_container">
    <UpcomingFeature width={360} height={160} />
    <p>Working hard to bring you something amazing. Stay tuned.</p>
  </div>

);

export default UpcomingFeatureNotice;
