import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { DoubleLeftOutlined } from '@ant-design/icons';
import { ROUTES } from '../../constants';
import {
  HomeIcon, ProjectIcon, SmallLogo, SupportIcon, SettingIcon, IDGeneratorIcon,
  IntegrationIcon, TestExecutionIcon, ReportIcon, Logo, GetStartedIcon, MonitorIcon,
} from '../../components';
import { ENABLE_MONITOR_MODE } from '../../configs/app-config';

const { Sider } = Layout;

const SideBar = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [selectedMenu, setSelectedMenu] = useState(ROUTES.HOME);
  const onCollapse = (isCollapsed: boolean) => {
    setCollapsed(isCollapsed);
  };
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (pathname !== selectedMenu) {
      setSelectedMenu(pathname);
    }
  }, [pathname, selectedMenu]);

  const routeTo = (route: string) => {
    history.push({ pathname: route });
  };

  return (
    <Sider breakpoint="lg" trigger={<DoubleLeftOutlined color="#fff" />} collapsible collapsed={collapsed} onCollapse={onCollapse} className="fp_sd_pnl">
      <div className="fp_dshrd_logo_cntnr">
        <div className="fp_white_logo">
          <Logo />
        </div>
        <div className="fp_small_logo">
          <SmallLogo />
        </div>
      </div>
      <Menu
        selectedKeys={[selectedMenu]}
        mode="inline"
        theme="dark"
        onClick={({ key }) => routeTo(key)}
      >
        {!ENABLE_MONITOR_MODE && (
        <>
          <Menu.Item data-testid="fp_menu_item_get_started" key={ROUTES.GET_STARTED} icon={<GetStartedIcon />}>
            Get started
          </Menu.Item>
          <Menu.Item data-testid="fp_menu_item_home" key={ROUTES.HOME} icon={<HomeIcon />}>
            Home
          </Menu.Item>
          <Menu.Item data-testid="fp_menu_item_projects" key={ROUTES.PROJECTS} icon={<ProjectIcon />}>
            Projects
          </Menu.Item>
        </>
        )}
        <Menu.Item data-testid="fp_menu_item_monitoring" key={ROUTES.MONITOR} icon={<MonitorIcon />}>
          Monitoring
        </Menu.Item>
        {!ENABLE_MONITOR_MODE && (
        <>
          <Menu.Item data-testid="fp_menu_item_id_generator" key={ROUTES.ID_GENERATOR} icon={<IDGeneratorIcon />}>
            ID generator
          </Menu.Item>
          <Menu.Item data-testid="fp_menu_item_integration" key={ROUTES.INTEGRATION} icon={<IntegrationIcon />}>
            Integration
          </Menu.Item>
          <Menu.Item data-testid="fp_menu_item_test_execution" key={ROUTES.TEST_EXECUTION} icon={<TestExecutionIcon />}>
            Test execution
          </Menu.Item>
          <Menu.Item data-testid="fp_menu_item_reporting" key={ROUTES.REPORTING} icon={<ReportIcon />}>
            Reporting
          </Menu.Item>
          <Menu.Item data-testid="fp_menu_item_setting" key={ROUTES.PROFILE} icon={<SettingIcon />}>
            Settings
          </Menu.Item>
          <Menu.Item data-testid="fp_menu_item_support" key={ROUTES.SUPPORT_VIEW} icon={<SupportIcon />}>
            Support
          </Menu.Item>
        </>
        )}
      </Menu>
    </Sider>
  );
};

export default SideBar;
