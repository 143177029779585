import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import {
  Avatar, Popover, Row,
} from 'antd';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logout } from '../login/actions';
import { notifyConfirmation } from '../../utils/notification';
import UserDetailPopUp from './components/UserDetailPopUp';

const MainHeader = () => {
  const dispatch = useAppDispatch();
  const { fName, lName } = useAppSelector((state) => state.userData.userData);
  const { email, accounts } = useAppSelector((state) => state.userData);
  const accountName = accounts?.map((account) => account.accountName);
  const isAccountActive: any = accounts?.map((account) => account.active);

  const logOut = () => {
    notifyConfirmation('Are you sure you want to logout?', 'Yes', () => logout(dispatch), 'No', () => { });
  };

  const userDetailTitle = (
    <Row style={{ marginBottom: 15 }}>
      <p
        className="fp_heading-3 fp_mrgn_tp_15"
      >
        User Details
      </p>
    </Row>
  );

  return (
    <div className="fp_hdr_cntnr">
      <div className="fp_hdr_lft_cntnr">
        {/* TODO: removed account selector for the demo version */}
        {/* <AccountSelector /> */}
      </div>
      <div className="fp_hdr_rght_cntnr">
        <div className="fp_hdr_innr_cntnr">
          <div className="fp_cmpnt fp_cmpnt_flx_clmn fp_hdr_prfl_cntnr">
            <Popover
              zIndex={1000}
              destroyTooltipOnHide
              overlayClassName="fp_sd_pnl"
              placement="bottomRight"
              title={userDetailTitle}
              content={(
                <UserDetailPopUp
                  fName={fName}
                  lName={lName}
                  email={email}
                  accountName={accountName}
                  isAccountActive={isAccountActive}
                  logOut={logOut}
                />
              )}
              color="transparent"
              overlayStyle={{
                width: '30%',
                borderRadius: 10,
              }}
              showArrow={false}
            >
              <div
                className="fp_hdr_prfl fp_cmpnt fp_cmpnt_algn_itms_cntr fp_cmpnt_flx_rw"
                style={{ clear: 'both', whiteSpace: 'nowrap' }}
              >
                <Avatar
                  className="fp_mrgn_rght_10"
                  size="small"
                  icon={<UserOutlined />}
                />
                <div>
                  <p className="fp_hdr_prfl_name">{`${fName} ${lName}`}</p>
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
