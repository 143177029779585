import { API } from 'aws-amplify';
import { APIS } from '../../utils/apiUtils';

export const getSecret = async (accountId: string) => {
  const { client_secret: clientSecret } = await API.get(APIS.BILLING_API, '/secret', { queryStringParameters: { accountId } });
  return clientSecret;
};

export const addServiceRecord = async (
  accountId: string, paymentType: string, paymentPlanId: string, testCaseCount: number,
) => {
  try {
    const response = await API.get(APIS.BILLING_API, '/add-service-record', {
      queryStringParameters: {
        accountId, paymentType, paymentPlanId, testCaseCount,
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error('No result found');
  }
};
