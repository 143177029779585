import React from 'react';
import { Col, Row } from 'antd';
import UserDetailsCard from './components/UserDetailsCard';
import CurrentPlanCard from './components/CurrentPlanCard';
import CardDetails from './components/CardDetails';
import PasswordResetForm from './components/PasswordResetForm';
import AccountDeletions from './components/AccountDeletions';
import { SERVICE_TYPE } from '../../types/common';

const UserProfile = () => (
  <Row className="user-profile">
    <Col className="container">
      <Row>
        <Col className="header">
          <h1>Profile</h1>
          <h4>Select a category</h4>
        </Col>
      </Row>
      <Row className="profile-con">
        <UserDetailsCard />
        <CurrentPlanCard cardService={SERVICE_TYPE.TEST_CASE_GENERATOR} />
      </Row>
      <Row className="profile-con">
        <CardDetails />
      </Row>
      <Row className="profile-con">
        <PasswordResetForm />
      </Row>
      <Row className="profile-con">
        <AccountDeletions />
      </Row>
    </Col>
  </Row>
);

export default UserProfile;
