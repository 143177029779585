import React, { useCallback } from 'react';
import {
  Button,
  Col, notification, Row,
} from 'antd';

import { RcFile } from 'antd/lib/upload';
import { CloudDownloadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { TextInfo } from '../../../components';
import { notifyError } from '../../../utils/notification';
import { downloadObjectAsJson, getContentFromAFile } from '../../../utils/fileUtils';
import { makeTestDataObject, validateTestDataFile } from '../../../utils/testDataFileUtil';
import UploadTile from '../../testcaseCreation/components/UploadFile/UploadTile';

type ScriptUploadProps = {
  setTestScriptFile: Function;
  setTestDataFile: Function;
  testScriptFile: RcFile|File|undefined;
  goBack: Function;
  goNext: Function;
  testDataFile: RcFile|File|undefined;
}

const ScriptUpload = ({
  setTestScriptFile, setTestDataFile, goBack, goNext, testScriptFile, testDataFile,
}: ScriptUploadProps) => {
  // download sample test file generated from test script
  const generateSampleTestDataFile = useCallback(async () => {
    const scriptContent = await getContentFromAFile(testScriptFile) as string;
    downloadObjectAsJson([makeTestDataObject(scriptContent)], 'SampleTestDataFile');
  }, [testScriptFile]);

  const onTestDataValidate = async (file: any) => {
    try {
      const scriptContent = await getContentFromAFile(testScriptFile) as string;
      await validateTestDataFile(file, scriptContent);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      throw error;
    }
  };

  const onFileRemove = (setFile: Function) => () => {
    setFile(undefined);
  };

  const validateAndGoNext = async () => {
    try {
      notification.destroy();
      if (!testScriptFile) {
        notifyError('Please upload a test script', 'test_script_error');
      }
      const scriptContent = await getContentFromAFile(testScriptFile) as string;
      const testDataObject = makeTestDataObject(scriptContent);
      if (!testDataFile && (_.keys(testDataObject).length > 0)) {
        notifyError('Please upload a test data file', 'test_data_error');
        return false;
      }
      if (testDataFile) {
        await validateTestDataFile(testDataFile, scriptContent);
      }
      goNext();
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <div className="fp_uplad_script_container">
      <TextInfo
        text="This page lets you select your own script to be used in the load test"
      />
      <Row>
        <Col
          xl={{ offset: 20 }}
          lg={{ offset: 18 }}
          md={{ offset: 18 }}
          xs={{ offset: 2, span: 20 }}
        >
          <Button
            data-testid="fp_next_btn"
            type="primary"
            className="fp_btn fp_btn-primary fp_navigate_btn"
            onClick={generateSampleTestDataFile}
            icon={<CloudDownloadOutlined />}
            disabled={!testScriptFile}
          >
            sample datafile
          </Button>
        </Col>
      </Row>
      <Row
        justify="center"
        gutter={[32, 32]}
        className="fp_testcase-upload"
      >
        <Col
          id="1"
          className="fp_testcase-upload-script"
        // span={8}
          xl={{ span: 8 }}
          lg={{ span: 8 }}
          md={{ span: 15 }}
          xs={{ span: 18 }}
        >
          <UploadTile
            title="Click to Upload your script"
            description="Support for a single file only."
            setFile={setTestScriptFile}
            acceptExtensions={['.ts', '.js']}
            onFileRemove={onFileRemove(setTestScriptFile)}
          />
        </Col>
        <Col
          id="2"
          className="fp_testcase-upload-data"
          xl={{ span: 8 }}
          lg={{ span: 8 }}
          md={{ span: 15 }}
          xs={{ span: 18 }}
        >
          <UploadTile
            title="Click to Upload your test data fles"
            description="Support for a single or bulk upload. Strictly prohibit from
          uploading company data or other band files"
            setFile={setTestDataFile}
            acceptExtensions={['.json', '.csv']}
            fileValidate={onTestDataValidate}
            onFileRemove={onFileRemove(setTestDataFile)}
          />
        </Col>
      </Row>
      <Row
        justify="space-between"
        className="fp_navigate_btn_container"
        gutter={[16, 16]}
      >
        <Col
          xl={{ span: 4 }}
          lg={{ span: 5 }}
          md={{ span: 6 }}
          xs={{ span: 24 }}
        >
          <Button
            data-testid="fp_previous_btn"
            type="primary"
            className="fp_btn fp_btn-primary fp_navigate_btn"
            onClick={() => goBack()}
          >
            Previous
          </Button>
        </Col>
        <Col
          xl={{ span: 4 }}
          lg={{ span: 5 }}
          md={{ span: 6 }}
          xs={{ span: 24 }}
        >
          <Button
            data-testid="fp_next_btn"
            type="primary"
            className="fp_btn fp_btn-primary fp_navigate_btn"
            onClick={validateAndGoNext}
          >
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ScriptUpload;
