/* eslint-disable no-unused-vars */
import {
  Modal,
  Row,
  Col,
  Button,
  Pagination,
  PaginationProps,
  Tooltip,
  Input,
} from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import FrontendLogList from './FrontendLogList';
import BrowserSelector from '../../components/BrowserSelector';
import { getFrontendLogs } from '../../action';
import { useAppSelector } from '../../../../redux/hooks';

interface propType {
  open: boolean
  onClose: Function
  selectedEDB: any[]
}

// eslint-disable-next-line no-shadow
enum LOG_TYPE {
  INFO = 'Info',
  WARNING = 'Warnings',
  ERRORS = 'Errors'
}

const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return <a className="nav-buttons">Previous</a>;
  }
  if (type === 'next') {
    return <a className="nav-buttons">Next</a>;
  }
  return originalElement;
};

const FrontendLogsModal = ({
  open,
  selectedEDB,
  onClose,
}: propType) => {
  const [defaultKey, setDefaultKey] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selectedLog, setSelectedLog] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [payload, setPayload] = useState<any>();
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [timeoutId, setTimeoutId] = useState(null);
  const [filters, setFilters] = useState<LOG_TYPE[]>(
    [LOG_TYPE.INFO, LOG_TYPE.WARNING, LOG_TYPE.ERRORS],
  );
  const pageSize = 10;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const accountId: string = useAppSelector(
    ({ userData }: any) => userData?.selectedAccount?.accountId,
  );
  const { monitorId, shareId } = useParams<any>();

  const handleFilterSection = (label: LOG_TYPE) => setFilters((prev:LOG_TYPE[]) => {
    if (prev.includes(label)) {
      return prev.filter((l: LOG_TYPE) => l !== label);
    }
    return [...prev, label];
  });

  const onClearClose = () => {
    onClose(false);
  };

  const fetchData = async (
    page: number, browser: string | undefined = undefined, keyword: string | undefined = undefined,
  ) => {
    setLoading(true);
    let sk = keyword;
    if (keyword === '#EMPTY#') {
      sk = undefined;
    } else if (!keyword && searchKeyword) {
      sk = searchKeyword;
    }
    const response = await getFrontendLogs(shareId, accountId, monitorId, pageSize, page, browser, searchParams.get('isGuestUrl') === 'true', sk);
    setPayload(response);
    if (!defaultKey) {
      setDefaultKey(response?.selectedBrowser);
    }
    setLoading(false);
  };

  const selectBrowser = (value: string) => fetchData(1, value);

  const selectedExecution = (log: any) => setSelectedLog(log);

  const getColor = (type: string) => {
    switch (type) {
      case 'warn':
        return 'var(--Indicator-warning-Light, #FDF38C)';
      case 'warning':
        return 'var(--Indicator-warning-Light, #FDF38C)';
      case 'info':
        return 'var(--Indicator-Info-light, #73ADFA)';
      case 'log':
        return 'var(--Indicator-Info-light, #73ADFA)';
      case 'error':
        return 'var(--Indicator-Danger-Light, #E46962)';
      default:
        return 'white';
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchData(page, payload?.selectedBrowser);
  };

  const downloadLogFile = (log: any) => {
    const currentDateTime = moment(selectedLog).format('YYYY-MM-DD_HH-mm-ss');
    const fileName = `data_${currentDateTime}.json`;
    const jsonStr = JSON.stringify(log.data, null, 2);
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const setFilterButtonStyles = (label: LOG_TYPE) => {
    const isButtonActive: boolean = filters.includes(label);
    let backgroundColor: string = 'rgba(255, 255, 255, 0.20)';
    switch (label) {
      case LOG_TYPE.INFO:
        backgroundColor = 'rgba(13, 110, 253, 0.50)';
        break;
      case LOG_TYPE.WARNING:
        backgroundColor = 'rgba(255, 185, 0, 0.50)';
        break;
      case LOG_TYPE.ERRORS:
        backgroundColor = 'rgba(231, 53, 80, 0.50)';
        break;
      default:
        break;
    }
    return { backgroundColor: isButtonActive ? backgroundColor : 'rgba(255, 255, 255, 0.20)' };
  };

  const filterLogs = (logs: any) => logs.filter(({ type }: any) => {
    switch (type) {
      case 'warn':
        return filters.includes(LOG_TYPE.WARNING);
      case 'warning':
        return filters.includes(LOG_TYPE.WARNING);
      case 'info':
        return filters.includes(LOG_TYPE.INFO);
      case 'log':
        return filters.includes(LOG_TYPE.INFO);
      case 'error':
        return filters.includes(LOG_TYPE.ERRORS);
      default:
        return false;
    }
  });

  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);
    setCurrentPage(1);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId: any = setTimeout(() => {
      fetchData(1, undefined, keyword || '#EMPTY#');
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    if (open) fetchData(currentPage);
  }, [open]);

  useEffect(() => () => {
    setSearchKeyword(undefined);
    setDefaultKey(undefined);
    setLoading(true);
    setSelectedLog(undefined);
    setCurrentPage(1);
    setPayload(undefined);
    setTimeoutId(null);
    setFilters([LOG_TYPE.INFO, LOG_TYPE.WARNING, LOG_TYPE.ERRORS]);
  }, []);

  const renderFrontEndLogs = () => {
    const filteredLogs: any[] = filterLogs(selectedLog?.data || []);
    return (
      <div className={`front-end-logs-container ${filteredLogs.length === 0 ? 'front-end-logs-container-no-data' : ''}`}>
        {filteredLogs.length ? filteredLogs.map((consoleLog: any) => (
          <Row key={`${JSON.stringify(consoleLog)}-${uuid()}`}>

            <Col span={12}>
              <Tooltip title={consoleLog?.text}>
                <p style={{ color: getColor(consoleLog.type) }}>{consoleLog?.text?.length > 60 ? `${consoleLog?.text?.slice(0, 60)}...` : consoleLog?.text}</p>
              </Tooltip>
            </Col>

            <Col span={12}>
              <a target="_blank" href={consoleLog?.location?.url} rel="noreferrer">{consoleLog.location?.url?.length > 60 ? `${consoleLog.location?.url.slice(0, 60)}...` : consoleLog.location?.url}</a>
            </Col>
          </Row>
        )) : <p>No results found</p>}
      </div>
    );
  };

  return (
    <>
      <Modal
        className="max-user-modal fp-modal fp-modal-primary close-modal-icon modal-max-width"
        width={600}
        open={open}
        closable
        maskClosable={false}
        onCancel={onClearClose}
        style={{ textAlign: 'center' }}
        footer={null}
      >
        {selectedLog ? (
          <>
            <h3 style={{ color: 'white', marginBottom: 20 }}>{`${selectedLog.resultId} - ${moment(selectedLog?.executedTime).format('MMM DD, YYYY hh:mm A')}`}</h3>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Button
                  style={{
                    alignItems: 'center',
                    border: '1px solid #844EE0',
                    background: 'var(--Solid-Primary, #844EE0)',
                    boxShadow: '0px 4px 4px 0px rgba(67, 0, 52, 0.05)',
                    borderRadius: 8,
                  }}
                  onClick={() => setSelectedLog(undefined)}
                >
                  Back
                </Button>
              </Col>
            </Row>
            <Row justify="end" style={{ marginBottom: 10 }}>
              <Col className="filter-label-text-column">
                <p>Filter :</p>
              </Col>
              {Object.values(LOG_TYPE).map((label: LOG_TYPE) => (
                <Col key={label} style={{ marginLeft: 5 }}>
                  <Button
                    key={label}
                    style={setFilterButtonStyles(label)}
                    onClick={() => handleFilterSection(label)}
                    className="fp_btn fp_btn-filter-btn"
                  >
                    {label}
                  </Button>
                </Col>
              ))}
              <Col offset={1}>
                <Button className="fp_btn fp_btn-fd-download-btn" type="primary" icon={<DownloadOutlined />} onClick={() => downloadLogFile(selectedLog)}>
                  Download
                </Button>
              </Col>
            </Row>
            {renderFrontEndLogs()}
          </>
        ) : (
          <>
            <h3 style={{ color: 'white', marginBottom: 20 }}>Frontend error logs</h3>
            <Row justify="end" style={{ marginBottom: '10px' }}>
              {(selectedEDB.length && defaultKey) && (
              // <Col span={10}>
              <BrowserSelector
                selectBrowser={selectBrowser}
                defaultKey={defaultKey}
                browserDataObject={selectedEDB
                  ?.reduce((acc: any, val: any) => ({ ...acc, [val]: val }), {})}
              />
              // </Col>
              )}
              <Col>
                <Input
                  prefix={<SearchOutlined />}
                  className="fp_monitor_job_results_log_search"
                  placeholder="Search"
                  onChange={(text) => handleSearch(text.target.value)}
                  data-testid="fp_monitor_job_results_log_search"
                  disabled={isLoading}
                />
              </Col>
            </Row>
            <FrontendLogList
              isLoading={isLoading}
              frontendLogs={payload?.consoleLogs || []}
              selectedExecution={selectedExecution}
            />
          </>
        )}
        <Row>
          <Col span={24} style={{ textAlign: 'right' }} className="pagination-container">
            {payload?.totalItemCount && !selectedLog && (
            <Pagination
              defaultCurrent={1}
              itemRender={itemRender}
              pageSize={pageSize}
              total={payload?.totalItemCount}
              current={currentPage}
              onChange={handlePageChange}
            />
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default FrontendLogsModal;
