import Icon from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { FunctionComponent, MouseEventHandler, SVGProps } from 'react';

type CreateTileProps = {
  selectType: MouseEventHandler<HTMLDivElement>;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  description: string;
  className: string;
  title: string;
}

const IconInfoTile = ({
  selectType, description, className, icon, title,
}: CreateTileProps) => (
  <Col className={className}>
    <Row className="fp_tile_icon_cntnr" onClick={selectType}>
      <Icon className="fp_tile_icon" component={icon} />
      <Row>
        <Col>
          <p>{title}</p>
        </Col>
      </Row>
    </Row>
    <Row className="fp_tile_desc">{description}</Row>
  </Col>
);

export default IconInfoTile;
