import React, { ReactElement, useEffect, useState } from 'react';
import { Modal, Button, Checkbox } from 'antd';

interface propType {
  open: boolean,
  title: string | ReactElement,
  message: string,
  onOkay: Function,
  name: string
}

const PopUpNotification = ({
  open, title, message, onOkay, name,
}: propType) => {
  const [doNotShow, setDoNotShow] = useState<boolean>(true);

  const onDoNotShowCheck = (e: any) => localStorage.setItem(name, e.target.checked ? 'ACTIVATED' : 'DISABLED');

  useEffect(() => {
    if (open && name) {
      const showPopup = localStorage.getItem(name);
      if (showPopup) {
        setDoNotShow(showPopup === 'ACTIVATED');
      } else {
        setDoNotShow(false);
      }
    }
  }, [open, name]);

  return (
    <Modal
      className="max-user-modal fp-modal fp-modal-primary fp_middle_notification_model"
      open={open && !doNotShow}
      title={title}
      onCancel={() => onOkay()}
      footer={[
        <Button
          type="primary"
          className="fp_btn fp_btn-primary"
          onClick={() => onOkay()}
        >
          Okay
        </Button>,
      ]}
    >
      <div className="form-section">
        <p style={{ marginBottom: 20 }}>{message}</p>
        <Checkbox onChange={onDoNotShowCheck}>Do not show this message again</Checkbox>
      </div>
    </Modal>
  );
};

export default PopUpNotification;
