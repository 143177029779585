/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import {
  Modal, Button, Form, Input, TreeSelect, notification,
} from 'antd';
import { createEditTestSuite, getTestCaseData } from '../action';
import { useAppSelector } from '../../../redux/hooks';
import { notifyError, notifyInProgress, notifySuccess } from '../../../utils/notification';
import { setTreeCheckableForLevel } from '../../../utils/commonUtils';

interface NewExecutionSuiteModalType {
  open: boolean
  close: Function
  handleRefresh: Function
}

const NewExecutionSuiteModal = ({
  open,
  close,
  handleRefresh,
}: NewExecutionSuiteModalType) => {
  const [testCaseData, setTestCaseData] = useState<any[]>([]);
  const accountId = useAppSelector(({ userData }: any) => userData?.selectedAccount?.accountId);

  const [form] = Form.useForm();

  const handleCancel = () => {
    close(false);
    form.resetFields();
    setTestCaseData([]);
  };

  const fetchData = async () => {
    const projects = await getTestCaseData(accountId);
    const updatedProjects = projects.map((project) => {
      const levelToMakeCheckable = 3;
      return setTreeCheckableForLevel(project, levelToMakeCheckable);
    });

    setTestCaseData(updatedProjects);
  };

  const handleOnCreate = async () => {
    notifyInProgress('Creating test suite');
    const newTestSuite = form.getFieldsValue();
    if (!newTestSuite?.name) return notifyError('Test suit name is required');
    if (!newTestSuite?.testcases) return notifyError('At least one test case is required');
    newTestSuite.testcases = newTestSuite.testcases.map((testcase: string) => JSON.parse(testcase));
    newTestSuite.accountId = accountId;
    handleCancel();
    await createEditTestSuite(newTestSuite);
    await handleRefresh();
    notification.destroy();
    return notifySuccess('Test suite created successfully');
  };

  useEffect(() => {
    if (open) fetchData();
  }, [open]);

  return (
    <Modal
      className="fp_test_execution_modal"
      closable
      open={open}
      footer={[
        <Button onClick={handleCancel} data-testid="fp_new_projec_cancel_btn" className="fp_btn fp_btn-outline">Discard</Button>,
        <Button onClick={handleOnCreate} data-testid="fp_new_project_add_btn" className="fp_btn fp_btn-primary">Create new</Button>,
      ]}
      onCancel={handleCancel}
    >
      <div>
        <div className="fp_test_execution_modal_header">
          <p>
            Create new execution suite
          </p>
        </div>
        <Form layout="vertical" form={form}>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name is required!' }]}>
            <Input placeholder="Enter regression suite name" className="fp_input" />
          </Form.Item>
          <Form.Item name="testcases" label="Select test scripts" rules={[{ required: true, message: 'Please select the test script!' }]}>
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              popupClassName="fp_test_execution_modal_treeselect"
              dropdownStyle={{
                maxHeight: 400, overflow: 'auto', background: '#4E4E4E', borderRadius: '20px',
              }}
              placeholder="Please select"
              treeCheckable
              multiple
              treeDefaultExpandAll
              treeData={testCaseData}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NewExecutionSuiteModal;
