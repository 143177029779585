import React from 'react';
import {
  Button,
  Checkbox,
  Modal,
  Row,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { EXTENSION_INSTRUCTION_MODAL_STATUS } from '../../../../../constants';
import ExtensionInstructionContent from './ExtensionInstructionContent';

interface ExtensionInstructionsModalProps {
  visible: boolean,
  onCancel: () => void,
}

const ExtensionInstructionsModal = ({ visible, onCancel }:
  ExtensionInstructionsModalProps) => {
  const onDoNotShowCheck = (e: CheckboxChangeEvent) => localStorage.setItem(EXTENSION_INSTRUCTION_MODAL_STATUS.KEY, e.target.checked ? 'ACTIVATED' : 'DISABLED');

  return (
    <Modal
      className="fp-modal fp-modal-primary fp_extension_instructions_modal"
      closable={false}
      open={visible}
      style={{ top: 50 }}
      onCancel={onCancel}
      footer={[
        <Row className="fp_extension_instructions_modal_footer" justify="center" align="middle">
          <Checkbox onChange={onDoNotShowCheck}>Don&apos;t show this again</Checkbox>
          <Button
            type="primary"
            className="fp_btn fp_btn-primary fp_extension_btn"
            onClick={onCancel}
          >
            Let&apos;s go
          </Button>
        </Row>,
      ]}
    >
      <ExtensionInstructionContent />
    </Modal>
  );
};

export default ExtensionInstructionsModal;
