import { PlusOutlined } from '@ant-design/icons';
import {
  Row, Col, Button, notification,
  Form,
} from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useAppSelector } from '../../../../../redux/hooks';
import { Project } from '../../../../../redux/projectSlice';
import AddTestcaseToTestSuitModal from '../../../../projects/project/modals/AddTestcaseToTestSuitModal';
import { notifyError, notifyInProgress, notifySuccess } from '../../../../../utils/notification';
import ExecutionDeviceSelectionModal from '../modals/ExecutionDeviceSelectionModal';
import ExecutionMonitoringModal from '../modals/ExecutionMonitoringModal';
import { createMonitorJob } from '../../../action';

type propTypes = {
  summeryData: any
  triggerTestCaseModal: Function
  isChecked: boolean
  selectedTestcases: Array<any>
};
const RequirementViewHeader = ({
  summeryData, triggerTestCaseModal, isChecked,
  selectedTestcases,
}: propTypes) => {
  const projects: Array<Project> = useAppSelector(
    ({ projectData }) => projectData.projects,
  );
  const history = useHistory();
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEDSModal, setEDSModal] = useState<boolean>(false);
  const [isEMModal, setEMModal] = useState<boolean>(false);

  const userId = useAppSelector((state: any) => state.userData.userId);
  const accountId = useAppSelector(({ userData }: any) => userData?.selectedAccount?.accountId);

  const handleAddToExecutionSuite = () => {
    notification.destroy();
    const allTestCaseHaveAS: boolean = selectedTestcases
      .every((testcase: any) => !!testcase.AutomationScript);
    if (!allTestCaseHaveAS) {
      return notifyError('Selected test cases lack automation scripts. Please assign or generate scripts before proceeding.');
    }
    return setIsModalOpen(true);
  };

  const createMonitor = async (selectedEDB: any, region: string) => {
    try {
      notifyInProgress('Creating new monitor');
      const fData = form.getFieldsValue();
      const [selectedTestCase] = selectedTestcases;

      const startDate = moment({
        year: fData.fromdate.year(),
        month: fData.fromdate.month(),
        date: fData.fromdate.date(),
        hour: fData.starttime.hours(),
        minute: fData.starttime.minutes(),
        second: fData.starttime.seconds(),
      });

      const endDate = moment({
        year: fData.todate.year(),
        month: fData.todate.month(),
        date: fData.todate.date(),
        hour: fData.starttime.hours(),
        minute: fData.starttime.minutes(),
        second: fData.starttime.seconds(),
      });

      const payload = {
        name: selectedTestCase?.TestScenario,
        repeatFrequency: fData.frequency,
        endDate,
        startDate,
        accountId,
        projectId: summeryData.requirement.projectId,
        testcaseId: selectedTestCase?.key,
        userId,
        requirementId: summeryData.requirement.requirementId,
        moduleId: summeryData.requirement.moduleId,
        selectedEDB,
        failAlertNotifier: fData?.email,
        failedNotifyFailCount: fData?.failedNotifyFailCount || 2,
        selectedRegion: region,
      };
      await createMonitorJob(payload);
      notification.destroy();
      notifySuccess('Monitor created successfully!');
    } catch (err) {
      notifyError('Failed to create new monitor');
      console.log(err);
    }
  };

  const handleMonitoring = () => {
    form.setFieldValue('failedNotifyFailCount', 2);
    setEMModal(true);
  };

  return (
    <div>
      <Row>
        <Col
          xl={{ span: 4, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          md={{ span: 4, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
        >
          <Button
            data-testid="fp_previous_btn"
            className="back_btn fp_btn-primary fp_btn"
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row>
        <Col
          style={{ textAlign: 'center' }}
          xl={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
        >
          <p className="fp_status-title-name">{summeryData?.module?.name || 'N/A'}</p>
          <p>{projects.find((project) => project.projectId === summeryData?.requirement?.projectId)?.name || 'N/A'}</p>
        </Col>
      </Row>
      <Row
        className="fp_reqrmnt_rw"
      >
        <Col
          xl={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          md={{ span: 2, offset: 2 }}
          sm={{ span: 24, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
        >
          <p>Requirement:</p>
        </Col>
        <Col
          xl={{ span: 22, offset: 0 }}
          lg={{ span: 22, offset: 0 }}
          md={{ span: 22, offset: 2 }}
          sm={{ span: 24, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
        >
          <p>{summeryData?.requirement?.requirementDescription || summeryData?.requirement?.requirement || 'N/A'}</p>
        </Col>
      </Row>
      <Row justify="end" className="fp_mrgn_tp_30">
        <Col span={24}>
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                className="fp_btn-primary fp_btn"
                data-testid="fp_previous_btn"
                icon={<PlusOutlined />}
                onClick={() => triggerTestCaseModal()}
              >
                Create New
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                className="fp_btn fp_btn-quaternary"
                disabled={!isChecked}
                onClick={() => handleMonitoring()}
              >
                Monitor
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                className="fp_btn fp_btn-quaternary"
                disabled={!isChecked}
                onClick={() => handleAddToExecutionSuite()}
              >
                Add to Execution Suite
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <AddTestcaseToTestSuitModal
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        selectedTestcases={selectedTestcases}
      />

      <ExecutionDeviceSelectionModal
        open={isEDSModal}
        onClose={setEDSModal}
        onRun={createMonitor}
        buttonName="Monitor"
      />

      <ExecutionMonitoringModal
        open={isEMModal}
        form={form}
        onClose={(flag: boolean) => {
          setEMModal(false);
          if (flag) {
            setEDSModal(true);
          }
        }}
      />
    </div>

  );
};

export default RequirementViewHeader;
