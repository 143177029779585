import { Col, Row } from 'antd';
import React from 'react';
import Loading from '../../components/Loading';
import MonitoringScheduleItem from './MonitoringItemScheduleView';

interface propType {
  isLoading: boolean
  job: any
}

const MonitoringSchedule = ({ isLoading = true, job }: propType) => (
  <div className="fp_list" style={{ marginTop: '0px' }}>
    <Row justify="start" className="fp_list_title_new">
      <Col span={4} className="column-align right-border"><p>Repeat</p></Col>
      <Col span={10} className="column-align right-border"><p>Duration</p></Col>
      <Col span={5} className="column-align right-border"><p>Last run</p></Col>
      <Col span={4} className="column-align"><p> </p></Col>
    </Row>
    {isLoading && !job ? <Loading plain size="large" /> : (
      <MonitoringScheduleItem
        job={job}
      />
    ) }
  </div>
);

export default MonitoringSchedule;
