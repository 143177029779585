/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Row, Col, Typography, Button, Input, Form, Spin,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { resetPassword } from '../actions';
import { checkGoogleAuth } from '../../../utils/checkGoogleAuth';
import { notifyError, notifySuccess } from '../../../utils/notification';

const { Title } = Typography;
type passwordFormType = {
  currentPassword: string,
  newPassword: string,
  confirmPassword: string
}

const PasswordCard = () => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [disableForm, setDisableForm] = useState<boolean>(false);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);

  const handleReset = () => {
    form.resetFields();
    setIsFormTouched(false);
  };

  const checkProvider = useCallback(async () => {
    form.isFieldsTouched(false);
    const formStatus = await checkGoogleAuth();
    setDisableForm(formStatus);
  }, []);

  useEffect(() => {
    checkProvider();
  }, [form]);

  const handleSubmit = async (values:passwordFormType) => {
    setIsSubmitting(true);
    try {
      await resetPassword(values.currentPassword, values.newPassword);
      form.resetFields();
      notifySuccess('Password reset successfully!');
    } catch (error) {
      notifyError('Failed to reset password');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Row className="bank-details-card">
      <Col span={24}>
        <Row className="card-details">
          <Col>
            <Title level={5}>Change Password</Title>
            <p>
              We recommend that you periodially update your password to help prevent unauthorized
              access to your account
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Form
                layout="vertical"
                requiredMark={false}
                className="password-con"
                form={form}
                onFinish={handleSubmit}
                disabled={disableForm}
                onChange={() => setIsFormTouched(true)}
              >
                <Col span={24}>
                  <Form.Item label="Current Password" name="currentPassword" rules={[{ required: true, message: 'Please input your password!' }]}>
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="New Password" name="newPassword" rules={[{ required: true, message: 'Please input your password!' }, { pattern: new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*[!@#$&*])(?=.*?[0-9]).{8,}$'), message: 'Your password must be a minimum of 8 characters and include at least one uppercase letter, one lowercase letter, one number, and one special character' }]}>
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Confirm Password" name="confirmPassword" rules={[{ required: true, message: 'Please input your confirm password!' }]}>
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Row className="password-btn-con">
                  <Button
                    type="primary"
                    shape="round"
                    disabled={!isFormTouched}
                    onClick={handleReset}
                  >
                    Discard
                  </Button>
                  <Button type="primary" shape="round" htmlType="submit" icon={isSubmitting ? <Spin indicator={<LoadingOutlined style={{ fontSize: 12, marginRight: 10 }} spin />} className="sign-in-spin" /> : null}>
                    Save
                  </Button>
                </Row>
                { disableForm && (
                <Row>
                  <Col>
                    <p className="card-details-danger">
                      Can not change the password because login from external provider
                    </p>
                  </Col>
                </Row>
                ) }
              </Form>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PasswordCard;
