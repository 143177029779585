import React, { ChangeEventHandler, useState } from 'react';
import {
  TabsProps, Tabs, Button, Col, Row,
} from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { TextEditor } from '../../../components';
import EditorIDE from './EditorIDE';
import ResultsView from '../../testcaseCreation/components/testCaseCreatedResult/components/ResultsView';
import renderBrowserIcon from '../../../components/Browsers';
import { convertVariableToString } from '../../../utils/commonUtils';
import { ENABLE_MONITOR_MODE } from '../../../configs/app-config';
import { getFileSignedUrl } from '../action';

interface IDETabMenuProps {
  executionData: any
  script: string
  handleOnChange: ChangeEventHandler
  disableEditMode?: boolean
  isShared?: boolean
  testcaseId?: string
}

interface Log {
  [key: string]: any;
}

interface ErrorObject {
  message: string;
  snippet?: string;
}

const extractErrors = (log: Log): ErrorObject[] => {
  const errors: ErrorObject[] = [];
  const ansiEscapePattern = '\\x1b\\[[0-9;]*m';
  const ansiEscapeRegex = new RegExp(ansiEscapePattern, 'g');

  // Function to recursively search for errors in nested objects
  function findErrors(obj: Log): void {
    Object.entries(obj).forEach(([, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          if (Object.prototype.hasOwnProperty.call(item, 'errors') && Array.isArray(item.errors) && item.errors.length > 0) {
            item.errors.forEach((error: any) => {
              if ('message' in error) {
                const errorObject: ErrorObject = { message: error.message.replace(ansiEscapeRegex, '') };
                if (error.location && 'file' in error.location && 'line' in error.location) {
                  errorObject.snippet = `Error in ${error.location.file} at line ${error.location.line}`;
                  if ('column' in error.location) {
                    errorObject.snippet += `, column ${error.location.column}`;
                  }
                }
                errors.push(errorObject);
              }
            });
          }
          findErrors(item);
        });
      } else if (typeof value === 'object' && value !== null) {
        findErrors(value);
      }
    });
  }

  findErrors(log);
  if (!errors.length && log?.errors[0]?.message) {
    const errorObject: ErrorObject = { message: log?.errors[0]?.message?.replace(ansiEscapeRegex, '') };
    errors.push(errorObject);
  }
  return errors;
};

const IDETabMenu = ({
  executionData,
  script,
  handleOnChange,
  disableEditMode = false,
  isShared = false,
  testcaseId = undefined,
}: IDETabMenuProps) => {
  const { monitorId } = useParams<any>();
  const [downloadFileName, setDownloadFileName] = useState<string[]>([]);
  const downloadFile = (data: string, filename: string) => {
    console.log('data :', data);
    const blob = new Blob([data], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const renderBrowserStatus = (browsers: any[]) => browsers
    ?.filter(({ isLogAvailable }: any) => isLogAvailable)
    ?.map((browser: any, index: number) => (
      <div
        className={`browser-result ${browser?.result?.expectedStatus === browser?.result?.actualStatus ? 'browser-result-pass' : 'browser-result-failed'}`}
        key={browser}
        style={index > 0 ? { marginLeft: 20 } : {}}
      >
        {renderBrowserIcon(browser?.key)}
        <div style={{ width: '100%', textAlign: 'center' }}>
          {convertVariableToString(browser?.result?.actualStatus)}
        </div>
      </div>
    ));

  const getExecutionErrors = (error: string | any[]) => {
    if (typeof error === 'string') {
      return (
        <TextEditor
          value={error}
          language="bash"
          padding={15}
          disabled
        />
      );
    }
    const filteredErrors = error?.filter(({ message }: any) => message !== 'No tests found');
    return filteredErrors?.map((err: any) => (
      (
        <>
          <br />
          {err?.message
            && (
              <TextEditor
                value={err?.message}
                language="bash"
                padding={15}
                disabled
              />
            )}
          {!err.message && (
            <>
              <TextEditor
                value={err?.snippet}
                language="bash"
                padding={15}
                disabled
              />
              <br />
            </>
          )}
        </>
      )
    ));
  };

  const items: TabsProps['items'] = (isShared || ENABLE_MONITOR_MODE) ? [] : [
    {
      key: '1',
      label: disableEditMode ? 'Test Script' : 'Edit',
      children: (
        <div>
          {disableEditMode && (
            <>
              {executionData?.results?.length && (
              <div className="browser-results-container" style={{ display: 'flex', flexDirection: 'row' }}>
                {renderBrowserStatus(executionData?.results[0]?.browsers || [])}
              </div>
              )}
              <br />
            </>
          )}
          <EditorIDE
            content={script}
            handleOnChange={handleOnChange}
            disabled={disableEditMode}
          />
          {getExecutionErrors(extractErrors(executionData?.log))}
        </div>
      ),
    },
  ];

  if (executionData?.log) {
    items.push({
      key: '2',
      label: 'Execution Log',
      children:
  <>
    <br />
    <TextEditor
      value={JSON.stringify(executionData?.log || {}, null, 4)}
      language="json"
      padding={15}
      disabled
    />
  </>,
    });
  }

  if (executionData?.results) {
    items.push({
      key: '3',
      label: 'Results',
      children: <ResultsView
        disableEditMode={disableEditMode}
        results={executionData?.results || []}
      />,
    });
  }

  const handleFileDownload = async (download: any) => {
    setDownloadFileName((prev: string[]) => [...prev, download.filename]);
    if (download.filename === 'execution-log.json') {
      const content = JSON.stringify(executionData?.log || {}, null, 4);
      downloadFile(content, download.filename);
    } else {
      const payload = {
        testcaseId,
        executionId: executionData?.execution?.executionId,
        monitorId,
        filename: download.s3FileName,
      };
      const url = await getFileSignedUrl(payload);
      const content: any = await axios.get(url, { responseType: 'blob' });
      downloadFile(content?.data, download.filename);
    }
    setDownloadFileName((prev: string[]) => prev
      .filter((filename: string) => filename !== download.filename));
  };

  const isDownload = (filename: string) => !!downloadFileName
    .find((f: string) => f === filename);

  if (executionData?.downloads?.length) {
    items.push({
      key: '4',
      label: 'Downloads',
      children: (
        <div>
          <Row gutter={[16, 16]}>
            {executionData?.downloads?.map((download: any, index: number) => (
              <Col offset={index === 0 ? 0 : 1} key={JSON.stringify(download)}>
                <Button
                  className="fp_btn fp_btn-download-btn"
                  type="primary"
                  loading={isDownload(download.filename)}
                  disabled={isDownload(download.filename)}
                  icon={<FileTextOutlined />}
                  onClick={() => handleFileDownload(download)}
                >
                  {isDownload(download.filename) ? 'Downloading...' : download.label}
                </Button>
              </Col>
            ))}
          </Row>
        </div>),
    });
  }

  return (
    <Tabs defaultActiveKey="1" items={items} />
  );
};

export default IDETabMenu;
