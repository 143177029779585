import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Define a type for the slice state

type UserDataType = {
  fName?: string;
  lName?: string;
  emailVerified?: boolean;
}

export type TeamType = {
  teamId: string;
  teamName: string;
  role: string;
}

export type AccountType = {
  active: any;
  accountId: string;
  accountName: string;
  role: string;
  teams: Array<TeamType>;
  createdBy: string;
  topUpBalance?: number;
}

interface UserDataState {
  userId?: string;
  email?: string;
  userData: UserDataType;
  accounts?: Array<AccountType>;
  selectedAccount?: AccountType;
  selectedTeam?: TeamType;
}

// extra init team filter options for all and no-team
export const extraTeams: Array<TeamType> = [
  {
    teamId: 'all',
    teamName: 'All',
    role: '',
  },
  {
    teamId: 'no-team',
    teamName: 'No-Team',
    role: '',
  },
];

// Define the initial state using that type
const initialState: UserDataState = {
  userId: undefined,
  email: undefined,
  userData: {},
  accounts: undefined,
  selectedAccount: undefined,
  selectedTeam: extraTeams[0],
};

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUser: (state, action: PayloadAction<Object>) => {
      state.userData = action.payload;
    },
    setAccounts: (state, action: PayloadAction<Array<AccountType>>) => {
      state.accounts = action.payload;
    },
    setSelectedAccount: (state, action: PayloadAction<AccountType | undefined>) => {
      state.selectedAccount = action.payload;
    },
    setSelectedTeam: (state, action: PayloadAction<TeamType | undefined>) => {
      state.selectedTeam = action.payload;
    },
    storeLogout: () => { },
  },
});

export const {
  setUserId, setEmail, setUser, setAccounts, setSelectedAccount, setSelectedTeam, storeLogout,
} = userDataSlice.actions;

export default userDataSlice.reducer;
