import React from 'react';

const NewLoader = () => (
  <div className="new-loader">
    <div className="new-loader-bar bar-1" />
    <div className="new-loader-bar bar-2" />
    <div className="new-loader-bar bar-3" />
    <div className="new-loader-bar bar-4" />
  </div>
);

export default NewLoader;
