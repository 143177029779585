import React, { FC } from 'react';
import {
  Row, Col, Tag, Button,
} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

interface UserDetailProps {
  fName?: string;
  lName?: string;
  email?: string;
  accountName?: string[];
  isAccountActive?: boolean;
  logOut: () => void;
}

const UserDetailPopUp: FC<UserDetailProps> = ({
  fName,
  lName,
  email,
  accountName,
  isAccountActive,
  logOut,
}: UserDetailProps) => (
  <div>
    <Row style={{ margin: '20px 0' }} justify="space-between" className="fp_list_item">
      <Col span={12}><p>Name</p></Col>
      <Col span={12}><p>{`${fName} ${lName}`}</p></Col>
    </Row>
    <Row style={{ margin: '20px 0' }} justify="space-between" className="fp_list_item">
      <Col span={12}><p>Email</p></Col>
      <Col span={12}><p>{email}</p></Col>
    </Row>
    <Row style={{ margin: '20px 0' }} justify="space-between" className="fp_list_item">
      <Col span={12}><p>Account Name</p></Col>
      <Col span={12}><p>{accountName}</p></Col>
    </Row>
    <Row style={{ margin: '20px 0' }} justify="space-between" className="fp_list_item">
      <Col span={12}><p>Account Status</p></Col>
      <Col span={12}>
        {isAccountActive
          ? (
            <Tag
              style={{ borderRadius: 10 }}
              icon={<CheckCircleOutlined />}
              color="#22BB33"
            >
              Active
            </Tag>
          )
          : <Tag icon={<CloseCircleOutlined />} color="#f50">Inactive</Tag>}
      </Col>
    </Row>
    <Row style={{ display: 'grid' }}>
      <Button
        onClick={logOut}
        type="primary"
        className="fp_btn fp_btn-primary"
        style={{ width: '100%', margin: 'auto' }}
      >
        Logout
      </Button>
    </Row>
  </div>
);

export default UserDetailPopUp;
