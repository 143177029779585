import { API } from 'aws-amplify';
import { RcFile } from 'antd/lib/upload';
import axios from 'axios';
import { APIS } from '../../utils/apiUtils';
import { TestCaseInput, UpdatedTestCase } from '../../redux/testCaseSlice';

export const createTestCase = async (testCase: TestCaseInput) => {
  await API.post(APIS.TEST_CASE_API, '/create', { body: testCase });
};

export const updateTestCase = async (updatedTestCase: UpdatedTestCase) => {
  await API.post(APIS.TEST_CASE_API, '/update', { body: updatedTestCase });
};

export const getTestCaseSummary = async (
  accountId: string | undefined, projectId: string, testcaseId: string,
) => {
  const { data = {} } = await API.get(APIS.TEST_CASE_API, '/get-test-case-summary', { queryStringParameters: { accountId, projectId, testcaseId } });
  return data;
};

export const getSignedUrl = async (key: string, contentType = '', method = 'getObject') => {
  const { body } = await API.get(APIS.TEST_CASE_API, '/get-signed-url', { queryStringParameters: { key, contentType, method } });
  return body.url;
};

export async function uploadFile(url: string, file: any|RcFile|File, contentType: string) {
  await axios.put(url,
    file,
    {
      headers: {
        'Content-Type': contentType,
      },
    });
}

export const runTestCase = async (params: any): Promise<boolean> => {
  try {
    return await API.post(APIS.EXECUTION_API, '/place-execution', { body: params });
  } catch (error: any) {
    console.log('error: ', error.message);
    return false;
  }
};

export const getTestCaseResults = async (
  accountId: string | undefined,
  testcaseId: string,
  executionId: string,
) => {
  const { data } = await API.get(APIS.TEST_CASE_API, '/get-results', { queryStringParameters: { accountId, testcaseId, executionId } });
  return data;
};

export const getLogContent = async (url: string) => {
  const response = await fetch(url).then((res) => res.json());
  return response;
};

export const deleteTestCase = async (
  requirementId: string,
  moduleId: string,
  testcaseId: string,
): Promise<boolean> => {
  try {
    const response = await API.del(APIS.TESTCASE_CREATING_API, '/testcase/delete', { queryStringParameters: { requirementId, moduleId, testcaseId } });
    return response;
  } catch (error) {
    console.error(`Error deleting test case with ID ${testcaseId} for requirement ${requirementId}: `, error);
    throw new Error(`Error deleting test case with ID ${testcaseId} for requirement ${requirementId}`);
  }
};

export const editTestCase = async (
  requirementId: string,
  testcaseId: string,
  updatedTestCase: UpdatedTestCase,
): Promise<boolean> => {
  try {
    const response = await API.post(APIS.TESTCASE_CREATING_API, '/testcase/edit', {
      queryStringParameters: { requirementId, testcaseId },
      body: updatedTestCase,
    });
    return response;
  } catch (error) {
    console.error(`Error editing test case with ID ${testcaseId} for requirement ${requirementId}: `, error);
    throw new Error(`Error editing test case with ID ${testcaseId} for requirement ${requirementId}`);
  }
};

export const addTestCase = async (
  requirementId: string,
  moduleId: string,
  testcase: TestCaseInput,
): Promise<TestCaseInput> => {
  try {
    const response = await API.post(APIS.TESTCASE_CREATING_API, '/testcase/create', {
      queryStringParameters: { requirementId, moduleId },
      body: testcase,
    });
    return response;
  } catch (error) {
    console.error(`Error creating test case for requirement ${requirementId}: `, error);
    throw new Error(`Error creating test case for requirement ${requirementId}`);
  }
};
