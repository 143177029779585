import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userDataReducer, { extraTeams } from './userSlice';
import projectDataReducer from './projectSlice';
import testCaseDataSlice from './testCaseSlice';
import billedPlanDataSlice from './billedPlanSlice';
import serviceDataSlice from './serviceSlice';

const combinedReducer = combineReducers({
  userData: userDataReducer,
  projectData: projectDataReducer,
  testCaseData: testCaseDataSlice,
  billedPlanData: billedPlanDataSlice,
  serviceData: serviceDataSlice,
});

const rootReducer = (state, action) => {
  let mutableState = state;
  if (action.type === 'userData/storeLogout') {
    mutableState = { userData: { userId: null, selectedTeam: extraTeams[0] } };
  }
  return combinedReducer(mutableState, action);
};

const store = configureStore({
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
