import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, notification } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { ROUTES, TESTCASE_CREATE_VIEW_TYPE, TESTCASE_TYPE } from '../../constants';
import NewTestcaseInitView from './NewTestcaseInitView';
import ManualScriptFlowView from './manualScript/ManualScriptFlowView';
import RecordFlowView from './Record/RecordFlowView';
import {
  createTestCase, getSignedUrl, runTestCase, uploadFile,
} from './action';
import { useAppSelector } from '../../redux/hooks';
import { notifyError, notifyInProgress, notifySuccess } from '../../utils/notification';
import DryRunModal from './manualScript/DryRunModal';

type NewTestcaseRouteParams = {
  projectId: string;
  testcaseId: string;
}

const NewTestcase = () => {
  const { projectId, testcaseId } = useParams<NewTestcaseRouteParams>();
  const selectedAccount = useAppSelector(({ userData }: any) => userData.selectedAccount);
  const userId = useAppSelector(({ userData }) => userData.userId);
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [testCaseType, setTestcaseType] = useState<string>(TESTCASE_TYPE.PLAYWRITE);
  const [form] = Form.useForm();
  const [testScriptFile, setTestScriptFile] = useState<RcFile|File|undefined>(undefined);
  const [testDataFile, setTestDataFile] = useState<RcFile|undefined>(undefined);
  const [
    testcaseCreateView,
    setTestcaseCreateView,
  ] = useState<string>(TESTCASE_CREATE_VIEW_TYPE.INIT);
  const [recordText, setRecordText] = useState<string>('');
  const [isTestcaseSaved, setTestcaseSaved] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isDryRunComplete, setDryRunComplete] = useState<boolean>(false);
  const [isTestCaseEdited, setTestCaseEdited] = useState<boolean>(false);

  const goNext = () => setCurrentStep(currentStep + 1);
  const goBack = () => setCurrentStep(currentStep - 1);

  const generateS3key = (file: RcFile|File|undefined) => (file ? `${selectedAccount.accountId}/${projectId}/${testcaseId}/${file.name}` : '');

  const exit = () => history.goBack();
  const resultView = () => history.push(`${ROUTES.VIEW_RESULTS}/${projectId}/${testcaseId}`);

  const clearForm = () => {
    setCurrentStep(0);
    setTestcaseType('');
    setTestScriptFile(undefined);
    setTestDataFile(undefined);
    setTestcaseCreateView(TESTCASE_CREATE_VIEW_TYPE.INIT);
    setRecordText('');
    form.resetFields();
  };

  const onSaveTestCase = async () => {
    try {
      if (!testScriptFile || testScriptFile.name === 'undefined') {
        throw new Error(JSON.stringify({ testScriptFile, recordText }));
      }
      if (!isTestcaseSaved || isTestCaseEdited) {
        notifyInProgress('Creating Test case', 'create_testcase');
        const scriptUploadKey = generateS3key(testScriptFile);
        const testDataUploadKey = generateS3key(testDataFile);

        const [scriptUploadSignUrl, testDataUploadSignUrl] = await Promise.all([
          getSignedUrl(scriptUploadKey, 'text/javascript', 'putObject'),
          testDataFile && getSignedUrl(testDataUploadKey, 'application/json', 'putObject'),
        ]);

        await Promise.all([
          testScriptFile && uploadFile(scriptUploadSignUrl, testScriptFile, 'text/javascript'),
          testDataUploadSignUrl && testDataFile && uploadFile(testDataUploadSignUrl, testDataFile, 'application/json'),
        ]);
        if (isTestCaseEdited) setTestCaseEdited(false);
      }

      const testcase = {
        projectId,
        testcaseId,
        type: testCaseType,
        name: form.getFieldValue('name'),
        accountId: selectedAccount ? selectedAccount.accountId : '',
        script: testScriptFile ? testScriptFile.name : '',
        testData: testDataFile ? testDataFile.name : '',
        thresholds: form.getFieldsValue(),
      };

      await createTestCase(testcase);
      setTestcaseSaved(true);
      notification.destroy();
      if (!isTestcaseSaved) notifySuccess('Test case created successfully', 'create_testcase_success');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      notification.destroy();
      notifyError('Error occurred', 'create_testcase_error');
    }
  };

  const onRunTestcase = async (isDryRun: boolean = false) => {
    if (isDryRun && isDryRunComplete) {
      setModalOpen(true);
      return;
    }
    await onSaveTestCase();

    notifyInProgress('Test case running...', 'testcase_run');
    const params = {
      projectId,
      testcaseId,
      userId,
      isDryRun,
    };
    await runTestCase(params);
    if (!isDryRun) {
      clearForm();
    } else {
      setModalOpen(true);
    }
    notification.destroy();
  };

  useMemo(() => {
    if (testcaseId) {
      form.setFieldValue('name', `Test case ${testcaseId}`);
    }
  }, [testcaseId]);

  const renderView = () => {
    switch (testcaseCreateView) {
      case TESTCASE_CREATE_VIEW_TYPE.INIT:
        return <NewTestcaseInitView selectCreateType={setTestcaseCreateView} />;

      case TESTCASE_CREATE_VIEW_TYPE.OWN_SCRIPT:
        return (
          <ManualScriptFlowView
            goNext={goNext}
            goBack={goBack}
            current={currentStep}
            setTestcaseCreateView={setTestcaseCreateView}
            testCaseType={testCaseType}
            setTestCaseType={setTestcaseType}
            testCaseSettings={form}
            setTestScriptFile={setTestScriptFile}
            setTestDataFile={setTestDataFile}
            testScriptFile={testScriptFile}
            onSaveTestCase={onSaveTestCase}
            onRunTestcase={onRunTestcase}
            setTestCaseEdited={setTestCaseEdited}
            exit={exit}
            resultView={resultView}
            isDryRunComplete={isDryRunComplete}
            testDataFile={testDataFile}
          />
        );

      case TESTCASE_CREATE_VIEW_TYPE.RECORD:
        return (
          <RecordFlowView
            goNext={goNext}
            goBack={goBack}
            current={currentStep}
            testCaseSettings={form}
            onSaveTestCase={onSaveTestCase}
            onRunTestcase={onRunTestcase}
            setTestCaseEdited={setTestCaseEdited}
            exit={exit}
            setTestScriptFile={setTestScriptFile}
            setTestcaseCreateView={setTestcaseCreateView}
            setRecordText={setRecordText}
            recordText={recordText}
            resultView={resultView}
            isDryRunComplete={isDryRunComplete}
            testScriptFile={testScriptFile}
            setTestDataFile={setTestDataFile}
          />
        );

      default:
        return <div>empty</div>;
    }
  };

  return (
    <>
      {renderView()}
      <DryRunModal
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
        projectId={projectId}
        testcaseId={testcaseId}
        setDryRunComplete={setDryRunComplete}
        isDryRunComplete={isDryRunComplete}
      />
    </>
  );
};

export default NewTestcase;
