import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import { ROUTES } from '../../../constants';
import { getFormattedDateTime } from '../../../utils/commonUtils';
import DeleteConfirmationModal from '../../testExecutionSuit/modals/DeleteConfirmationModal';
import { TrashCanIcon } from '../../../components';

interface GeneratedTestcaseModuleListViewItemProps {
  module: any
  deleteModule: Function
}
const GeneratedTestcaseModuleListViewItem = ({
  module,
  deleteModule,
} : GeneratedTestcaseModuleListViewItemProps) => {
  const location: any = useLocation();
  const { projectId }: { projectId: undefined|string } = useParams();
  const [deleteScript, setDeleteScript] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const testCaseRender = () => {
    history.push(`${ROUTES.PROJECTS}/${projectId}/${module.moduleId}`, { moduleId: module.moduleId, moduleName: module.name, projectName: location?.state?.projectName });
  };
  return (
    <div className="fp_test_exec_list_item">
      <Row justify="space-between" className="fp_list_item">
        <Col span={8} onClick={() => testCaseRender()}>
          <Row
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Col span={24}>
              <p className="fp_testcase_name">{module.name}</p>
            </Col>
          </Row>
        </Col>
        <Col span={6} onClick={() => testCaseRender()}>
          <Row>
            <Col span={18}>
              <p>{getFormattedDateTime(module.createdAt)}</p>
            </Col>
          </Row>
        </Col>

        <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Row className="fp_test_exec_list_item_action">
            <Col>
              {deleteScript && <Button onClick={() => setModalOpen(true)} type="primary" icon={deleteScript && <TrashCanIcon />} className="fp_btn fp_btn-primary btn_delete">Delete Module</Button>}
            </Col>
            <Col>
              <p><MoreOutlined onClick={() => setDeleteScript(!deleteScript)} style={{ fontSize: '20px' }} /></p>
            </Col>
          </Row>
        </Col>
      </Row>

      <DeleteConfirmationModal
        title="Are you sure you want to delete this module?"
        deleteButtonText="Delete Module"
        isModalOpen={isModalOpen}
        handleCancel={() => setModalOpen(false)}
        handleDelete={() => {
          deleteModule(module?.moduleId);
          setModalOpen(false);
        }}
        isLoading={false}
      />
    </div>
  );
};

export default GeneratedTestcaseModuleListViewItem;
