import React, { useState } from 'react';
import {
  Button, Col, Row,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { TrashCanIcon } from '../../../components';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';
import StatusView from '../../testcases/result/components/StatusView';
import { renderText } from '../../../utils/commonUtils';

interface propType {
  testcase: any
  deleteTestCase: Function
  selectTestCase: Function
}

const GeneratedTestSuitListViewItem = (
  { testcase, deleteTestCase, selectTestCase }: propType,
) => {
  const [deleteScript, setDeleteScript] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const renderExecutionStatus = () => {
    if (testcase?.executionStatus) {
      return (
        <div className="fp_testcase_test_status">
          <StatusView
            status={testcase?.executionStatus}
            executionResults={[]}
            passPercentage={String(Math.round(testcase?.passPercentage * 100) / 100 || 0)}
            disableRunningAnimation
          />
        </div>
      );
    }
    return '-';
  };

  const viewAutomationScript = () => {
    selectTestCase(testcase.testcaseId);
  };

  return (
    <div className="fp_test_exec_list_item" key={JSON.stringify(testcase)}>
      <Row justify="space-between" className="fp_list_item">
        <Col span={8} className="fp_list_item_module">{renderText(testcase.name, 60)}</Col>
        <Col span={6} className="fp_list_item_module">
          <div>{renderText(testcase?.projectName)}</div>
          <span>/</span>
          <div>{renderText(testcase?.moduleName)}</div>
        </Col>
        <Col span={3} className="fp_list_item_result"><p>{renderExecutionStatus()}</p></Col>
        <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Row className="fp_test_exec_list_item_action">
            <Col>
              <Button onClick={deleteScript ? () => setModalOpen(true) : viewAutomationScript} type="primary" icon={deleteScript && <TrashCanIcon />} className={`fp_btn fp_btn-primary ${deleteScript ? 'btn_delete' : 'btn_run'}`}>{!deleteScript ? 'View' : 'Delete script'}</Button>
            </Col>
            <Col>
              <p><MoreOutlined onClick={() => setDeleteScript(!deleteScript)} style={{ fontSize: '20px' }} /></p>
            </Col>
          </Row>
        </Col>
      </Row>
      <DeleteConfirmationModal
        title="Are you sure you want to delete this test script?"
        deleteButtonText="Delete script"
        isModalOpen={isModalOpen}
        handleCancel={() => setModalOpen(false)}
        handleDelete={() => deleteTestCase(testcase.testcaseId)}
        isLoading={false}
      />
    </div>
  );
};

export default GeneratedTestSuitListViewItem;
