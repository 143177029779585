import {
  CheckOutlined, CloseOutlined, CloudDownloadOutlined, CloudUploadOutlined,
  InfoCircleFilled,
  RightOutlined,
} from '@ant-design/icons';
import {
  Button, Col, Row, Switch, Upload, UploadProps,
  Image,
  notification,
} from 'antd';
import React, { useState } from 'react';
import { downloadObjectAsJson } from '../../../utils/fileUtils';
import { generateFile, manipulateRecordData } from '../../../utils/generateTestScript';
import { makeTestDataObject, validateTestDataFile } from '../../../utils/testDataFileUtil';
import step1 from '../../../styles/assets/InputRecords/1.svg';
import step2 from '../../../styles/assets/InputRecords/2.svg';
import step3 from '../../../styles/assets/InputRecords/3.svg';
import step4 from '../../../styles/assets/InputRecords/4.svg';
import step5 from '../../../styles/assets/InputRecords/5.svg';
import step6 from '../../../styles/assets/InputRecords/6.svg';
import step7 from '../../../styles/assets/InputRecords/7.svg';
import step8 from '../../../styles/assets/InputRecords/8.svg';
import { TextEditor } from '../../../components';

type TypeSelectProps = {
  goBack: Function;
  setTestScriptFile: Function;
  goNext: Function;
  recordText: string;
  setRecordText: Function;
  setTestDataFile: Function;
}

const InputRecord = ({
  goBack, goNext, setTestScriptFile, recordText, setRecordText, setTestDataFile,
}: TypeSelectProps) => {
  const [scriptContent, setScriptContent] = useState<any>('');
  const [parameterize, setParameterize] = useState<any>(false);
  const [dataFile, setDataFile] = useState<any>(undefined);

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    accept: '.json, .csv',
    action: '',
    async beforeUpload(file: any) {
      try {
        await validateTestDataFile(file, scriptContent);
        setDataFile(file);
        return false;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return Upload.LIST_IGNORE;
      }
    },
  };

  const createScriptAndGoNextStep = async () => {
    try {
      notification.destroy();
      let finalScriptContent = '';
      if (!scriptContent) {
        finalScriptContent = manipulateRecordData(recordText, true, parameterize) as string;
      }
      const scriptFile = generateFile(scriptContent || finalScriptContent, 'text/javascript', 'loadtesterScript.spec.js');
      setTestScriptFile(scriptFile);
      if (parameterize) {
        if (dataFile) {
          await validateTestDataFile(dataFile, scriptContent || finalScriptContent);
          setTestDataFile(dataFile);
        } else {
          const testDataString = JSON.stringify(
            makeTestDataObject(scriptContent || finalScriptContent), null, 4,
          );
          const testDataFile = generateFile(testDataString, 'application/json', 'testData.json');
          setTestDataFile(testDataFile);
        }
      }
      goNext();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const steps = [
    {
      title: 'Step 1',
      image: step1,
      description: 'Install Footprint extension from chrome web store Footprint',
    },
    {
      title: 'Step 2',
      image: step2,
      description: 'Load your website',
    },
    {
      title: 'Step 3',
      image: step3,
      description: 'Click on the footprint extension',
    },
    {
      title: 'Step 4',
      image: step4,
      description: 'Click on Start recording button',
    },
    {
      title: 'Step 5',
      image: step5,
      description: 'Do your test actions',
    },
    {
      title: 'Step 6',
      image: step6,
      description: 'Click on Stop recording button',
    },
    {
      title: 'Step 7',
      image: step7,
      description: 'Click on Copy button',
    },
    {
      title: 'Step 8',
      image: step8,
      description: 'Come back to this screen and click Paste button',
    },
  ];

  return (
    <div className="fp_type_select_container">
      <Row
        justify="center"
        className="fp_textinfo_container"
      >
        <Col span={22} style={{ fontWeight: 600 }}>
          <Row>
            <Col span={1} className="fp_textinfo_icon">
              <InfoCircleFilled style={{ color: '#93DEFF', fontSize: 20 }} />
            </Col>
            <p className="fp_textinfo_title">Generating the script though our record and play option</p>
          </Row>
          <Row>
            {steps.map((step) => (
              <Col span={3} key={step.id} xs={{ span: 6 }}>
                <p className="fp_textinfo_rcrd">{step.title}</p>
                <div className="ft_textinfo_col">
                  <Image src={step.image} />
                </div>
                <p className="fp_textinfo_rcrd">{step.description}</p>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row
        justify="center"
        className="fp_input_record_btn_container"
      >
        <Col
          span={24}
        >
          <p className="fp_textinfo_ttl">
            Click the generate button to convert recording data into a test script
          </p>
        </Col>
      </Row>
      <Row
        justify="space-between"
        className="fp_testcase_input_record_container"
        gutter={[16, 16]}
      >
        <Col
          xl={{ span: 9 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row>
            <TextEditor
              value={recordText}
              onChange={(e) => setRecordText(e.target.value)}
              padding={15}
              language="pug"
              style={{
                width: '100%',
                height: '420px',
              }}
            />
          </Row>
          <Row className="fp_pst_btn_rw">
            <Col
              xl={{ span: 4 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Button
                data-testid="fp_previous_btn"
                type="primary"
                className="fp_btn fp_btn-primary fp_navigate_btn fp_responsive_btn"
                onClick={() => {
                  navigator.clipboard.readText().then((text) => setRecordText(text));
                }}
              >
                Paste
              </Button>
            </Col>
          </Row>
        </Col>
        <Col
          xl={{ span: 4 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
          className="fp_navigate_gnrt_btn"
        >
          <Button
            data-testid="fp_next_btn"
            type="primary"
            className="fp_btn fp_btn-primary fp_navigate_btn fp_responsive_btn"
            onClick={() => {
              setScriptContent(manipulateRecordData(recordText, true, parameterize));
            }}
          >
            Generate
            {' '}
            <RightOutlined />
          </Button>
        </Col>
        <Col
          xl={{ span: 11 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row>
            <TextEditor
              value={scriptContent}
              readOnly
              padding={15}
              language="javascript"
              style={{
                width: '100%',
                height: '420px',
              }}
            />
          </Row>
          <Row style={{ paddingTop: '20px' }} className="fp_btn_wrap" gutter={[16, 16]}>
            <Col
              xl={{ span: 10, offset: 0 }}
              lg={{ span: 11, offset: 1 }}
              md={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
            // style={{ display: 'flex', alignItems: 'center' }}
            >
              <Row style={{ display: 'flex', flexFlow: 'nowrap' }}>
                <Switch
                  className="fp_switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(checked: boolean) => {
                    setParameterize(checked);
                    setScriptContent(manipulateRecordData(recordText, true, checked));
                  }}
                  disabled={!scriptContent}
                />
                <p style={{ marginLeft: 10, whiteSpace: 'nowrap' }}>convert to parameters</p>
              </Row>
            </Col>
            <Col
              xl={{ span: 7 }}
              lg={{ span: 5 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Button
                data-testid="fp_next_btn"
                type="primary"
                className="fp_btn fp_btn-primary fp_navigate_btn fp_responsive_btn"
                onClick={() => { downloadObjectAsJson([makeTestDataObject(scriptContent)], 'SampleTestDataFile'); }}
                icon={<CloudDownloadOutlined />}
                disabled={!parameterize}
              >
                sample dataFile
              </Button>
            </Col>
            <Col
              xl={{ span: 6 }}
              lg={{ span: 6 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Upload {...uploadProps} className="fp_responsive_btn">
                <Button
                  data-testid="fp_next_btn"
                  style={{ background: 'FFAA00' }}
                  type="primary"
                  className="fp_btn fp_navigate_btn fp_responsive_btn red_btn"
                  icon={<CloudUploadOutlined />}
                  disabled={!parameterize}
                >
                  upload dataFile
                </Button>
              </Upload>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        justify="space-between"
        className="fp_navigate_btn_container"
        gutter={[16, 16]}
      >
        <Col
          xl={{ span: 4 }}
          lg={{ span: 5 }}
          md={{ span: 6 }}
          xs={{ span: 24 }}
        >
          <Button
            data-testid="fp_previous_btn"
            type="primary"
            className="fp_btn fp_btn-primary fp_navigate_btn"
            onClick={() => goBack()}
          >
            Previous
          </Button>
        </Col>
        <Col
          xl={{ span: 4 }}
          lg={{ span: 5 }}
          md={{ span: 6 }}
          xs={{ span: 24 }}
        >
          <Button
            data-testid="fp_next_btn"
            type="primary"
            className="fp_btn fp_btn-primary fp_navigate_btn"
            onClick={createScriptAndGoNextStep}
          >
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default InputRecord;
