/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import {
  Col, Row, Input, Button, Breadcrumb,
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import Loading from '../../components/Loading';
import TestExecutionListView from './components/TestExecutionListView';
import NewExecutionSuiteModal from './modals/NewExecutionSuiteModal';
import { useAppSelector } from '../../redux/hooks';
import { notifyError } from '../../utils/notification';
import { getTestSuites, runTestSuite } from './action';
import ExecutionDeviceSelectionModal from '../testcaseCreation/components/testCaseCreatedResult/modals/ExecutionDeviceSelectionModal';
import { ENTRY_TYPE, SERVICE } from '../../types/common';
import { EXECUTION_RESULT } from '../../constants';
import GuideModal from '../../components/modals/GuideModal';
import ExecutionApproachSelectionModal from './components/ExecutionApproachSelectionModal';

const TestExecutionSuit = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const accountId = useAppSelector(({ userData }: any) => userData?.selectedAccount?.accountId);

  const autoRefreshIntervalRef = useRef<any>(null);

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [testSuites, setTestSuites] = useState<any[]>([]);
  const [searchedTestSuites, setSearchedTestSuites] = useState<any[]>([]);
  const [isEDSModal, setEDSModal] = useState<boolean>(false);
  const [selectedTestSuite, setSelectedTestSuite] = useState<any>();
  const [autoRefreshList, setAutoRefreshList] = useState<string[]>([]);
  const [isGuideModalOpen, setGuideModalOpen] = useState<boolean>(true);
  const [isEASModal, setEASModal] = useState<boolean>(false);
  const [isParallelRun, setParallelRun] = useState<boolean | undefined>();
  const userId = useAppSelector((state) => state.userData.userId);

  const fetchData = async () => {
    const tsData = await getTestSuites(accountId);
    setTestSuites(tsData || []);
    setSearchedTestSuites(tsData || []);
    let updatedRefreshList = autoRefreshList;
    tsData?.forEach((ts: any) => {
      if (ts?.executionStatus) {
        if ([EXECUTION_RESULT.COMPLETED, EXECUTION_RESULT.FAILED].includes(ts.executionStatus)) {
          updatedRefreshList = updatedRefreshList.filter((testSuiteId: string) => testSuiteId !== ts.testSuiteId);
        } else {
          updatedRefreshList.push(ts.testSuiteId);
        }
      }
    });
    setAutoRefreshList(updatedRefreshList);
    return updatedRefreshList.length === 0;
  };

  const handleSearch = (keyword: string) => {
    setSearchedTestSuites(testSuites.filter(({ name }) => name.toLowerCase().includes(keyword.toLowerCase())));
  };

  const addToAutoRefresh = (testSuiteId: string) => {
    if (!autoRefreshList.includes(testSuiteId)) {
      setAutoRefreshList((prev: string[]) => [...prev, testSuiteId]);
    }
  };

  const handleAutoRefresh = async () => {
    await fetchData();
    if (autoRefreshIntervalRef.current) clearInterval(autoRefreshIntervalRef.current);
    const interval = setInterval(async () => {
      const flag: Boolean = await fetchData();
      if (flag) {
        clearInterval(interval);
        clearInterval(autoRefreshIntervalRef.current);
      }
    }, 10000);
    autoRefreshIntervalRef.current = interval;
  };

  const initialTestSuitsFetch = async () => {
    try {
      setLoading(true);
      await handleAutoRefresh();
    } catch (error) {
      notifyError('Failed to fetch data !');
    } finally {
      setLoading(false);
    }
  };

  const runSuite = async (selectedEDB: string[]) => {
    await runTestSuite(
      {
        accountId,
        testSuiteId: selectedTestSuite.testSuiteId,
        service: SERVICE.TEST_SUITE,
        userId,
        selectedEDB,
        isParallelRun,
      },
    );
    setSelectedTestSuite(undefined);
    addToAutoRefresh(selectedTestSuite.testSuiteId);
    handleAutoRefresh();
  };

  const openEDSModal = (testSuite: any) => {
    setSelectedTestSuite(testSuite);
  };

  useEffect(() => {
    if (selectedTestSuite) {
      setEASModal(true);
    }
  }, [selectedTestSuite]);

  useEffect(() => {
    if (accountId) {
      initialTestSuitsFetch();
    }
    return () => {
      setAutoRefreshList([]);
      clearInterval(autoRefreshIntervalRef.current);
    };
  }, []);

  return (
    <div className="fp_pg_base fp_test_exec_suit">
      <Row>
        <p className="fp_heading-2 fp_heading-2-middle">Test execution suites</p>
      </Row>
      <Row justify="end" className="fp_mrgn_tp_30">
        <Col span={12}>
          <Row>
            <Breadcrumb separator=">" className="bread-crumb-seperator">
              <Breadcrumb.Item className="breadcrumb-item">Test execution suite</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                onClick={() => setModalVisible(true)}
                data-testid="fp_new_project_btn"
                type="primary"
                className="fp_btn fp_btn-primary"
                icon={<PlusOutlined />}
              >
                Create New
              </Button>
            </Col>
            <Col>
              <Input
                prefix={<SearchOutlined />}
                className="fp_exec_suit_search"
                placeholder="Search test execution suites"
                onChange={(text) => handleSearch(text.target.value)}
                data-testid="fp_project_search"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <TestExecutionListView
        testSuites={searchedTestSuites}
        handleRefresh={fetchData}
        isLoading={isLoading}
        openEDSModal={openEDSModal}
      />
      <NewExecutionSuiteModal
        open={isModalVisible}
        close={setModalVisible}
        handleRefresh={fetchData}
      />
      <ExecutionDeviceSelectionModal
        open={isEDSModal}
        onClose={setEDSModal}
        onRun={runSuite}
        buttonName="Run"
        isTestSuite
      />
      <ExecutionApproachSelectionModal
        open={isEASModal}
        onClose={(flag: boolean) => {
          setEASModal(false);
          if (flag) {
            setEDSModal(true);
          }
        }}
        setParallelRun={setParallelRun}
      />
      <GuideModal
        open={isGuideModalOpen}
        onCancel={() => setGuideModalOpen(false)}
        guideId="guide-modal-test-suite"
        body="Here, you can effortlessly select and run multiple automation scripts related to various user journeys in bulk. Our platform enables parallel execution of scripts, significantly reducing overall execution time and enhancing efficiency."
      />
    </div>
  );
};

export default TestExecutionSuit;
