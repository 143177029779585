import { Button, Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import { Project } from '../../redux/projectSlice';
import { getFormattedDateTime } from '../../utils/commonUtils';
import { PROJECT_SERVICE_TYPE, ROUTES } from '../../constants';
import { TrashCanIcon } from '../../components';
import DeleteConfirmationModal from '../testExecutionSuit/modals/DeleteConfirmationModal';

interface ProjectListViewItemProps {
  project: Project;
  deleteProject: Function;
}

const ProjectListViewItem = ({ project, deleteProject }: ProjectListViewItemProps) => {
  const history = useHistory();
  const [serviceType, setServiceType] = useState('');
  const [deleteScript, setDeleteScript] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    switch (project.serviceType) {
      case PROJECT_SERVICE_TYPE.TESTCASE_CREATING:
        setServiceType(PROJECT_SERVICE_TYPE.TESTCASE_CREATING);
        break;
      default:
        setServiceType(PROJECT_SERVICE_TYPE.LOAD_TESTING);
        break;
    }
  }, [project.serviceType]);
  const viewTestCases = () => history.push(`${ROUTES.PROJECTS}/${project.projectId}`, { projectName: project.name, projectServiceType: serviceType });
  return (
    <div className="fp_test_exec_list_item">
      <Row justify="space-between" className="fp_list_item">
        <Col span={12} onClick={viewTestCases}><p>{project.name}</p></Col>
        {/* <Col span={6}><p>{project.type}</p></Col> */}
        {/* <Col span={6}><p>{serviceType}</p></Col> */}
        <Col span={6} onClick={viewTestCases}>
          <Row>
            <Col>
              <p>{getFormattedDateTime(project.createdAt)}</p>
            </Col>
          </Row>
        </Col>
        <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Row className="fp_test_exec_list_item_action">
            <Col>
              {deleteScript && <Button onClick={() => setModalOpen(true)} type="primary" icon={deleteScript && <TrashCanIcon />} className="fp_btn fp_btn-primary btn_delete">Delete Project</Button>}
            </Col>
            <Col>
              <p><MoreOutlined onClick={() => setDeleteScript(!deleteScript)} style={{ fontSize: '20px' }} /></p>
            </Col>
          </Row>
        </Col>
        {/* <Col span={6} className="fp_cmpnt fp_cmpnt_jsfy_cntn_flx_end">
              TODO: ADD MENU
              <MoreOutlined className="fp_list_item_more_icon" />
            </Col> */}
      </Row>
      <DeleteConfirmationModal
        title="Are you sure you want to delete this project?"
        deleteButtonText="Delete Project"
        isModalOpen={isModalOpen}
        handleCancel={() => setModalOpen(false)}
        handleDelete={() => {
          deleteProject(project?.projectId);
          setModalOpen(false);
        }}
        isLoading={false}
      />
    </div>
  );
};

export default ProjectListViewItem;
