import React from 'react';
import {
  Checkbox, Table, notification,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { SkeletonTable } from '../../../../../components';
import { SkeletonTableColumnsType } from '../../../../../components/SkeletonTable';
import { DataType } from '../../../../../types/common';
import ColumnList from './ColumnList';
import { tableColumns } from './tableColums';
import { addTestCaseRating } from '../../../action';

interface TableProps {
  tableData: any[],
  setIsChecked: Function,
  setSelectedTestcases: Function,
  handleRowClick: Function,
  form: FormInstance,
  triggerTestCaseModal: Function,
  setFormData: Function,
  setDeleteConfirmModel: Function,
  handleScriptGeneration: Function,
  setTestDataModal: Function,
  requirement: any,
  isTableDataLoading: boolean,
  triggerAutoRefresh: Function,
}

const TableView = ({
  tableData,
  setIsChecked,
  setSelectedTestcases,
  handleRowClick,
  form,
  triggerTestCaseModal,
  setFormData,
  setDeleteConfirmModel,
  handleScriptGeneration,
  setTestDataModal,
  requirement,
  isTableDataLoading,
  triggerAutoRefresh,
}: TableProps) => {
  const data: any = Object.values(tableData).map((item: any) => ({
    key: item.testcaseId,
    BDD: item.bdd,
    BDDStatus: item.bddStatus,
    AutomationScriptStatus: item.automationScriptStatus,
    AutomationScript: item.automationScript,
    TestScenario: item.scenario,
    TestCaseRate: item?.testCaseRate,
    IsTestDataAdded: item.isTestDataAdded,
    Preconditions: typeof item.preConditions === 'object' && Array.isArray(item.preConditions)
      ? <ColumnList stepList={item.preConditions} /> : item.preConditions,
    TestCaseSteps: typeof item.steps === 'object' && Array.isArray(item.steps)
      ? <ColumnList stepList={item.steps} /> : item.steps,
    ExpectedResults: Array.isArray(item.expectedOutcome)
      ? <ColumnList stepList={item.expectedOutcome} /> : item.expectedOutcome,
  }));

  const rowSelection = {
    onChange:
      (
        selectedRowKeys: React.Key[],
        selectedRows: DataType[],
        checked: any | boolean,
      ) => {
        // Check if any row is selected and the selection type is either 'single' or 'all'
        if (selectedRowKeys.length !== 0 && (checked.type === 'single' || checked.type === 'all')) {
          setIsChecked(true);
        } else {
          setIsChecked(false);
        }
        setSelectedTestcases(selectedRows);
      },
    getCheckboxProps: (record: DataType | any) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    renderCell: (
      checked: boolean,
      _record: DataType,
      index: number,
      originNode: React.ReactNode,
    ) => {
      const rowNumber = index + 1;
      return (
        <div>
          {originNode && (
            <>
              <Checkbox checked={checked} style={{ display: 'none' }} />
              {originNode}
            </>
          )}
          <span>{rowNumber.toString().padStart(2, '0')}</span>
        </div>
      );
    },
  };

  const addRating = async (
    testcaseId: string, rating: string,
  ) => {
    const { moduleId, requirementId } = requirement;
    await addTestCaseRating({
      moduleId, requirementId, testcaseId, rating,
    });
    await triggerAutoRefresh({ isDestroyNotifications: false });
    setTimeout(async () => {
      notification.destroy();
    }, 3000);
  };

  const columns = tableColumns(
    handleRowClick,
    form,
    triggerTestCaseModal,
    setFormData,
    setDeleteConfirmModel,
    handleScriptGeneration,
    setTestDataModal,
    requirement?.serviceId,
    addRating,
    requirement,
  );

  return (
    <SkeletonTable
      rowCount={requirement?.noOfTestcases || 2}
      active
      loading={isTableDataLoading}
      columns={columns as SkeletonTableColumnsType[]}
      className="fp_test_case_result_skeleton_table"
    >
      <Table
        className="fp_tst_rslt"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </SkeletonTable>
  );
};

export default TableView;
