import React from 'react';
import {
  Button,
  Checkbox,
  Modal,
  Row,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface GuideModalProps {
  open: boolean,
  onCancel: Function,
  body: any,
  guideId: string
}

const GuideModal = ({
  open, onCancel, body, guideId,
}:
  GuideModalProps) => {
  const onDoNotShowCheck = (e: CheckboxChangeEvent) => localStorage.setItem(guideId, e.target.checked ? 'ACTIVATED' : 'DISABLED');
  const doNotShow = localStorage.getItem(guideId) === 'ACTIVATED';

  return (
    <Modal
      className="fp-modal fp-modal-primary fp_guide_modal"
      closable={false}
      open={open && !doNotShow}
      width={250}
      style={{ top: 200, left: 50 }}
      onCancel={() => onCancel()}
      footer={[
        <Row className="fp_extension_instructions_modal_footer" justify="center" align="middle">
          <Checkbox onChange={onDoNotShowCheck}>Don&apos;t show this again</Checkbox>
          <Button
            type="primary"
            className="fp_btn fp_btn-primary fp_extension_btn"
            onClick={() => onCancel()}
          >
            Okay
          </Button>
        </Row>,
      ]}
    >

      <div className="form-section">
        {body}
      </div>
    </Modal>
  );
};

export default GuideModal;
