import {
  Col, Row, Input, Button, Modal, Form, notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import Loading from '../../components/Loading';
import { ROUTES } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Project, ProjectInput, ProjectType } from '../../redux/projectSlice';
import { extraTeams } from '../../redux/userSlice';
import { notifyError, notifyInProgress, notifySuccess } from '../../utils/notification';
import { createProject, deleteProject, getProjects } from './actions';
import ProjectListView from './ProjectListView';
import { sortByCreateAtTime } from '../../utils/commonUtils';

const Projects = () => {
  const userId = useAppSelector(({ userData }) => userData.userId);
  const projects: Array<Project> = useAppSelector(({ projectData }) => projectData.projects);
  const selectedAccount = useAppSelector(({ userData }) => userData.selectedAccount);
  const selectedTeam = useAppSelector(({ userData }) => userData.selectedTeam);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const history = useHistory();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [filteredProjects, setFilteredProjects] = useState<Array<Project>>(projects);
  const [searchText, setSearchText] = useState<string>('');
  const [searchedProjects, setSearchedProjects] = useState<Array<Project>>(projects);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const load = async () => {
    try {
      setLoading(true);
      await getProjects(selectedAccount?.accountId, dispatch);
      setFilteredProjects(projects);
    } catch (e) {
      notifyError('Project getting error.', 'project_get_err');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, [selectedAccount]);

  const filterNoTeamProjects = () => projects.filter(({
    sharedBy, sharedToTeamId, type, createdBy,
  }: Project) => (
    (sharedBy && !sharedToTeamId) || (type === ProjectType.USER && createdBy === userId)
  ));

  const filterTeamProjects = (selectedTeamId: string | undefined) => projects.filter(({
    teamId, sharedToTeamId,
  }: Project) => (
    (teamId && teamId === selectedTeamId) || (sharedToTeamId && sharedToTeamId === selectedTeamId)
  ));

  const searchProject = (text: string, filteredList: Array<Project>) => {
    const lowText = text.toLowerCase();
    if (text) {
      return filteredList.filter(({ name }) => name.toLowerCase().indexOf(lowText) !== -1);
    }
    return filteredList;
  };

  useEffect(() => {
    function filter() {
      switch (selectedTeam?.teamId) {
        case extraTeams[0].teamId:
          return projects;
        case extraTeams[1].teamId:
          return filterNoTeamProjects();
        default:
          return filterTeamProjects(selectedTeam?.teamId);
      }
    }
    const filterValue = filter();
    setFilteredProjects(filterValue);
  }, [selectedTeam, projects]);

  useEffect(() => {
    setSearchedProjects(sortByCreateAtTime([...searchProject(searchText, filteredProjects)]));
  }, [searchText, filteredProjects]);

  const addNewProject = async () => {
    try {
      setModalVisible(false);
      const fData = form.getFieldsValue();
      if (!fData || !fData.name || !fData.name.trim()) {
        notification.destroy();
        notifyError('Empty project names are not allowed!');
        return;
      }
      const projectName = fData.name.trim();
      const processedProjectName = projectName.replace(/ +/g, ' ');
      if (projects.find((project) => project.name === processedProjectName)) {
        notification.destroy();
        notifyError('The project name already exists!');
        return;
      }
      notifyInProgress('Creating project...', 'project_create_in_progress');
      if (form.getFieldsError().some(({ errors }) => errors.length)) {
        return;
      }
      const project: ProjectInput = {
        name: processedProjectName,
        accountId: selectedAccount?.accountId,
      };
      const projectId = await createProject(project);
      history.push(`${ROUTES.PROJECTS}/${projectId}`, { projectName: project.name });
      notification.destroy();
      notifySuccess('Project created successfully.', 'project_create_success');
    } catch (e) {
      notification.destroy();
      notifyError('Project adding error.', 'project_get_err');
    }
  };

  const handleProjectDelete = async (projectId: string) => {
    notifyInProgress('Deleting project');
    const success: boolean = await deleteProject(
      selectedAccount?.accountId as string, projectId, dispatch,
    );
    await load();
    if (success) {
      notifySuccess('Project deleted sucessfully!');
    } else {
      notifyError('Failed to delete selected project');
    }
  };

  const renderContent = isLoading ? <Loading plain size="large" /> : <ProjectListView projects={searchedProjects} deleteProject={handleProjectDelete} />;

  return (
    <div className="fp_pg_base fp_test_exec_suit">
      <Row>
        <p className="fp_heading-2 fp_heading-2-middle">Projects</p>
      </Row>
      <Row justify="space-between" className="fp_mrgn_tp_30">
        <Col span={6}>
          <Row>
            <Input
              prefix={<SearchOutlined />}
              width={372}
              className="fp_exec_suit_search"
              placeholder="Search"
              onChange={(text) => setSearchText(text.target.value)}
              data-testid="fp_project_search"
            />
          </Row>
        </Col>
        <Col span={18}>
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                data-testid="fp_new_project_btn"
                type="primary"
                className="fp_btn fp_btn-primary fp_new_project_btn"
                onClick={() => setModalVisible(true)}
                icon={<PlusOutlined />}
              >
                New Project
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {renderContent}
      <Modal
        className="fp-modal fp-modal-primary"
        open={isModalVisible}
        footer={[
          <Button onClick={() => setModalVisible(false)} data-testid="fp_new_projec_cancel_btn" className="fp_btn fp_btn-outline">Cancel</Button>,
          <Button onClick={addNewProject} data-testid="fp_new_project_add_btn" className="fp_btn fp_btn-primary">Add</Button>,
        ]}
        onCancel={() => setModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter a project name',
              },
              {
                validator(_, value) {
                  const projectName = value?.trim();
                  if (projects.find((project) => project.name === projectName)) {
                    notification.destroy();
                    notifyError('Sorry, this project name already exists. Please choose a different name');
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="Project name" className="fp_input" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default Projects;
