import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  notification,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  addTestCaseToTestSuite, createEditTestSuite, getTestSuites,
} from '../../../testExecutionSuit/action';
import { useAppSelector } from '../../../../redux/hooks';
import Loading from '../../../../components/Loading';
import {
  notifyError, notifyInProgress, notifySuccess, notifyWarning,
} from '../../../../utils/notification';

interface AddTestcaseToTestSuitModalProps {
  visible: boolean,
  onCancel: () => void,
  selectedTestcases: any[]
}

const AddTestcaseToTestSuitModal = ({ visible, onCancel, selectedTestcases }:
  AddTestcaseToTestSuitModalProps) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const accountId = useAppSelector(({ userData }: any) => userData?.selectedAccount?.accountId);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [testSuites, setTestSuites] = useState<any[]>([]);
  const [selectedTestSuites, setSelectedTestSuites] = useState<string[]>([]);
  const [newTestSuiteName, setNewTestSuiteName] = useState<string>();

  const handleClearClose = () => {
    onCancel();
    setLoading(true);
    setExpanded(false);
    setSelectedTestSuites([]);
    setNewTestSuiteName(undefined);
  };

  const addToExecutionSuite = async () => {
    notifyInProgress('Adding test cases to selected test suites...');
    const testcases = selectedTestcases.map(({ key }) => key);
    onCancel();
    const success = await addTestCaseToTestSuite(accountId, selectedTestSuites, testcases);
    notification.destroy();
    if (success) {
      notifySuccess('Test cases added successfully to selected test suites!');
    } else {
      notifyError('Failed to add selected test cases to the test suites. Please try again.');
    }
    handleClearClose();
  };

  const fetchData = async () => {
    setLoading(true);
    const tsData = await getTestSuites(accountId);
    setTestSuites(tsData || []);
    setLoading(false);
  };

  const addNewTestSuite = async () => {
    if (!newTestSuiteName || newTestSuiteName === '') return notifyWarning('Test suite name is required');
    setExpanded(!expanded);
    notifyInProgress('Creating new test suite...');
    const newTestSuite = { accountId, name: newTestSuiteName, testcases: [] };
    const success = await createEditTestSuite(newTestSuite);
    notification.destroy();
    if (success) {
      notifySuccess('Successfully created new test suite');
    } else {
      notifyError('Failed to create new test suite');
    }
    return fetchData();
  };

  const handleTestSuiteSelection = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedTestSuites((prev: string[]) => [...prev, value]);
    } else {
      setSelectedTestSuites((prev: string[]) => prev.filter((s: string) => s === value));
    }
  };

  useEffect(() => {
    if (visible) {
      fetchData();
    }
  }, [visible]);

  return (
    <Modal
      className={`fp_test_execution_modal ${expanded ? 'colored' : ''}`}
      closable={false}
      width={600}
      open={visible}
      onCancel={handleClearClose}
      afterClose={() => setExpanded(false)}
      footer={[
        <div>
          {!expanded
            ? (
              <>
                <Button onClick={handleClearClose} data-testid="fp_new_projec_cancel_btn" className="fp_btn fp_btn-outline">Discard</Button>
                <Button onClick={addToExecutionSuite} data-testid="fp_new_project_add_btn" className="fp_btn fp_btn-primary">Add to execution suite</Button>
              </>
            )
            : (
              <>
                <div className="fp_test_execution_modal_header expandable">
                  <p>
                    Create new execution suite
                  </p>
                </div>
                <Form layout="vertical">
                  <Form.Item name="testSuiteName" label="Name" rules={[{ required: true, message: 'Name is required!' }]}>
                    <Input placeholder="Enter regression suite name" className="fp_input" onChange={(e: any) => setNewTestSuiteName(e.target.value)} />
                  </Form.Item>
                </Form>
                <Button onClick={() => { setNewTestSuiteName(undefined); setExpanded(!expanded); }} data-testid="fp_new_projec_cancel_btn" className="fp_btn fp_btn-outline">Discard</Button>
                <Button onClick={addNewTestSuite} icon={<PlusOutlined />} data-testid="fp_new_projec_cancel_btn" className="fp_btn fp_btn-primary">Create new</Button>

              </>
            )}
        </div>,
      ]}
    >
      <div>
        <div className="fp_test_execution_modal_header">
          <p>
            Add testcase to execution suite
          </p>
          {!expanded && <Button icon={<PlusOutlined />} onClick={() => setExpanded(!expanded)} data-testid="fp_new_projec_cancel_btn" className="fp_btn fp_btn-outline">Create new</Button>}
        </div>
        {isLoading ? <Loading plain size="large" /> : (
          <div className="fp_test_execution_modal_content">
            {testSuites.map((testSuite: any) => (
              <Checkbox
                key={JSON.stringify(testSuite)}
                onChange={handleTestSuiteSelection}
                value={testSuite.testSuiteId}
              >
                {testSuite.name}
              </Checkbox>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddTestcaseToTestSuitModal;
