import React from 'react';
// import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import SummaryView from './SummaryView';

interface routerParams {
  projectId: string;
  testcaseId: string;
}
const ResultView = () => {
  const { projectId, testcaseId }: routerParams = useParams();

  return (
    // <div className="fp_pg_base">
    //   <Tabs defaultActiveKey="1">
    //     <Tabs.TabPane tab="Summary" key="1" className="fp_heading-2">
    <SummaryView
      projectId={projectId}
      testcaseId={testcaseId}
      isDryRunResults={false}
      setDryRunComplete={undefined}
    />
    //     </Tabs.TabPane>
    //     {/* TODO: commented timeline report section for demo version */}
    //     {/* <Tabs.TabPane tab="Timeline report" key="2">Timeline report</Tabs.TabPane> */}
    //   </Tabs>
    // </div>
  );
};
export default ResultView;
