import { PAYMENT_PLANS } from '../constants';

export type SupportInfoType = {
  accountId?: string,
  userName: string,
  userEmail: string,
  issue: string,
  fileKey: string,
};

export type PAYMENT_PLAN = keyof typeof PAYMENT_PLANS;
export type DataType = {
  key: string;
  TestCaseID: string;
  TestScenario: string;
  Preconditions: string[];
  TestCaseSteps: string[];
  ExpectedResults: string[];
  IsBDDAvailable: boolean;
  [key: string]: any;
};

export type contactUsParams = {
  accountId: string | undefined,
  description?: string,
  type: string,
  contactDetails: object,
};

export type SignUpParams = {
  firstName: string
  lastName: string
  email: string
  password: string
  inviteId?: string
  accountName?: string
  token: string
};

export const SCRIPT_TYPE = {
  BDD: 'BDD',
  AUTOMATION_SCRIPT: 'AUTOMATION_SCRIPT',
};

export const AUTOMATION_SCRIPT_TYPE = {
  SELENIUM: 'SELENIUM',
  PLAYWRIGHT: 'PLAYWRIGHT',
};

export const SCRIPT_STATUS = {
  GENERATING: 'GENERATING',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR',
  REQUIRED_TO_REGENERATE: 'REQUIRED_TO_REGENERATE',
};

export const INPUT_TYPE = {
  BDD: 'BDD',
  AUTOMATION_SCRIPT: 'AUTOMATION_SCRIPT',
  TEST_DATA: 'TEST_DATA',
};

export const SERVICE_TYPE = {
  AUTOMATION_SCRIPT_GENERATOR: 'automation_scripts_generator',
  TEST_CASE_GENERATOR: 'testcase_creating',
};

export const TEST_CASE_RATING = {
  LIKED: 'LIKED',
  DISLIKED: 'DISLIKED',
};

export const ENTRY_TYPE = {
  MANUAL: 'MANUAL',
  RECORD: 'RECORD',
};

export type Projects = {
  title: string;
  value: string;
  children: Projects[];
  checkable: boolean;
};

export const SERVICE = {
  LOAD_TESTER: 'load_tester',
  FOOT_PRINT: 'foot_print',
  TEST_SUITE: 'test_suite',
};

export type ServiceType = typeof SERVICE[keyof typeof SERVICE];

export const CTR_IMPORT_TYPE = {
  PATH: 'PATH',
  OBJECT: 'OBJECT',
};

export const SCHEDULE_STATE = {
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
};

export const PAUSE_RESUME_BUTTON = {
  PAUSE: 'Pause',
  RESUME: 'Resume',
};

export const MONITOR_STATUS = {
  IN_PROGRESS: 'In Progress',
  PAUSED: 'Paused',
  COMPLETE: 'Completed',
};

export const BROWSER_TYPE = {
  CHROMIUM: 'Chrome',
  SAFARI: 'Safari',
  FIREFOX: 'Firefox',
};

export const BROWSER = {
  Chromium: 'Desktop_Chrome',
  Safari: 'Desktop_Safari',
  Firefox: 'Desktop_Firefox',
};
