import {
  Button, Col, Row, Tooltip,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { InsightIcon } from '../../components';
import { BROWSER } from '../../types/common';

const ExecutionTimeChartComponent = (
  { executionTime, selectedEDB }:
    { executionTime: any[], selectedEDB: string[] },
) => {
  const [series, setSeries] = useState<any[]>([]);
  const options = {
    chart: {
      id: 'area-datetime',
      type: 'area',
      background: 'transparent',
      zoom: {
        autoScaleYaxis: true,
      },
    },
    colors: ['rgba(249, 65, 68, 1)', 'rgba(249, 199, 79, 1)', 'rgba(45, 156, 219, 1)'],
    annotations: {
      yaxis: [{
        y: 30,
      }],
      xaxis: [{
        type: 'datetime',
        categories: executionTime.map((item) => moment(item.executedTime).format('M/D/YY h.mm a')),
        yAxisIndex: 0,
      }],
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    markers: {
      size: 0,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      labels: {
        colors: '#fff',
      },
    },
    xaxis: {
      tickAmount: 20,
      min: new Date('2022-07-18 01:00:00'),
      labels: {
        format: 'dd MM yyyy',
        rotate: -25,
        rotateAlways: true,
        style: {
          colors: '#fff',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#fff',
        },
      },
    },
    labels: executionTime.map((item) => moment(item.executedTime).format('M/D/YY h.mm a')),
    tooltip: {
      shared: true,
      custom({
        series: _series, seriesIndex, dataPointIndex,
      }: any) {
        const selectedResult = executionTime[dataPointIndex];
        return `<div style="padding:5px;">${_series[seriesIndex][dataPointIndex]} seconds${selectedResult?.resultId ? ` (${selectedResult?.resultId})` : ''}</div>`;
      },
      x: {
        format: 'dd/MM/yy',
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
  };

  useEffect(() => {
    if (selectedEDB?.length) {
      const array: any[] = [];
      selectedEDB.forEach((browser: string) => {
        switch (browser) {
          case BROWSER.Chromium:
            array.push({
              name: 'Chrome',
              data: executionTime.map((item) => Math.round(item.Desktop_Chrome / 1000)),
              color: 'rgba(249, 65, 68, 1)',
            });
            break;
          case BROWSER.Firefox:
            array.push({
              name: 'Firefox',
              data: executionTime.map((item) => Math.round(item.Desktop_Firefox / 1000)),
              color: 'rgba(249, 199, 79, 1)',
            });
            break;
          case BROWSER.Safari:
            array.push({
              name: 'Safari',
              data: executionTime.map((item) => Math.round(item.Desktop_Safari / 1000)),
              color: 'rgba(45, 156, 219, 1)',
            });
            break;
          default:
            console.log('Unspecified browser :', browser);
            break;
        }
      });
      setSeries(array);
    }
  }, [selectedEDB]);

  return (
    <div className="chart-container">
      <Row style={{ marginTop: '20px' }}>
        <Col span={16}>
          <p className="fp_heading-2" style={{ marginLeft: '20px', marginTop: '10px' }}>Execution Time</p>
        </Col>
        <Col span={7}>
          <Row justify="end" style={{ marginTop: '10px' }}>
            <Tooltip title="Coming Soon">
              <Button className="chart-option-btn chart-option-btn-primary">
                <InsightIcon />
              </Button>
            </Tooltip>
          </Row>
        </Col>
      </Row>
      <Row justify="start" style={{ marginTop: '20px' }}>
        <Col span={1}>
          <p className="fp_medium-text-2 fp_medium-text-2-secondary chart-y-label">
            Duration (Seconds)
          </p>
        </Col>
        <Col span={23}>
          <ReactApexChart options={options} series={series} type="area" height={350} width="95%" />
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: '-20px' }}>
        <p className="fp_medium-text-2 fp_medium-text-2-secondary">Date/Time</p>
      </Row>
    </div>
  );
};

export default ExecutionTimeChartComponent;
