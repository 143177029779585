import React, { useEffect, useState } from 'react';
import Loading from '../../../components/Loading';
import { getRecorderStatus, invokeRecorder } from '../action';
import { CODEGEN_SERVER_URL } from '../../../configs/app-config';

const TestRecorder = () => {
  const [serverData, setServerData] = useState<any>();

  const fetchServerStatus = (serverId: string) => getRecorderStatus(serverId);

  const triggerRecorder = async () => {
    const res = await invokeRecorder();

    const interval = setInterval(async () => {
      const serverDataRes = await fetchServerStatus(res.serverId);
      if (serverDataRes?.taskStatus === 'RUNNING') {
        clearInterval(interval);
        setTimeout(() => {
          setServerData(serverDataRes);
        }, 10000);
      }
    }, 5000);
  };

  useEffect(() => {
    triggerRecorder();
  }, []);

  const renderView = () => {
    if (serverData?.vncPort) {
      return (
        <iframe
          src={`${CODEGEN_SERVER_URL}:${serverData.vncPort}?serverId=${serverData.serverId}`}
          title="live test execution"
          width="100%"
          height="100%"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        />
      );
    }
    return <Loading plain size="large" />;
  };

  return renderView();
};

export default TestRecorder;
