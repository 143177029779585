import {
  Modal,
  Row,
  Button,
  Col,
} from 'antd';
import React from 'react';
import { CTR_IMPORT_TYPE } from '../../../../types/common';

interface ExecutionApproachSelectionModalProps {
  open: boolean
  setCTRImportMethod: Function
}

const buttons: any[] = [
  {
    label: 'As a path',
    value: CTR_IMPORT_TYPE.PATH,
  },
  {
    label: 'As an object',
    value: CTR_IMPORT_TYPE.OBJECT,
  },
];

const CTRImportModal = ({
  open,
  setCTRImportMethod,
}: ExecutionApproachSelectionModalProps) => {
  const handleCTRImportMethod = (flag: string) => {
    setCTRImportMethod(flag);
  };

  return (
    <>
      <Modal
        className="max-user-modal fp-modal fp-modal-primary"
        width={600}
        open={open}
        title="How would you like to run your test execution suite"
        closable={false}
        footer={[]}
      >
        <Row>
          {buttons.map((item: any, index: number) => (
            <Col
              span={11}
              offset={buttons.length === (index + 1) ? 2 : 0}
              key={JSON.stringify(item)}
            >
              <Button
                onClick={() => handleCTRImportMethod(item.value)}
                className="fp-ctr-button fp-ctr-button-device-button"
              >
                {item.label}
              </Button>
            </Col>
          ))}
        </Row>
        <Row>
          <div className="aes-tooltip-container">
            Selecting &apos;path&apos; lets you reference a file located
            in &apos;Test Resources&apos;, whereas choosing &apos;object&apos;
            reads and incorporates the file&apos;s content as a string directly into your script.
          </div>
        </Row>
      </Modal>
    </>
  );
};

export default CTRImportModal;
