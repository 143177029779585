import { notification } from 'antd';
import { API } from 'aws-amplify';
import { APIS } from '../../utils/apiUtils';
import { notifyInProgress } from '../../utils/notification';

export type generateTokenBody = {
  accountId: string,
  expireInDays: number
}

export const generateToken = async (body: generateTokenBody) => {
  notifyInProgress('Generating new token', 'token_create');
  try {
    await API.post(APIS.INTEGRATION_API, '/create', { body });
    notification.destroy();
  } catch (error: any) {
    notification.destroy();
    throw new Error('Failed to create new token');
  }
};

export const getTokenData = (accountId: string) => API.get(APIS.INTEGRATION_API, '/token/get', { queryStringParameters: { accountId } });
