import React from 'react';
import { InlineWidget } from 'react-calendly';
import { CALENDLY_EVENT_URL } from '../../../constants';
import Loading from '../../../components/Loading';

const CalendlySchedule = () => (
  <>
    <InlineWidget
      styles={{
        height: '1100px',
        width: '100%',
      }}
      pageSettings={{
        backgroundColor: '222222',
        primaryColor: '00a2ff',
        textColor: 'ffffff',
      }}
      LoadingSpinner={() => <Loading plain size="large" />}
      prefill={{
        date: new Date(Date.now() + 86400000),
      }}
      url={CALENDLY_EVENT_URL}
    />
  </>
);

export default CalendlySchedule;
