import { Auth } from 'aws-amplify';

export const checkGoogleAuth = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user.signInUserSession && user.signInUserSession.idToken.payload['cognito:provider'] === 'accounts.google.com') {
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
};
