import React from 'react';
import { FormInstance, Steps } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { NEW_TESTCASE_STEPS_RECORD, TESTCASE_CREATE_VIEW_TYPE } from '../../../constants';
import SaveScript from '../manualScript/SaveScript';
import InputRecord from './InputRecord';

type SelectTestTypeProps = {
  goNext: Function;
  goBack: Function;
  current: number;
  testCaseSettings: FormInstance;
  onSaveTestCase: Function;
  onRunTestcase: Function;
  setTestCaseEdited: Function;
  exit: Function;
  setTestScriptFile: Function;
  recordText: string;
  setRecordText: Function;
  resultView: Function;
  isDryRunComplete: boolean;
  setTestcaseCreateView: Function;
  testScriptFile: RcFile|File|undefined;
  setTestDataFile: Function;
}

const { Step } = Steps;

const RecordFlowView = ({
  goNext, goBack, current,
  testCaseSettings, onSaveTestCase, exit, setTestScriptFile, setRecordText, recordText,
  resultView,
  setTestCaseEdited,
  onRunTestcase,
  isDryRunComplete,
  setTestcaseCreateView,
  testScriptFile,
  setTestDataFile,
}: SelectTestTypeProps) => {
  const SelectStepView = () => {
    switch (current) {
      case NEW_TESTCASE_STEPS_RECORD.INPUT_RECORD:
        return (
          <InputRecord
            goBack={() => setTestcaseCreateView(TESTCASE_CREATE_VIEW_TYPE.INIT)}
            setTestScriptFile={setTestScriptFile}
            goNext={goNext}
            setRecordText={setRecordText}
            recordText={recordText}
            setTestDataFile={setTestDataFile}
          />
        );

      case NEW_TESTCASE_STEPS_RECORD.SAVE:
        return (
          <SaveScript
            testCaseSettings={testCaseSettings}
            goBack={goBack}
            onSaveTestCase={onSaveTestCase}
            onRunTestcase={onRunTestcase}
            setTestCaseEdited={setTestCaseEdited}
            exit={exit}
            resultView={resultView}
            isDryRunComplete={isDryRunComplete}
            setTestScriptFile={setTestScriptFile}
            testScriptFile={testScriptFile}
          />
        );

      default:
        return <div>empty</div>;
    }
  };
  return (
    <div>
      <div>
        <Steps
          type="navigation"
          size="default"
          responsive
          current={current}
        >
          <Step title="Record a script" />
          <Step title="Setting up thresholds &amp; run test" />
        </Steps>
      </div>
      {SelectStepView()}
    </div>
  );
};

export default RecordFlowView;
