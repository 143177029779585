import { API } from 'aws-amplify';
import { APIS } from '../../utils/apiUtils';
import { notifyError } from '../../utils/notification';

export const getAllMonitorJobs = async (accountId: string) => {
  try {
    const response = await API.get(APIS.MONITOR_API, '/get-all-monitor-jobs', { queryStringParameters: { accountId } });
    return response;
  } catch (error: any) {
    console.log('getAllMonitorJobs function failed:', error.message);
    return [];
  }
};

export const getMonitorJob = async (
  shareId: string | undefined,
  accountId: string, monitorId: string, prevLastEvaluatedKey?: string,
  isGuestUrl: boolean | undefined = false,
  keyword?: string | undefined,
) => {
  try {
    const response = await API.get(APIS.MONITOR_API, shareId ? '/get-shared-monitor-job' : '/get-monitor-job', {
      queryStringParameters: {
        shareId, accountId, monitorId, prevLastEvaluatedKey, keyword, isGuestUrl,
      },
    });
    return response;
  } catch (error: any) {
    console.log('getMonitorJob function failed:', error.message);
    notifyError('Failed to fetch monitor job data.');
    return undefined;
  }
};

export const updateMonitorJob = (queryStringParameters: any, body: any) => API.post(APIS.MONITOR_API, '/update-monitor', { queryStringParameters, body });

export const editMonitorJob = (queryStringParameters: any, body: any) => API.post(APIS.MONITOR_API, '/update-monitor-details', { queryStringParameters, body });

export const disableMonitor = (queryStringParameters: any) => API.get(APIS.MONITOR_API, '/disable-monitor', { queryStringParameters });

export const deleteMonitorFunc = (queryStringParameters: any) => API.get(APIS.MONITOR_API, '/delete-monitor', { queryStringParameters });

export const deleteMonitorTestRun = (queryStringParameters: any) => API.get(APIS.MONITOR_API, '/test-run-delete', { queryStringParameters });

export const authenticateGuest = (queryStringParameters: any) => API.get(APIS.MONITOR_API, '/guest/get-guest-token', { queryStringParameters });

export const generateGuestUrl = (queryStringParameters: any) => API.get(APIS.MONITOR_API, '/generate-shared-link', { queryStringParameters });
