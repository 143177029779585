import React, { useEffect, useState } from 'react';
import {
  Button, Modal,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { generateFile } from '../../../utils/generateTestScript';
import { TextEditor } from '../../../components';

type TypeSelectProps = {
  visible: boolean,
  onClose: Function,
  setTestScriptFile: Function;
  testScriptFile: RcFile|File|undefined;
  setTestCaseEdited: Function;
};

const EditTestCaseModal = ({
  visible,
  onClose,
  setTestScriptFile,
  testScriptFile,
  setTestCaseEdited,
}: TypeSelectProps) => {
  const [testCase, setTestCase] = useState<string>('');
  const [finalTestCase, setFinalTestCase] = useState<string>('');

  const createScriptAndGoNextStep = () => {
    if (testCase) {
      const file = generateFile(testCase, 'text/javascript', 'loadtesterScript.spec.js');
      setTestScriptFile(file);
      onClose();
    }
  };

  const handleOnClose = () => {
    setTestCase(finalTestCase);
    setTestCaseEdited(false);
    onClose();
  };

  const handleOnChange = (e: any) => {
    setTestCase(e.target.value);
    setTestCaseEdited(true);
  };

  useEffect(() => {
    const file = testScriptFile;
    const read = new FileReader();

    if (!file) return;
    read.readAsBinaryString(testScriptFile);

    read.onloadend = () => {
      setTestCase(read?.result as string || '');
      setFinalTestCase(read?.result as string || '');
    };
  }, [testScriptFile]);

  return (
    <Modal
      className="max-user-modal fp-modal-edit-test-case fp-modal-primary"
      open={visible}
      title="Edit Test Case"
      width={1000}
      closable={false}
      footer={[
        <Button type="primary" className="fp_btn fp_btn-primary" onClick={() => handleOnClose()}>Cancel</Button>,
        <Button type="primary" className="fp_btn fp_btn-outline" onClick={() => createScriptAndGoNextStep()}>Save</Button>,
      ]}
    >
      <TextEditor
        value={testCase}
        language="javascript"
        placeholder="Please enter JS code."
        onChange={handleOnChange}
        padding={15}
        style={{
          height: '65vh',
        }}
      />
    </Modal>
  );
};

export default EditTestCaseModal;
