import React from 'react';
import { Card } from 'antd';
import { Service } from '../../redux/serviceSlice';

type CardWrapperProps = {
  item: Service;
  view: () => void;
  icon: React.ReactNode;
  setHoveredService: Function;
}

const CardWrapper = ({
  item,
  view,
  icon,
  setHoveredService,
}: CardWrapperProps) => (
  <div className="card_container">
    <Card onMouseEnter={() => setHoveredService(item)} onMouseLeave={() => setHoveredService(undefined)} className="card_content cards__card card" onClick={() => !item.isDisabled && view()}>
      <div className="content_container">
        <div className="icon_container">
          {icon}
        </div>
        <div className="details_container">
          <p className="content_tittle">{item.serviceName}</p>
        </div>
      </div>
    </Card>
  </div>
);

export default CardWrapper;
