import React, {
  useEffect, useState, useCallback,
} from 'react';
import {
  CaretRightOutlined,
  ClockCircleOutlined,
  DeleteFilled,
  MoreOutlined,
  PauseCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  Row, Col, Button, notification, Skeleton, Dropdown, Menu, Tooltip, Tabs, Breadcrumb,
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { getTestCaseResults, getTestCaseSummary, runTestCase } from '../action';
import { EXECUTION_RESULT, ROUTES } from '../../../constants';
import { Project } from '../../../redux/projectSlice';
import { TestCase } from '../../../redux/testCaseSlice';
import Loading from '../../../components/Loading';
import { deleteTestCases } from '../../projects/actions';
import {
  notifyInProgress, notifyError, notifySuccess, notifyConfirmation,
} from '../../../utils/notification';
import ExecutionDrawer from './components/ExecutionDrawer';
import ActiveError from './components/ActiveError';
import LineBar from './components/LineBar';
import Recordings from './components/Recordings';
import ThresholdModal from './components/ThresholdModal';
import ReRunModal from './components/ReRunModal';
import StatusView from './components/StatusView';

interface SummaryViewProps {
  projectId: string
  testcaseId: string
  isDryRunResults: boolean
  setDryRunComplete: any
  isChart: boolean
}

const SummaryView = ({
  projectId,
  testcaseId,
  isDryRunResults = false,
  setDryRunComplete = undefined,
  isChart = true,
}: SummaryViewProps) => {
  const [selectedExecution, setSelectedExecution] = useState<any>({
    status: EXECUTION_RESULT.RUNNING,
  });
  const [summaryData, setSummaryData] = useState<any>({});
  const [showThresholds, setThresholds] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [viewExecutions, setViewExecutions] = useState<boolean>(false);
  const [executionSynchronizer, setExecutionSynchronizer] = useState<any>(null);
  const [viewMaxUserRecordings, setViewMaxUserRecordings] = useState<boolean>(false);
  const [showReRunModal, setReRunModal] = useState<boolean>(false);

  const selectedAccount = useAppSelector(
    ({ userData }) => userData.selectedAccount,
  );
  const userId = useAppSelector(({ userData }) => userData.userId);
  const testCases: Array<TestCase> = useAppSelector(
    ({ testCaseData }) => testCaseData.testCases,
  );
  const projects: Array<Project> = useAppSelector(
    ({ projectData }) => projectData.projects,
  );

  const sortByDate = (array: Array<any>) => array.sort((a, b) => {
    const aDate = new Date(a.placedAt);
    const bDate = new Date(b.placedAt);
    return bDate.getTime() - aDate.getTime();
  });

  const getDuration = () => {
    const startTime = summaryData?.execution?.placedAt;
    const endTime = selectedExecution?.executionEndTime;
    if (!startTime || !endTime) return '';
    return moment
      .duration(moment(endTime).diff(moment(startTime)))
      .humanize();
  };

  const syncExecutionStatus = async (
    executionId: string,
    reRun: boolean = false,
  ) => {
    const synchronize = async () => {
      const results = await getTestCaseResults(
        selectedAccount?.accountId,
        testcaseId,
        executionId,
      );
      setSelectedExecution(results);
      return results.status;
    };

    if (!reRun) await synchronize();
    const executionStatusSynchronizer = setInterval(async () => {
      const status = await synchronize();
      if (
        status === EXECUTION_RESULT.PASSED
        || status === EXECUTION_RESULT.FAILED
      ) {
        clearInterval(executionStatusSynchronizer);
        if (isDryRunResults) setDryRunComplete(true);
      }
      notification.destroy();
    }, 10000);
    setExecutionSynchronizer(executionStatusSynchronizer);
  };

  const getTestCaseSummaryData = async () => {
    const data = await getTestCaseSummary(
      selectedAccount?.accountId,
      projectId,
      testcaseId,
    );
    if (!data?.executions?.length) {
      setSummaryData(data);
      setSelectedExecution({ status: EXECUTION_RESULT.SAVED });
      throw new Error('No executions found');
    }
    return data;
  };

  const load = async (
    reRun: boolean = false,
    eEId: string | undefined = undefined,
  ) => {
    try {
      setLoading(!reRun);
      const data: any = await getTestCaseSummaryData();
      const executions: any[] = sortByDate(data?.executions);
      let execution = executions[0];
      if (eEId) {
        execution = executions.find(
          ({ executionId }: any) => executionId === eEId,
        );
      }
      execution.index = executions.length - executions.indexOf(execution);
      setSummaryData({ ...data, execution, executions });
      await syncExecutionStatus(execution.executionId, reRun);
      setLoading(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setLoading(false);
    }
  };

  const dispatch = useAppDispatch();
  const history = useHistory();
  const deleteTestCase = useCallback(async () => {
    try {
      await deleteTestCases(selectedAccount?.accountId || '', projectId, testcaseId, dispatch);
      notifySuccess('Test Case Deleted successfully', 'delete_testcase_success');
      history.push(`${ROUTES.PROJECTS}/${projectId}`);
    } catch (error) {
      notifyError('Delete Test Case getting error', 'delete_testcase_get_err');
    }
  }, [selectedAccount, projectId, testcaseId, dispatch, history]);

  const confirmCall = () => {
    notifyConfirmation(
      'Are you sure you want to delete this Test Case?',
      'Delete',
      () => {
        deleteTestCase();
      },
      'Cancel',
      () => {
        // Perform cancel action here
      },
      'delete_item_key',
      0,
    );
  };
  const triggerReRun = async () => {
    notifyInProgress(
      summaryData?.executionIndex > 0
        ? 'Re-running the test case...'
        : 'Running the test case...',
      're-run',
    );
    await runTestCase({
      projectId,
      testcaseId,
      userId,
      isDryRun: false,
    });
    await load(true);
  };

  const switchExecution = async (executionId: string) => {
    clearInterval(executionSynchronizer);
    await load(false, executionId);
    setViewExecutions(false);
  };

  useEffect(() => {
    if (selectedAccount && projectId && testcaseId) {
      load(false);
    }
    return () => clearInterval(executionSynchronizer);
  }, [selectedAccount, projectId, testcaseId, projects, testCases]);

  const dropDownMenu = () => (
    <Menu
      className="fp-drop-down-menu"
      items={[
        {
          key: '1',
          label: (
            <Button
              className="fp_btn fp_btn-link"
              onClick={() => selectedExecution?.maxUsers >= 0 && setViewMaxUserRecordings(true)}
            >
              View Recordings
            </Button>
          ),
        },
      ]}
    />
  );

  if (isLoading) return <Loading plain size="large" />;

  return (
    <div className="fp_pg_base">
      <Row>
        <Breadcrumb separator=">" className="bread-crumb-seperator">
          <Breadcrumb.Item href="/projects" className="breadcrumb-item">
            Projects
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/projects/${projectId}`} className="breadcrumb-item">
            {`${summaryData?.project?.name || 'N/A'} `}
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/projects/${projectId}/${testcaseId}`} className="breadcrumb-item">
            {`${summaryData?.testCase?.name || 'N/A'} `}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        <Col span={6}>
          <p className={isDryRunResults ? '' : 'fp_status-title-name'}>
            {`${summaryData?.testCase?.name || 'N/A'} - Execution ${summaryData?.execution?.index || 0} `}
          </p>
        </Col>
        <Col span={10} />
        <Col span={8}>
          <Row>
            <Col style={{ padding: 5 }}>
              <Button
                className="fp_btn fp_btn-outline fp_navigate_btn"
                onClick={() => setThresholds(true)}
              >
                View Thresholds
              </Button>
            </Col>
            <Col style={{ padding: 5 }}>
              <Button
                type="primary"
                className="fp_btn fp_btn-primary fp_navigate_btn"
                onClick={() => setViewExecutions(true)}
              >
                Execution List
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Summary" key="1" className="fp_heading-2">
          <div>
            <div className="test-status-square">
              <Row justify="space-between">
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <Row
                    gutter={[16, 16]}
                  >
                    <Col
                      className="fp_status-tile"
                      xxl={{ span: 5 }}
                      xl={{ span: 7 }}
                      lg={{ span: 7 }}
                      md={{ span: 10 }}
                      sm={{ span: 24 }}
                    >
                      <div className="fp_testcase_test_status margin_top_bottom_20">
                        <StatusView status={selectedExecution.status} />
                      </div>
                    </Col>
                    <Col
                      className="fp_status-tile"
                      xl={{ span: 6 }}
                      lg={{ span: 7 }}
                      md={{ span: 15 }}
                      sm={{ span: 24 }}
                    >
                      <Row>
                        <Col>
                          {selectedExecution?.executionEndTime
                            && summaryData?.execution?.placedAt ? (
                              <Row className="fp_testcase_summary_info">
                                <ClockCircleOutlined style={{ fontSize: 15 }} />
                                {`Duration ${getDuration()}`}
                              </Row>
                            ) : (
                              <Row className="fp_testcase_summary_info">
                                <Skeleton.Input
                                  size="small"
                                  active={selectedExecution.status === EXECUTION_RESULT.RUNNING}
                                />
                              </Row>
                            )}
                          {summaryData?.execution?.placedAt ? (
                            <Row className="fp_testcase_summary_info">
                              <CaretRightOutlined style={{ fontSize: 15 }} />
                              {`Start ${moment(
                                summaryData.execution.placedAt,
                              ).format('lll')}`}
                            </Row>
                          ) : (
                            <Row className="fp_testcase_summary_info">
                              <Skeleton.Input
                                size="small"
                                active={selectedExecution.status === EXECUTION_RESULT.RUNNING}
                              />
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      className="fp_status-tile"
                      xl={{ span: isDryRunResults ? 14 : 6 }}
                      lg={{ span: 6 }}
                      md={{ span: 15 }}
                      sm={{ span: 24 }}
                    >
                      {selectedExecution?.executionEndTime ? (
                        <Row className="fp_testcase_summary_info">
                          <PauseCircleOutlined style={{ fontSize: 15 }} />
                          {`End ${moment(
                            selectedExecution?.executionEndTime,
                          ).format('lll')}`}
                        </Row>
                      ) : (
                        <Row className="fp_testcase_summary_info">
                          <Skeleton.Input
                            size="small"
                            active={selectedExecution.status === EXECUTION_RESULT.RUNNING}
                          />
                        </Row>
                      )}
                    </Col>
                    {!isDryRunResults && (
                      <Col
                        className="fp_status-tile"
                        xl={{ span: 4 }}
                        lg={{ span: 5 }}
                        md={{ span: 9 }}
                        sm={{ span: 24 }}
                      >
                        <Button
                          onClick={() => setReRunModal(true)}
                          icon={selectedExecution.status
                            !== EXECUTION_RESULT.SAVED && (
                              <ReloadOutlined
                                style={{ fontSize: 15 }}
                              />
                          )}
                          className="fp_btn fp_btn-outline fp_navigate_btn"
                        >
                          {selectedExecution.status === EXECUTION_RESULT.SAVED ? 'Run' : 'Rerun'}
                        </Button>
                      </Col>
                    )}
                    <Col
                      xl={{ span: 1 }}
                      lg={{ span: 1 }}
                      md={{ span: 1 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="fp_status-tile-right"
                    >
                      <Dropdown overlay={dropDownMenu}>
                        <Button className="fp-drop-down-button">
                          <MoreOutlined
                            className="fp_list_item_more_icon"
                          />
                        </Button>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
                {/* {!isDryRunResults && (
                )} */}
              </Row>
            </div>
            <Row
              justify="space-between"
              className="fp_testcase_summary_tile_container"
              gutter={[0, 32]}
            >
              <Col
                className="fp_testcase_summary_tile"
                id="fp_testcase_summary_max_user"
                xl={{ span: 4 }}
                lg={{ span: 5 }}
                md={{ span: 7 }}
                sm={{ span: 10 }}
                xs={{ span: 24 }}
              >
                {selectedExecution?.maxUsers >= 0 ? (
                  <>
                    <Row className="fp_testcase_summary_tile_value_container">

                      <Col className={`fp_testcase_summary_tile_value ${selectedExecution.percentileValue
                        <= summaryData?.testCase?.thresholds?.expectedTestSuccessPercentile ? 'green' : 'red'}`}
                      >
                        {(selectedExecution?.percentileValue)?.toFixed(1) || 'N/A'}
                      </Col>
                      <Col className={`fp_testcase_summary_tile_unit ${selectedExecution.percentileValue
                        <= summaryData?.testCase?.thresholds?.expectedTestSuccessPercentile ? 'green' : 'red'}`}
                      >
                        S
                      </Col>
                    </Row>
                    <Col className="fp_testcase_summary_tile_title">
                      <Tooltip trigger="hover" title={`90% of the time system responds in ${(selectedExecution?.percentileValue)?.toFixed(1) || 'N/A'} seconds.`}>
                        90th Percentile
                      </Tooltip>
                    </Col>
                  </>
                ) : (
                  <Skeleton
                    active={selectedExecution.status === EXECUTION_RESULT.RUNNING}
                  />
                )}
              </Col>
              <Col
                className="fp_testcase_summary_tile"
                xl={{ span: 4 }}
                lg={{ span: 5 }}
                md={{ span: 7 }}
                sm={{ span: 10 }}
                xs={{ span: 24 }}
              >
                {selectedExecution?.maxUsers >= 0 ? (
                  <>
                    <Row className="fp_testcase_summary_tile_value_container">
                      <Col className={`fp_testcase_summary_tile_value ${selectedExecution.averageResponseTime
                        <= summaryData?.testCase?.thresholds?.expectedMeanResponseTime ? 'green' : 'red'}`}
                      >
                        {(selectedExecution.averageResponseTime && selectedExecution.averageResponseTime.toFixed(1)) || 'N/A'}
                      </Col>
                      <Col className={`fp_testcase_summary_tile_unit ${selectedExecution.averageResponseTime
                        <= summaryData?.testCase?.thresholds?.expectedMeanResponseTime ? 'green' : 'red'}`}
                      >
                        S
                      </Col>
                    </Row>
                    <Col className="fp_testcase_summary_tile_title">
                      <Tooltip trigger="hover" title="The average time the server takes to return the results of a request to the user.">
                        Avg. response time
                      </Tooltip>
                    </Col>
                  </>
                ) : (
                  <Skeleton
                    active={selectedExecution.status === EXECUTION_RESULT.RUNNING}
                  />
                )}
              </Col>
              <Col
                className="fp_testcase_summary_tile"
                xl={{ span: 4 }}
                lg={{ span: 5 }}
                md={{ span: 7 }}
                sm={{ span: 10 }}
                xs={{ span: 24 }}
              >
                {selectedExecution?.maxUsers >= 0 ? (
                  <>
                    <Row className="fp_testcase_summary_tile_value_container">
                      <Col className="fp_testcase_summary_tile_value blue">
                        {(selectedExecution.averageThroughput || 0).toFixed(1) || 'N/A'}
                      </Col>
                      <Col className="fp_testcase_summary_tile_unit blue">Hit/s</Col>
                    </Row>
                    <Col className="fp_testcase_summary_tile_title">
                      Avg. Throughput
                    </Col>
                  </>
                ) : (
                  <Skeleton
                    active={selectedExecution.status === EXECUTION_RESULT.RUNNING}
                  />
                )}
              </Col>
              <Col
                className="fp_testcase_summary_tile"
                xl={{ span: 4 }}
                lg={{ span: 5 }}
                md={{ span: 7 }}
                sm={{ span: 10 }}
                xs={{ span: 24 }}
              >
                {selectedExecution?.maxUsers >= 0 ? (
                  <>
                    <Row className="fp_testcase_summary_tile_value_container">
                      <Col className="fp_testcase_summary_tile_value yellow">
                        {selectedExecution?.maxUsers || '0'}
                      </Col>
                    </Row>
                    <Col className="fp_testcase_summary_tile_title">
                      Concurrent Users
                    </Col>
                  </>
                ) : (
                  <Skeleton
                    active={selectedExecution.status === EXECUTION_RESULT.RUNNING}
                  />
                )}
              </Col>
              <Col
                className="fp_testcase_summary_tile"
                xl={{ span: 4 }}
                lg={{ span: 5 }}
                md={{ span: 7 }}
                sm={{ span: 10 }}
                xs={{ span: 24 }}
              >
                {selectedExecution?.errors >= 0 ? (
                  <>
                    <Row className="fp_testcase_summary_tile_value_container">
                      <Col className="fp_testcase_summary_tile_value red">
                        {selectedExecution?.errors}
                      </Col>
                    </Row>
                    <Col className="fp_testcase_summary_tile_title">
                      Errors
                    </Col>
                  </>
                ) : (
                  <Skeleton
                    active={selectedExecution.status === EXECUTION_RESULT.RUNNING}
                  />
                )}
              </Col>
            </Row>
            {isChart
              && (
                <Row className="test-status-square fp_testcase_summary_tile_container">
                  <Col span={24}>
                    {
                      selectedExecution?.errorUserVary
                      && <ActiveError errorUserVary={selectedExecution?.errorUserVary} />
                    }
                  </Col>
                </Row>
              )}
            {isChart
              && (
                <Row className="test-status-square fp_testcase_summary_tile_container">
                  <Col span={24}>
                    {selectedExecution?.avgRespTimes
                      && <LineBar avgRespTimes={selectedExecution.avgRespTimes} />}
                  </Col>
                </Row>
              )}
            {/* <Row className="test-status-square fp_testcase_summary_tile_container">
              <Col span={24}>
                <PerformanceGraph />
              </Col>
            </Row> */}
            <ReRunModal
              open={showReRunModal}
              onClose={setReRunModal}
              testCase={summaryData.testCase}
              triggerReRun={triggerReRun}
            />
            <ThresholdModal
              testCase={summaryData?.testCase || {}}
              showThresholds={showThresholds}
              setThresholds={setThresholds}
              reload={load}
            />
            <ExecutionDrawer
              executions={summaryData.executions}
              open={viewExecutions}
              onSelect={switchExecution}
              onClose={() => setViewExecutions(false)}
            />
            <Recordings
              open={viewMaxUserRecordings}
              onClose={() => setViewMaxUserRecordings(false)}
              usersList={selectedExecution?.usersList}
              projectId={projectId}
              testcaseId={testcaseId}
              accountId={selectedAccount?.accountId}
              executionId={summaryData?.execution?.executionId}
            />
            <Row>
              <Col
                xl={{ span: 4 }}
                lg={{ span: 5 }}
                md={{ span: 7 }}
                sm={{ span: 10 }}
                xs={{ span: 24 }}
              >
                <Button
                  className="fp_btn fp_btn-link"
                  onClick={() => confirmCall()}
                >
                  <DeleteFilled />
                  Delete Test Case
                </Button>
              </Col>
            </Row>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Timeline Report" key="2" className="fp_heading-2">
          Timeline Report
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
export default SummaryView;
