import { API } from 'aws-amplify';
import { AccountType, setAccounts, setSelectedAccount } from '../../redux/userSlice';
import { AppDispatch } from '../../redux/store';
import { APIS } from '../../utils/apiUtils';
import { setServiceData, setServiceList } from '../../redux/serviceSlice';

export const getUserAccounts = async (userId: string | undefined, dispatch: AppDispatch) => {
  const { accounts } = await API.get(APIS.USER_API, '/accounts-and-teams', {});
  const selectedAccount = accounts.find((acc: AccountType) => acc.createdBy === userId);
  dispatch(setAccounts(accounts));
  dispatch(setSelectedAccount(selectedAccount));
};

export const getServicesList = async (accountId: string | undefined, dispatch: AppDispatch) => {
  const { serviceList, serviceData } = await API.get(APIS.USER_API, '/services', { queryStringParameters: { accountId } });
  dispatch(setServiceList(serviceList));
  dispatch(setServiceData(serviceData));
};
