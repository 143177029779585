import { Button, Modal } from 'antd';
import React from 'react';
import { DataType } from '../../../../../types/common';
import ColumnList from '../components/ColumnList';

interface RowDataProps {
  rowData: DataType,
  indexValue: number,
  visible: boolean,
  onCancel: () => void,
}

const TestCaseModal = ({
  rowData, indexValue, visible, onCancel,
}: RowDataProps) => (
  <Modal
    className="fp-modal fp-modal-primary fp-modal-testcase"
    open={visible}
    onCancel={onCancel}
    footer={[
      <Button
        type="primary"
        className="fp_btn fp_btn-outline"
        onClick={onCancel}
      >
        Cancel
      </Button>,
    ]}
  >
    <p className="fp_status-title-name">
      Test Scenario Preview
    </p>
    <div className="fp_tstcs_mdl">
      <p>
        Test Case ID
      </p>
      <p>{`Testcase ${indexValue + 1}`}</p>
      <p>
        Test Scenario
      </p>
      <p>{rowData[0]?.TestScenario}</p>
      <p>
        Preconditions
      </p>
      <div>
        <ColumnList stepList={rowData[0]?.Preconditions?.props?.stepList} />
      </div>
      <p>
        Steps
      </p>
      <div>
        <ColumnList stepList={rowData[0]?.TestCaseSteps?.props?.stepList} />
      </div>
      <p>
        Expected Outcome
      </p>
      <p>
        <div>
          <ColumnList stepList={rowData[0]?.ExpectedResults?.props?.stepList} />
        </div>
      </p>
    </div>
  </Modal>
);

export default TestCaseModal;
