import React from 'react';
import {
  LineChart,
  ResponsiveContainer,
  Line,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';

type Props = {
  errorUserVary: string
}

const ActiveError = ({ errorUserVary }: Props) => {
  const errorUserVaryData = JSON.parse(errorUserVary || '[]');

  return (
    <>
      <p style={{ color: 'white' }}>Number of users vs total errors with time</p>
      <ResponsiveContainer width="100%" height={600} min-width={300}>
        <LineChart
          margin={{
            top: 30, right: 20, left: 20, bottom: 30,
          }}
          data={errorUserVaryData}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={{ fill: '#fff' }}
            label={{
              value: 'Time per resource (s)', fill: 'white', position: 'insideBottom', offset: -20,
            }}
          />
          <YAxis
            yAxisId="left-axis"
            tick={{ fill: '#fff' }}
            label={{
              value: 'Total errors', fill: 'white', position: 'left', angle: -90,
            }}
          />
          <YAxis
            yAxisId="right-axis"
            orientation="right"
            tick={{ fill: '#fff' }}
            label={{
              value: 'Total users', fill: 'white', position: 'right', angle: -270,
            }}
          />
          <Tooltip wrapperClassName="fp_graph_tooltip" itemStyle={{ color: 'white' }} cursor={false} />
          <Legend iconType="square" verticalAlign="top" height={36} />
          <Line
            dot={{ stroke: 'red', strokeWidth: 3 }}
            activeDot={{ stroke: 'red', strokeWidth: 3, r: 8 }}
            name="Total errors"
            strokeWidth="5"
            yAxisId="left-axis"
            dataKey="errors"
            stroke="#d7d23e"
          />
          <Line
            dot={{ stroke: 'red', strokeWidth: 3 }}
            activeDot={{ stroke: 'red', strokeWidth: 3, r: 8 }}
            strokeWidth="5"
            name="Total users"
            yAxisId="right-axis"
            dataKey="users"
            stroke="#fcfcfc"
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default ActiveError;
